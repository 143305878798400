<app-modal-v2>
  <div class="modal-s new-user-modal">
    <app-modal-content>
      <div class="modal-body">
        <p class="title-s sub-headline">
          {{ 'appointment.viewing_confirmed_l' | translate }}
        </p>
        <p class="title-m">
          <img src="/assets/images/icons/icon-checkbox-green.svg" />
          {{ 'appointment.viewing_guaranteed_l' | translate }}
        </p>
        <p class="default-s confirmation-dialog__message">
          {{ 'appointment.viewing_confirmed_description_l' | translate }}
        </p>
        @if (showSchufaLink) {
          <a
            class="mb15 d-block"
            target="_self"
            (click)="dismiss()"
            routerLink="/service/schufa/personalInformation"
            >{{ 'appointment.get_schufa_now_l' | translate }}!</a
          >
        }
        <p class="default-s confirmation-dialog__message">
          {{ 'appointment.download_calendar_file_question' | translate }}
        </p>
      </div>
    </app-modal-content>
    <app-modal-footer>
      <div class="modal-footer confirmation-dialog__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">{{
          'general.no_l' | translate
        }}</app-button>
        <app-button [type]="'primary'" (clickEvent)="close()">{{
          'general.yes_l' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
