<div
  class="tab-wrapper position-relative"
  [ngClass]="{
    'tab-navigation--fade-left': leftFade,
    'tab-navigation--fade-right': rightFade
  }"
>
  <div class="start"></div>
  <div
    class="tab-navigation"
    #tabNavigation
    (scroll)="onHorizontalScroll()"
    (wheel)="onWheelScroll($event)"
  >
    @for (item of navigationItems; track item.label; let i = $index) {
      <a
        class="tab-navigation__link"
        routerLink="{{ item.link }}"
        routerLinkActive="active"
        (click)="onClick(item.link, i)"
      >
        {{ item.label | translate }}
      </a>
    }
  </div>
  <div class="end"></div>
</div>
