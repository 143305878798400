import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePipe } from '@angular/common';

// Since KeyValuePipe orders the object
// by properties name when no compareFn
// is given, by passing a compareFn (() => 0)
// it maintains the order of input Object keys

@Pipe({
  name: 'appKeyValueMaintainOrder',
  standalone: true
})
export class KeyValueMaintainMapOrderPipe
  extends KeyValuePipe
  implements PipeTransform
{
  transform(value: unknown): any {
    return super.transform(value, () => 0);
  }
}
