import { createSelector } from '@ngrx/store';
import { getSearchProfilesState as state } from '../reducers';
import * as fromReducer from './search-profiles.reducers';

export const getSearchProfiles = createSelector(
  state,
  fromReducer.getSearchProfiles
);
export const getMemberSearchProfiles = createSelector(
  state,
  fromReducer.getMemberSearchProfiles
);
export const getSearchProfile = createSelector(
  getSearchProfiles,
  data => data && data[0]
);

export const getSearchProfilesActionState = createSelector(
  state,
  fromReducer.getLoadSearchProfilesActionState
);
export const getSaveSearchProfileActionState = createSelector(
  state,
  fromReducer.getSaveSearchProfileActionState
);
export const getDeleteSearchProfileActionState = createSelector(
  state,
  fromReducer.getDeleteSearchProfileActionState
);
export const getDeleteAllSearchProfilesActionState = createSelector(
  state,
  fromReducer.getDeleteAllSearchProfilesActionState
);
export const getDeactivateAllSearchProfilesActionState = createSelector(
  state,
  fromReducer.getDeactivateAllSearchProfilesActionState
);
export const getActivateAllSearchProfilesActionState = createSelector(
  state,
  fromReducer.getActivateAllSearchProfilesActionState
);

export const getSearchProfileProjectRegistrationProject = createSelector(
  state,
  fromReducer.getSearchProfileProjectRegistrationProject
);

export const getSearchProfileProjectRegistrationActionState = createSelector(
  state,
  fromReducer.getSearchProfileProjectRegistrationActionState
);
