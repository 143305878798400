import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseState } from 'libs/infrastructure/base-state';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { first } from 'rxjs';
import { fetchPaymentBranding, getPaymentBranding } from 'tenant-pool/+state';

@Injectable()
export class PaymentsService {
  private store = inject<Store<BaseState>>(Store);
  private themeService = inject(ThemeService);

  public applyBranding(token: string): void {
    this.store.dispatch(fetchPaymentBranding({ token }));
    this.store
      .select(getPaymentBranding)
      .pipe(first(branding => !!branding))
      .subscribe(branding => this.themeService.createTheme(branding));
  }
}
