import { Pipe, PipeTransform, inject } from '@angular/core';
import { ConstantsTypeTranslatorService } from 'libs/services';

@Pipe({
  name: 'appConstantsTypePipe',
  standalone: true
})
export class ConstantsTypePipe implements PipeTransform {
  private constantsTypeService = inject(ConstantsTypeTranslatorService);

  transform(value: any, types?: any): any {
    return this.constantsTypeService.getTranslation(value, types);
  }
}
