import { Directive, Input, TemplateRef, inject } from '@angular/core';

@Directive({
  selector: '[appTableCell]',
  standalone: true
})
export class DataTableCellDirective {
  template = inject<TemplateRef<HTMLDivElement>>(TemplateRef);

  @Input('appTableCell') name: string;
}
