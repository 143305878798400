import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-schufa-benefits-card',
  templateUrl: './schufa-benefits-card.component.html',
  styleUrls: ['./schufa-benefits-card.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class SchufaBenefitsCardComponent {}
