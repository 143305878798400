import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import * as fromBaseState from 'libs/infrastructure/base-state';
import * as fromAppState from 'tenant-pool/+state';
import { AuthTokenService } from 'libs/infrastructure';

@Injectable()
export class NewHomeGuard {
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private authTokenService = inject(AuthTokenService);

  canActivate(_route: ActivatedRouteSnapshot) {
    if (!this.authTokenService.getToken().access_token) {
      return of(false);
    }
    this.store.dispatch(
      fromAppState.LoadPropertyMatchesRented({
        data: { page: 0, sort: 'id,asc', size: 100 },
        loadMore: false
      })
    );

    return this.store
      .select(fromAppState.getPropertyMatchesRentedActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() => of(true))
      );
  }
}
