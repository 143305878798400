export const dialogConfig = {
  property: {
    removeApplication: {
      message: 'property.remove_application_confirmation_message',
      titleMessage: 'property.remove_application_confirmation_title'
    },
    removeProposal: {
      message: 'property.remove_proposal_confirmation_message',
      titleMessage: 'property.remove_proposal_confirmation_title'
    }
  },
  settings: {
    account: {
      delete: {
        message: 'account.delete_confirmation_message',
        titleMessage: 'account.delete_confirmation_title'
      }
    }
  },
  searchProfile: {
    delete: {
      message: 'search_profile.delete_confirmation_message',
      titleMessage: 'search_profile.delete_confirmation_title'
    },
    renew: {
      message: 'search_profile.renew_confirmation_message',
      titleMessage: 'search_profile.renew_confirmation_title',
      cancelButtonMessage: 'search_profile.cancel_a',
      okButtonMessage: 'search_profile.renew_a'
    }
  },
  schufa: {
    cancel: {
      message: 'schufa.cancel.confirmation_message',
      titleMessage: 'schufa.cancel.confirmation_title'
    }
  },
  userActiveness: {
    title: 'user.confirm_activeness.title_m',
    message_active: 'user.confirm_activeness.select_yes_m',
    message_not_active: 'user.confirm_activeness.select_no_m',
    okButtonMessage: 'general.ok'
  },
  digitalContract: {
    cancel: {
      titleMessage: 'digital_contract.cancel_signing_process_title',
      message: 'digital_contract.cancel_signing_process_msg',
      cancelButtonMessage: 'digital_contract.cancel_signing_process.back_a',
      okButtonMessage: 'digital_contract.cancel_signing_process.continue_a'
    },
    sendMessage: {
      titleMessage: 'digital_contract.wrong_data_notify_title',
      message: 'digital_contract.wrong_data_notify_msg'
    }
  },
  rentDeposit: {
    oneTime: {
      titleMessage: 'rent_deposit.one_time_confirm.title_l',
      message: 'rent_deposit.one_time_confirm.message_l',
      okButtonMessage: 'rent_deposit.one_time_confirm.ok_a'
    },
    ruvPrivacyAck: {
      titleMessage: 'rent_deposit.ruv_privacy.title_l',
      message: 'rent_deposit.ruv_privacy.message_l'
    }
  }
};
