import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { ReasonData } from '@ui/shared/models';

import {
  acceptProposalMutation,
  denyProposalMutation,
  AcceptProposalMutationResponse,
  DenyProposalMutationResponse
} from '../queries';

import { PropertyMatchesFacade } from './property-matches.facade';

@Injectable()
export class ProposalFacade {
  private apollo = inject(Apollo);
  private propertySearcherFacade = inject(PropertyMatchesFacade);

  public denyProposal(reasonData: ReasonData) {
    return this.apollo.mutate<DenyProposalMutationResponse>({
      mutation: denyProposalMutation,
      variables: { reasonData },
      fetchPolicy: 'no-cache'
    });
  }

  public acceptProposal(id: string) {
    return this.apollo.mutate<AcceptProposalMutationResponse>({
      mutation: acceptProposalMutation,
      variables: { id },
      fetchPolicy: 'no-cache',
      update: (_, res) => this.updateQueries(res.data.acceptProposal.id)
    });
  }

  public updateQueries(id?: string) {
    this.propertySearcherFacade.refetchMatch(id);
  }
}
