import { LanguageConfig } from 'libs/infrastructure';
import { AvailableLanguageCodesEnum } from '@ui/shared/models';

export const languageConfig: LanguageConfig = {
  defaultLanguageCode: AvailableLanguageCodesEnum.DE,
  availableLanguagesByCode: [
    AvailableLanguageCodesEnum.DE,
    AvailableLanguageCodesEnum.EN
  ]
};
