export enum DataTableColumnAlignment {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export interface DataTableColumn {
  name?: string;
  label?: string;
  required?: boolean;

  // TODO: use alignment also for the body cells (manually set atm) and not only for the header cells
  //  https://immomio.atlassian.net/browse/ART-328
  alignment?: DataTableColumnAlignment;
  noWrap?: boolean;
}

export const DataTableDefaultConfig = {
  isLoading: true,
  withStickyLastCell: true,
  noDataHeader: 'shared.no_data',
  noDataMessage: 'general.no_data_message_l'
};

export const DataTableColumnDefaultConfig = {
  alignment: DataTableColumnAlignment.LEFT,
  withoutPaddingLeft: false,
  clickable: true
};
