import { createSelector } from '@ngrx/store';
import { TooltipTour } from '@ui/shared/models';
import { getTooltipState } from '../reducers';
import * as fromTooltipReducer from './tooltip.reducer';

export const getTooltips = createSelector(
  getTooltipState,
  fromTooltipReducer.getTooltips
);

export const getTooltipTours = createSelector(
  getTooltipState,
  fromTooltipReducer.getTooltipTours
);

export const getTooltipToursForPath = createSelector(
  fromTooltipReducer.getCombinedPathTooltipTourMap,
  (
    combinedPathTooltipTourMap: Map<string, TooltipTour[]>,
    props: { path: string }
  ) => {
    return combinedPathTooltipTourMap.get(props.path);
  }
);

export const getTooltipProgress = createSelector(
  getTooltipState,
  fromTooltipReducer.getTooltipProgress
);
