import { createReducer, on } from '@ngrx/store';
import * as fromActions from './system.actions';

export interface SystemState {
  isMyNewHome: boolean;
}

export const initialState: SystemState = {
  isMyNewHome: false
};

export const systemReducer = createReducer(
  initialState,

  on(fromActions.SaveMyNewHomeState, (state, { isMyNewHome }) => {
    return {
      ...state,
      isMyNewHome
    };
  })
);

export const isMyNewHomeState = (state: SystemState) => state.isMyNewHome;
