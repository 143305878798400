import { gql } from 'apollo-angular';
import { Tooltip, TooltipTour } from '@ui/shared/models';

export interface TooltipsUnseenResponse {
  tooltipsUnseen: Tooltip[];
}

export interface TooltipToursUnseenResponse {
  tooltipToursUnseen: TooltipTour[];
}

export const anchorPointFields = `
  id
  anchorPoint
  path
  app
`;

export const tooltipFields = `
  id
  title
  description
  trigger
  active
  onlyForNewUsers
  anchorPoint {
    ${anchorPointFields}
  }
`;

export const tooltipTourElementFields = `
  id
  tooltip {
    ${tooltipFields}
  }
  step
`;

export const tooltipTourFields = `
  id
  title
  tourElements {
    ${tooltipTourElementFields}
  }
`;

export const tooltipsUnseenQuery = gql`
  query tooltipsUnseen($input: TooltipPathInput!){
    tooltipsUnseen(input: $input) {
      ${tooltipFields}
    }
  }
`;

export const tooltipToursUnseenQuery = gql`
  query tooltipToursUnseen($input: TooltipPathInput!){
    tooltipToursUnseen(input: $input){
      ${tooltipTourFields}
    }
  }
`;

export const tooltipsSeenMutation = gql`
  mutation tooltipsSeen($input: TooltipIdInput!) {
    tooltipsSeen(input: $input) {
      status
    }
  }
`;
