import { Component, forwardRef, OnInit, Input, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { customEmailValidator } from 'libs/components/legacy/form/controls/validation';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';
import { SalutationType } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';

@UntilDestroy()
@Component({
  selector: 'app-moving-auction-contact',
  templateUrl: './moving-auction-contact.component.html',
  styleUrls: ['./moving-auction-contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MovingAuctionContactComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MovingAuctionContactComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class MovingAuctionContactComponent
  extends BaseStep
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);

  @Input() processing: boolean;

  public formGroup: FormGroup;

  private onChange: (args) => any = () => null;
  private onTouch: () => any = () => null;

  public SALUTATION_OPTIONS = [
    { id: '0', label: 'GENDERTYPES_MALE', value: SalutationType.MR },
    { id: '1', label: 'GENDERTYPES_FEMALE', value: SalutationType.MS },
    {
      id: '2',
      label: 'general.salutation.company',
      value: SalutationType.COMPANY
    }
  ];

  ngOnInit(): void {
    super.ngOnInit();
    this.formGroup = this.fb.group({
      salutation: [null],
      name: [null, Validators.required],
      phone: [null, Validators.required],
      email: [
        null,
        Validators.compose([
          Validators.required,
          customEmailValidator,
          Validators.maxLength(50)
        ])
      ],
      acceptContact: [true], // TODO not found in design, remove?
      acceptInformation: [false] // TODO not found in design, remove?
    });

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
    });
  }

  public validate() {
    return this.formGroup.valid ? null : { missingFields: true };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }

  protected onCheckForm() {
    this.formGroup.valid
      ? this.onCompleteStep()
      : this.formGroup.markAllAsTouched();
  }
}
