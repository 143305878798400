import { Injectable } from '@angular/core';

import { PropertyMatchBean, PropertySearcherUser } from '@ui/shared/models';
import {
  RentDepositDeliveryData,
  RentDepositDeliveryOption,
  RentDepositFormData,
  RentDepositInfo,
  RentDepositLandlordOption,
  RentDepositModel,
  RentDepositPaymentOption
} from '@ui/shared/models';
import moment from 'moment';
import {
  GERMAN_COUNTRY_CODE,
  GERMAN_COUNTRY_NAME
} from 'libs/config/country-config';

@Injectable()
export class RentalDepositService {
  public convertToPayload(
    formValue: RentDepositFormData,
    rentInfo: RentDepositInfo
  ): RentDepositModel {
    const { personalInformation, object, payment } = formValue;
    const { tenantData } = personalInformation || {};
    const { currentAddress } = tenantData || {};
    const { country, district, ...restAddress } = currentAddress || {};
    const { propertyData, landlordData, contractData } = object || {};
    const { selectedRole, landlordAddress, ...restOfLandlordData } =
      landlordData || {};
    const { bankData, deliveryData, selectedPaymentOption } = payment || {};
    const { selectedDeliveryOption, ...restDeliveryData } = deliveryData || {};

    const isYearlyPayment =
      selectedPaymentOption === RentDepositPaymentOption.YEARLY;
    const realPerson =
      selectedRole === RentDepositLandlordOption.NATURAL_PERSON;

    return {
      tenantData: {
        ...tenantData,
        nationality: GERMAN_COUNTRY_CODE,
        currentAddress: {
          ...restAddress,
          countryName: undefined,
          country: GERMAN_COUNTRY_CODE
        }
      },
      propertyData,
      landlordData: {
        ...restOfLandlordData,
        realPerson,
        landlordAddress: {
          ...landlordAddress,
          district: undefined,
          countryName: undefined
        }
      },
      contractData: {
        ...contractData,
        paymentType: selectedPaymentOption,
        paymentAmount: isYearlyPayment
          ? rentInfo.ruvData.yearlyAmount
          : rentInfo.ruvData.monthlyAmount,
        totalDeposit: rentInfo.oneTimeDeposit,
        rentalContractSignDate: contractData?.rentalContractSignDate
          ? moment
              .utc(contractData.rentalContractSignDate, 'YYYY-MM-DD')
              .toDate()
          : null,
        startOfRentalContract: contractData?.startOfRentalContract
          ? moment
              .utc(contractData.startOfRentalContract, 'YYYY-MM-DD')
              .toDate()
          : new Date()
      },
      bankData,
      deliveryData: {
        ...(restDeliveryData as RentDepositDeliveryData),
        sendDocumentsToLandlord:
          selectedDeliveryOption === RentDepositDeliveryOption.LANDLORD
      }
    };
  }

  public convertToFormData(
    user: PropertySearcherUser,
    propertyMatch: PropertyMatchBean
  ): RentDepositFormData {
    return {
      personalInformation: {
        tenantData: {
          lastname: user.profile.name,
          firstname: user.profile.firstname,
          dateOfBirth: moment(user.profile.dateOfBirth).format('YYYY-MM-DD'),
          emailAddress: user.email,
          nationality: GERMAN_COUNTRY_CODE,
          phoneNumberPrefix: '+49',
          phoneNumber: user.profile.phone,
          currentAddress: {
            ...user.address,
            countryName: GERMAN_COUNTRY_NAME,
            country: GERMAN_COUNTRY_CODE // R+V only accepts DE as country code.
          }
        }
      },
      object: {
        propertyData: {
          ...propertyMatch.property,
          ...propertyMatch.property.data,
          address: {
            ...propertyMatch.property.data.address,
            country:
              propertyMatch.property.data.address.country || GERMAN_COUNTRY_CODE
          }
        },
        landlordData: {
          selectedRole: RentDepositLandlordOption.LEGAL_PERSON,
          name: propertyMatch.rentDepositInfo?.landlordAddress
            ? propertyMatch.property?.customerName ||
              propertyMatch.property?.customer?.name
            : null,
          landlordAddress: {
            ...propertyMatch.rentDepositInfo?.landlordAddress,
            countryName: GERMAN_COUNTRY_NAME
          }
        }
      },
      payment: {
        selectedPaymentOption: RentDepositPaymentOption.YEARLY
      }
    };
  }
}
