import { createAction, props } from '@ngrx/store';
import { MovingAuctionForm, MovingAuctionResponse } from '@ui/shared/models';

export const MovingAuctionWizardSetRedirect = createAction(
  '[MovingAuction] Wizard Set Redirect',
  props<{ redirect: string[] }>()
);

export const MovingAuctionWizardNextStep = createAction(
  '[MovingAuction] Wizard Next Step'
);

export const MovingAuctionWizardPreviousStep = createAction(
  '[MovingAuction] Wizard Previous Step'
);

export const MovingAuctionWizardGoToStep = createAction(
  '[MovingAuction] Wizard Go To Step',
  props<{ stepNumber: number }>()
);

export const MovingAuctionWizardReset = createAction(
  '[MovingAuction] Wizard Reset'
);

export const MovingAuctionSendRequest = createAction(
  '[MovingAuction] Send Request',
  props<{ movingAuctionForm: MovingAuctionForm }>()
);

export const MovingAuctionSendRequestSuccess = createAction(
  '[MovingAuction] Send Request Success',
  props<{ movingAuctionResponse: MovingAuctionResponse }>()
);

export const MovingAuctionSendRequestFail = createAction(
  '[MovingAuction] Send Request Fail',
  props<{ error: Error }>()
);
