import { Routes } from '@angular/router';

import { RentDepositObjectComponent } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-object/rent-deposit-object.component';
import { RentDepositPaymentComponent } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-payment/rent-deposit-payment.component';
import { RentDepositPersonalInformationComponent } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-personal-information/rent-deposit-personal-information.component';
import { RentDepositSummaryComponent } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-summary/rent-deposit-summary.component';

export const RentDepositStepRoutes: Routes = [
  {
    path: 'personalInformation',
    component: RentDepositPersonalInformationComponent,
    data: {
      title: 'rent_deposit.wizard.personal_information_title_l'
    }
  },
  {
    path: 'object',
    component: RentDepositObjectComponent,
    data: {
      title: 'rent_deposit.wizard.object_title_l'
    }
  },
  {
    path: 'payment',
    component: RentDepositPaymentComponent,
    data: {
      title: 'rent_deposit.wizard.payment_title_l'
    }
  },
  {
    path: 'summary',
    component: RentDepositSummaryComponent,
    data: {
      title: 'rent_deposit.wizard.summary_title_l'
    }
  }
];
