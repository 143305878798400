import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from '@angular/core';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-application-confirmation',
  templateUrl: './application-confirmation.component.html',
  styleUrls: ['./application-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, TranslateModule]
})
export class ApplicationConfirmationComponent {
  private themeService = inject(ThemeService);

  @Input() header: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() showButton = true;
  @Output() buttonClicked = new EventEmitter();

  public get logo(): string {
    return (
      this.themeService?.getActiveTheme?.logo?.url ||
      '/assets/images/logos/logo-immomio-main.svg'
    );
  }
}
