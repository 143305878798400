import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { ModalService } from 'libs/components/legacy/modal';
import {
  AuthTokenService,
  errorMessageParser,
  LocalStorageService,
  LogoutReasons,
  NotificationService,
  SessionStorageService,
  WINDOW_REF
} from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import {
  PropertySearcherUser,
  PropertyType,
  SearchProfile
} from '@ui/shared/models';
import { getRedirectUrl } from 'libs/utils';

import moment from 'moment';

import { of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import * as fromPropertySelectors from 'tenant-pool/+state/apply/apply.selectors';
import {
  dialogConfig,
  MainPageNavigation,
  NAVIGATION_LINK,
  notificationConfig,
  storageKeys
} from 'tenant-pool/config';
import { AuthService, UserFacade } from 'tenant-pool/core/services';

import { SearchProfileDetailsModalComponent } from 'tenant-pool/components/search-profile-details-modal/search-profile-details-modal.component';

import { AuthToken } from 'libs/infrastructure/keycloak-authentication-module/model';
import { KeycloakTenantService } from 'libs/infrastructure/keycloak-authentication-module/services/keycloak-tenant.service';
import { OpenCompleteModal } from '../property-matches/property-matches.actions';
import * as fromAppReducers from '../reducers';
import * as fromSearchProfileActions from '../search-profiles/search-profiles.actions';
import * as fromSearchProfileSelectors from '../search-profiles/search-profiles.selectors';
import * as fromActions from './user.actions';
import * as fromSelectors from './user.selectors';

@Injectable()
export class UserEffects {
  private actions$ = inject(Actions);
  private userFacade = inject(UserFacade);
  private authService = inject(AuthService);
  private authTokenService = inject(AuthTokenService);
  private store = inject<Store<fromAppReducers.AppState>>(Store);
  private sessionStorage = inject(SessionStorageService);
  private localStorage = inject(LocalStorageService);
  private modalService = inject(ModalService);
  private notificationService = inject(NotificationService);
  private keycloakService = inject(KeycloakTenantService);
  private windowRef = inject(WINDOW_REF);

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadUserData>(fromActions.LOAD_USER_DATA),
      switchMap(() => {
        void this.keycloakService.refresh();
        return this.userFacade.loadUser().pipe(
          tap(user => this.handleSearchConfirmation(user)),
          mergeMap(user => [
            new fromActions.LoadUserDataSuccess(user),
            new fromBaseState.UserUpdateLastLogin()
          ]),
          catchError(err =>
            of(
              new fromActions.LoadUserDataFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        );
      })
    )
  );

  firstSocialLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CheckFirstSocialLogin>(
        fromActions.CHECK_FIRST_SOCIAL_LOGIN
      ),
      switchMap(({ email }) =>
        this.userFacade.firstSocialLogin(email).pipe(
          mergeMap(isSocialLogin => [
            new fromActions.CheckFirstSocialLoginSuccess({
              isSocialLogin,
              email
            })
          ]),
          catchError(() =>
            of(
              new fromActions.CheckFirstSocialLoginFail({
                isSocialLogin: true,
                email,
                error: true
              })
            )
          )
        )
      )
    )
  );

  loadUserDataFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadUserDataFail>(fromActions.LOAD_USER_DATA_FAIL),
      map(() => {
        return new fromActions.LoadUserDataSuccess({});
      })
    )
  );

  saveUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SaveUserData>(fromActions.SAVE_USER_DATA),
      map(action => action.user),
      switchMap(user =>
        this.userFacade.saveUser(user).pipe(
          mergeMap(result => {
            return [
              new fromActions.SaveUserDataSuccess(result),
              new fromBaseState.ShowInfo(notificationConfig.user.save.success)
            ];
          }),
          catchError(err => [
            new fromActions.SaveUserDataFail(
              err ? err.message : 'Unexpected error'
            ),
            new fromBaseState.ShowError(notificationConfig.user.save.error)
          ])
        )
      )
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.Register>(fromActions.REGISTER),
      withLatestFrom(
        this.store.select(fromPropertySelectors.getProperty),
        this.store.select(
          fromSearchProfileSelectors.getSearchProfileProjectRegistrationProject
        ),
        this.store.select(fromBaseState.getCurrentLocale)
      ),
      switchMap(([{ userData }, property, project, currentLanguage]) => {
        const { brandedCustomerId, ...rest } = userData;
        return this.authService
          .register(rest, property?.customer?.id || brandedCustomerId)
          .pipe(
            mergeMap(({ access_token, session_state }) => {
              /**
               * We want users to be redirected straight to edit profile page after registration,
               * To encourage them to complete their profiles.
               * Unless this is a project registration!!!
               * Then we want them to see their project search profile
               */
              let pathAfterAuth = `/${MainPageNavigation.PROFILE}/edit/step/dkZero`;
              const searchProfile: SearchProfile = userData.searchProfile;
              const isSearchProfilePropertyTypeGarage =
                searchProfile?.propertyType === PropertyType.GARAGE;

              if (project)
                pathAfterAuth = `/${MainPageNavigation.SEARCH_PROFILES}`;

              if (property?.type === PropertyType.GARAGE)
                pathAfterAuth = `${NAVIGATION_LINK.PROPERTIES_APPLICATIONS}`;

              if (isSearchProfilePropertyTypeGarage) {
                // Only for SPs that are of the type garage open this modal
                pathAfterAuth = `${NAVIGATION_LINK.SEARCH_PROFILES}`;
                this.store.dispatch(
                  OpenCompleteModal({ showGenericModal: true })
                );
              }

              return [
                new fromBaseState.UserLoginSuccess(
                  { access_token, session_state } as AuthToken,
                  { pathAfterAuth }
                ),
                new fromActions.RegisterSuccess(),
                new fromActions.ChangePreferredLanguage(currentLanguage, false)
              ];
            }),
            catchError(err =>
              of(
                new fromActions.RegisterFail(new Error(errorMessageParser(err)))
              )
            )
          );
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteUser>(fromActions.DELETE_USER),
      switchMap(() => {
        const session_state = this.authTokenService.getToken()?.session_state;
        const redirectUrl = getRedirectUrl(
          this.windowRef.location.toString(),
          '/login',
          {
            queryParams: { reason: LogoutReasons.USER_PROFILE_DELETED },
            pathAfterAuth: ''
          }
        );

        return this.userFacade.deleteUser(session_state, redirectUrl).pipe(
          mergeMap(res => [new fromActions.DeleteUserSuccess(res.redirectUri)]),
          catchError(err =>
            of(
              new fromActions.DeleteUserFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        );
      })
    )
  );

  deleteUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteUserSuccess>(fromActions.DELETE_USER_SUCCESS),
      map(({ redirectUri }) => {
        this.sessionStorage.removeItem(
          storageKeys.redirectedToNewHomeAfterLogin
        );

        this.sessionStorage.removeItem('nonce');
        this.sessionStorage.removeItem('state');
        this.authTokenService.removeToken();

        return new fromBaseState.UserLogoutSuccess(redirectUri);
      })
    )
  );

  changeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangeEmail>(fromActions.CHANGE_EMAIL),
      switchMap(action =>
        this.userFacade.changeEmail(action.newEmail).pipe(
          mergeMap(() => [
            new fromActions.ChangeEmailSuccess(),
            new fromBaseState.ShowInfo(
              notificationConfig.user.changeEmail.success
            )
          ]),
          catchError(error => [new fromActions.ChangeEmailFail(error)])
        )
      )
    )
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.ConfirmEmail>(fromBaseState.CONFIRM_EMAIL),
      switchMap(action =>
        this.userFacade.confirmEmail(action.token).pipe(
          mergeMap(() => [
            new fromBaseState.ConfirmEmailSuccess(),
            new fromBaseState.UserLogout(
              getRedirectUrl(this.windowRef.location.toString(), '/login', {
                queryParams: { message: LogoutReasons.USER_EMAIL_CHANGED },
                pathAfterAuth: ''
              })
            )
          ]),
          catchError(error => [
            new fromBaseState.ConfirmEmailFail(error),
            new fromBaseState.Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ]
            }),
            new fromBaseState.ShowError(
              notificationConfig.user.confirmEmail.error
            )
          ])
        )
      )
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangePassword>(fromActions.CHANGE_PASSWORD),
      switchMap((action: fromActions.ChangePassword) =>
        this.userFacade
          .changePassword(action.password, action.confirmedPassword)
          .pipe(
            mergeMap(() => [
              new fromActions.ChangePasswordSuccess(),
              new fromBaseState.ShowInfo(
                notificationConfig.user.changePassword.success
              )
            ]),
            catchError(error => [
              new fromActions.ChangeEmailFail(error),
              new fromBaseState.ShowError(
                notificationConfig.user.changePassword.error
              )
            ])
          )
      )
    )
  );

  setSearchingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SetSearchingStatus>(fromActions.SET_SEARCHING_STATUS),
      switchMap(({ token, isSearching }) =>
        this.userFacade.setSearchingStatus(token, isSearching).pipe(
          mergeMap(() => [
            new fromActions.SetSearchingStatusSuccess(),
            new fromBaseState.Go({
              path: [MainPageNavigation.LOGIN],
              query: { isSearching }
            })
          ]),
          catchError(error => [
            new fromActions.ChangeEmailFail(error),
            new fromBaseState.ShowError(
              notificationConfig.user.setSearchingStatus.error
            ),
            new fromBaseState.Go({ path: [MainPageNavigation.LOGIN] })
          ])
        )
      )
    )
  );

  unlockAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SetSearchingStatus>(fromActions.UNLOCK_ACCOUNT),
      switchMap(({ token }) =>
        this.userFacade.unlockAccount(token).pipe(
          mergeMap(() => [
            new fromActions.UnlockAccountSuccess(),
            new fromBaseState.ShowInfo(
              notificationConfig.user.unlockAccount.success
            ),
            new fromBaseState.Go({ path: [MainPageNavigation.LOGIN] })
          ]),
          catchError(error => [
            new fromActions.UnlockAccountFail(error),
            new fromBaseState.ShowError(
              notificationConfig.user.unlockAccount.error
            ),
            new fromBaseState.Go({ path: [MainPageNavigation.LOGIN] })
          ])
        )
      )
    )
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBaseState.VERIFY_EMAIL),
      map((action: fromBaseState.VerifyEmail) => action.token),
      switchMap(token =>
        this.userFacade.verifyEmail(token).pipe(
          tap(() =>
            this.sessionStorage.setItem(
              storageKeys.emailVerifiedInCurrentSession,
              true
            )
          ),
          mergeMap(() => [
            new fromBaseState.VerifyEmailSuccess(),
            new fromBaseState.Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ]
            })
          ]),
          catchError(error => [
            new fromBaseState.VerifyEmailFail(error),
            new fromBaseState.ShowError(
              errorMessageParser(
                error,
                notificationConfig.user.verifyEmail.error
              )
            ),
            new fromBaseState.Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ]
            })
          ])
        )
      )
    )
  );

  resendEmailVerification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.RESEND_VERIFICATION_EMAIL),
        withLatestFrom(this.store.select(fromSelectors.getUserId)),
        switchMap(([_, userId]) =>
          this.userFacade
            .resendEmailVerification(userId)
            .pipe(
              catchError(error => [
                new fromActions.ResendVerificationEmailFail(error)
              ])
            )
        )
      ),
    { dispatch: false }
  );

  openActivenessModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.OpenActivenessModal>(
          fromActions.OPEN_ACTIVENESS_MODAL
        ),
        tap(({ titleMessage, message, okButtonMessage }) => {
          this.notificationService.showInfoModal(
            titleMessage,
            message,
            okButtonMessage
          );
        })
      ),
    { dispatch: false }
  );

  leaveInternalTenantPool$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LeaveInternalTenantPool>(
        fromActions.LEAVE_INTERNAL_TENANT_POOL
      ),
      switchMap(() =>
        this.userFacade.LeaveInternalTenantPool().pipe(
          mergeMap(() => [
            new fromActions.LeaveInternalTenantPoolSuccess(),
            new fromBaseState.ShowInfo(
              notificationConfig.user.LeaveInternalTenantPool.success
            )
          ]),
          catchError(error => [
            new fromActions.LeaveInternalTenantPoolFail(error),
            new fromBaseState.ShowError(
              notificationConfig.user.LeaveInternalTenantPool.error
            )
          ])
        )
      )
    )
  );

  assignRegistrationToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.AssignRegistrationToken>(
        fromActions.ASSIGN_REGISTRATION_TOKEN
      ),
      switchMap(({ tokenId }) =>
        this.userFacade.assignRegistrationToken(tokenId).pipe(
          map(() => new fromActions.AssignRegistrationTokenSuccess()),
          catchError(error =>
            of(new fromActions.AssignRegistrationTokenFail(error))
          )
        )
      )
    )
  );

  sagaDataTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SagaDataTransfer>(fromActions.SAGA_DATA_TRANSFER),
      switchMap(({ accept, token }) =>
        this.userFacade.sagaDataTransfer(accept, token).pipe(
          map(() => new fromActions.SagaDataTransferResponseSuccess()),
          catchError(error => [
            new fromActions.SagaDataTransferResponseFail(error),
            new fromBaseState.ShowError(errorMessageParser(error))
          ])
        )
      )
    )
  );

  private handleSearchConfirmation(user: PropertySearcherUser) {
    const userActiveness = this.localStorage.getItem(
      storageKeys.userActivenessSelection
    );

    if (userActiveness) {
      this.localStorage.removeItem(storageKeys.userActivenessSelection);
      const title = dialogConfig.userActiveness.title;
      const message = userActiveness.isSearching
        ? dialogConfig.userActiveness.message_active
        : dialogConfig.userActiveness.message_not_active;
      const okButton = dialogConfig.userActiveness.okButtonMessage;
      if (!userActiveness.isSearching) {
        this.store.dispatch(
          new fromSearchProfileActions.DeleteAllSearchProfiles()
        );
      }
      this.store.dispatch(
        new fromActions.OpenActivenessModal(title, message, okButton)
      );
    } else {
      if (moment().isBefore(moment(user.searchUntil))) return;

      const redirectAction = new fromBaseState.Go({
        path: [MainPageNavigation.SEARCH_PROFILES]
      });
      const confirmation = this.modalService.openConfirmation({
        data: dialogConfig.searchProfile.renew,
        backdrop: 'static',
        keyboard: false
      });

      confirmation
        .onClose()
        .subscribe(() => this.store.dispatch(redirectAction));

      confirmation.onDismiss().subscribe(() => {
        this.store.dispatch(redirectAction);

        this.store.dispatch(
          new fromSearchProfileActions.DeleteAllSearchProfiles()
        );
        this.modalService.open<SearchProfileDetailsModalComponent>(
          SearchProfileDetailsModalComponent
        );
      });
    }
  }

  changePreferredLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangePreferredLanguage>(
        fromActions.CHANGE_PREFERRED_LANGUAGE
      ),
      switchMap((action: fromActions.ChangePreferredLanguage) =>
        this.userFacade.changePreferredLanguage(action.languageCode).pipe(
          mergeMap(() => {
            const actions = [
              new fromBaseState.ShowInfo(
                notificationConfig.user.changePreferredLanguage.success
              ),
              new fromActions.ChangePreferredLanguageSuccess(
                action.languageCode,
                action.reload
              )
            ];

            if (!action.reload) {
              actions.shift();
            }

            return actions;
          }),

          catchError(() => [
            new fromBaseState.ShowError(
              notificationConfig.user.changePreferredLanguage.error
            )
          ])
        )
      )
    )
  );

  changePreferredLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangePreferredLanguageSuccess>(
        fromActions.CHANGE_PREFERRED_LANGUAGE_SUCCESS
      ),
      map(action => {
        return action.reload
          ? new fromBaseState.ChangeLocale(action.languageCode)
          : { type: 'NO_ACTION' };
      }),
      // delay added to provide user enough time to see the message
      delay(3000)
    )
  );

  fetchPreferredLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchPreferredLanguage>(
        fromActions.FETCH_PREFERRED_LANGUAGE
      ),
      withLatestFrom(this.store.select(fromBaseState.getCurrentLocale)),
      switchMap(([_, currentLanguage]) => {
        return this.userFacade.fetchPreferredLanguage().pipe(
          mergeMap(preferredLanguage => {
            const actions: Action[] = [
              new fromActions.FetchPreferredLanguageSuccess(preferredLanguage)
            ];

            if (preferredLanguage !== currentLanguage) {
              actions.unshift(
                new fromBaseState.ShowInfo(
                  notificationConfig.user.changePreferredLanguage.reloading
                )
              );
            }

            return actions;
          }),
          catchError(() => [
            new fromBaseState.ShowError(
              notificationConfig.user.fetchPreferredLanguage.error
            )
          ])
        );
      })
    )
  );

  fetchPreferredLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchPreferredLanguageSuccess>(
        fromActions.FETCH_PREFERRED_LANGUAGE_SUCCESS
      ),
      withLatestFrom(this.store.select(fromBaseState.getCurrentLocale)),
      filter(
        ([{ languageCode }, currentLanguage]) =>
          languageCode !== currentLanguage
      ),
      map(([{ languageCode }]) => {
        return new fromBaseState.ChangeLocale(languageCode);
      }),
      // delay added to provide user enough time to see the message
      delay(3000)
    )
  );

  isRegisteredToImmomio$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchIsRegisteredAtImmomio>(
        fromActions.FETCH_IS_REGISTERED_AT_IMMOMIO
      ),
      switchMap(() =>
        this.userFacade.fetchIsRegisteredAtImmomio().pipe(
          map(
            result => new fromActions.FetchIsRegisteredAtImmomioSuccess(result)
          ),
          catchError(error =>
            of(new fromActions.FetchIsRegisteredAtImmomioFail(error))
          )
        )
      )
    )
  );

  registerResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.RegisterResident>(fromActions.REGISTER_RESIDENT),
      switchMap(() =>
        this.userFacade.registerResident().pipe(
          map(() => new fromActions.RegisterResidentSuccess()),
          catchError(error => of(new fromActions.RegisterResidentFail(error)))
        )
      )
    )
  );
}
