import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  QueryList,
  ViewChildren,
  EventEmitter
} from '@angular/core';
import { SalesLegalText, SalesLegalTextType } from '@ui/shared/models';
import { CheckboxComponent } from 'libs/components/legacy/form';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent as CheckboxComponent_1 } from '../../legacy/form/controls/checkbox/checkbox.component';

@Component({
  selector: 'app-sales-legal-checkboxes-preview',
  templateUrl: './sales-legal-checkboxes-preview.component.html',
  styleUrls: ['./sales-legal-checkboxes-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CheckboxComponent_1, FormsModule]
})
export class SalesLegalCheckboxesPreviewComponent {
  @ViewChildren(CheckboxComponent)
  checkboxesComponents: QueryList<CheckboxComponent>;
  @Input() salesLegalTexts: SalesLegalText[];
  @Input() previewOnly: boolean;
  @Output() allCheckboxesChecked = new EventEmitter<boolean>();
  public checkboxValue: boolean;

  public get introduction(): SalesLegalText {
    return this.salesLegalTexts.find(
      value => value.type === SalesLegalTextType.INTRODUCTION
    );
  }

  public get checkboxes(): SalesLegalText[] {
    return this.salesLegalTexts.filter(
      value => value.type === SalesLegalTextType.CHECKBOX
    );
  }

  public get conclusion(): SalesLegalText {
    return this.salesLegalTexts.find(
      value => value.type === SalesLegalTextType.CONCLUSION
    );
  }

  public checkboxValueChanged(): void {
    const anyUncheckedValue = this.checkboxesComponents
      .map(c => c.value)
      .some(v => !v);

    if (!this.previewOnly) this.allCheckboxesChecked.emit(!anyUncheckedValue);
  }
}
