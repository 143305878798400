import { NgModule } from '@angular/core';

import { DateTimePipe } from './date-time.pipe';

const pipes = [DateTimePipe];

@NgModule({
  imports: [...pipes],
  exports: pipes,
  providers: pipes
})
export class DateTimePipeModule {}
