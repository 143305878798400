import { Injectable, inject } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  movingChecklistChangeElementMutation,
  movingChecklistGetByPropertyQuery,
  movingChecklistResetMutation,
  movingChecklistSetMoveInDateMutation
} from 'tenant-pool/core/queries/moving-checklist.queries';
import { map } from 'rxjs/operators';
import {
  MovingChecklist,
  MovingChecklistSetMoveInDatePayload,
  MovingChecklistTodo
} from '@ui/shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class MovingChecklistFacade {
  private apollo = inject(Apollo);

  private movingChecklistWatchQuery: QueryRef<{
    movingChecklistGetByProperty: MovingChecklist;
  }>;

  public getMovingChecklistByPropertyID(): Observable<MovingChecklist> {
    this.movingChecklistWatchQuery = this.apollo.watchQuery<{
      movingChecklistGetByProperty: MovingChecklist;
    }>({
      query: movingChecklistGetByPropertyQuery,
      fetchPolicy: 'no-cache'
    });

    return this.movingChecklistWatchQuery.valueChanges.pipe(
      map(response => response?.data?.movingChecklistGetByProperty)
    );
  }

  public setMoveInDate(
    data: MovingChecklistSetMoveInDatePayload
  ): Observable<MovingChecklist> {
    const { doNotAskForMoveInDate, moveInDate } = data;
    return this.apollo
      .mutate<{ movingChecklistSetMoveInDate: MovingChecklist }>({
        mutation: movingChecklistSetMoveInDateMutation,
        variables: {
          moveInDate,
          doNotAskForMoveInDate
        }
      })
      .pipe(map(response => response?.data?.movingChecklistSetMoveInDate));
  }

  public changeElement(
    element: MovingChecklistTodo
  ): Observable<MovingChecklist> {
    return this.apollo
      .mutate<{ movingChecklistChangeElement: MovingChecklist }>({
        mutation: movingChecklistChangeElementMutation,
        variables: {
          element
        }
      })
      .pipe(map(response => response?.data?.movingChecklistChangeElement));
  }

  public reset(): Observable<MovingChecklist> {
    return this.apollo
      .mutate<{ movingChecklistReset: MovingChecklist }>({
        mutation: movingChecklistResetMutation,
        update: () => this.refetchChecklist()
      })
      .pipe(map(response => response?.data?.movingChecklistReset));
  }

  public refetchChecklist() {
    if (this.movingChecklistWatchQuery) return;
    void this.movingChecklistWatchQuery.refetch();
  }
}
