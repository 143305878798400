import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { CardComponent } from '../../../atoms/card/card.component';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  standalone: true,
  imports: [
    CardComponent,
    SvgIconComponent,
    ImageComponent,
    NgClass,
    ButtonComponent,
    TranslateModule
  ]
})
export class ServiceCardComponent {
  @Input() showNewMarker: boolean;
  @Input() headline: string;
  @Input() description: string;
  @Input() copyTextLabel: string;
  @Input() copyText: string;
  @Input() copyTextDescriptionTitle: string;
  @Input() copyTextDescription: string;

  @Input() price: string;
  @Input() buttonText: string;
  @Input() logoUrl: string;
  @Output() copyEvent = new EventEmitter<string>();
  @Output() buttonEvent = new EventEmitter<Event>();

  public baseClass = 'service-card';

  public copy() {
    this.copyEvent.emit(this.copyText);
  }

  public buttonPress(event: Event) {
    this.buttonEvent.emit(event);
  }
}
