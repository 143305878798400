import { createSelector } from '@ngrx/store';
import { getRentDepositState } from 'tenant-pool/+state/reducers';
import * as fromReducer from './rent-deposit.reducer';

export const getCurrentWizardStep = createSelector(
  getRentDepositState,
  fromReducer.getCurrentWizardStep
);

export const getRuvActionState = createSelector(
  getRentDepositState,
  fromReducer.getRuvActionState
);

export const getRentDepositInfo = createSelector(
  getRentDepositState,
  fromReducer.getRentDepositInfo
);
