import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appIsNotNullAndUndefined',
  standalone: true
})
export class IsNotNullAndUndefined implements PipeTransform {
  transform(value: unknown): boolean {
    return value !== null && value !== undefined;
  }
}
