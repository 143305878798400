import { createSelector } from '@ngrx/store';
import { getResidentState } from '../reducers';
import * as fromResidentReducer from './resident.reducer';

export const getResidentVerifyEmailActionState = createSelector(
  getResidentState,
  fromResidentReducer.getVerifyEmailActionState
);

export const getResidentChangeEmailActionState = createSelector(
  getResidentState,
  fromResidentReducer.getChangeEmailActionState
);
