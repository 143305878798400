import { createSelector } from '@ngrx/store';
import { getPaymentsState } from '../reducers';
import * as fromPaymentsReducer from './payments.reducer';

export const getPaymentRequest = createSelector(
  getPaymentsState,
  fromPaymentsReducer.getPaymentRequest
);

export const getPaymentRequestActionState = createSelector(
  getPaymentsState,
  fromPaymentsReducer.getPaymentRequestActionState
);

export const getPaymentMandate = createSelector(
  getPaymentsState,
  fromPaymentsReducer.getPaymentMandate
);

export const getPaymentMandateActionState = createSelector(
  getPaymentsState,
  fromPaymentsReducer.getPaymentMandateActionState
);

export const getPaymentBranding = createSelector(
  getPaymentsState,
  ({ paymentBranding }) => paymentBranding
);

export const getPaymentPayInResponse = createSelector(
  getPaymentsState,
  fromPaymentsReducer.getPaymentPayInResponse
);

export const getManualPaymentActionState = createSelector(
  getPaymentsState,
  fromPaymentsReducer.getManualPaymentActionState
);

export const getPaymentExecutionActionState = createSelector(
  getPaymentsState,
  fromPaymentsReducer.getPaymentExecutionActionState
);

export const getSmartDepositDictionary = createSelector(
  getPaymentsState,
  ({ smartDepositDictionary }) => smartDepositDictionary
);

export const getIsSmartDepositDictionaryPending = createSelector(
  getPaymentsState,
  ({ smartDepositDictionaryActionState }) =>
    smartDepositDictionaryActionState.pending
);

export const getIsSmartDepositFeePending = createSelector(
  getPaymentsState,
  state => state.smartDepositFeeActionState?.pending
);

export const getSmartDepositFee = createSelector(
  getPaymentsState,
  ({ smartDepositFee }) => smartDepositFee
);

export const getSmartDeposit = createSelector(
  getPaymentsState,
  ({ smartDeposit }) => smartDeposit
);

export const getSmartDepositActionState = createSelector(
  getPaymentsState,
  ({ smartDepositActionState }) => smartDepositActionState
);

export const getSmartDepositBranding = createSelector(
  getPaymentsState,
  ({ smartDepositBranding }) => smartDepositBranding
);

export const getApplySmartDepositActionState = createSelector(
  getPaymentsState,
  ({ applySmartDepositActionState }) => applySmartDepositActionState
);

export const getApplySmartDepositResponseToken = createSelector(
  getPaymentsState,
  ({ applySmartDepositResponseToken }) => applySmartDepositResponseToken
);

export const getStartSmartDepositLegitimationActionState = createSelector(
  getPaymentsState,
  ({ startSmartDepositLegitimationActionState }) =>
    startSmartDepositLegitimationActionState
);

export const getCancelSmartDepositLegitimationActionState = createSelector(
  getPaymentsState,
  ({ cancelSmartDepositLegitimationActionState }) =>
    cancelSmartDepositLegitimationActionState
);

export const getCancelSmartDepositActionState = createSelector(
  getPaymentsState,
  ({ cancelSmartDepositActionState }) => cancelSmartDepositActionState
);

export const getIsSmartDepositPending = createSelector(
  getPaymentsState,
  ({ smartDepositActionState }) => smartDepositActionState?.pending
);

export const getIsDictionaryAndSmartDepositLoaded = createSelector(
  getIsSmartDepositDictionaryPending,
  getIsSmartDepositPending,
  (isDictionaryPending, isSmartDepositPending) =>
    !isDictionaryPending && !isSmartDepositPending
);
