import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { map } from 'rxjs/operators';

import { notificationConfig } from 'tenant-pool/config';

import { propertyQuery, PropertyQueryResult } from '../queries';

@Injectable()
export class PropertyFacade {
  private apollo = inject(Apollo);

  public get(id: string) {
    return this.apollo
      .query<PropertyQueryResult>({
        query: propertyQuery,
        variables: { id }
      })
      .pipe(
        map(response => {
          const property = response && response.data && response.data.property;

          if (!property) {
            throw new Error(notificationConfig.property.fetch.error);
          }

          return property;
        })
      );
  }
}
