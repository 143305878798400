import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  inject
} from '@angular/core';
import {
  NgbNavChangeEvent,
  NgbNav,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SideModalContentDirective } from 'libs/components/molecules/side-modal/side-modal-content.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    NgTemplateOutlet,
    NgbNavOutlet,
    TranslateModule
  ]
})
export class SideModalComponent {
  private _router = inject(Router);

  @ViewChild('navOutlet', { read: ElementRef })
  navOutlet: ElementRef<HTMLDivElement>;
  @ContentChildren(SideModalContentDirective)
  contents: QueryList<SideModalContentDirective>;
  @Output() navChanged = new EventEmitter<any>();

  public activeNav: string;

  public dismiss(): void {
    void this._router.navigate(['', { outlets: { side: null } }], {
      queryParamsHandling: 'preserve'
    });
  }

  public onNavChange(event: NgbNavChangeEvent) {
    this.navChanged.emit(event.nextId);
    this.scrollToTop();
  }

  private scrollToTop(): void {
    this.navOutlet.nativeElement.scroll(0, 0);
  }
}
