import { gql } from 'apollo-angular';
import { MovingAuctionResponse } from '@ui/shared/models';

export interface SendRequestResponse {
  sendRequest: MovingAuctionResponse;
}

export const responseFields = `
  responseType
`;

export const sendRequestMutation = gql`
  mutation sendRequest($movingAuctionForm: MovingAuctionFormInput) {
    sendRequest(movingAuctionForm: $movingAuctionForm) {
      ${responseFields}
    }
  }
`;
