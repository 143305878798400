import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import {
  AuthTokenService,
  SessionStorageService,
  WINDOW_REF
} from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';

import { storageKeys } from 'tenant-pool/config';

import * as fromAppState from '../../+state';

@Injectable()
export class UserDataGuard {
  private store = inject<Store<fromAppState.AppState>>(Store);
  private authTokenService = inject(AuthTokenService);
  private sessionStorage = inject(SessionStorageService);
  private windowRef = inject(WINDOW_REF);

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this reload can probably be removed, because of the change in
    // app.router.ts from ART-5391
    if (!this.authTokenService.getToken().access_token) {
      this.store.dispatch(new fromBaseState.UserRedirect(state.url));
      return of(false);
    }

    return this.checkStore().pipe(
      switchMap(userData => {
        /**
         * user data is set to {} on loadUserDataFail
         */
        const isUserDataEmpty = Object.keys(userData)?.length === 0;
        if (isUserDataEmpty) {
          this.authTokenService.removeToken();
          this.store.dispatch(new fromBaseState.UserRedirect());
          return of(false);
        }
        const stateAfterAuth = this.sessionStorage.getItem(
          storageKeys.stateAfterAuth
        );

        if (stateAfterAuth) {
          this.sessionStorage.removeItem(storageKeys.stateAfterAuth);
        }

        return of(true);
      }),
      catchError(() => {
        this.store.dispatch(new fromBaseState.UserRedirect());
        return of(false);
      })
    );
  }

  checkStore() {
    return this.store.select(fromAppState.getUserData).pipe(
      tap(userData => {
        if (!userData) {
          this.store.dispatch(new fromAppState.LoadUserData());
        }
      }),
      filter(userData => !!userData),
      take(1)
    );
  }
}
