import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Attachment } from '@ui/shared/models';
import { ImageSizePipe } from '../../../../pipes/image-size.pipe';
import { AvatarComponent } from '../../../legacy/avatar/avatar.component';

@Component({
  selector: 'app-navigation-user-profile',
  templateUrl: './navigation-user-profile.component.html',
  styleUrls: ['./navigation-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AvatarComponent, ImageSizePipe]
})
export class NavigationUserProfileComponent {
  @Input() name: string;
  @Input() email: string;
  @Input() portrait: Attachment;
  @Input() dark: boolean;
}
