import { Directive, Input, TemplateRef, inject } from '@angular/core';

@Directive({
  selector: '[appSideSheetContent]',
  standalone: true
})
export class SideSheetContentDirective {
  template = inject<TemplateRef<any>>(TemplateRef);

  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean;

  // Used as reference for the output of currentFormIdChange in the app-side-sheet component
  @Input() identifier?: string;
}
