import { Component, Input, OnInit, inject } from '@angular/core';

import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { getArrayListValidator } from 'libs/components/legacy/form/controls/validation/validators';

import { Constants, SelfDisclosureQuestion } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from 'libs/components/atoms/button/button.component';
import { SelfDisclosureService } from '../../self-disclosure.service';
import { ComponentsModule } from '../../../components/components.module';
import { SelfDisclosurePersonComponent } from '../self-disclosure-person/self-disclosure-person.component';

@UntilDestroy()
@Component({
  selector: 'app-self-disclosure-person-list',
  templateUrl: './self-disclosure-person-list.component.html',
  styleUrls: ['./self-disclosure-person-list.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelfDisclosurePersonComponent,
    ComponentsModule,
    TranslateModule,
    ButtonComponent
  ]
})
export class SelfDisclosurePersonListComponent implements OnInit {
  private fb = inject(FormBuilder);
  private selfDisclosureService = inject(SelfDisclosureService);

  private _selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input()
  get selfDisclosureQuestion() {
    return this._selfDisclosureQuestion;
  }

  set selfDisclosureQuestion(value) {
    this._selfDisclosureQuestion = { ...value };
  }

  @Input() form: FormGroup;
  @Input() confirmations: string[];
  @Input() constants: Constants;

  public get getFormControls() {
    return (this.getFormArray.controls as FormGroup[]) || [];
  }

  private get getAnswerUnavailable() {
    return this.form.get('answerUnavailable');
  }

  private get getFormArray() {
    return this.form.get('answer') as FormArray;
  }

  public ngOnInit() {
    this.getAnswerUnavailable.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selfDisclosureQuestion.answerUnavailable =
          this.getAnswerUnavailable.value;
        this.updateValidity(value);
      });
    this.updateValidity(this.selfDisclosureQuestion.answerUnavailable);
  }

  public removePerson(index: number) {
    this.getFormArray.removeAt(index);
  }

  public addPerson() {
    const personConfig = this.selfDisclosureService.getPersonConfig(
      this.selfDisclosureQuestion.mandatory &&
        this.selfDisclosureQuestion.showSelfDisclosureQuestions
    );

    personConfig.subQuestions = this.fb.array([]);
    personConfig.confirmations = this.fb.array([]);

    this.selfDisclosureQuestion.subQuestions.forEach(subQuestion => {
      const config = this.selfDisclosureService.getConfigForSubQuestion(
        subQuestion.type,
        subQuestion.mandatory && subQuestion.showSelfDisclosureQuestions
      );
      const formGroup: FormGroup = this.fb.group(config);
      formGroup.patchValue(subQuestion); // need to patch, so all fields are saved in answer
      (personConfig.subQuestions as FormArray).push(formGroup);
    });
    this.confirmations.forEach(text => {
      const confirmationGroup = this.fb.group(
        this.selfDisclosureService.getConfirmationConfig(text)
      );
      (personConfig.confirmations as FormArray).push(confirmationGroup);
    });
    this.getFormArray.push(this.fb.group(personConfig));
  }

  private updateValidity(value: boolean) {
    /**
     * Here we add or remove the validators to show the red border only if field is invalid
     */
    if (value) {
      this.getFormArray.clearValidators();
    } else {
      this.getFormArray.setValidators(
        getArrayListValidator(
          this.selfDisclosureQuestion.mandatory &&
            this.selfDisclosureQuestion.showSelfDisclosureQuestions
        )
      );
    }
    this.getFormArray.updateValueAndValidity();
  }
}
