@if (quotaStatistic) {
  <span class="contingent-header">
    @if (quotaStatistic.availableQuota >= 10) {
      <img src="/assets/images/icons/icon-checkbox-green.svg" />
    }
    @if (
      quotaStatistic.availableQuota < 10 && quotaStatistic.availableQuota > 0
    ) {
      <img src="/assets/images/icons/icon-info.svg" />
    }
    @if (quotaStatistic.availableQuota === 0) {
      <img src="/assets/images/icons/icon-no.svg" />
    }
    @if (showUnlimited) {
      <span
        >{{ text | translate }} {{ 'quota_info.unlimited_l' | translate }}</span
      >
    } @else {
      @if (isAdmin) {
        <a
          class="nav p-0"
          [routerLink]="['/manage/digital-contracts', 'booking']"
          >{{ text | translate }} {{ quotaStatistic.availableQuota }}</a
        >
      }
      @if (!isAdmin) {
        <span>{{ text | translate }} {{ quotaStatistic.availableQuota }}</span>
      }
    }
  </span>
}
