import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';

import * as fromBaseState from 'libs/infrastructure/base-state';
import * as fromSelectors from 'tenant-pool/+state/schufa/schufa.selectors';
import * as fromAccountSelector from 'tenant-pool/+state/user/user.selectors';
import { MainPageNavigation } from 'tenant-pool/config';

@Injectable()
export class SchufaStepGuard {
  private store = inject<Store<fromBaseState.AppState>>(Store);

  canActivate(route: ActivatedRouteSnapshot) {
    const { requiredCompletion } = route.data;
    return this.store.select(fromSelectors.getCompletedSteps).pipe(
      withLatestFrom(this.store.select(fromAccountSelector.getUserData)),
      map(([completedSteps, userData]) => {
        if (completedSteps.includes(requiredCompletion)) return true;
        this.store.dispatch(
          new fromBaseState.Go({
            path: [
              userData
                ? `${MainPageNavigation.SERVICE_CENTER}/${MainPageNavigation.SCHUFA}`
                : MainPageNavigation.SCHUFA,
              requiredCompletion
            ]
          })
        );
      })
    );
  }
}
