import { Action } from '@ngrx/store';
import { BrandingTheme, SimpleCustomerBranding } from '@ui/shared/models';

export const VALIDATE_THEME_TOKEN = '[Branding] Validate Theme Token';
export const VALIDATE_THEME_TOKEN_FAIL = '[Branding] Validate Theme Token Fail';
export const VALIDATE_THEME_TOKEN_SUCCESS =
  '[Branding] Validate Theme Token Success';

export class ValidateThemeToken implements Action {
  readonly type = VALIDATE_THEME_TOKEN;
  constructor(
    public token: string,
    public preview?: boolean
  ) {}
}

export class ValidateThemeTokenFail implements Action {
  readonly type = VALIDATE_THEME_TOKEN_FAIL;
  constructor(public error: any) {}
}

export class ValidateThemeTokenSuccess implements Action {
  readonly type = VALIDATE_THEME_TOKEN_SUCCESS;
  constructor(public theme: BrandingTheme) {}
}

export const LOAD_CUSTOMER_BRANDING = '[Branding] Load Customer Branding';
export const LOAD_CUSTOMER_BRANDING_FAIL =
  '[Branding] Load Customer Branding Fail';
export const LOAD_CUSTOMER_BRANDING_SUCCESS =
  '[Branding] Load Customer Branding Success';

export class LoadCustomerBranding implements Action {
  readonly type = LOAD_CUSTOMER_BRANDING;
  constructor(public customerId: string) {}
}

export class LoadCustomerBrandingFail implements Action {
  readonly type = LOAD_CUSTOMER_BRANDING_FAIL;
  constructor(public error: any) {}
}

export class LoadCustomerBrandingSuccess implements Action {
  readonly type = LOAD_CUSTOMER_BRANDING_SUCCESS;
  constructor(public customerBranding: SimpleCustomerBranding) {}
}

export type BrandingActions =
  | ValidateThemeToken
  | ValidateThemeTokenFail
  | ValidateThemeTokenSuccess
  | LoadCustomerBranding
  | LoadCustomerBrandingFail
  | LoadCustomerBrandingSuccess;
