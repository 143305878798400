import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
  Gallery,
  GalleryItem,
  ImageItem,
  ImageSize,
  ThumbnailsPosition
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

import {
  buildBEMClassNamesByGivenBaseClassAndFlags,
  isRentalObject,
  isTouchScreen,
  isValueNotNullAndUndefined
} from 'libs/utils';
import {
  ApplicantStatus,
  Attachment,
  AvailableFrom,
  IconTypeEnum
} from '@ui/shared/models';
import { Elevation, ElevationType } from 'libs/directives';
import { ImageSizePipe } from 'libs/pipes/image-size.pipe';
import { CardBorderStyleEnum } from 'libs/components/atoms/card/card.enum';
import { LandlordInfoData } from 'libs/components/molecules/landlord-info/landlord-info.model';
import { imageExtension } from 'libs/config';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, DecimalPipe, CurrencyPipe } from '@angular/common';
import { DateTimePipe } from '../../../../pipes/date-time.pipe';
import { AddressPipe } from '../../../../pipes/address.pipe';
import { LandlordInfoComponent } from '../../landlord-info/landlord-info.component';
import { BadgeComponent } from '../../../atoms/badge/badge.component';
import { ApplicationStatusPipelineComponent } from '../../status-pipelines/application-status-pipeline/application-status-pipeline.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ContextMenuItemComponent } from '../../context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../context-menu/context-menu.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { PropertyCardPropertyData } from './property-card.model';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
  standalone: true,
  imports: [
    CardComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    ButtonComponent,
    ImageComponent,
    ApplicationStatusPipelineComponent,
    BadgeComponent,
    NgClass,
    LandlordInfoComponent,
    NgbTooltip,
    DecimalPipe,
    CurrencyPipe,
    TranslateModule,
    AddressPipe,
    DateTimePipe
  ]
})
export class PropertyCardComponent implements OnInit {
  private lightbox = inject(Lightbox);
  private gallery = inject(Gallery);
  private imageSizePipe = inject(ImageSizePipe);
  private sanitizer = inject(DomSanitizer);
  private translate = inject(TranslateService);

  @Input() propertyData: PropertyCardPropertyData;
  @Input() landlordInfoData: LandlordInfoData;

  @Input() pageView = false;
  @Input() isRented = false;
  @Input() userIsTenant = false;
  @Input() actionNeeded = false;
  @Input() isApplyPage = false;
  @Input() isProposal = false;
  @Input() isProcessing = false;
  @Input() isShowSelfDisclosure = false;
  @Input() isNotBlocked = false;
  @Input() fadeOutCard = false;
  @Input() elevation: ElevationType = Elevation.ZERO;
  @Input() withoutBorder = false;

  @Input() hasCustomQuestions = false;
  @Input() hasUnansweredQuestions = false;
  @Input() hasDeclaredIntention = false;
  @Input() hasDeniedIntention = false;

  @Input() showAddress = false;
  @Input() showLandlordInfo = true;
  @Input() showAvailableFromDate = true;
  @Input() showExternalId = true;

  @Input() enableContextMenu = true;
  @Input() enableAppointmentSelection = false;
  @Input() enableImageGallery = false;
  @Input() enableExportAppointmentToCalendar = false;
  @Input() enableDownloadPDF = false;

  @Input() applicationStatus: ApplicantStatus | null;
  @Input() actionBadgeContent = '';

  @Output() notificationBellClickEvent = new EventEmitter();
  @Output() answerCustomQuestionsClickEvent = new EventEmitter();
  @Output() declareIntentClickEvent = new EventEmitter();
  @Output() denyIntentClickEvent = new EventEmitter();
  @Output() exportAppointmentToCalendarClickEvent = new EventEmitter();
  @Output() downloadPDFClickEvent = new EventEmitter();
  @Output() removePropertyClickEvent = new EventEmitter();
  @Output() selectAppointmentClickEvent = new EventEmitter();
  @Output() showSelfDisclosureClickEvent = new EventEmitter();
  @Output() showDetailsClickEvent = new EventEmitter();

  public baseClass = 'property-card';
  public imageGalleryItems: GalleryItem[];
  public IconTypeEnum = IconTypeEnum;

  public images: Attachment[] = [];
  public hasImages = false;

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      ['page-view']: this.pageView
    });
  }

  public getElevation(): ElevationType {
    return this.pageView ? Elevation.ZERO : this.elevation;
  }

  public getContextMenuClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__context-menu`,
      {
        hide: this.hideButtonsInitially
      }
    );
  }

  public getDownloadPDFButtonClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__download-pdf-button`,
      {
        hide: this.hideButtonsInitially
      }
    );
  }

  public getBodyClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__body`,
      {
        ['out-faded']: !this.pageView && this.fadeOutCard
      }
    );
  }

  public getImageClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__image`,
      {
        clickable: this.hasImages
      }
    );
  }

  public get cardBorderStyle(): CardBorderStyleEnum {
    return this.pageView || this.withoutBorder
      ? CardBorderStyleEnum.NONE
      : this.highlightCardAsActionNeeded
        ? CardBorderStyleEnum.ACTIVE
        : this.highlightCardAsUserIsTenant
          ? CardBorderStyleEnum.WARNING
          : CardBorderStyleEnum.DEFAULT;
  }

  public get cardBorderRadius(): boolean {
    return !this.pageView;
  }

  public get hideButtonsInitially(): boolean {
    return !this.pageView && !isTouchScreen();
  }

  public get highlightCardAsActionNeeded() {
    return !this.pageView && this.actionNeeded;
  }

  public get highlightCardAsUserIsTenant() {
    return !this.pageView && this.userIsTenant;
  }

  public get showImageGallery(): boolean {
    return this.pageView && this.hasImages && this.enableImageGallery;
  }

  public get showApplicationStatusPipeline(): boolean {
    return this.isNotNullAndUndefined(this.applicationStatus);
  }

  public get showContextMenu(): boolean {
    return this.enableContextMenu && !this.isApplyPage && !this.isProposal;
  }

  public get getBackgroundImage() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${this.propertyData.attachments?.[0]?.url})`
    );
  }

  public get galleryButtonLabel() {
    const pictureCount = this.propertyData.attachments?.length;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.translate.instant(
      `property.gallery_button_${pictureCount === 1 ? 'singular' : 'plural'}_l`
    );
  }

  public get isRentalObject(): boolean {
    return isRentalObject(this.propertyData?.marketingType);
  }

  public checkDateAndStringValidity(availableFrom: AvailableFrom): boolean {
    if (
      isValueNotNullAndUndefined(
        availableFrom?.dateAvailable || availableFrom?.stringAvailable
      ) &&
      (availableFrom?.stringAvailable !== '' || availableFrom?.dateAvailable)
    ) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.images = [
      ...this.propertyData.attachments,
      ...(this.propertyData.documents || []).filter(a => {
        if (!a.extension) return false;
        return imageExtension.includes(a.extension.toLowerCase());
      })
    ];

    this.hasImages = this.images.length > 0;

    if (this.enableImageGallery) {
      this.loadLightbox(this.images);
    }
  }

  public loadLightbox(attachments: Attachment[]) {
    this.imageGalleryItems = attachments?.map(attachment => {
      return new ImageItem({
        src: attachment?.url,
        thumb: this.imageSizePipe.transform(attachment, 'S')
      });
    });

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Bottom
    });
    // Load items into the lightbox gallery ref
    lightboxRef.load(this.imageGalleryItems);
  }

  public isNotNullAndUndefined(value: any) {
    return isValueNotNullAndUndefined(value);
  }

  public notificationBellClick(): void {
    this.notificationBellClickEvent.emit();
  }

  public answerCustomQuestionsClick(): void {
    this.answerCustomQuestionsClickEvent.emit();
  }

  public declareIntentClick(): void {
    this.declareIntentClickEvent.emit();
  }

  public denyIntentClick(): void {
    this.denyIntentClickEvent.emit();
  }

  public exportAppointmentToCalendarClick(): void {
    this.exportAppointmentToCalendarClickEvent.emit();
  }

  public downloadPDFClick(): void {
    this.downloadPDFClickEvent.emit();
  }

  public removePropertyClick(): void {
    this.removePropertyClickEvent.emit();
  }

  public showSelfDisclosureClick(): void {
    this.showSelfDisclosureClickEvent.emit();
  }

  public selectAppointmentClick(): void {
    this.selectAppointmentClickEvent.emit();
  }

  public showDetailsClick(): void {
    this.showDetailsClickEvent.emit();
  }

  public showImageGalleryClick() {
    if (!this.showImageGallery) return;
    this.lightbox.open(0, 'lightbox', {
      panelClass: 'fullscreen'
    });
  }
}
