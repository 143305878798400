import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PropertyBean,
  PropertyMatchBean,
  SearchProfile
} from '@ui/shared/models';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import * as fromPropertyMatchesActions from 'tenant-pool/+state/property-matches/property-matches.actions';
import * as fromPropertyMatchesSelector from 'tenant-pool/+state/property-matches/property-matches.selector';
import * as fromSearchProfileActions from 'tenant-pool/+state/search-profiles/search-profiles.actions';
import * as fromSearchProfileSelector from 'tenant-pool/+state/search-profiles/search-profiles.selectors';
import { Store } from '@ngrx/store';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ActionState } from 'libs/state-utils';
import { MainPageNavigation } from 'tenant-pool/config';
import {
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeGarage
} from 'libs/utils';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { AddressPipe } from 'libs/pipes';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-tenant-acceptance-modal',
  templateUrl: './tenant-acceptance-modal.component.html',
  styleUrls: ['./tenant-acceptance-modal.component.scss'],
  standalone: true,
  imports: [
    ComponentsModule,
    LottieComponent,
    SvgIconComponent,
    FormsModule,
    TranslateModule,
    AsyncPipe,
    CurrencyPipe,
    AddressPipe
  ]
})
export class TenantAcceptanceModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);
  private store = inject<Store<fromBaseState.BaseState>>(Store);

  @Input() property: PropertyBean;
  @ViewChild('lottieAnimation') lottieAnimation: ElementRef;

  public lottieConfig: AnimationOptions;
  public activateSearchProfile = false;
  public closeOpenApplication = true;
  public searchProfiles$: Observable<SearchProfile[]>;
  public searchProfilesActionState$: Observable<ActionState>;
  public propertiesMatches$: Observable<PropertyMatchBean[]>;
  public propertiesMatchesActionState$: Observable<ActionState>;

  public get isFlat() {
    return isPropertyTypeFlat(this.property?.type);
  }

  public get isGarage() {
    return isPropertyTypeGarage(this.property?.type);
  }

  public get isCommercial() {
    return isPropertyTypeCommercial(this.property?.type);
  }

  public get haveFunWithPropertyTranslation() {
    return this.isFlat
      ? 'new-home.have_fun_with_new_flat_l'
      : this.isCommercial
        ? 'new-home.have_fun_with_new_commercial_l'
        : this.isGarage
          ? 'new-home.have_fun_with_new_garage_l'
          : '';
  }

  ngOnInit() {
    this.store.dispatch(
      new fromBaseState.Go({
        path: [MainPageNavigation.NEW_HOME]
      })
    );
    this.store.dispatch(new fromSearchProfileActions.LoadSearchProfiles());
    this.store.dispatch(
      fromPropertyMatchesActions.LoadPropertyMatchesBeans({
        data: { page: 0, size: 15, sort: 'id,asc' },
        loadMore: false
      })
    );
    this.propertiesMatches$ = this.store
      .select(fromPropertyMatchesSelector.getPropertyMatchesData)
      .pipe(shareReplay(1));
    this.propertiesMatchesActionState$ = this.store.select(
      fromPropertyMatchesSelector.getPropertyMatchesDataActionState
    );
    this.searchProfiles$ = this.store.select(
      fromSearchProfileSelector.getSearchProfiles
    );
    this.searchProfilesActionState$ = this.store.select(
      fromSearchProfileSelector.getSearchProfilesActionState
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.lottieConfig = {
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/data.json' // the path to the animation json
    };
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    this.ngbActiveModal.close({
      activateSearchProfile: this.activateSearchProfile,
      closeOpenApplication: this.closeOpenApplication
    });
  }

  public get picture() {
    return this.property?.titleImage || this.property?.data?.attachments?.[0];
  }
}
