import {
  DigitalContract,
  DocuSignResponse,
  CurrentState
} from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import * as fromActions from './digital-contract.actions';

export interface DigitalContractState {
  digitalContract: DigitalContract;
  digitalContractActionState: ActionState;
  docuSignResponse: DocuSignResponse;
  digitalContractPageNav: string;
  currentDigitalContractIndex: number;
  currentStepNumber: number;
  tenantInformationActionState: ActionState;
  sendMessageActionState: ActionState;
  currentStatus: CurrentState;
  getSigningActionState: ActionState;
  getAesStatusActionState: ActionState;

  qesActionState: ActionState;
  qesLink: string;
}

export const initialState: DigitalContractState = {
  digitalContract: null,
  docuSignResponse: null,
  digitalContractActionState: ActionStateCreator.create(),
  digitalContractPageNav: 'OPEN',
  currentDigitalContractIndex: 0,
  currentStepNumber: 1,
  tenantInformationActionState: ActionStateCreator.create(),
  sendMessageActionState: ActionStateCreator.create(),
  currentStatus: null,
  getSigningActionState: ActionStateCreator.create(),
  getAesStatusActionState: ActionStateCreator.create(),

  qesActionState: ActionStateCreator.create(),
  qesLink: ''
};

export function reducer(
  state = initialState,
  action: fromActions.digitalContractsAction
): DigitalContractState {
  switch (action.type) {
    case fromActions.LOAD_DIGITAL_CONTRACT: {
      return {
        ...state,
        digitalContractActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_DIGITAL_CONTRACT_FAIL: {
      return {
        ...state,
        digitalContractActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.LOAD_DIGITAL_CONTRACT_SUCCESS: {
      return {
        ...state,
        digitalContractActionState: ActionStateCreator.onSuccess(),
        digitalContract: action.contract
      };
    }

    case fromActions.SEND_TENANT_INFO_SUCCESS: {
      return {
        ...state,
        tenantInformationActionState: ActionStateCreator.onSuccess(),
        docuSignResponse: action.docuSignResponse
      };
    }

    case fromActions.SEND_TENANT_INFO: {
      return {
        ...state,
        tenantInformationActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SEND_TENANT_INFO_FAIL: {
      return {
        ...state,
        tenantInformationActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SEND_MESSAGE: {
      return {
        ...state,
        sendMessageActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        sendMessageActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.SEND_MESSAGE_FAIL: {
      return {
        ...state,
        sendMessageActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.GET_SIGNING_URL: {
      return {
        ...state,
        getSigningActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.GET_SIGNING_URL_SUCCESS: {
      return {
        ...state,
        getSigningActionState: ActionStateCreator.onSuccess(),
        docuSignResponse: action.docuSignResponse
      };
    }

    case fromActions.GET_SIGNING_URL_FAIL: {
      return {
        ...state,
        getSigningActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.GET_CURRENT_STATE: {
      return {
        ...state,
        getAesStatusActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.GET_CURRENT_STATE_FAIL: {
      return {
        ...state,
        getAesStatusActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.GET_CURRENT_STATE_SUCCESS: {
      return {
        ...state,
        currentStatus: action.currentState,
        getAesStatusActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.START_QES_CHECK: {
      return {
        ...state,
        qesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.START_QES_CHECK_SUCCESS: {
      return {
        ...state,
        qesLink: action.link,
        qesActionState: ActionStateCreator.onSuccess()
      };
    }
    case fromActions.START_QES_CHECK_FAIL: {
      return {
        ...state,
        qesActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.DIGITAL_CONTRACT_WIZARD_NEXT_STEP: {
      return {
        ...state,
        currentStepNumber: state.currentStepNumber + 1
      };
    }

    case fromActions.DIGITAL_CONTRACT_WIZARD_PREVIOUS_STEP: {
      return {
        ...state,
        currentStepNumber:
          state.currentStepNumber > 0 ? state.currentStepNumber - 1 : 0
      };
    }

    case fromActions.DIGITAL_CONTRACT_WIZARD_GO_TO_STEP: {
      return {
        ...state,
        currentStepNumber: action.stepNumber
      };
    }

    default:
      return state;
  }
}

export const getDigitalContract = (state: DigitalContractState) =>
  state.digitalContract;
export const getDigitalContractLoading = (state: DigitalContractState) =>
  state.digitalContractActionState.pending;
export const getTenantInformationActionState = (state: DigitalContractState) =>
  state.tenantInformationActionState;
export const getMessageSending = (state: DigitalContractState) =>
  state.sendMessageActionState.pending;
export const getContractWizardStepNumber = (state: DigitalContractState) =>
  state.currentStepNumber;
export const getDocuSignResponse = (state: DigitalContractState) =>
  state.docuSignResponse;

export const getSigningUrlLoading = (state: DigitalContractState) =>
  state.getSigningActionState.pending;
export const getTenantInformationLoading = (state: DigitalContractState) =>
  state.tenantInformationActionState.pending;
export const getAesStatusLoading = (state: DigitalContractState) =>
  state.getAesStatusActionState.pending;
export const getAesCheckState = (state: DigitalContractState) =>
  state.currentStatus;
export const getQesActionState = (state: DigitalContractState) =>
  state.qesActionState;
export const getQesLink = (state: DigitalContractState) => state.qesLink;
