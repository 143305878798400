import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ConstantsLoader, ConstantsParserService } from 'libs/infrastructure';
import { Constants } from '@ui/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { constantsList, ConstantsListResult } from 'tenant-pool/core/queries';

@Injectable()
export class ConstantsLoaderService extends ConstantsLoader {
  private apollo = inject(Apollo);
  private constantsParser = inject(ConstantsParserService);

  load(): Observable<Constants> {
    return this.apollo
      .query<ConstantsListResult>({ query: constantsList })
      .pipe(
        map(result => {
          const data = result.data.getAllConstants;
          return this.constantsParser.parse(data);
        })
      );
  }
}
