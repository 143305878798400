import { Injectable, inject } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { TooltipApp } from '@ui/shared/models';
import { map } from 'rxjs/operators';
import {
  tooltipsSeenMutation,
  tooltipsUnseenQuery,
  TooltipsUnseenResponse,
  tooltipToursUnseenQuery,
  TooltipToursUnseenResponse
} from '../queries/tooltip.queries';

@Injectable()
export class TooltipFacade {
  private apollo = inject(Apollo);

  private tooltipsUnseenQuery: QueryRef<TooltipsUnseenResponse>;
  private tooltipToursUnseenQuery: QueryRef<TooltipToursUnseenResponse>;

  public tooltipsUnseen(path: string) {
    this.tooltipsUnseenQuery = this.apollo.watchQuery<TooltipsUnseenResponse>({
      query: tooltipsUnseenQuery,
      variables: {
        input: {
          path: path,
          app: TooltipApp.PROPERTY_SEARCHER
        }
      }
    });

    return this.tooltipsUnseenQuery.valueChanges.pipe(
      map(response => response.data.tooltipsUnseen)
    );
  }

  public tooltipToursUnseen(path: string) {
    this.tooltipToursUnseenQuery =
      this.apollo.watchQuery<TooltipToursUnseenResponse>({
        query: tooltipToursUnseenQuery,
        variables: {
          input: {
            path: path,
            app: TooltipApp.PROPERTY_SEARCHER
          }
        }
      });

    return this.tooltipToursUnseenQuery.valueChanges.pipe(
      map(response => response.data.tooltipToursUnseen)
    );
  }

  public tooltipsSeen(ids: number[]) {
    return this.apollo.mutate({
      mutation: tooltipsSeenMutation,
      variables: {
        input: {
          tooltipIdsSeen: ids,
          app: TooltipApp.PROPERTY_SEARCHER
        }
      }
    });
  }
}
