import { AppointmentsBundles } from '@ui/shared/models';
import { gql } from 'apollo-angular';

export const appointmentFields = `
    applicationId
    applicationStatus
    property {
      propertyId
      address {
        city
        country
        countryName
        region
        street
        houseNumber
        zipCode
      }
      name
      image {
        url
        title
        type
        identifier
        extension
        encrypted
      }
      customerLogo
      customerName
    }
    appointments {
      id
      created
      updated
      date
      state
      maxInviteeCount
      showContactInformation
      full
      sizeOfAttendees
      specialInstructions
      exclusiveAttendees {
        firstname
        lastname
        state
      }
      contact {
        firstName
        name
        email
        mobile
        phone
        address {
          city
          country
          countryName
          region
          street
          houseNumber
          zipCode
        }
        fullName
      }
    }
    appointmentAcceptances {
      id
      state
      appointmentId
    }
    appointmentInvitations {
      id
      appointmentId
    }
`;

export interface AppointmentsResult {
  getAppointmentsBundles: AppointmentsBundles[];
  getOfflineUserAppointmentsBundles: AppointmentsBundles;
}

export const appointmentsBundlesQuery = gql`
  query getAppointmentsBundles($userId: ID!) {
    getAppointmentsBundles(userId: $userId) {
      ${appointmentFields}
    }
  }
`;

export const selectAppointmentMutation = gql`
  mutation selectAppointment($input: SelectAppointmentInput) {
    selectAppointment(input: $input) {
      status
    }
  }
`;

export const cancelAppointmentsMutation = gql`
  mutation cancelAppointments($input: CancelAppointmentDataInput) {
    cancelAppointments(input: $input) {
      status
      statusText
    }
  }
`;

export const cancelAppointmentMutation = gql`
  mutation cancelAppointment($input: CancelAppointmentDataInput) {
    cancelAppointment(input: $input) {
      status
    }
  }
`;

export const switchAppointmentMutation = gql`
  mutation switchAppointment($input: SelectAppointmentInput) {
    switchAppointment(input: $input) {
      status
    }
  }
`;

// Same as above with different names for Offline users
// to differentiate between them

export const offlineUserAppointmentsBundlesQuery = gql`
  query getOfflineUserAppointmentsBundles($applicationId: ID!) {
    getOfflineUserAppointmentsBundles(applicationId: $applicationId) {
      ${appointmentFields}
    }
  }
`;

export const offlineUserSelectAppointmentMutation = gql`
  mutation selectOfflineUserAppointment($input: SelectAppointmentInput) {
    selectOfflineUserAppointment(input: $input)
  }
`;

export const registrationReminderMutation = gql`
  mutation registrationReminder($applications: [ID]) {
    registrationReminder(applications: $applications) {
      status
      statusText
    }
  }
`;

export const offlineUserCancelAppointmentMutation = gql`
  mutation cancelOfflineUserAppointment(
    $cancelAppointmentData: CancelAppointmentDataInput
  ) {
    cancelOfflineUserAppointment(
      cancelAppointmentDataInput: $cancelAppointmentData
    )
  }
`;

export const offlineUserSwitchAppointmentMutation = gql`
  mutation switchOfflineUserAppointment($input: SelectAppointmentInput) {
    switchOfflineUserAppointment(input: $input)
  }
`;
