import { createSelector } from '@ngrx/store';
import { getSelfDisclosureState as state } from '../reducers';
import * as fromReducer from './self-disclosure.reducers';

export const getSelfDisclosureModel = createSelector(
  state,
  fromReducer.getSelfDisclosureModel
);
export const getSelfDisclosureResponses = createSelector(
  state,
  fromReducer.getSelfDisclosureResponses
);
export const getLoadModelActionState = createSelector(
  state,
  fromReducer.getLoadModelActionState
);
export const getSaveSelfDisclosureAnswersActionState = createSelector(
  state,
  fromReducer.getSaveSelfDisclosureAnswersActionState
);
export const getLoadResponsesActionState = createSelector(
  state,
  fromReducer.getLoadResponsesActionState
);
export const getSelfDisclosurePropertySearcher = createSelector(
  state,
  fromReducer.getSelfDisclosurePropertySearcher
);
