<app-modal-v2>
  <div class="modal-m automated-rental-modal">
    <app-modal-content>
      <div class="new-home__modal d-flex flex-column">
        <div class="title-m mb30 text-center new-home__header">
          <p class="new-home__header-headline m0">
            {{ 'new-home.you_are_tenant_title_l' | translate }}
          </p>
          <app-button
            [type]="'context-menu'"
            [iconLeft]="'close'"
            class="close-btn"
            (clickEvent)="close()"
          >
          </app-button>
        </div>
        <div class="title-xxl mb30 text-center">
          {{ 'new-home.what_now_l' | translate }}
        </div>
        <div>
          <div class="d-flex flex-row new-home__info-area mb0">
            <div class="col-2 pl0 d-flex justify-content-center p7">
              <svg-icon
                src="/assets/images/icons/icon-my-home.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 35, 'height.px': 35 }"
                class="primary-text-svg-color"
              ></svg-icon>
            </div>
            <div class="col-10 pl0 pr0">
              <strong>{{
                'new-home.go_to_new_home_info_title_l' | translate
              }}</strong>
              <p class="mb20">
                {{ 'new-home.go_to_new_home_info_description_l' | translate }}
              </p>
            </div>
          </div>
          <app-button [type]="'primary'" (clickEvent)="goToNewHome()">
            {{ 'new-home.go_to_new_home_info_action_l' | translate }}
          </app-button>
        </div>
        @if (showAssistantRedirect) {
          <hr class="w-100 mt15 mb15" />
          <div>
            <div class="d-flex flex-row new-home__info-area mb0">
              <div class="col-2 pl0 d-flex justify-content-center p7">
                <svg-icon
                  src="/assets/images/icons/icon-moving.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 35, 'height.px': 35 }"
                  class="primary-text-svg-color"
                ></svg-icon>
              </div>
              <div class="col-10 pl0 pr0">
                <strong>{{
                  'new-home.mooving_assistant_info_title_l' | translate
                }}</strong>
                <p class="mb20">
                  {{
                    'new-home.mooving_assistant_info_description_l' | translate
                  }}
                </p>
              </div>
            </div>
            <app-button [type]="'primary'" (clickEvent)="goToMovingAssistant()">
              {{ 'new-home.mooving_assistant_info_action_l' | translate }}
            </app-button>
          </div>
        }
      </div>
    </app-modal-content>
  </div>
</app-modal-v2>
