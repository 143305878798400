import { createSelector } from '@ngrx/store';
import { getMovingAuctionState } from 'tenant-pool/+state/reducers';
import * as fromReducer from './moving-auction.reducer';

export const getCurrentMovingAuctionWizardStep = createSelector(
  getMovingAuctionState,
  fromReducer.getCurrentMovingAuctionWizardStep
);
export const getMovingAuctionResponse = createSelector(
  getMovingAuctionState,
  fromReducer.getMovingAuctionResponse
);
export const getSendRequestActionState = createSelector(
  getMovingAuctionState,
  fromReducer.getSendRequestActionState
);
export const getRedirect = createSelector(
  getMovingAuctionState,
  fromReducer.getRedirect
);
