import { gql } from 'apollo-angular';
import {
  landlordDistrictGroup,
  landlordObjectTypes
} from 'libs/infrastructure/base-state';

export const landlordBrandingQuery = gql`
  query landlordBranding($input: ValidateTokenInput!) {
    landlordBranding(input: $input) {
      name
      primaryColor
      secondaryColor
      primaryTextColor
      secondaryTextColor
      buttonTextColor
      backgroundColor
      cardBackgroundColor
      active
      customerId
      onlyShowConfiguredCityPartsToUser
      allowUserToLeaveItp
      customerName
      tokenId
      logo {
        url
        title
      }
      favicon {
        url
      }
      logoRedirectUrl
      districtGroup {
        ${landlordDistrictGroup}
      }
      objectTypes {
        ${landlordObjectTypes}
      }
    }
  }
`;

export const customerBrandingQuery = gql`
  query landlordBranding($customerId: ID) {
    customerBranding(customerId: $customerId) {
      name
      logo
    }
  }
`;
