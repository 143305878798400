<app-button
  [elevation]="1"
  [type]="currentAmount | quotaStyle: alwaysShowNumber"
  [iconLeft]="currentAmount | quotaIcon: alwaysShowNumber"
  [useDefaultCursor]="useDefaultCursor"
  ghost="true"
  (clickEvent)="onClick()"
>
  {{
    currentAmount
      | quotaTranslation: positiveQuota : emptyQuota : negativeQuota
      | translate
  }}
  {{ currentAmount | quotaNumber: alwaysShowNumber }}
</app-button>
