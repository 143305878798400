import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  ManualPaymentInput,
  PaymentExecutionInput,
  SmartDepositLegitimationInput,
  SmartDepositRequestOfferInput
} from '@ui/shared/models';
import { map } from 'rxjs';
import {
  applySmartDepositMutation,
  ApplySmartDepositResponse,
  cancelSmartDepositLegitimationMutation,
  cancelSmartDepositMutation,
  fetchMandateQuery,
  FetchMandateResponse,
  fetchPaymentBrandingQuery,
  FetchPaymentBrandingResponse,
  fetchPaymentRequestQuery,
  FetchPaymentRequestResponse,
  fetchSmartDepositQuery,
  FetchSmartDepositResponse,
  LoadSmartDepositBrandingResponse,
  LoadSmartDepositDictionaryResponse,
  LoadSmartDepositFeeResponse,
  manualPaymentMutation,
  paymentExecutionMutation,
  PaymentExecutionResponse,
  smartDepositBrandingQuery,
  smartDepositDictionaryQuery,
  smartDepositFeeQuery,
  startSmartDepositLegitimationMutation
} from '../queries/payments.queries';

@Injectable()
export class PaymentsFacade {
  private apollo = inject(Apollo);

  public fetchPaymentRequest(token: string) {
    const fetchPaymentRequestQueryRef =
      this.apollo.watchQuery<FetchPaymentRequestResponse>({
        query: fetchPaymentRequestQuery,
        variables: {
          token
        },
        fetchPolicy: 'no-cache'
      });

    return fetchPaymentRequestQueryRef.valueChanges.pipe(
      map(response => response.data.fetchPaymentRequest)
    );
  }

  public fetchMandate(token: string, mandateId: string) {
    const fetchMandateQueryRef = this.apollo.watchQuery<FetchMandateResponse>({
      query: fetchMandateQuery,
      variables: {
        token,
        mandateId
      },
      fetchPolicy: 'no-cache'
    });

    return fetchMandateQueryRef.valueChanges.pipe(
      map(response => response.data.fetchMandate)
    );
  }

  public fetchPaymentBranding(token: string) {
    const fetchPaymentBrandingQueryRef =
      this.apollo.watchQuery<FetchPaymentBrandingResponse>({
        query: fetchPaymentBrandingQuery,
        variables: {
          token
        },
        fetchPolicy: 'no-cache'
      });

    return fetchPaymentBrandingQueryRef.valueChanges.pipe(
      map(response => response.data.fetchPaymentBranding)
    );
  }

  public fetchSmartDeposit(token: string) {
    const fetchSmartDepositQueryRef =
      this.apollo.watchQuery<FetchSmartDepositResponse>({
        query: fetchSmartDepositQuery,
        variables: {
          token
        },
        fetchPolicy: 'no-cache'
      });

    return fetchSmartDepositQueryRef.valueChanges.pipe(
      map(response => response.data.fetchSmartDeposit)
    );
  }

  public loadSmartDepositBranding(token: string) {
    return this.apollo
      .query<LoadSmartDepositBrandingResponse>({
        query: smartDepositBrandingQuery,
        variables: {
          token
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.smartDepositBranding));
  }

  public manualPayment(input: ManualPaymentInput) {
    return this.apollo.mutate({
      mutation: manualPaymentMutation,
      variables: {
        input
      }
    });
  }

  public paymentExecution(input: PaymentExecutionInput) {
    return this.apollo
      .mutate<PaymentExecutionResponse>({
        mutation: paymentExecutionMutation,
        variables: {
          input
        }
      })
      .pipe(map(response => response.data.paymentExecution));
  }

  public loadSmartDepositFee(deposit: number) {
    return this.apollo
      .query<LoadSmartDepositFeeResponse>({
        query: smartDepositFeeQuery,
        variables: { deposit },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.smartDepositFee));
  }

  public applySmartDeposit(
    token: string,
    smartDepositApplicationPayload: SmartDepositRequestOfferInput
  ) {
    return this.apollo
      .mutate<ApplySmartDepositResponse>({
        mutation: applySmartDepositMutation,
        variables: {
          token,
          smartDepositApplicationPayload
        }
      })
      .pipe(map(response => response.data.applySmartDeposit));
  }

  public cancelSmartDeposit(token: string) {
    return this.apollo.mutate({
      mutation: cancelSmartDepositMutation,
      variables: {
        token
      }
    });
  }

  public startSmartDepositLegitimation(
    token: string,
    input: SmartDepositLegitimationInput
  ) {
    return this.apollo.mutate({
      mutation: startSmartDepositLegitimationMutation,
      variables: {
        token,
        input
      }
    });
  }

  public cancelSmartDepositLegitimation(token: string) {
    return this.apollo.mutate({
      mutation: cancelSmartDepositLegitimationMutation,
      variables: {
        token
      }
    });
  }

  public loadSmartDepositDictionary() {
    return this.apollo
      .query<LoadSmartDepositDictionaryResponse>({
        query: smartDepositDictionaryQuery,
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.smartDepositDictionary));
  }
}
