<div class="self-disclosure-flat__content">
  <form [formGroup]="getFormControl">
    <div class="mt25 mb10">{{ selfDisclosureQuestion.title | translate }}</div>

    <div class="row">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label>{{
            'general.street_name' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="text"
            [placeholder]="'forms.type_street_name' | translate"
            formControlName="street"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-6">
        <app-form-field>
          <app-form-field-label>{{
            'general.city_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="text"
            [placeholder]="'forms.type_city' | translate"
            formControlName="city"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label>{{
            'general.flat_number_of_rooms_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="number"
            [placeholder]="'forms.type_number_of_rooms' | translate"
            formControlName="numberOfRooms"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-6">
        <app-form-field>
          <app-form-field-label>{{
            'general.area_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="number"
            [placeholder]="'forms.type_size_in_sq' | translate"
            formControlName="sizeInSm"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label>{{
            'general.total_rent_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="number"
            [placeholder]="'forms.type_total_rent' | translate"
            formControlName="totalRent"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>
  </form>
</div>
