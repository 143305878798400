import { gql } from 'apollo-angular';
import { ServiceCard } from '@ui/shared/models';

export interface ServiceCardsResponse {
  serviceCards: ServiceCard[];
}

export interface MilesCardAllowedResponse {
  milesCardAllowed: {
    milesAllowed: boolean;
  };
}

export const basicServiceCardFields = `
  cardType
  activationCode
  registrationLink
  headline
  description
`;

export const serviceCardsQuery = gql`
  query serviceCards {
    serviceCards {
      ${basicServiceCardFields}
    }
  }
`;

export const milesCardAllowedQuery = gql`
  query milesCardAllowed {
    milesCardAllowed {
      milesAllowed
    }
  }
`;
