import { createSelector } from '@ngrx/store';
import { getAppointmentsState } from '../reducers';
import * as fromReducer from './appointments.reducers';

export const getAppointmentsData = createSelector(
  getAppointmentsState,
  fromReducer.getAppointmentsData
);
export const getAppointmentsBundleById = createSelector(
  getAppointmentsData,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  (appointments, { id }) => appointments.find(a => a.applicationId === id)
);
export const getAppointmentsActionState = createSelector(
  getAppointmentsState,
  fromReducer.getAppointmentsActionState
);
export const getAppointmentsCount = createSelector(
  getAppointmentsData,
  appointments =>
    appointments.filter(item => item?.appointments?.length > 0).length
);
export const getAppointmentsItemActionState = createSelector(
  getAppointmentsState,
  fromReducer.getAppointmentsItemActionState
);
