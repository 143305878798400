<div
  ngbDropdown
  [placement]="placement"
  [autoClose]="'outside'"
  class="dropdown-multiselect__container"
  (openChange)="setShowFilters($event)"
>
  <button
    type="button"
    class="btn d-flex justify-content-between align-items-center"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <span class="dropdown-toggle__inner">
      @if (!selectedItems.length) {
        {{ placeholder | translate }}
      }
      @if (selectedItems.length > maxDisplayCountOfSelectedItems) {
        {{
          itemsSelectedPlaceholder | translate: { count: selectedItems.length }
        }}
      }
      @if (
        selectedItems.length > 0 &&
        selectedItems.length <= maxDisplayCountOfSelectedItems
      ) {
        @for (
          selectedItem of selectedItems;
          track selectedItem.name;
          let selectedItemIndex = $index
        ) {
          @if (selectedItemIndex > 0 && textDelimiterForSelectedItems) {
            <span class="pe-1">, </span>
          }
          <span
            [ngClass]="{
              'ms-1': selectedItemIndex > 0 && !textDelimiterForSelectedItems
            }"
          >
            @if (selectedItems[selectedItemIndex].badgeColor) {
              <app-badge
                [color]="selectedItems[selectedItemIndex].badgeColor"
                class="d-inline-block"
              >
                <ng-container
                  *ngTemplateOutlet="plainTextSelectedItem"
                ></ng-container>
              </app-badge>
            } @else {
              {{ selectedItems[selectedItemIndex].name | translate }}
              <div
                [ngClass]="[
                  'symbol',
                  selectedItems[selectedItemIndex].value
                    ? 'symbol-true'
                    : 'symbol-false'
                ]"
              ></div>
            }
            <ng-template #plainTextSelectedItem>
              {{ selectedItems[selectedItemIndex].name | translate }}
              <div
                [ngClass]="[
                  'symbol',
                  selectedItems[selectedItemIndex].value
                    ? 'symbol-true'
                    : 'symbol-false'
                ]"
              ></div>
            </ng-template>
          </span>
        }
      }
    </span>

    <div class="d-flex align-items-center">
      @if (selectedItems.length || !form.pristine) {
        <i class="icon icon--close ms-2" (click)="clear($event)"></i>
      }
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>

  <div ngbDropdownMenu>
    <div class="dropdown__items" [formGroup]="form">
      @for (
        group of items | appKeyValueMaintainOrder;
        track group.value.title;
        let i = $index
      ) {
        <p class="uppercase-m semibold">{{ group.value.title | translate }}</p>
        @for (item of group.value.items; track item.name) {
          <app-form-field>
            <div class="checkbox-group">
              <app-check-intermediate
                class="dropdown__check"
                [formControlName]="item.value"
              >
                <app-form-field-label>
                  @if (item.badgeColor) {
                    <app-badge [color]="item.badgeColor">
                      <ng-container
                        *ngTemplateOutlet="plainTextItem"
                      ></ng-container>
                    </app-badge>
                  } @else {
                    {{ item.name | translate }}
                  }
                  <ng-template #plainTextItem
                    >{{ item.name | translate }}
                  </ng-template>
                </app-form-field-label>
              </app-check-intermediate>
            </div>
          </app-form-field>
        }
        @if (i < groupItemsLength - 1) {
          <hr />
        }
      }
    </div>
    @if (showApplyButton) {
      <div class="dropdown__actions">
        <button
          class="btn btn-apply"
          [disabled]="!groupItemsLength"
          (click)="apply()"
        >
          {{ 'APPLY_A' | translate }}
        </button>
      </div>
    }
  </div>
</div>
