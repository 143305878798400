import { createSelector } from '@ngrx/store';
import { getSchufaState } from 'tenant-pool/+state/reducers';
import * as fromReducer from './schufa.reducers';

export const getSchufaPersonalDataResponseData = createSelector(
  getSchufaState,
  fromReducer.getSchufaPersonalDataResponseData
);

export const getSchufaPersonalDataActionState = createSelector(
  getSchufaState,
  fromReducer.getSchufaPersonalDataActionState
);

export const getSchufaPaymentResponseData = createSelector(
  getSchufaState,
  fromReducer.getSchufaPaymentResponseData
);

export const getSchufaPaymentActionState = createSelector(
  getSchufaState,
  fromReducer.getSchufaPaymentActionState
);

export const getSchufaPaymentErrorCounter = createSelector(
  getSchufaState,
  fromReducer.getSchufaPaymentErrorCounter
);

export const getSchufaPaymentRetryCounter = createSelector(
  getSchufaState,
  fromReducer.getSchufaPaymentRetryCounter
);

export const getSchufaOrderResponseData = createSelector(
  getSchufaState,
  fromReducer.getSchufaOrderResponseData
);

export const getSchufaOrderActionState = createSelector(
  getSchufaState,
  fromReducer.getSchufaOrderActionState
);

export const getSchufaDeliveryResponseData = createSelector(
  getSchufaState,
  fromReducer.getSchufaDeliveryResponseData
);

export const getSchufaDeliveryActionState = createSelector(
  getSchufaState,
  fromReducer.getSchufaDeliveryActionState
);

export const getCurrentStepNumber = createSelector(
  getSchufaState,
  fromReducer.getCurrentStepNumber
);
export const getCompletedSteps = createSelector(
  getSchufaState,
  fromReducer.getCompletedSteps
);
