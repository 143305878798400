import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appReplace',
  standalone: true
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, replace: string, replaceWith: string): string {
    return replace && replaceWith ? value.replace(replace, replaceWith) : value;
  }
}
