import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  input,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ButtonTypeEnum } from 'libs/components/atoms/button/button.enum';
import {
  DropdownStepperChildren,
  DropdownStepperParent,
  IconTypeEnum
} from '@ui/shared/models';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu
} from '@ng-bootstrap/ng-bootstrap';
import { BaseControl } from 'libs/components/legacy/form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-single-select-dropdown-stepper',
  templateUrl: './single-select-dropdown-stepper.component.html',
  styleUrl: './single-select-dropdown-stepper.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectDropdownStepperComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SingleSelectDropdownStepperComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgClass,
    NgbDropdownMenu,
    ButtonComponent,
    TranslateModule
  ]
})
export class SingleSelectDropdownStepperComponent<T> extends BaseControl<T> {
  items = input.required<DropdownStepperParent<T>[]>();
  dropdownTitle = input.required<string>();
  placeholderText = input.required<string>();
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  selectedParent = signal<DropdownStepperParent<T> | undefined | null>(
    undefined
  );
  selectedChild = signal<DropdownStepperChildren<T> | undefined | null>(
    undefined
  );
  animationState = 'showParents';
  isTransitioningToManufacturers = false;
  isDropdownOpened = false;

  public writeValue(value?: T): void {
    super.writeValue(value);
    const selectedChild = this.findValueInItems(value);
    if (selectedChild) this.selectedChild.set(selectedChild);
  }

  set value(value: T) {
    super.value = value;
    const selectedChild = this.findValueInItems(value);
    if (selectedChild) this.selectedChild.set(selectedChild);
  }

  handleDropdownOpenChange(opened: boolean) {
    if (!opened) this.animationState = 'showParents';
    this.isDropdownOpened = opened;
  }

  selectParent(parent: DropdownStepperParent<T>): void {
    if (this.isTransitioningToManufacturers) return;

    this.selectedParent.set(parent);
    this.animationState = 'showChildren';
  }

  selectChild(child: DropdownStepperChildren<T>): void {
    this.value = child.value;
    this.selectedChild.set(child);
    this.dropdown.close();
  }

  backToParents(): void {
    this.isTransitioningToManufacturers = true;
    setTimeout(() => {
      // After the animation has finished we want to reset the selected parent
      // If we do this immediately the user would see that the child list looks broken because it's empty
      this.selectedParent.set(undefined);
      this.isTransitioningToManufacturers = false;
    }, 375);
    this.animationState = 'showParents';
  }

  clear(event?: Event): void {
    // When you don't have this, then the dropdown would be opened/closed
    // It stops the parent element from also receiving the click event
    if (event) event.stopPropagation();
    this.value = null;
    this.selectedChild.set(null);
  }

  private findValueInItems(value: T): DropdownStepperChildren<T> {
    return this.items()
      .flatMap(parent => parent.items)
      .find(child => child.value === value);
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IconTypeEnum = IconTypeEnum;
}
