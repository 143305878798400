<div class="row schufa-identification">
  <div class="col-12 mt20">
    <h5>{{ 'schufa.download_identify_title_l' | translate }}</h5>
    <div>{{ 'schufa.download_identify_description_l' | translate }}</div>
    @if (deliveryResponseError?.length > 0) {
      <div class="error mt10">
        {{ 'schufa.download_identify_error_l' | translate }}
      </div>
    }
  </div>

  <div class="col-12 schufa-identification__content">
    <form [formGroup]="form">
      <div class="mt10">
        <div class="schufa__identity-card-verify">
          <div class="d-flex row">
            <div class="col-12 mb10 d-flex">
              <app-form-field>
                <app-form-field-label>
                  {{ 'schufa.identity_card_type_l' | translate }}
                  <app-hint class="pl5">{{
                    'schufa.identity_card_hint_m' | translate
                  }}</app-hint>
                </app-form-field-label>
                <app-flat-select
                  [items]="options"
                  formControlName="idCardOld"
                  required
                >
                </app-flat-select>
              </app-form-field>
            </div>
          </div>
          <div class="schufa__identity-card-img mb10 col-12">
            @if (!idCardOld) {
              <div>
                @if (idCardImage) {
                  <img
                    [src]="'/assets/images/' + idCardImage + '.jpg'"
                    alt="identity-card-new"
                  />
                } @else {
                  <img
                    src="/assets/images/id_card_new_default.jpg"
                    alt="identity-card-new"
                  />
                }
              </div>
            } @else {
              @if (idCardImage) {
                <img
                  [src]="'/assets/images/' + idCardImage + '.jpg'"
                  alt="identity-card-old"
                />
              } @else {
                <img
                  src="/assets/images/id_card_old_default.jpg"
                  alt="identity-card-old"
                />
              }
            }
          </div>

          <div class="schufa__identity-card-input col-12">
            <strong>{{ 'schufa.identity_card_input_l' | translate }}</strong>
            <div>{{ 'schufa.identity_card_hint_l' | translate }}</div>

            <div
              class="schufa__identity-card-input-fields row mr0 ml0 d-flex mt12"
            >
              <div class="col-12 col-md-6 mb20 d-flex">
                <app-form-field>
                  <input
                    appInput
                    type="text"
                    required
                    [placeholder]="'forms.type_identity_field_a' | translate"
                    formControlName="idCardSerialNumber"
                    class="form-control"
                    (focusin)="onFocusIn(1)"
                    (focusout)="onFocusOut()"
                  />
                </app-form-field>
                <app-form-field-label
                  [showRequiredMarker]="true"
                ></app-form-field-label>
              </div>
              <div class="col-12 col-md-6 mb20 d-flex">
                <app-form-field>
                  <input
                    appInput
                    type="text"
                    required
                    [placeholder]="'forms.type_identity_field_b' | translate"
                    formControlName="idCardBirthDate"
                    class="form-control"
                    (focusin)="onFocusIn(2)"
                    (focusout)="onFocusOut()"
                  />
                </app-form-field>
                <app-form-field-label
                  [showRequiredMarker]="true"
                ></app-form-field-label>
              </div>
              <div class="col-12 col-md-6 mb20 d-flex">
                <app-form-field>
                  <input
                    appInput
                    type="text"
                    required
                    [placeholder]="'forms.type_identity_field_c' | translate"
                    formControlName="idCardExpireDate"
                    class="form-control"
                    (focusin)="onFocusIn(3)"
                    (focusout)="onFocusOut()"
                  />
                </app-form-field>
                <app-form-field-label
                  [showRequiredMarker]="true"
                ></app-form-field-label>
              </div>
              <div class="col-12 col-md-6 mb20 d-flex">
                <app-form-field>
                  <input
                    appInput
                    type="text"
                    required
                    [placeholder]="'forms.type_identity_field_d' | translate"
                    formControlName="idCardCheckSum"
                    class="form-control"
                    (focusin)="onFocusIn(4)"
                    (focusout)="onFocusOut()"
                  />
                </app-form-field>
                <app-form-field-label
                  [showRequiredMarker]="true"
                ></app-form-field-label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-form-field>
        <app-confirmation-info
          class="form-control"
          formControlName="withdrawalAccepted"
          required
          [infoText]="'schufa.withdrawal_accepted_l'"
        ></app-confirmation-info>
      </app-form-field>
    </form>
  </div>
  <div></div>
</div>
