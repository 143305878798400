import { gql } from 'apollo-angular';
import { attachmentFields } from 'libs/infrastructure/base-state';
import { Project } from '@ui/shared/models';

export interface ProjectQueryResponse {
  project: Project;
}

export const limitedProjectDataFields = `
  titleImage {
    ${attachmentFields}
  }
  name
  slogan
`;

export const projectDataFields = `
  id
  data {
    ${limitedProjectDataFields}
  }
`;

export const projectQuery = gql`
  query project($token: String) {
    project(token: $token) {
      ${projectDataFields}
    }
  }
`;
