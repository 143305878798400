import { ActionState, ActionStateCreator } from 'libs/state-utils';
import {
  SchufaDeliveryResponseData,
  SchufaOrderResponseData
} from 'tenant-pool/core/queries/schufa.queries';
import * as fromActions from './schufa.actions';

export interface SchufaState {
  schufaPersonalDataActionState: ActionState;
  schufaPersonalDataResponseData: SchufaOrderResponseData;

  schufaPaymentActionState: ActionState;
  schufaPaymentResponseData: SchufaOrderResponseData;

  schufaOrderActionState: ActionState;
  schufaOrderResponseData: SchufaDeliveryResponseData;

  schufaDeliveryActionState: ActionState;
  schufaDeliveryResponseData: SchufaDeliveryResponseData;

  schufaPaymentErrorCounter: number;
  schufaPaymentRetryCounter: number;

  currentStepNumber: number;
  completedSteps: string[];
}

export const initialState: SchufaState = {
  schufaPersonalDataActionState: ActionStateCreator.create(),
  schufaPersonalDataResponseData: null,

  schufaPaymentActionState: ActionStateCreator.create(),
  schufaPaymentResponseData: null,

  schufaOrderActionState: ActionStateCreator.create(),
  schufaOrderResponseData: null,

  schufaDeliveryActionState: ActionStateCreator.create(),
  schufaDeliveryResponseData: null,

  schufaPaymentErrorCounter: 0,
  schufaPaymentRetryCounter: 0,

  currentStepNumber: 1,
  completedSteps: []
};

export function reducer(
  state = initialState,
  action: fromActions.SchufaActions
): SchufaState {
  switch (action.type) {
    case fromActions.INITIAL_STATE: {
      return {
        ...initialState
      };
    }

    case fromActions.NEXT_STEP: {
      const currentStepNumber = state.currentStepNumber + 1;
      const completedSteps = [...state.completedSteps, action.completedStep];

      return {
        ...state,
        currentStepNumber,
        completedSteps,
        schufaPaymentRetryCounter: 0
      };
    }

    case fromActions.SEND_SCHUFA_PERSONAL_DATA: {
      return {
        ...state,
        schufaPersonalDataActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SEND_SCHUFA_PERSONAL_DATA_FAIL: {
      return {
        ...state,
        schufaPersonalDataActionState: ActionStateCreator.onError(
          new Error('SEND_SCHUFA_PERSONAL_DATA_ERROR')
        ),
        schufaPersonalDataResponseData: action.schufaResponse
      };
    }

    case fromActions.SEND_SCHUFA_PERSONAL_DATA_SUCCESS: {
      return {
        ...state,
        schufaPersonalDataActionState: ActionStateCreator.onSuccess(),
        schufaPersonalDataResponseData: action.schufaResponse
      };
    }

    case fromActions.SEND_SCHUFA_PAYMENT: {
      return {
        ...state,
        schufaPaymentActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SEND_SCHUFA_PAYMENT_FAIL: {
      const counter = state.schufaPaymentErrorCounter + 1;
      return {
        ...state,
        schufaPaymentActionState: ActionStateCreator.onError(
          new Error('SEND_SCHUFA_PAYMENT_ERROR')
        ),
        schufaPaymentErrorCounter: counter,
        schufaPaymentRetryCounter: 0,
        schufaPaymentResponseData: action.schufaResponse
      };
    }

    case fromActions.SEND_SCHUFA_PAYMENT_SUCCESS: {
      let retryCounter = state.schufaPaymentRetryCounter;
      if (
        action.schufaResponse &&
        action.schufaResponse.error &&
        action.schufaResponse.error.find(
          error => error.code === 'ValidierungUniserv.0002'
        )
      ) {
        retryCounter++;
      }
      return {
        ...state,
        schufaPaymentActionState: ActionStateCreator.onSuccess(),
        schufaPaymentResponseData: action.schufaResponse,
        schufaPaymentErrorCounter: 0,
        schufaPaymentRetryCounter: retryCounter
      };
    }

    case fromActions.SEND_SCHUFA_ORDER: {
      return {
        ...state,
        schufaOrderActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SEND_SCHUFA_ORDER_FAIL: {
      return {
        ...state,
        schufaOrderActionState: ActionStateCreator.onError(
          new Error('SEND_SCHUFA_ORDER_ERROR')
        ),
        schufaOrderResponseData: action.schufaResponse
      };
    }

    case fromActions.SEND_SCHUFA_ORDER_SUCCESS: {
      return {
        ...state,
        schufaOrderActionState: ActionStateCreator.onSuccess(),
        schufaOrderResponseData: action.schufaResponse
      };
    }

    case fromActions.SEND_SCHUFA_DELIVERY: {
      return {
        ...state,
        schufaDeliveryActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SEND_SCHUFA_DELIVERY_FAIL: {
      return {
        ...state,
        schufaDeliveryActionState: ActionStateCreator.onError(
          new Error('SEND_SCHUFA_DELIVERY_ERROR')
        ),
        schufaDeliveryResponseData: action.schufaResponse
      };
    }

    case fromActions.SEND_SCHUFA_DELIVERY_SUCCESS: {
      return {
        ...state,
        schufaDeliveryActionState: ActionStateCreator.onSuccess(),
        schufaDeliveryResponseData: action.schufaResponse
      };
    }

    default:
      return state;
  }
}

export const getCurrentStepNumber = (state: SchufaState) =>
  state.currentStepNumber;
export const getCompletedSteps = (state: SchufaState) => state.completedSteps;

export const getSchufaPersonalDataResponseData = (state: SchufaState) =>
  state.schufaPersonalDataResponseData;
export const getSchufaPersonalDataActionState = (state: SchufaState) =>
  state.schufaPersonalDataActionState;

export const getSchufaPaymentResponseData = (state: SchufaState) =>
  state.schufaPaymentResponseData;
export const getSchufaPaymentActionState = (state: SchufaState) =>
  state.schufaPaymentActionState;
export const getSchufaPaymentErrorCounter = (state: SchufaState) =>
  state.schufaPaymentErrorCounter;
export const getSchufaPaymentRetryCounter = (state: SchufaState) =>
  state.schufaPaymentRetryCounter;

export const getSchufaOrderResponseData = (state: SchufaState) =>
  state.schufaOrderResponseData;
export const getSchufaOrderActionState = (state: SchufaState) =>
  state.schufaOrderActionState;

export const getSchufaDeliveryResponseData = (state: SchufaState) =>
  state.schufaDeliveryResponseData;
export const getSchufaDeliveryActionState = (state: SchufaState) =>
  state.schufaDeliveryActionState;
