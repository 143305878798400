import { gql } from 'apollo-angular';

export const verifyEmailMutation = gql`
  mutation verifyEmail($input: ResidentVerifyEmailInputBean!) {
    verifyEmail(input: $input)
  }
`;

export const changeEmailMutation = gql`
  mutation changeEmail($input: ResidentChangeEmailTokenBean!) {
    changeEmail(input: $input)
  }
`;
