import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Go } from 'libs/infrastructure/base-state';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthTokenService } from 'libs/infrastructure';

/**
 * Used to handle the redirects for project search profile creation
 * @deprecated This guard is a workaround to preserve the query params after logging in. It's not needed anymore
 * So the route search-profiles could be called directly. But the URL is generated by the BE.
 * That's why it can't be refactored just in the FE.
 * @param route
 */
export const projectRegistrationLinkGuard = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const token = inject(AuthTokenService).getToken();

  if (!token?.access_token) {
    store.dispatch(
      new Go({
        path: ['auth', 'register', 'propertyLocation'],
        extras: {
          queryParams: {
            project: route.params.token
          },
          replaceUrl: true
        }
      })
    );
  } else {
    store.dispatch(
      new Go({
        path: ['search-profiles'],
        extras: {
          queryParams: {
            project: route.params.token
          },
          replaceUrl: true
        }
      })
    );
  }

  return false;
};
