import { OnInit, Input, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WizardStep } from 'libs/components/legacy/wizard/wizard-step/wizard-step';
import { Step } from 'libs/components/legacy/wizard/models';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive({ standalone: true })
export class BaseSchufaStep extends WizardStep implements OnInit {
  @Input() form: FormGroup;
  @Input() stepIndex: number;
  @Input() steps: Step[];

  public nextStep() {
    const stepName = this.steps[this.stepIndex].name;
    this.onNextStep.emit(stepName);
  }

  public onSubmit() {
    this.form.valid ? this.nextStep() : this.form.markAllAsTouched();
  }
}
