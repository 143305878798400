interface SwitchItemConfig {
  id?: string;
  value?: string | number | boolean; // value for forms
  disabled?: boolean;
  tooltip?: string;
  label?: string;
  amount?: number;
  invisible?: boolean;
}

enum SwitchSize {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large'
}

export { SwitchItemConfig, SwitchSize };
