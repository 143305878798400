import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-load-more',
  template: `
    <ng-content></ng-content>
    @if (showIndicator && !loading) {
      <div class="d-flex justify-content-center">
        <app-button [type]="'link'" (clickEvent)="onLoadMore()">{{
          'LOAD_MORE_L' | translate
        }}</app-button>
      </div>
    }
    @if (loading) {
      @for (i of [].constructor(5); track i) {
        <div class="skeleton" [style]="skeletonStyle"></div>
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, TranslateModule]
})
export class LoadMoreComponent {
  @Input() showIndicator = true;
  @Input() loading = false;
  @Output() loadMore = new EventEmitter();
  public skeletonStyle = {
    height: '25px',
    margin: '5px 0'
  };

  public onLoadMore() {
    this.loadMore.emit();
  }
}
