import { gql } from 'apollo-angular';
import { Property } from '@ui/shared/models';
import { attachmentFields } from 'tenant-pool/core/queries/guest-mode.queries';
import { customerBrandingFields } from 'tenant-pool/core/queries/user.queries';
import { customerDefinedValues } from 'libs/queries/customer-defined-fields.queries';
import { propertyDataPsSide } from 'libs/infrastructure/base-state/gql-queries';

export interface PropertyQueryResult {
  property: Property;
}

export const propertyQuery = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      status
      size
      externalId
      type
      totalRentGross
      entryPrice
      marketingType
      rented
      dataPrivacyUrl
      salesLegalTexts {
        id
        type
        text
      }
      data {
        ${propertyDataPsSide}
      }
      titleImage  {
        ${attachmentFields}
      }
      branding {
        ${customerBrandingFields}
      }
      allowContinueAsGuest
      applyLink
      ${customerDefinedValues}
      customer {
        id
        name
        logo
      }
      wbs
    }
  }
`;
