import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { v4 as uuid } from 'uuid';

import {
  AuthTokenService,
  getResponseValidator,
  RESET_PASSWORD_SUCCESS_MESSAGE,
  SessionStorageService,
  WINDOW_REF
} from 'libs/infrastructure';

import { UserConversionService } from 'libs/services';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { getRedirectUrl } from 'libs/utils';

import {
  logoutMutation,
  LogoutMutationResult,
  requestPasswordResetMutation,
  resetPasswordMutation,
  ResetPasswordResponse,
  ssoQuery,
  SSOQueryResult,
  userUpdateLastLogin
} from 'tenant-pool/core/queries';
import * as fromUserState from 'tenant-pool/+state/user';
import * as fromPropertyMatchesState from 'tenant-pool/+state/property-matches/property-matches.actions';
import { FormStorageService } from 'tenant-pool/auth/services';
import { MainPageNavigation, storageKeys } from 'tenant-pool/config';
import { of } from 'rxjs';

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private authTokenService = inject(AuthTokenService);
  private apollo = inject(Apollo);
  private userConversionService = inject(UserConversionService);
  private sessionStorage = inject(SessionStorageService);
  private formStorageService = inject(FormStorageService);
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private windowRef = inject(WINDOW_REF);

  userLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromBaseState.USER_LOGIN),
        switchMap((action: fromBaseState.UserLogin) => {
          const { email, redirectUri, loginMethod } = action.userCredentials;

          return this.apollo
            .query({
              query: ssoQuery,
              variables: { email, redirectUri, loginMethod }
            })
            .pipe(
              tap(getResponseValidator()),
              map((result: ApolloQueryResult<SSOQueryResult>) => {
                const { nonce, state, url } = result.data.sso;

                this.sessionStorage.setItem('nonce', nonce);
                this.sessionStorage.setItem('state', state);
                this.sessionStorage.setItem('sessionID', uuid());
                this.windowRef.location.href = url;
              }),
              catchError(err =>
                of(new fromBaseState.UserLoginFail(err.message))
              )
            );
        })
      ),
    { dispatch: false }
  );

  userLoginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBaseState.USER_LOGIN_FAIL),
      map(() => new fromBaseState.UserRedirect())
    )
  );

  userLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.UserLoginSuccess>(fromBaseState.USER_LOGIN_SUCCESS),
      mergeMap(({ token, stateAfterAuth }) => {
        this.formStorageService.clear();
        this.sessionStorage.removeItem('propertyData');
        if (token) {
          this.authTokenService.setToken(token);
        }
        return [
          new fromBaseState.Go({
            path: [stateAfterAuth.pathAfterAuth],
            query: stateAfterAuth.queryParams
          }),
          fromPropertyMatchesState.OpenPostLoginModal()
        ];
      })
    )
  );

  userLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.UserLogout>(fromBaseState.USER_LOGOUT),
      withLatestFrom(this.store.select(fromUserState.getUserData)),
      switchMap(([action, user]) => {
        const { internalPoolReturnUrl } = user || {
          internalPoolReturnUrl: ''
        };
        const redirectUri = action.redirectUrl
          ? action.redirectUrl
          : getRedirectUrl(
              this.windowRef.location.toString(),
              '/login?loggedOut=true',
              null
            );

        const session_state = this.authTokenService.getToken()?.session_state;

        return this.apollo
          .mutate({
            mutation: logoutMutation,
            variables: { redirectUri, session_state }
          })
          .pipe(
            tap(getResponseValidator()),
            mergeMap((result: ApolloQueryResult<LogoutMutationResult>) => {
              const { url } = result.data.userLogout;

              this.sessionStorage.removeItem(
                storageKeys.redirectedToNewHomeAfterLogin
              );
              this.sessionStorage.removeItem('nonce');
              this.sessionStorage.removeItem('state');
              this.authTokenService.removeToken();

              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return [
                new fromBaseState.RemoveTranslations() as any,
                new fromBaseState.UserLogoutSuccess(url, internalPoolReturnUrl)
              ];
            }),
            catchError(err => [new fromBaseState.UserLogoutFail(err)])
          );
      })
    )
  );

  userLogoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromBaseState.UserLogoutSuccess>(
          fromBaseState.USER_LOGOUT_SUCCESS
        ),
        map(({ redirectUrl, internalPoolReturnUrl }) => {
          if (internalPoolReturnUrl) {
            this.windowRef.open(internalPoolReturnUrl, '_self');
          } else {
            this.windowRef.location.href = redirectUrl;
          }
        })
      ),
    { dispatch: false }
  );

  userRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.UserRedirect>(fromBaseState.USER_REDIRECT),
      map(
        ({ pathAfterAuth }) =>
          new fromBaseState.Go({
            path: [MainPageNavigation.LOGIN],
            query: { pathAfterAuth }
          })
      )
    )
  );

  // We need updateLastLogin for an anonymous account check, Backend handle all important stuff.

  userUpdateLastLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBaseState.USER_UPDATE_LAST_LOGIN),
      switchMap(() =>
        this.apollo
          .mutate({ mutation: userUpdateLastLogin })
          .pipe(map(() => new fromBaseState.UserUpdateLastLoginSuccess()))
      )
    )
  );

  updateLastLoginSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.UpdateApplicationSuccess>(
        fromBaseState.UPDATE_APPLICATION_SUCCESS
      ),
      switchMap(() =>
        this.store.select(fromUserState.getUserData).pipe(
          filter(user => !!user?.id),
          withLatestFrom(this.store.select(fromBaseState.getCookiesPreference))
        )
      ),
      map(([user, cookiePreference]) => {
        const payload = this.userConversionService.convertPsUser(user);
        return new fromBaseState.UpdateCookieTracking(
          cookiePreference,
          payload
        );
      })
    )
  );

  requestPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.RequestPasswordReset>(
        fromBaseState.REQUEST_PASSWORD_RESET
      ),
      switchMap(action =>
        this.apollo
          .mutate({
            mutation: requestPasswordResetMutation,
            variables: { email: action.email }
          })
          .pipe(
            map(() => new fromBaseState.RequestPasswordResetSuccess()),
            catchError(err => [
              new fromBaseState.RequestPasswordResetFail(new Error(err.message))
            ])
          )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.ResetPassword>(fromBaseState.RESET_PASSWORD),
      map(action => action.resetPasswordPayload),
      switchMap(payload =>
        this.apollo
          .mutate<ResetPasswordResponse>({
            mutation: resetPasswordMutation,
            variables: {
              password: payload.password,
              confirmedPassword: payload.confirmedPassword,
              token: payload.token
            }
          })
          .pipe(
            map((res: ApolloQueryResult<ResetPasswordResponse>) => {
              const successCodes = [200, 201, 202];
              if (!successCodes.includes(res.data.resetPassword.status)) {
                return new fromBaseState.ResetPasswordFail(
                  new Error('ERR_SETTING_NEW_PASSWORD')
                );
              }

              return new fromBaseState.ResetPasswordSuccess();
            }),
            catchError(error => [new fromBaseState.ResetPasswordFail(error)])
          )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromBaseState.ResetPasswordSuccess>(
        fromBaseState.RESET_PASSWORD_SUCCESS
      ),
      map(() => {
        this.authTokenService.removeToken();
        return new fromBaseState.Go({
          path: ['/login'],
          query: { message: RESET_PASSWORD_SUCCESS_MESSAGE }
        });
      })
    )
  );
}
