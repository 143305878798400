import { gql } from 'apollo-angular';

import { responseStatusFields } from 'tenant-pool/core/queries/guest-mode.queries';
import { PropertyMatches } from 'tenant-pool/models';
import {
  allPropertySearcherFields,
  propertySearcherFields
} from 'tenant-pool/core/queries/property-searcher.queries';
import { PropertyMatchBean } from '@ui/shared/models';

export const rentedFlatsApplicationsQuery = gql`
  query rentedFlatsApplications {
    rentedFlatsApplications {
      ${allPropertySearcherFields}
    }
  }
`;

export interface RemoveApplicationMutationResponse {
  removeApplication: PropertyMatchBean;
}

export const removeApplicationMutation = gql`
  mutation removeApplication($reasonData: ReasonInput!) {
    removeApplication(input: $reasonData) {
      id
      status
    }
  }
`;

export const declareIntentMutation = gql`
  mutation declareIntent($declareIntentData: DeclareIntentInput!) {
    declareIntent(input: $declareIntentData) {
      statusText
    }
  }
`;
export const revokeAllOpenApplications = gql`
  mutation revokeAllOpenApplications($input: ReasonInput!) {
    revokeAllOpenApplications(input: $input) {
      statusText
    }
  }
`;
export const checkIdentityMutation = gql`
  mutation checkIdentity($token: String!) {
    checkIdentity(token: $token) {
      statusText
    }
  }
`;

export interface CheckApplicationExistResult {
  checkApplicationExist: { id: string };
}

export const checkApplicationExistQuery = gql`
  query checkApplicationExist($propertyId: ID!) {
    checkApplicationExist(propertyId: $propertyId)
  }
`;

export interface CreateApplicationMutationResponse {
  createApplication: PropertyMatchBean;
}

export const createApplicationMutation = gql`
  mutation createApplication($userId: ID!, $propertyId: ID!, $token: String) {
    createApplication(userId: $userId, propertyId: $propertyId, token: $token) {
      id
      status
      property {
        customer {
          customQuestionAllowed
        }
      }
    }
  }
`;

export const applyAnonymouslyMutation = gql`
  mutation propertyApplyAnonymously($payload: AnonymousApplicationPayloadInput!) {
    applyAnonymously(payload: $payload) {
      ${responseStatusFields}
    }
  }
`;

export interface ApplicationsResult {
  applications?: PropertyMatches;
  findAvailableForNewConversation?: PropertyMatchBean[];
}

export interface RentedFlatsApplicationsQueryResult {
  rentedFlatsApplications: PropertyMatchBean[];
}

export interface ApplicationQueryResult {
  application: PropertyMatchBean;
}

export const denyIntentMutation = gql`
  mutation denyIntent($declareIntentData: DeclareIntentInput, $token: String!) {
    denyIntent(input: $declareIntentData, token: $token) {
      statusText
    }
  }
`;

export const findAvailableForNewConversationQuery = gql`
  query findAvailableForNewConversation($userId: ID!) {
    findAvailableForNewConversation(userId: $userId) {
      ${propertySearcherFields}
    }
  }
`;
