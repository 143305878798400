import { gql } from 'apollo-angular';

export const movingChecklist = `
    moveInDate
    doNotAskForMoveInDate
    checklist
`;

export const movingChecklistGetByPropertyQuery = gql`
    query movingChecklistGetByProperty {
        movingChecklistGetByProperty {
            ${movingChecklist}
        }
    }
`;
export const movingChecklistSetMoveInDateMutation = gql`
    mutation movingChecklistSetMoveInDate($moveInDate: String, $doNotAskForMoveInDate: Boolean) {
        movingChecklistSetMoveInDate(moveInDate: $moveInDate, doNotAskForMoveInDate: $doNotAskForMoveInDate) {
            ${movingChecklist}
        }
    }
`;
export const movingChecklistChangeElementMutation = gql`
    mutation movingChecklistChangeElement($element: MovingChecklistTodoInput!) {
        movingChecklistChangeElement(element: $element) {
            ${movingChecklist}
        }
    }
`;
export const movingChecklistResetMutation = gql`
    mutation movingChecklistReset {
        movingChecklistReset {
            ${movingChecklist}
        }
    }
`;
