import { createSelector } from '@ngrx/store';

import { getGuestModeState } from '../reducers';
import * as fromGuestModeReducer from './guest-mode.reducers';

export const getGuestRegisterStatus = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestRegisterStatus
);

export const getGuestAppointmentsBundles = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestAppointmentsBundles
);

export const getGuestApplication = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestApplication
);

export const getGuestCustomQuestionResponses = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestCustomQuestionResponses
);

export const getGuestQuestionContainer = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestQuestionContainer
);

export const getGuestBranding = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestBranding
);

export const guestApplyDone = createSelector(
  getGuestModeState,
  fromGuestModeReducer.guestApplyDone
);

export const guestDeleteLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.guestDeleteLoading
);

export const guestDeleteDone = createSelector(
  getGuestModeState,
  fromGuestModeReducer.guestDeleteDone
);

export const guestApplyLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.guestApplyLoading
);

export const getGuestApplicationLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestApplicationLoading
);

export const getGuestApplicationError = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestApplicationError
);

export const getGuestBrandingLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestBrandingLoading
);

export const getGuestCustomQuestionResponsesLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestCustomQuestionResponsesLoading
);

export const getGuestAppointmentsLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestAppointmentsLoading
);

export const getGuestDeclareIntentLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestDeclareIntentLoading
);

export const getGuestDeclareIntentActionState = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestDeclareIntentActionState
);

export const guestCheckApplicationLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.guestCheckApplicationLoading
);

export const getGuestRegisterLoading = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestRegisterLoading
);

export const getGuestRegisterActionState = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestRegisterActionState
);

export const getGuestAppointmentsLoaded = createSelector(
  getGuestModeState,
  fromGuestModeReducer.getGuestAppointmentsLoaded
);
