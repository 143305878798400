import { Routes } from '@angular/router';
import { MovingAuctionAddressComponent } from './moving-auction-address/moving-auction-address.component';
import { MovingAuctionContactComponent } from './moving-auction-contact/moving-auction-contact.component';
import { MovingAuctionDataComponent } from './moving-auction-data/moving-auction-data.component';

export const MovingAuctionStepRoutes: Routes = [
  {
    path: 'address',
    component: MovingAuctionAddressComponent,
    data: {
      title: 'moving_auction.wizard.address_title_l'
    }
  },
  {
    path: 'data',
    component: MovingAuctionDataComponent,
    data: {
      title: 'moving_auction.wizard.data_title_l'
    }
  },
  {
    path: 'contact',
    component: MovingAuctionContactComponent,
    data: {
      title: 'moving_auction.wizard.contact_title_l'
    }
  }
];
