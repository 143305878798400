import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { RentDepositModel, RentDepositPaymentOption } from '@ui/shared/models';
import { RentDepositBaseStep } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-base-step.directive';
import { ProtocolPdfService } from 'tenant-pool/screens/rent-deposit/services/protocol-pdf.service';
import { take } from 'rxjs/operators';
import { DownloadService } from 'libs/infrastructure';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PropertyMatchBean } from '@ui/shared/models';
import { CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FullNamePipe, DateTimePipe, AddressPipe } from 'libs/pipes';
import { ComponentsModule } from 'libs/components';
import { RentDepositSummaryCardHeaderComponent } from '../rent-deposit-summary-card-header/rent-deposit-summary-card-header.component';

@Component({
  selector: 'app-rent-deposit-summary',
  templateUrl: './rent-deposit-summary.component.html',
  styleUrls: ['./rent-deposit-summary.component.scss'],
  providers: [ProtocolPdfService],
  standalone: true,
  imports: [
    RentDepositSummaryCardHeaderComponent,
    ComponentsModule,
    FormsModule,
    TranslateModule,
    CurrencyPipe,
    AddressPipe,
    DateTimePipe,
    FullNamePipe
  ]
})
export class RentDepositSummaryComponent extends RentDepositBaseStep {
  private protocolService = inject(ProtocolPdfService);
  private downloadService = inject(DownloadService);
  private translateService = inject(TranslateService);

  @Input() rentDepositModel: RentDepositModel;
  @Input() propertyMatch: PropertyMatchBean;
  @Input() processing: boolean;

  @Output() edit = new EventEmitter<string>();
  @Output() privacyModal = new EventEmitter();

  public acknowledgePrivacy: boolean;

  public get rentDepositInfo() {
    return this.propertyMatch?.rentDepositInfo;
  }

  public get isNaturalPerson() {
    return this.rentDepositModel?.landlordData.realPerson;
  }

  public get isYearly() {
    return (
      this.rentDepositModel?.contractData?.paymentType ===
      RentDepositPaymentOption.YEARLY
    );
  }

  public get isMonthly() {
    return (
      this.rentDepositModel?.contractData?.paymentType ===
      RentDepositPaymentOption.MONTHLY
    );
  }

  public openPrivacyModal() {
    this.privacyModal.emit();
  }

  public onGoToStep(stepName: string) {
    this.edit.emit(stepName);
  }

  public onCompleteStep() {
    super.completeStep();
  }

  public getProtocolPdf() {
    this.protocolService
      .getProtocolFile(this.rentDepositModel)
      .pipe(take(1))
      .subscribe((file: Blob) => {
        this.downloadService.download(
          file,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${this.translateService.instant('rent-deposit.protocol-pdf-name_l', {
            firstName: this.rentDepositModel?.tenantData?.firstname,
            lastName: this.rentDepositModel?.tenantData?.lastname
          })}.pdf`
        );
      });
  }
}
