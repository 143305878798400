import { NgModule } from '@angular/core';
import { AddressVerticalPipe } from './address-vertical.pipe';

import { AddressPipe } from './address.pipe';

const pipes = [AddressPipe, AddressVerticalPipe];

@NgModule({
  imports: [...pipes],
  exports: pipes,
  providers: pipes
})
export class AddressPipeModule {}
