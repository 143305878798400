import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, TranslateModule]
})
export class HeaderMobileComponent {
  @Input() title: string;
  @Input() showBack = false;
  @Input() showClose = false;

  @Output() backEvent = new EventEmitter<Event>();
  @Output() closeEvent = new EventEmitter<Event>();

  onBack(e: Event) {
    this.backEvent.emit(e);
  }

  onClose(e: Event) {
    this.closeEvent.emit(e);
  }
}
