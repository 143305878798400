import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Elevation, ElevationDirective, ElevationType } from 'libs/directives';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from 'libs/components/legacy/form';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { BadgeComponent } from '../../atoms/badge/badge.component';
import { SwitchItemConfig, SwitchSize } from './switch.model';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SwitchComponent)
    }
  ],
  standalone: true,
  imports: [
    NgClass,
    NgbTooltip,
    BadgeComponent,
    TranslateModule,
    ElevationDirective
  ]
})
export class SwitchComponent
  extends BaseControl<string | number | boolean>
  implements OnInit
{
  @Input() config: SwitchItemConfig[];
  @Input() itemValueKey = 'id';
  @Input() selectedItem: string;
  @Input() size: SwitchSize = SwitchSize.NORMAL;
  @Input() stretch = false;
  @Input() enableDivider = false;
  @Input() enableScrollFade = false;
  @Input() elevation: ElevationType = Elevation.ONE;

  @Output() itemSelectEvent = new EventEmitter();
  public leftFade = false;
  public rightFade = true;
  @ViewChild('switchContainer') el: ElementRef;
  private _errors = null;
  private _touched = false;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }

  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  public ngOnInit() {
    this.value = this.config?.find(
      item => item.id === this.selectedItem
    )?.value;
  }

  writeValue(value: string | number | boolean) {
    if (value) {
      this.value = value;
      this.selectedItem = this.config.find(
        item => item.value === this.value
      )?.id;
    }
  }

  selectItem(item: SwitchItemConfig): void {
    if (this.disabled || item.disabled || item.id === this.selectedItem) return;
    this.itemSelectEvent.emit(item.id);
    this.selectedItem = item.id;
    this.value = item.value;
    this.touched = true;
  }

  public onHorizontalScroll(): void {
    const target = this.el.nativeElement as HTMLElement;
    const edgeTolerance = 10;
    // remove fade if scroll is near edges
    if (target.scrollLeft < edgeTolerance) {
      this.leftFade = false;
    } else if (
      target.scrollLeft >=
      target.scrollWidth - target.offsetWidth - edgeTolerance
    ) {
      this.rightFade = false;
    } else {
      this.leftFade = true;
      this.rightFade = true;
    }
  }

  public onWheelScroll(event: WheelEvent): void {
    const target = this.el.nativeElement as HTMLElement;
    if (!event.deltaY) {
      return; // keep horizontal scrolling same
    }

    // transform vertical scroll to horizontal scroll
    target.scrollLeft += event.deltaY + event.deltaX;
  }
}
