import { FormControl, FormGroup } from '@angular/forms';

export enum TypeOfEmployment {
  Temporary = 'Temporary',
  Unlimited = 'Unlimited'
}

export enum ResidentSince {
  LESS_THAN_TWO_YEARS = 'less_than_two_years',
  MORE_THAN_TWO_YEARS = 'more_than_two_years'
}

export enum Occupation {
  EMPLOYEE = 6,
  WORKER = 9,
  OFFICIAL = 8,
  FREELANCER = 1,
  TRADER = 2,
  IN_TRAINING = 13,
  RETIRED_PENSIONER = 11,
  SOLDIER_OFFICER = 22
}

export enum SmartDepositAccordionPanelId {
  PERSONAL = 'personal',
  CONTACT = 'contact',
  BUDGET = 'budget',
  RENTED_OBJECT = 'rentedObject',
  BANK = 'bank'
}

export class SmartDepositAccordionPanel {
  form: SmartDepositForms;
  id: SmartDepositAccordionPanelId;
  title: string;
  subtitle?: string;
}

export type SmartDepositPersonalForm = FormGroup<{
  salutation: FormControl<number | null>;
  givenName: FormControl<string>;
  familyName: FormControl<string>;
  birthDate: FormControl<Date | null>;
  birthName: FormControl<string>;
  nationality: FormControl<string | null>;
  maritalStatus: FormControl<number | null>;
  wayOfLiving: FormControl<number | null>;
}>;

export type SmartDepositAddressForm = FormGroup<{
  street: FormControl<string>;
  houseNumber: FormControl<string>;
  zipCode: FormControl<string>;
  city: FormControl<string>;
  country?: FormControl<string>;
}>;

export type SmartDepositContactForm = FormGroup<{
  email: FormControl<string>;
  telephone: FormControl<string>;
  address: SmartDepositAddressForm;
  residentSince: FormControl<ResidentSince>;
  previousAddress: SmartDepositAddressForm;
}>;

export type SmartDepositBudgetForm = FormGroup<{
  occupationalGroup: FormControl<number | null>;
  commercialSector: FormControl<number | null>;
  typeOfEmployment: FormControl<TypeOfEmployment | null>;
  employmentIncome: FormControl<number | null>;
  employedSince: FormControl<Date | null>;
  employedUntil: FormControl<Date | null>;
  numberOfChildren: FormControl<number | null>;
  otherIncome: FormControl<number | null>;
}>;

export type SmartDepositRentedObjectForm = FormGroup<{
  startOfRental: FormControl<Date | null>;
  startOfGuarantee: FormControl<Date | null>;
  address: SmartDepositAddressForm;
  basicRent: FormControl<number | null>;
}>;

export type SmartDepositBankForm = FormGroup<{
  iban: FormControl<string>;
  schufaQuery: FormControl<boolean>;
  advertising: FormControl<boolean>;
  ownAccount: FormControl<boolean>;
  dataProcessing: FormControl<boolean>;
}>;

export type SmartDepositLandlordForm = FormGroup<{
  name: FormControl<string>;
  address: SmartDepositAddressForm;
}>;

export type SmartDepositForm = FormGroup<{
  deposit: FormControl<number | null>;
  [SmartDepositAccordionPanelId.PERSONAL]: SmartDepositPersonalForm;
  [SmartDepositAccordionPanelId.CONTACT]: SmartDepositContactForm;
  [SmartDepositAccordionPanelId.BUDGET]: SmartDepositBudgetForm;
  [SmartDepositAccordionPanelId.RENTED_OBJECT]: SmartDepositRentedObjectForm;
  [SmartDepositAccordionPanelId.BANK]: SmartDepositBankForm;
  landlord: SmartDepositLandlordForm;
}>;

export type SmartDepositFormValue = SmartDepositForm['value'];

export type SmartDepositLegitimationForm = FormGroup<{
  email: FormControl<string>;
  telephone: FormControl<string>;
  consentDataProcessing: FormControl<boolean>;
}>;

export type SmartDepositForms =
  | SmartDepositPersonalForm
  | SmartDepositContactForm
  | SmartDepositBudgetForm
  | SmartDepositRentedObjectForm
  | SmartDepositBankForm
  | SmartDepositLandlordForm;
