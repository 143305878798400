import { createSelector } from '@ngrx/store';

import { getDigitalContractState } from '../reducers';
import * as fromContractReducers from './digital-contract.reducers';

export const getDigitalContractLoading = createSelector(
  getDigitalContractState,
  fromContractReducers.getDigitalContractLoading
);

export const getTenantInformationActionState = createSelector(
  getDigitalContractState,
  fromContractReducers.getTenantInformationActionState
);

export const getTenantInformationLoading = createSelector(
  getDigitalContractState,
  fromContractReducers.getTenantInformationLoading
);

export const getAesStatusLoading = createSelector(
  getDigitalContractState,
  fromContractReducers.getAesStatusLoading
);

export const getMessageSending = createSelector(
  getDigitalContractState,
  fromContractReducers.getMessageSending
);

export const getDigitalContract = createSelector(
  getDigitalContractState,
  fromContractReducers.getDigitalContract
);

export const getContractWizardStepNumber = createSelector(
  getDigitalContractState,
  fromContractReducers.getContractWizardStepNumber
);

export const getDocuSignResponse = createSelector(
  getDigitalContractState,
  fromContractReducers.getDocuSignResponse
);

export const getSigningUrlLoading = createSelector(
  getDigitalContractState,
  fromContractReducers.getSigningUrlLoading
);

export const getAesCheckState = createSelector(
  getDigitalContractState,
  fromContractReducers.getAesCheckState
);

export const getQesActionState = createSelector(
  getDigitalContractState,
  fromContractReducers.getQesActionState
);

export const getQesLink = createSelector(
  getDigitalContractState,
  fromContractReducers.getQesLink
);
