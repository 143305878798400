import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { coerceBooleanProperty } from 'libs/utils';
import * as fromAppState from '../../+state';

@Injectable()
export class ExtendSearchGuard {
  private store = inject<Store<fromAppState.AppState>>(Store);

  canActivate(route: ActivatedRouteSnapshot) {
    const { token, searching } = route.queryParams;
    const isSearching = coerceBooleanProperty(searching);

    this.store.dispatch(
      new fromAppState.SetSearchingStatus(token, isSearching)
    );

    return of(false);
  }
}
