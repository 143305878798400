<div class="file-upload__container">
  <label
    class="file-upload__area"
    [ngClass]="{ disabled: disabled, hidden: !showButton }"
  >
    <div [class.hover]="dragHover" [class.has-error]="error">
      <div class="default-s file-upload__placeholder">
        <div class="icon icon--upload"></div>
        <div>
          <span class="ml5">
            {{ 'file_upload.drop_file_here_l' | translate }}
            {{ 'general.or_l' | translate }}
          </span>
          <span class="file-upload__link">{{
            'file_upload.choose_file_l' | translate
          }}</span>
        </div>
      </div>
      <p class="uppercase-m semibold file-upload__info">{{ subInformation }}</p>

      <input
        #fileInput
        class="file-upload__input"
        type="file"
        [size]="size"
        [attr.accept]="accept ? accept : null"
        [attr.multiple]="multiple ? '' : null"
        [attr.disabled]="disabled ? '' : null"
        [attr.required]="required ? '' : null"
        (change)="fileSelectHandler($event)"
      />
    </div>
  </label>
  @if (error) {
    <app-message [messageType]="'error'"> {{ error | translate }} </app-message>
  }
</div>
