import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SlicePipe } from '@angular/common';

@Component({
  selector: 'app-grouped-districts-detail',
  templateUrl: './grouped-districts-detail.component.html',
  styleUrls: ['./grouped-districts-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SlicePipe, TranslateModule]
})
export class GroupedDistrictsDetailComponent {
  public showAllCityParts: boolean;
  public splitLength = 20;
  @Input() districtGroup: any;

  public toggleShowAllCityParts() {
    this.showAllCityParts = !this.showAllCityParts;
  }
}
