import { NavigationItem } from 'tenant-pool/models';

export const profileLink = '/profile';
export const profileEditLink = '/profile/edit';

export const navigationUserDropdownConfig: NavigationItem[] = [
  {
    name: 'profile',
    label: 'navigation.profile_l',
    link: profileLink,
    icon: 'nav-profile'
  },
  {
    name: 'settings',
    label: 'general.user_settings_a',
    link: '/settings',
    icon: 'nav-settings'
  },
  {
    name: 'helpAndSupport',
    label: 'navigation.help_and_support_l',
    link: `${profileLink}/help-and-support`,
    icon: 'nav-settings'
  }
];
