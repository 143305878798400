import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { MainPageNavigation } from 'tenant-pool/config/navigation.config';
import { ITPSettings, PropertyMatchBean } from '@ui/shared/models';
import { RentDepositInfo } from '@ui/shared/models';
import { showAssistant } from 'tenant-pool/config/hideTypes';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-tenant-experience-modal',
  templateUrl: './tenant-experience-modal.component.html',
  styleUrls: ['./tenant-experience-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, SvgIconComponent, TranslateModule]
})
export class TenantExperienceModalComponent {
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private ngbActiveModal = inject(NgbActiveModal);

  @Input() foundApplication: PropertyMatchBean;
  @Input() rentDepositInfo: RentDepositInfo;
  @Input() itpSettings: ITPSettings;

  public get showAssistantRedirect() {
    const {
      schufaCard,
      shopCard,
      movingChecklist,
      movingAuction,
      milesCard,
      wechselPilotCard
    } = this.itpSettings || {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return showAssistant(
      schufaCard,
      shopCard,
      movingChecklist,
      movingAuction,
      milesCard,
      wechselPilotCard
    );
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    this.ngbActiveModal.close();
  }

  public goToRentDeposit() {
    this.store.dispatch(
      new fromBaseState.Go({
        path: ['rent-deposit', this.foundApplication.id]
      })
    );
    this.close();
  }

  public goToNewHome() {
    this.store.dispatch(
      new fromBaseState.Go({
        path: [MainPageNavigation.NEW_HOME]
      })
    );
    this.close();
  }

  public goToMovingAssistant() {
    this.store.dispatch(
      new fromBaseState.Go({
        path: [
          MainPageNavigation.SERVICE_CENTER,
          MainPageNavigation.SERVICE_CENTER_LIST
        ]
      })
    );
    this.close();
  }
}
