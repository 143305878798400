import moment from 'moment';
import {
  CustomQuestion,
  CustomQuestionAnswer,
  CustomQuestionSubType,
  CustomQuestionType
} from '@ui/shared/models';

export function customQuestionsPayloadHelper(
  customQuestionsAnswers: CustomQuestionAnswer[],
  customQuestions: CustomQuestion[]
) {
  return customQuestionsAnswers?.map((customQuestionAnswer, i) => {
    const { id, comment, answer, key, selectedRange } = customQuestionAnswer;
    const selectedFields = { id, comment, answer, key, selectedRange };
    if (customQuestions && customQuestions[i]) {
      if (
        customQuestions[i].type === CustomQuestionType.RANGE &&
        selectedRange
      ) {
        switch (customQuestions[i].subType) {
          case CustomQuestionSubType.RANGE_DATE: {
            return {
              ...selectedFields,
              selectedRange: moment.utc(selectedRange, 'YYYY-MM-DD').valueOf()
            };
          }
          case CustomQuestionSubType.RANGE_VALUE: {
            return {
              ...selectedFields,
              selectedRange
            };
          }
          default: {
            return {
              ...selectedFields,
              selectedRange: null
            };
          }
        }
      }
    }
    return selectedFields;
  });
}
