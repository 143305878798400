import { createReducer, on } from '@ngrx/store';
import {
  CustomerBranding,
  PaymentMandate,
  PaymentPayInResponse,
  PaymentRequest,
  SmartDepositDictionary,
  SmartDepositGuaranteeProduct,
  SmartDepositOverview
} from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import * as fromActions from './payments.actions';

export interface PaymentsState {
  paymentRequest: PaymentRequest;
  paymentMandate: PaymentMandate;
  paymentBranding: CustomerBranding;
  paymentPayInResponse: PaymentPayInResponse;

  paymentRequestActionState: ActionState;
  paymentMandateActionState: ActionState;
  paymentBrandingActionState: ActionState;
  manualPaymentActionState: ActionState;
  paymentExecutionActionState: ActionState;

  smartDepositFee: SmartDepositGuaranteeProduct;
  smartDepositFeeActionState: ActionState;
  smartDeposit: SmartDepositOverview;
  smartDepositActionState: ActionState;
  smartDepositBranding: CustomerBranding;
  smartDepositBrandingActionState: ActionState;

  applySmartDepositResponseToken: string;
  applySmartDepositActionState: ActionState;
  cancelSmartDepositActionState: ActionState;

  startSmartDepositLegitimationActionState: ActionState;
  cancelSmartDepositLegitimationActionState: ActionState;

  smartDepositDictionary: SmartDepositDictionary;
  smartDepositDictionaryActionState: ActionState;
}

export const initialState: PaymentsState = {
  paymentRequest: null,
  paymentMandate: null,
  paymentBranding: null,
  paymentPayInResponse: null,
  paymentRequestActionState: ActionStateCreator.create(),
  paymentMandateActionState: ActionStateCreator.create(),
  paymentBrandingActionState: ActionStateCreator.create(),
  manualPaymentActionState: ActionStateCreator.create(),
  paymentExecutionActionState: ActionStateCreator.create(),
  smartDepositFee: null,
  smartDepositFeeActionState: ActionStateCreator.create(),
  smartDeposit: null,
  smartDepositActionState: ActionStateCreator.create(),
  smartDepositBranding: null,
  smartDepositBrandingActionState: ActionStateCreator.create(),
  applySmartDepositResponseToken: null,
  applySmartDepositActionState: ActionStateCreator.create(),
  cancelSmartDepositActionState: ActionStateCreator.create(),
  startSmartDepositLegitimationActionState: ActionStateCreator.create(),
  cancelSmartDepositLegitimationActionState: ActionStateCreator.create(),
  smartDepositDictionary: null,
  smartDepositDictionaryActionState: ActionStateCreator.create()
};

export const reducer = createReducer(
  initialState,

  on(fromActions.fetchPaymentRequest, state => ({
    ...state,
    paymentRequestActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.fetchPaymentRequestSuccess, (state, { paymentRequest }) => ({
    ...state,
    paymentRequest,
    paymentRequestActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.fetchPaymentRequestFail, (state, { error }) => ({
    ...state,
    paymentRequestActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.fetchMandate, state => ({
    ...state,
    mandateStatusActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.fetchMandateSuccess, (state, { paymentMandate }) => ({
    ...state,
    paymentMandate,
    mandateStatusActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.fetchMandateFail, (state, { error }) => ({
    ...state,
    mandateStatusActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.manualPayment, state => ({
    ...state,
    manualPaymentActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.manualPaymentSuccess, state => ({
    ...state,
    manualPaymentActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.manualPaymentFail, (state, { error }) => ({
    ...state,
    manualPaymentActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.paymentExecution, state => ({
    ...state,
    paymentExecutionActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.paymentExecutionSuccess, (state, { response }) => ({
    ...state,
    paymentPayInResponse: response,
    paymentExecutionActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.paymentExecutionFail, (state, { error }) => ({
    ...state,
    paymentExecutionActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.loadSmartDepositFee, state => ({
    ...state,
    smartDepositFeeActionState: ActionStateCreator.onStart()
  })),

  on(
    fromActions.loadSmartDepositFeeSuccess,
    (state, { guaranteeFee, commissionAmount, commission }) => ({
      ...state,
      smartDepositFee: {
        guaranteeFee,
        commissionAmount,
        commission
      },
      smartDepositFeeActionState: ActionStateCreator.onSuccess()
    })
  ),

  on(fromActions.loadSmartDepositFeeError, (state, { error }) => ({
    ...state,
    smartDepositFeeActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.loadSmartDepositDictionary, state => ({
    ...state,
    smartDepositDictionaryActionState: ActionStateCreator.onStart()
  })),

  on(
    fromActions.loadSmartDepositDictionarySuccess,
    (state, { smartDepositDictionary }) => ({
      ...state,
      smartDepositDictionary,
      smartDepositDictionaryActionState: ActionStateCreator.onSuccess()
    })
  ),

  on(fromActions.loadSmartDepositDictionaryError, (state, { error }) => ({
    ...state,
    smartDepositDictionaryActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.fetchSmartDeposit, state => ({
    ...state,
    smartDepositActionState: ActionStateCreator.onStart()
  })),

  on(
    fromActions.fetchSmartDepositSuccess,
    (state, { smartDepositOverview }) => ({
      ...state,
      smartDeposit: smartDepositOverview,
      smartDepositActionState: ActionStateCreator.onSuccess()
    })
  ),

  on(fromActions.fetchSmartDepositError, (state, { error }) => ({
    ...state,
    smartDepositActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.loadSmartDepositBranding, state => ({
    ...state,
    smartDepositBrandingActionState: ActionStateCreator.onStart()
  })),

  on(
    fromActions.loadSmartDepositBrandingSuccess,
    (state, { smartDepositBranding }) => ({
      ...state,
      smartDepositBranding,
      smartDepositBrandingActionState: ActionStateCreator.onSuccess()
    })
  ),

  on(fromActions.loadSmartDepositBrandingError, (state, { error }) => ({
    ...state,
    smartDepositBrandingActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.applySmartDeposit, state => ({
    ...state,
    applySmartDepositActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.applySmartDepositSuccess, (state, { token }) => ({
    ...state,
    applySmartDepositResponseToken: token,
    applySmartDepositActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.applySmartDepositError, (state, { error }) => ({
    ...state,
    applySmartDepositActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.cancelSmartDeposit, state => ({
    ...state,
    cancelSmartDepositActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.cancelSmartDepositSuccess, state => ({
    ...state,
    cancelSmartDepositActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.cancelSmartDepositError, (state, { error }) => ({
    ...state,
    cancelSmartDepositActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.startSmartDepositLegitimation, state => ({
    ...state,
    startSmartDepositLegitimationActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.startSmartDepositLegitimationSuccess, state => ({
    ...state,
    startSmartDepositLegitimationActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.startSmartDepositLegitimationError, (state, { error }) => ({
    ...state,
    startSmartDepositLegitimationActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.cancelSmartDepositLegitimation, state => ({
    ...state,
    cancelSmartDepositLegitimationActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.cancelSmartDepositLegitimationSuccess, state => ({
    ...state,
    cancelSmartDepositLegitimationActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.cancelSmartDepositLegitimationError, (state, { error }) => ({
    ...state,
    cancelSmartDepositLegitimationActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.clearSmartDepositFromStore, state => ({
    ...state,
    smartDeposit: null,
    smartDepositActionState: ActionStateCreator.create()
  })),

  on(fromActions.clearSmartDepositLegitimationActionState, state => ({
    ...state,
    startSmartDepositLegitimationActionState: ActionStateCreator.create(),
    cancelSmartDepositLegitimationActionState: ActionStateCreator.create()
  })),

  on(fromActions.fetchPaymentBranding, state => ({
    ...state,
    paymentBrandingActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.fetchPaymentBrandingSuccess, (state, { paymentBranding }) => ({
    ...state,
    paymentBranding,
    paymentBrandingActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.fetchPaymentBrandingFail, (state, { error }) => ({
    ...state,
    paymentBrandingActionState: ActionStateCreator.onError(error)
  }))
);

export const getPaymentRequest = (state: PaymentsState) => state.paymentRequest;
export const getPaymentMandate = (state: PaymentsState) => state.paymentMandate;
export const getPaymentRequestActionState = (state: PaymentsState) =>
  state.paymentRequestActionState;
export const getPaymentMandateActionState = (state: PaymentsState) =>
  state.paymentMandateActionState;
export const getPaymentPayInResponse = (state: PaymentsState) =>
  state.paymentPayInResponse;
export const getManualPaymentActionState = (state: PaymentsState) =>
  state.manualPaymentActionState;
export const getPaymentExecutionActionState = (state: PaymentsState) =>
  state.paymentExecutionActionState;
