import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { showAssistant } from 'tenant-pool/config/hideTypes';
import { AppState, getUserData, LoadUserData } from 'tenant-pool/+state';
import { Go } from 'libs/infrastructure/base-state/router/router.actions';
import { MainPageNavigation } from 'tenant-pool/config';
import {
  AuthTokenService,
  SessionStorageService,
  WINDOW_REF
} from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';

@Injectable()
export class ServiceCenterGuard {
  private store = inject<Store<AppState>>(Store);
  private authTokenService = inject(AuthTokenService);
  private sessionStorage = inject(SessionStorageService);
  private windowRef = inject(WINDOW_REF);

  canLoad(): Observable<boolean> {
    /*
     * if PS is logged out and tries to go {URL}/de/assistant/list we need to set the url to the pathAfterAuth, cause of the language key
     * {URL}/de/assistant/list -> assistant/list -> /assistant/list
     * We need to do this, so the user gets redirected to assistant page.
     * this code exist in user-data.guard.ts too, but for some reason APP is not going through this guard
     * we need to use windowRef cause in the moment u go to the app only the browser knows the location.
     * i tried with route/router/ActivatedRoute etc non of these services had the desired route
     */
    if (!this.authTokenService.getToken().access_token) {
      let path = this.windowRef.location.pathname.replace(
        /^(\/([\w]{2})\/)/,
        ''
      );
      if (!path.startsWith('/')) {
        path = `/${path}`;
      }
      this.store.dispatch(new fromBaseState.UserRedirect(path));
      return of(false);
    }

    return this.store.select(getUserData).pipe(
      tap(data => {
        if (!data) this.store.dispatch(new LoadUserData());
      }),
      filter(data => !!data),
      map(data => {
        const {
          schufaCard,
          shopCard,
          movingChecklist,
          movingAuction,
          milesCard,
          wechselPilotCard
        } = data.customerBranding?.itpSettings || {};

        if (
          !showAssistant(
            schufaCard,
            shopCard,
            movingChecklist,
            movingAuction,
            milesCard,
            wechselPilotCard
          )
        ) {
          this.store.dispatch(
            new Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ]
            })
          );
          return false;
        }
        return true;
      })
    );
  }
}
