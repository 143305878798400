import { Action } from '@ngrx/store';
import {
  CustomQuestion,
  HierarchicalRootQuestion,
  HierarchicalRootQuestionResponse,
  CustomQuestionAnswer,
  RootQuestionContainer,
  PropertyMatchBean
} from '@ui/shared/models';

export const OPEN_CUSTOM_QUESTIONS_MODAL =
  '[Custom Questions] Open Custom Question Modal';

export class OpenCustomQuestionsModal implements Action {
  readonly type = OPEN_CUSTOM_QUESTIONS_MODAL;

  constructor(
    public propertyMatchBean: PropertyMatchBean,
    public openSecondModalForGarageProperties = false
  ) {}
}

export const SAVE_CUSTOM_QUESTIONS_ANSWERS = '[Custom Questions] Save Answers';
export const SAVE_CUSTOM_QUESTIONS_ANSWERS_FAIL =
  '[Custom Questions] Save Answers Fail';
export const SAVE_CUSTOM_QUESTIONS_ANSWERS_SUCCESS =
  '[Custom Questions] Save Answers Success';

export class SaveCustomQuestionsAnswers implements Action {
  readonly type = SAVE_CUSTOM_QUESTIONS_ANSWERS;

  constructor(public answers: CustomQuestionAnswer[]) {}
}

export class SaveCustomQuestionsAnswersSuccess implements Action {
  readonly type = SAVE_CUSTOM_QUESTIONS_ANSWERS_SUCCESS;
}

export class SaveCustomQuestionsAnswersFail implements Action {
  readonly type = SAVE_CUSTOM_QUESTIONS_ANSWERS_FAIL;

  constructor(public error: any) {}
}

export const LOAD_CUSTOM_QUESTIONS = '[Custom Questions] Load Custom Questions';
export const LOAD_CUSTOM_QUESTIONS_FAIL =
  '[Custom Questions] Load Custom Questions Fail';
export const LOAD_CUSTOM_QUESTIONS_SUCCESS =
  '[Custom Questions] Load Custom Questions Success';

export class LoadCustomQuestions implements Action {
  readonly type = LOAD_CUSTOM_QUESTIONS;

  constructor(public id: string) {}
}

export class LoadCustomQuestionsSuccess implements Action {
  readonly type = LOAD_CUSTOM_QUESTIONS_SUCCESS;

  constructor(public customQuestions: CustomQuestion[]) {}
}

export class LoadCustomQuestionsFail implements Action {
  readonly type = LOAD_CUSTOM_QUESTIONS_FAIL;

  constructor(public error: Error) {}
}

export const LOAD_QUESTIONS_BY_CUSTOMER =
  '[Custom Questions] Load Questions By Customer';

export class LoadQuestionsByCustomer implements Action {
  readonly type = LOAD_QUESTIONS_BY_CUSTOMER;

  constructor(public id: string) {}
}

export const LOAD_CUSTOMER_QUESTIONS_SUCCESS =
  '[Hierarchical Questions] Load Customer Questions Success';

export class LoadCustomerQuestionsSuccess implements Action {
  readonly type = LOAD_CUSTOMER_QUESTIONS_SUCCESS;

  constructor(public customerQuestions: RootQuestionContainer[]) {}
}

export const LOAD_QUESTIONS_AND_ANSWERS_BY_CUSTOMER =
  '[Custom Questions] Load Questions And Answers By Customer';

export class LoadQuestionsAndAnswersByCustomer implements Action {
  readonly type = LOAD_QUESTIONS_AND_ANSWERS_BY_CUSTOMER;

  constructor(public id: string) {}
}

export const LOAD_QUESTIONS_AND_ANSWERS_BY_CUSTOMER_SUCCESS =
  '[Custom Questions] Load Questions And Answers By Customer Success';

export class LoadQuestionsAndAnswersByCustomerSuccess implements Action {
  readonly type = LOAD_QUESTIONS_AND_ANSWERS_BY_CUSTOMER_SUCCESS;

  constructor(public customerQuestions: RootQuestionContainer[]) {}
}

export const PROCESS_HIERARCHICAL_QUESTIONS =
  '[Hierarchical Questions] Process Hierarchical Questions';

export class ProcessHierarchicalQuestions implements Action {
  readonly type = PROCESS_HIERARCHICAL_QUESTIONS;

  constructor(public hierarchicalRootQuestions: HierarchicalRootQuestion[]) {}
}

export const LOAD_HIERARCHICAL_QUESTIONS_SUCCESS =
  '[Hierarchical Questions] Load Hierarchical Questions Success';

export class LoadHierarchicalQuestionsSuccess implements Action {
  readonly type = LOAD_HIERARCHICAL_QUESTIONS_SUCCESS;

  constructor(public hierarchicalRootQuestions: RootQuestionContainer[]) {}
}

export const SET_HIERARCHICAL_QUESTIONS_MODAL_OPEN =
  '[Hierarchical Questions] Set Modal Open';

export class SetHierarchicalQuestionsModalOpen implements Action {
  readonly type = SET_HIERARCHICAL_QUESTIONS_MODAL_OPEN;

  constructor(public isOpen: boolean) {}
}

export const HIERARCHICAL_ROOT_QUESTION_RESPONSE =
  '[Hierarchical Questions] Root Question Response';
export const HIERARCHICAL_ROOT_QUESTION_RESPONSE_FAIL =
  '[Hierarchical Questions] Root Question Response Fail';
export const HIERARCHICAL_ROOT_QUESTION_RESPONSE_SUCCESS =
  '[Hierarchical Questions] Root Question Response Success';

export class HierarchicalQuestionResponse implements Action {
  readonly type = HIERARCHICAL_ROOT_QUESTION_RESPONSE;

  constructor(public response: HierarchicalRootQuestionResponse[]) {}
}

export class HierarchicalQuestionResponseSuccess implements Action {
  readonly type = HIERARCHICAL_ROOT_QUESTION_RESPONSE_SUCCESS;

  constructor(public response: HierarchicalRootQuestion[]) {}
}

export class HierarchicalQuestionResponseFail implements Action {
  readonly type = HIERARCHICAL_ROOT_QUESTION_RESPONSE_FAIL;

  constructor(public error: Error) {}
}

export type CustomQuestionsAction =
  | OpenCustomQuestionsModal
  | LoadCustomQuestions
  | LoadCustomQuestionsFail
  | LoadCustomQuestionsSuccess
  | SaveCustomQuestionsAnswers
  | SaveCustomQuestionsAnswersFail
  | SaveCustomQuestionsAnswersSuccess
  | LoadQuestionsByCustomer
  | LoadCustomerQuestionsSuccess
  | LoadQuestionsAndAnswersByCustomer
  | LoadQuestionsAndAnswersByCustomerSuccess
  | ProcessHierarchicalQuestions
  | LoadHierarchicalQuestionsSuccess
  | SetHierarchicalQuestionsModalOpen
  | HierarchicalQuestionResponse
  | HierarchicalQuestionResponseFail
  | HierarchicalQuestionResponseSuccess;
