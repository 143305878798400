import { gql } from 'apollo-angular';

export interface ConstantsListResult {
  getAllConstants: {
    [key: string]: string[];
  };
}

export const constantsList = gql`
  query {
    getAllConstants
  }
`;
