import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';
import { QuotaNumberPipe } from './pipes/quota-number.pipe';
import { QuotaTranslationPipe } from './pipes/quota-translation.pipe';
import { QuotaIconPipe } from './pipes/quota-icon.pipe';
import { QuotaStylePipe } from './pipes/quota-button.pipe';

@Component({
  selector: 'app-quota-button',
  templateUrl: './quota-button.component.html',
  styleUrls: ['./quota-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    TranslateModule,
    QuotaStylePipe,
    QuotaIconPipe,
    QuotaTranslationPipe,
    QuotaNumberPipe
  ]
})
export class QuotaButtonComponent {
  @Input() currentAmount: number;
  @Input() useDefaultCursor: boolean;

  @Input() positiveQuota: string;
  @Input() emptyQuota: string;
  @Input() negativeQuota: string;

  @Input() alwaysShowNumber: boolean;

  @Output() clickEvent = new EventEmitter();

  public onClick() {
    this.clickEvent.emit();
  }
}
