import { Component, EventEmitter, Input, Output, inject } from '@angular/core';

import { ActionState } from 'libs/state-utils';

import {
  SchufaDeliveryOption,
  SchufaIdentityData,
  SchufaPersonalInformationData,
  SchufaSalutation,
  SendSchufaDelivery
} from 'tenant-pool/models';
import { SchufaError } from 'tenant-pool/core/queries';

import * as fromAppState from 'tenant-pool/+state';
import { Store } from '@ngrx/store';
import { GenericFormGroup } from '@ui/shared/models';
import { FormState } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseSchufaStep } from '../../base-schufa-step';
import { SchufaBenefitsCardComponent } from '../schufa-benefits-card/schufa-benefits-card.component';
import { SchufaIdentificationComponent } from '../schufa-identification/schufa-identification.component';
import { ComponentsModule } from '../../../../../../../../libs/legacy-lib/src/lib/components/components.module';

@Component({
  selector: 'app-schufa-delivery',
  templateUrl: './schufa-delivery.component.html',
  styleUrls: ['./schufa-delivery.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    SchufaIdentificationComponent,
    SchufaBenefitsCardComponent,
    TranslateModule
  ]
})
export class SchufaDeliveryComponent extends BaseSchufaStep {
  private store = inject<Store<fromAppState.AppState>>(Store);

  @Input() personalData: SchufaPersonalInformationData;
  @Input() deliveryActionState: ActionState;

  @Input() set deliveryResponseError(val: SchufaError[]) {
    if (val) {
      this._deliveryResponseError = val;
      this.form?.markAllAsTouched();
    }
  }

  get deliveryResponseError() {
    return this._deliveryResponseError;
  }

  private _deliveryResponseError = [] as SchufaError[];
  @Output() schufaIdentify = new EventEmitter<SendSchufaDelivery>();

  public deliveryOptions = [
    {
      name: 'schufa.delivery.download_l',
      value: SchufaDeliveryOption.DOWNLOAD
    },
    { name: 'schufa.delivery.post_l', value: SchufaDeliveryOption.POST }
  ];

  public get deliverySelection() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('deliverySelection').value;
  }

  public get isMale() {
    return (
      this.personalData?.salutation?.toUpperCase() === SchufaSalutation.HERR
    );
  }

  public get isDownload() {
    return this.deliverySelection === SchufaDeliveryOption.DOWNLOAD;
  }

  public get isPost() {
    return this.deliverySelection === SchufaDeliveryOption.POST;
  }

  public get downloadFormInvalid() {
    return this.isDownload && this.form.invalid;
  }

  public cancel() {
    this.onCancel.emit();
  }

  public onSubmit() {
    this.form?.status === FormState.VALID
      ? this.submit()
      : this.form?.markAllAsTouched();
  }

  private submit() {
    if (this.isDownload) {
      const identityPayload = this.getIdentityPayload(this.form);
      this.schufaIdentify.emit(this.getValue(identityPayload));
    } else {
      const post = { postDelivery: true };
      this.form.patchValue(post);
      this.schufaIdentify.emit(post);
    }
  }

  private getIdentityPayload(
    form: GenericFormGroup<SendSchufaDelivery>
  ): SendSchufaDelivery {
    const { deliverySelection, identityData, ...rest } =
      form.value as SendSchufaDelivery & {
        deliverySelection: SchufaDeliveryOption;
      };
    const { withdrawalAccepted, ...restIdentityData } =
      identityData as SchufaIdentityData & { withdrawalAccepted: boolean };
    return {
      ...rest,
      identityData: { ...restIdentityData }
    };
  }

  private getValue(value: SendSchufaDelivery): SendSchufaDelivery {
    const { identityData, ...rest } = value;
    return {
      ...rest,
      identityData: {
        ...identityData,
        iBAN: identityData.iBAN.toUpperCase().replace(/\s/g, '')
      }
    };
  }
}
