import { OnInit, Input, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { WizardStep } from 'libs/components/legacy/wizard/wizard-step/wizard-step';
import { Step } from 'libs/components/legacy/wizard/models';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive({ standalone: true })
export class RentDepositBaseStep extends WizardStep implements OnInit {
  @Input() form: FormGroup;
  @Input() stepIndex: number;
  @Input() steps: Step[];

  completeStep() {
    this.onComplete.emit();
  }

  nextStep() {
    const next = this.stepIndex + 1;
    const stepName = this.steps[next].name;
    this.onNextStep.emit(stepName);
  }

  previousStep() {
    const next = this.stepIndex - 1;
    const stepName = this.steps[next].name;
    this.onPreviousStep.emit(stepName);
  }
}
