<div class="moving-auction-contact container-padding">
  <div class="row justify-content-center">
    <div class="col-xl-6 col-md-8 col-sm-10 col-12">
      <div class="row mb20">
        <div class="col-12">
          <span class="moving-auction-contact__title">{{
            'moving_auction.wizard_contact.title' | translate
          }}</span>
        </div>
      </div>

      <div class="moving-auction-contact__card mb20">
        <div class="row mb20 title-m">
          <div class="col-12">
            {{ 'moving-auction.wizard_contact.contact_question' | translate }}
          </div>
        </div>
        <form [formGroup]="formGroup" appFocusFormError>
          <div class="row mb20">
            <div class="col-12">
              <app-form-field>
                <app-form-field-label>{{
                  'moving_auction.wizard_contact.salutation_option' | translate
                }}</app-form-field-label>
                <app-switch
                  class="moving-auction-contact__switch"
                  [config]="SALUTATION_OPTIONS"
                  [selectedItem]="SALUTATION_OPTIONS[0].id"
                  formControlName="salutation"
                >
                </app-switch>
              </app-form-field>
            </div>
          </div>

          <div class="row mb15">
            <div class="col-12">
              <app-form-field-label [showRequiredMarker]="true">
                {{ 'NAME_L' | translate }}
              </app-form-field-label>
              <app-form-field>
                <input
                  appInput
                  type="text"
                  required
                  [placeholder]="
                    'ps.moving_auction.forms.type_name' | translate
                  "
                  formControlName="name"
                  class="form-control"
                />
              </app-form-field>
            </div>
          </div>

          <div class="row moving-auction-contact__gutter">
            <div class="col pr0">
              <app-form-field-label [showRequiredMarker]="true">
                {{ 'PHONE_L' | translate }}
              </app-form-field-label>
              <app-form-field>
                <app-phone-input
                  required
                  formControlName="phone"
                ></app-phone-input>
              </app-form-field>
            </div>
            <div class="col pl0">
              <app-form-field-label [showRequiredMarker]="true">
                {{ 'EMAIL_L' | translate }}
              </app-form-field-label>
              <app-form-field>
                <input
                  appInput
                  type="text"
                  required
                  [placeholder]="
                    'ps.moving_auction.forms.type_email' | translate
                  "
                  formControlName="email"
                  class="form-control"
                />
              </app-form-field>
            </div>
          </div>
        </form>
      </div>

      <div class="moving-auction-contact__card moving-auction-contact__agb">
        <div class="row">
          <div class="col-12">
            <span
              [innerHTML]="'moving_auction.wizard_contact.info-box' | translate"
            ></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 p0">
          <app-wizard-footer
            [hasNext]="false"
            [hasPrevious]="true"
            [hasFinish]="true"
            [disabled]="processing"
            [completeActionText]="'moving_auction.wizard_contact.finish_a'"
            (completeStep)="onCheckForm()"
            (previousStep)="previousStep()"
          >
          </app-wizard-footer>
        </div>
      </div>
    </div>
  </div>
</div>
