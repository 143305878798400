import { ModuleWithProviders, NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  NgbDropdownModule,
  NgbModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEditorModule } from 'ngx-editor';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  DateTimeService,
  GeoService,
  GOOGLE_MAPS_API_CONFIG
} from 'libs/services';
import { PipesModule } from 'libs/pipes';
import { MatSliderModule } from '@angular/material/slider';
import { IsValueSelectedInDropdownStepperPipe } from 'libs/components/atoms/multi-select-dropdown-stepper/is-value-selected.pipe';
import {
  MultiSelectDropdownStepperComponent,
  MultiSelectGroupedIntermediateDropdownComponent,
  SingleSelectDropdownStepperComponent,
  ButtonComponent,
  CardComponent,
  TextFieldComponent,
  ImageComponent,
  BadgeComponent,
  BreadcrumbsComponent,
  MultiSelectGroupedDropdownComponent,
  SalesLegalCheckboxesPreviewComponent,
  InfoCollapseComponent,
  ApplicationConfirmationComponent,
  InfiniteScrollComponent,
  GroupedDistrictsDetailComponent,
  GroupedCityMapComponent,
  InformationBoxComponent,
  LocaleFlagComponent,
  QuotaInfoBadgeComponent,
  MultiSelectDropdownV2Component,
  SingleSelectDropdownComponent,
  TextEditorComponent,
  LoadMoreComponent
} from 'libs/components/atoms';
import {
  DataTableCellDirective,
  DataTableHeaderDirective,
  DataTableComponent,
  DataTableCellComponent,
  BasicTableComponent,
  QuotaTableComponent,
  NavigationComponent,
  NavigationDrawerComponent,
  NavigationDrawerItemComponent,
  NavigationDrawerListComponent,
  NavigationUserProfileComponent
} from 'libs/components/organisms';
import {
  SmartInputFieldSetComponent,
  NoDataDisclaimerComponent,
  StatusPipelineBaseComponent,
  StatusInfoComponent,
  HeaderMobileComponent,
  ApplicationStatusPipelineComponent,
  ProjectStatusPipelineComponent,
  LandlordInfoComponent,
  SideSheetComponent,
  SideSheetContentDirective,
  SwitchComponent,
  MapComponent,
  SideModalComponent,
  SideModalContentDirective,
  TooltipComponent,
  TooltipDirective,
  TabNavigationComponent,
  ContextMenuComponent,
  ContextMenuItemComponent,
  SocialLoginComponent,
  FileUploadInputComponent,
  LangPickerComponent,
  SegmentComponent,
  LabelListComponent,
  LabelListTagComponent,
  GetAssignableContactTagsPipe,
  GetAssignableSystemTagsPipe,
  GetAssignablePropertyTagsPipe,
  TagComponent,
  FunnelComponent,
  PropertyCardComponent,
  PortalCardComponent,
  ProjectCardComponent,
  ServiceCardComponent,
  PropertyGroupCardComponent,
  SelectionCardComponent,
  RegisterCardComponent,
  AutoCompleteFieldComponent,
  LocationSearchFieldComponent,
  PhoneInputComponent,
  QuotaButtonComponent,
  QuotaStylePipe,
  QuotaIconPipe,
  QuotaTranslationPipe,
  QuotaNumberPipe
} from 'libs/components/molecules';
import {
  AutofocusDirective,
  ElevationDirective,
  FocusFormErrorDirective
} from 'libs/directives';
import {
  ModalService,
  ToastService,
  MessengerFilterService,
  MessengerNotificationService,
  AttachCustomQuestionModalComponent,
  HierarchicalQuestionInputComponent,
  HierarchicalQuestionSelectionComponent,
  CheckboxComponent,
  FormFieldErrorComponent,
  AdBlockInfoComponent,
  AddressFormComponent,
  AppClickAreaDirective,
  AppInputDirective,
  AppointmentSelectionComponent,
  AppointmentsModalComponent,
  AttachmentItemComponent,
  AttachmentItemV2Component,
  AttachmentPreviewComponent,
  AttachmentPreviewV2Component,
  AttachmentsComponent,
  AttachmentsListComponent,
  AttachmentsListV2Component,
  AvailableCustomQuestionComponent,
  AvailableHierarchicalQuestionComponent,
  AvatarComponent,
  CalendarComponent,
  ChatAttachmentComponent,
  ChatComponent,
  ChatContactItemComponent,
  ChatContactListComponent,
  ChatCreateModalComponent,
  ChatSettingsModalComponent,
  ChatTextMessageComponent,
  CheckComponent,
  CheckIntermediateComponent,
  CityAutocompleteComponent,
  ColorPickerComponent,
  CommentsComponent,
  ConfirmationDialogComponent,
  ConfirmReasonComponent,
  ConfirmReasonModalComponent,
  ConversationGeneralConfigFormComponent,
  ConversationRestrictionConfigFormComponent,
  CookieBannerComponent,
  CustomCookieSettingsModalComponent,
  CustomQuestionComponent,
  CustomQuestionDetailsComponent,
  CustomQuestionFormComponent,
  CustomQuestionPreviewModalComponent,
  CustomQuestionsFieldComponent,
  CustomQuestionsFormComponent,
  CustomQuestionsModalContentComponent,
  DateComponent,
  DigitalContractSignerComponent,
  DropdownMultiselectComponent,
  DropdownSelectComponent,
  EditAttachmentComponent,
  EmbeddedLinkComponent,
  FieldsetDisabledComponent,
  FileUploadComponent,
  FlatSelectComponent,
  FooterComponent,
  FooterV2Component,
  FormFieldComponent,
  FormFieldInfoComponent,
  FormFieldLabelComponent,
  FormFieldLangPickerComponent,
  GlobalCustomQuestionsComponent,
  HeaderComponent,
  HeaderV2Component,
  HeadingComponent,
  HierarchicalQuestionFormComponent,
  HierarchicalQuestionsDisplayQuestionComponent,
  HierarchicalQuestionsDisplayRootComponent,
  HierarchicalRootQuestionFormComponent,
  HightlightedBgComponent,
  HintComponent,
  InfoBoxComponent,
  InternationalPhoneComponent,
  InvoicePaymentMethodComponent,
  ItemCheckComponent,
  KnockoutCriteriaComponent,
  ListControlsComponent,
  ListNavigationComponent,
  ListNavigationV2Component,
  LoadingSpinnerComponent,
  LockableDataComponent,
  LogoComponent,
  MatchControlValidator,
  MessageComponent,
  MessageTemplateFormComponent,
  MessageTemplatesComponent,
  MessengerComponent,
  MobileIdTutorialComponent,
  ModalComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component,
  MultiselectComponent,
  NavigationItemV2Component,
  NewUpdateBannerComponent,
  NoContentComponent,
  PageSizeSelectionComponent,
  PaginationComponent,
  PasswordComponent,
  PerformanceWarningBannerComponent,
  ProfileDkTwoComponent,
  PropertyBannerComponent,
  PropertyInterestComponent,
  PropertyParkingSpaceComponent,
  QualifiedElectronicSignatureComponent,
  QualifiedElectronicSignatureConfirmTermsAndConditionsComponent,
  QualifiedElectronicSignatureMethodModalComponent,
  RadioButtonComponent,
  RadioGroupComponent,
  RangeSliderComponent,
  RejectionDialogComponent,
  ScoreCircleComponent,
  SearchProfileAddressComponent,
  SearchProfileDetailsComponent,
  SearchProfileInfoComponent,
  SearchProfileLandlordDistrictsComponent,
  SearchProfilePropertyDetailsComponent,
  SelectComponent,
  SetPasswordFormComponent,
  SimpleTextInputComponent,
  SliderComponent,
  SlideToggleComponent,
  SocialsComponent,
  TableHeaderComponent,
  TenantProfileFormComponent,
  TimeComponent,
  ToastContainerComponent,
  UpdateModalComponent,
  VerifyEmailBannerComponent,
  WizardComponent,
  WizardFooterComponent,
  WizardProgressComponent,
  WizardStepDirective
} from './legacy';

import { ComponentsModuleConfig } from './components.config';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode'
    }),
    NgbPopoverModule,
    NgbDropdownModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    LayoutModule,
    PipesModule,
    ColorPickerModule,
    NgScrollbarModule,
    AngularSvgIconModule,
    DragDropModule,
    LetDirective,
    NgxEditorModule,
    MatSliderModule,
    IsValueSelectedInDropdownStepperPipe,
    CityAutocompleteComponent,
    DigitalContractSignerComponent,
    MobileIdTutorialComponent,
    QualifiedElectronicSignatureComponent,
    QualifiedElectronicSignatureConfirmTermsAndConditionsComponent,
    QualifiedElectronicSignatureMethodModalComponent,
    SearchProfileAddressComponent,
    SearchProfileDetailsComponent,
    SearchProfileInfoComponent,
    SearchProfileLandlordDistrictsComponent,
    SearchProfilePropertyDetailsComponent,
    ColorPickerComponent,
    PageSizeSelectionComponent,
    AddressFormComponent,
    MessageComponent,
    LogoComponent,
    ListNavigationComponent,
    HightlightedBgComponent,
    FileUploadComponent,
    WizardStepDirective,
    WizardProgressComponent,
    WizardComponent,
    WizardFooterComponent,
    CommentsComponent,
    FooterComponent,
    SocialsComponent,
    HeaderComponent,
    NoContentComponent,
    MatchControlValidator,
    HintComponent,
    PaginationComponent,
    AttachmentsListComponent,
    AttachmentItemComponent,
    AttachmentPreviewComponent,
    AttachmentItemV2Component,
    AttachmentPreviewV2Component,
    AttachmentsListV2Component,
    AttachmentsComponent,
    CalendarComponent,
    ToastContainerComponent,
    AdBlockInfoComponent,
    InvoicePaymentMethodComponent,
    SetPasswordFormComponent,
    LoadingSpinnerComponent,
    CookieBannerComponent,
    CustomCookieSettingsModalComponent,
    VerifyEmailBannerComponent,
    PerformanceWarningBannerComponent,
    InfoBoxComponent,
    AppClickAreaDirective,
    AppInputDirective,
    AvatarComponent,
    ListControlsComponent,
    TableHeaderComponent,
    EmbeddedLinkComponent,
    MessengerComponent,
    ChatComponent,
    ChatContactItemComponent,
    ChatContactListComponent,
    ChatTextMessageComponent,
    ChatAttachmentComponent,
    ChatCreateModalComponent,
    ChatSettingsModalComponent,
    PropertyBannerComponent,
    PropertyParkingSpaceComponent,
    NewUpdateBannerComponent,
    MessageTemplatesComponent,
    MessageTemplateFormComponent,
    ProfileDkTwoComponent,
    GlobalCustomQuestionsComponent,
    ConfirmReasonComponent,
    AppointmentSelectionComponent,
    PropertyInterestComponent,
    HeadingComponent,
    UpdateModalComponent,
    HeaderV2Component,
    FooterV2Component,
    TenantProfileFormComponent,
    KnockoutCriteriaComponent,
    FieldsetDisabledComponent,
    LockableDataComponent,
    CheckComponent,
    CheckIntermediateComponent,
    CheckboxComponent,
    DateComponent,
    DropdownMultiselectComponent,
    DropdownSelectComponent,
    FlatSelectComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    FormFieldErrorComponent,
    FormFieldInfoComponent,
    FormFieldLangPickerComponent,
    ScoreCircleComponent,
    SimpleTextInputComponent,
    ConversationRestrictionConfigFormComponent,
    ConversationGeneralConfigFormComponent,
    SlideToggleComponent,
    ItemCheckComponent,
    ListNavigationV2Component,
    NavigationItemV2Component,
    PasswordComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    ScoreCircleComponent,
    SelectComponent,
    SimpleTextInputComponent,
    SlideToggleComponent,
    SliderComponent,
    TimeComponent,
    InternationalPhoneComponent,
    MultiselectComponent,
    RangeSliderComponent,
    ModalFooterComponent,
    ModalContentComponent,
    ConfirmationDialogComponent,
    RejectionDialogComponent,
    ModalComponent,
    ModalV2Component,
    AppointmentsModalComponent,
    ConfirmReasonModalComponent,
    AttachCustomQuestionModalComponent,
    AvailableCustomQuestionComponent,
    CustomQuestionComponent,
    CustomQuestionDetailsComponent,
    CustomQuestionPreviewModalComponent,
    CustomQuestionFormComponent,
    CustomQuestionsFieldComponent,
    CustomQuestionsFormComponent,
    HierarchicalQuestionFormComponent,
    HierarchicalRootQuestionFormComponent,
    CustomQuestionsModalContentComponent,
    AvailableHierarchicalQuestionComponent,
    HierarchicalQuestionsDisplayRootComponent,
    HierarchicalQuestionsDisplayQuestionComponent,
    HierarchicalQuestionSelectionComponent,
    HierarchicalQuestionInputComponent,
    EditAttachmentComponent,
    TextFieldComponent,
    ImageComponent,
    BadgeComponent,
    CardComponent,
    ButtonComponent,
    BreadcrumbsComponent,
    MultiSelectGroupedDropdownComponent,
    SalesLegalCheckboxesPreviewComponent,
    InfoCollapseComponent,
    ApplicationConfirmationComponent,
    InfiniteScrollComponent,
    GroupedDistrictsDetailComponent,
    GroupedCityMapComponent,
    InformationBoxComponent,
    LocaleFlagComponent,
    QuotaInfoBadgeComponent,
    MultiSelectDropdownV2Component,
    SingleSelectDropdownComponent,
    TextEditorComponent,
    LoadMoreComponent,
    MultiSelectGroupedIntermediateDropdownComponent,
    SingleSelectDropdownStepperComponent,
    MultiSelectDropdownStepperComponent,
    DataTableCellDirective,
    DataTableHeaderDirective,
    DataTableComponent,
    DataTableCellComponent,
    BasicTableComponent,
    QuotaTableComponent,
    NavigationComponent,
    NavigationDrawerComponent,
    NavigationDrawerItemComponent,
    NavigationDrawerListComponent,
    NavigationUserProfileComponent,
    SmartInputFieldSetComponent,
    NoDataDisclaimerComponent,
    StatusPipelineBaseComponent,
    StatusInfoComponent,
    HeaderMobileComponent,
    ApplicationStatusPipelineComponent,
    ProjectStatusPipelineComponent,
    LandlordInfoComponent,
    SideSheetComponent,
    SideSheetContentDirective,
    SwitchComponent,
    MapComponent,
    SideModalComponent,
    SideModalContentDirective,
    TooltipComponent,
    TooltipDirective,
    TabNavigationComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SocialLoginComponent,
    FileUploadInputComponent,
    LangPickerComponent,
    SegmentComponent,
    LabelListComponent,
    LabelListTagComponent,
    GetAssignableContactTagsPipe,
    GetAssignableSystemTagsPipe,
    GetAssignablePropertyTagsPipe,
    TagComponent,
    FunnelComponent,
    PropertyCardComponent,
    PortalCardComponent,
    ProjectCardComponent,
    ServiceCardComponent,
    PropertyGroupCardComponent,
    SelectionCardComponent,
    RegisterCardComponent,
    AutoCompleteFieldComponent,
    LocationSearchFieldComponent,
    PhoneInputComponent,
    QuotaButtonComponent,
    QuotaStylePipe,
    QuotaIconPipe,
    QuotaTranslationPipe,
    QuotaNumberPipe,
    ElevationDirective,
    FocusFormErrorDirective,
    AutofocusDirective
  ],
  exports: [
    AutofocusDirective,
    CityAutocompleteComponent,
    DigitalContractSignerComponent,
    MobileIdTutorialComponent,
    SearchProfileAddressComponent,
    SearchProfileDetailsComponent,
    SearchProfileInfoComponent,
    SearchProfileLandlordDistrictsComponent,
    SearchProfilePropertyDetailsComponent,
    ColorPickerComponent,
    PageSizeSelectionComponent,
    AddressFormComponent,
    MessageComponent,
    LogoComponent,
    ListNavigationComponent,
    HightlightedBgComponent,
    WizardStepDirective,
    WizardProgressComponent,
    WizardComponent,
    WizardFooterComponent,
    CommentsComponent,
    FooterComponent,
    SocialsComponent,
    HeaderComponent,
    NoContentComponent,
    MatchControlValidator,
    HintComponent,
    PaginationComponent,
    AttachmentsListComponent,
    AttachmentItemComponent,
    AttachmentPreviewComponent,
    AttachmentItemV2Component,
    AttachmentPreviewV2Component,
    AttachmentsListV2Component,
    AttachmentsComponent,
    CalendarComponent,
    ToastContainerComponent,
    AdBlockInfoComponent,
    InvoicePaymentMethodComponent,
    SetPasswordFormComponent,
    LoadingSpinnerComponent,
    CookieBannerComponent,
    CustomCookieSettingsModalComponent,
    VerifyEmailBannerComponent,
    PerformanceWarningBannerComponent,
    InfoBoxComponent,
    AppClickAreaDirective,
    AppInputDirective,
    AvatarComponent,
    EditAttachmentComponent,
    ListControlsComponent,
    TableHeaderComponent,
    EmbeddedLinkComponent,
    MessengerComponent,
    ChatComponent,
    ChatContactItemComponent,
    ChatContactListComponent,
    ChatTextMessageComponent,
    ChatAttachmentComponent,
    ChatCreateModalComponent,
    ChatSettingsModalComponent,
    PropertyBannerComponent,
    PropertyParkingSpaceComponent,
    NewUpdateBannerComponent,
    MessageTemplatesComponent,
    MessageTemplateFormComponent,
    ProfileDkTwoComponent,
    GlobalCustomQuestionsComponent,
    ConfirmReasonComponent,
    AppointmentSelectionComponent,
    PropertyInterestComponent,
    HeadingComponent,
    UpdateModalComponent,
    HeaderV2Component,
    FooterV2Component,
    TenantProfileFormComponent,
    KnockoutCriteriaComponent,
    FieldsetDisabledComponent,
    LockableDataComponent,
    CheckComponent,
    CheckIntermediateComponent,
    CheckboxComponent,
    DateComponent,
    DropdownMultiselectComponent,
    DropdownSelectComponent,
    FlatSelectComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    FormFieldErrorComponent,
    FormFieldInfoComponent,
    FormFieldLangPickerComponent,
    ScoreCircleComponent,
    SimpleTextInputComponent,
    ConversationRestrictionConfigFormComponent,
    ConversationGeneralConfigFormComponent,
    SlideToggleComponent,
    ItemCheckComponent,
    ListNavigationV2Component,
    NavigationItemV2Component,
    PasswordComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    ScoreCircleComponent,
    SelectComponent,
    SimpleTextInputComponent,
    SlideToggleComponent,
    SliderComponent,
    TimeComponent,
    InternationalPhoneComponent,
    MultiselectComponent,
    RangeSliderComponent,
    ModalFooterComponent,
    ModalContentComponent,
    ConfirmationDialogComponent,
    RejectionDialogComponent,
    ModalComponent,
    ModalV2Component,
    ChatCreateModalComponent,
    AppointmentsModalComponent,
    ConfirmReasonModalComponent,
    MultiSelectDropdownStepperComponent,
    MultiSelectGroupedIntermediateDropdownComponent,
    SingleSelectDropdownStepperComponent,
    ButtonComponent,
    CardComponent,
    TextFieldComponent,
    ImageComponent,
    BadgeComponent,
    BreadcrumbsComponent,
    MultiSelectGroupedDropdownComponent,
    SalesLegalCheckboxesPreviewComponent,
    InfoCollapseComponent,
    ApplicationConfirmationComponent,
    InfiniteScrollComponent,
    GroupedDistrictsDetailComponent,
    GroupedCityMapComponent,
    InformationBoxComponent,
    LocaleFlagComponent,
    QuotaInfoBadgeComponent,
    MultiSelectDropdownV2Component,
    SingleSelectDropdownComponent,
    TextEditorComponent,
    LoadMoreComponent,
    DataTableCellDirective,
    DataTableHeaderDirective,
    DataTableComponent,
    DataTableCellComponent,
    BasicTableComponent,
    QuotaTableComponent,
    NavigationComponent,
    NavigationDrawerComponent,
    NavigationDrawerItemComponent,
    NavigationDrawerListComponent,
    NavigationUserProfileComponent,
    SmartInputFieldSetComponent,
    NoDataDisclaimerComponent,
    StatusPipelineBaseComponent,
    StatusInfoComponent,
    HeaderMobileComponent,
    ApplicationStatusPipelineComponent,
    ProjectStatusPipelineComponent,
    LandlordInfoComponent,
    SideSheetComponent,
    SideSheetContentDirective,
    SwitchComponent,
    MapComponent,
    SideModalComponent,
    SideModalContentDirective,
    TooltipComponent,
    TooltipDirective,
    TabNavigationComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SocialLoginComponent,
    FileUploadInputComponent,
    LangPickerComponent,
    SegmentComponent,
    LabelListComponent,
    LabelListTagComponent,
    GetAssignableContactTagsPipe,
    GetAssignableSystemTagsPipe,
    GetAssignablePropertyTagsPipe,
    TagComponent,
    FunnelComponent,
    PropertyCardComponent,
    PortalCardComponent,
    ProjectCardComponent,
    ServiceCardComponent,
    PropertyGroupCardComponent,
    SelectionCardComponent,
    RegisterCardComponent,
    AutoCompleteFieldComponent,
    LocationSearchFieldComponent,
    PhoneInputComponent,
    QuotaButtonComponent,
    QuotaStylePipe,
    QuotaIconPipe,
    QuotaTranslationPipe,
    QuotaNumberPipe,
    ElevationDirective,
    FocusFormErrorDirective
  ],
  providers: [
    ModalService,
    ToastService,
    MessengerFilterService,
    MessengerNotificationService,
    KeyValuePipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class ComponentsModule {
  public static forRoot(
    config: ComponentsModuleConfig = {}
  ): ModuleWithProviders<ComponentsModule> {
    return {
      ngModule: ComponentsModule,
      providers: [
        {
          provide: GOOGLE_MAPS_API_CONFIG,
          useValue: config.googleApiConfig || {}
        },
        DateTimeService,
        GeoService,
        MessengerNotificationService
      ]
    };
  }
}
