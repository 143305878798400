<div [class]="baseClass" class="w-100 h-100">
  <app-card
    class="h-100"
    [borderStyle]="showNewMarker ? 'active' : undefined"
    [padding]="'none'"
  >
    @if (showNewMarker) {
      <div [class]="baseClass + '__new-marker'">
        <svg-icon
          src="/assets/images/icons/icon-new.svg"
          [applyClass]="true"
          [svgStyle]="{ 'height.px': 35 }"
          class="white-svg-color circle text"
        ></svg-icon>
      </div>
    }
    <div class="card-body d-flex flex-column h-100">
      <div class="m-0 d-flex flex-nowrap">
        @if (logoUrl) {
          <div [class]="baseClass + '__logo-container ps-0 pe-0 flex-shrink-0'">
            <app-image
              [defaultSrc]="logoUrl"
              alt="{{ headline }}"
              [borderRadius]="'big'"
              [borderStyle]="'neutral'"
              [maxHeightInPx]="95"
            >
            </app-image>
          </div>
        }
        <div class="d-flex flex-column flex-grow-1">
          <div
            [class]="baseClass + '__card-content pt-0 pb-0 pe-0'"
            [class.ps-0]="!logoUrl"
          >
            <div [class]="baseClass + '__badges mb-2 flex-shrink-1 flex-wrap'">
              <ng-content select="[badge]"></ng-content>
            </div>
            <div class="pe-0 me-0 mt-3 flex-shrink-1">
              @if (headline) {
                <h3 [ngClass]="[baseClass + '__title', 'title-l']" class="mb-1">
                  {{ headline | translate }}
                </h3>
              }
              @if (description) {
                <p
                  [class]="baseClass + '__paragraph'"
                  class="mb-0"
                  [innerHTML]="description | translate"
                ></p>
              }
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-auto">
        <div class="col-12" [class.mt20]="!(copyText || copyTextLabel)">
          @if (copyText || copyTextLabel) {
            <div class="mb10">
              <hr class="mt20 mb20" />
              @if (copyTextDescriptionTitle) {
                <strong> {{ copyTextDescriptionTitle | translate }} </strong>
              }
              @if (copyTextDescription) {
                <p class="p0 mb10">{{ copyTextDescription | translate }}</p>
              }
              <p class="w-100" [class]="baseClass + '__copy-text-label'">
                {{ copyTextLabel | translate }}
              </p>
              <app-button
                [type]="'snippet'"
                iconRight="copy"
                [spaceBetweenIcons]="true"
                [elevationHoverEffect]="false"
                (clickEvent)="copy()"
              >
                {{ copyText | translate }}
              </app-button>
            </div>
          }
          @if (buttonText) {
            <app-button (clickEvent)="buttonPress($event)">
              {{ buttonText | translate }}
            </app-button>
          }
        </div>
      </div>
    </div>
  </app-card>
</div>
