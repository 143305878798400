import { Action } from '@ngrx/store';
import {
  GuestApplication,
  CustomQuestionResponse,
  CustomerBranding,
  QuestionResponseContainer,
  AppointmentsBundles,
  CancelAppointmentData,
  DeclareIntentData,
  ReasonData
} from '@ui/shared/models';
import { UserData, GuestStatus } from 'tenant-pool/models';

export const CHECK_GUEST_APPLICATION_POSSIBLE =
  '[Guest Mode] Check Guest Application Possible';
export const CHECK_GUEST_APPLICATION_POSSIBLE_SUCCESS =
  '[Guest Mode] Check Guest Application Possible Success';
export const CHECK_GUEST_APPLICATION_POSSIBLE_FAIL =
  '[Guest Mode] Check Guest Application Possible Fail';

export class CheckGuestApplicationPossible implements Action {
  readonly type = CHECK_GUEST_APPLICATION_POSSIBLE;
  constructor(
    public email: string,
    public propertyId: string
  ) {}
}

export class CheckGuestApplicationPossibleSuccess implements Action {
  readonly type = CHECK_GUEST_APPLICATION_POSSIBLE_SUCCESS;
  constructor(public guestStatus: GuestStatus) {}
}

export class CheckGuestApplicationPossibleFail implements Action {
  readonly type = CHECK_GUEST_APPLICATION_POSSIBLE_FAIL;
  constructor(public error: string) {}
}

export const APPLY_AS_GUEST = '[Guest Mode] Apply as Guest';
export const APPLY_AS_GUEST_SUCCESS = '[Guest Mode] Apply as Guest Success';
export const APPLY_AS_GUEST_FAIL = '[Guest Mode] Apply as Guest Fail';

export class ApplyAsGuest implements Action {
  readonly type = APPLY_AS_GUEST;
  constructor(
    public guestData: any,
    public token: string
  ) {}
}

export class ApplyAsGuestSuccess implements Action {
  readonly type = APPLY_AS_GUEST_SUCCESS;
}

export class ApplyAsGuestFail implements Action {
  readonly type = APPLY_AS_GUEST_FAIL;
  constructor(public error: string) {}
}

export const OPEN_GUEST_REGISTER_MODAL =
  '[Guest Mode] Open Guest Register Modal';

export class OpenGuestRegisterModal implements Action {
  readonly type = OPEN_GUEST_REGISTER_MODAL;
  constructor(
    public application: GuestApplication,
    public token: string,
    public declareIntent = false
  ) {}
}

export const GUEST_REGISTER = '[Guest Mode] Guest Register';
export const GUEST_REGISTER_SUCCESS = '[Guest Mode] Guest Register Success';
export const GUEST_REGISTER_FAIL = '[Guest Mode] Guest Register Fail';

export class GuestRegister implements Action {
  readonly type = GUEST_REGISTER;
  constructor(
    public userData: UserData,
    public token: string,
    public applicationIdToDeclareIntentTo?: string
  ) {}
}

export class GuestRegisterSuccess implements Action {
  readonly type = GUEST_REGISTER_SUCCESS;
}

export class GuestRegisterFail implements Action {
  readonly type = GUEST_REGISTER_FAIL;
  constructor(public error: string) {}
}

export const GUEST_APPLICATION_REGISTER =
  '[Guest Mode] Guest Application Register';

export class GuestApplicationRegister implements Action {
  readonly type = GUEST_APPLICATION_REGISTER;
  constructor(
    public userData: UserData,
    public token: string
  ) {}
}

export const LOAD_GUEST_CUSTOM_QUESTION_RESPONSES =
  '[Guest Mode] Load Guest Custom Question Responses';
export const LOAD_GUEST_CUSTOM_QUESTION_RESPONSES_SUCCESS =
  '[Guest Mode] Load Guest Custom Question Responses Success';
export const LOAD_GUEST_CUSTOM_QUESTION_RESPONSES_FAIL =
  '[Guest Mode] Load Guest Custom Question Responses Fail';

export class LoadGuestCustomQuestionResponses implements Action {
  readonly type = LOAD_GUEST_CUSTOM_QUESTION_RESPONSES;
  constructor(public token: string) {}
}

export class LoadGuestCustomQuestionResponsesSuccess implements Action {
  readonly type = LOAD_GUEST_CUSTOM_QUESTION_RESPONSES_SUCCESS;
  constructor(public customQuestionResponses: CustomQuestionResponse[]) {}
}

export class LoadGuestCustomQuestionResponsesFail implements Action {
  readonly type = LOAD_GUEST_CUSTOM_QUESTION_RESPONSES_FAIL;
  constructor(public error: string) {}
}

export const LOAD_GUEST_BRANDING = '[Guest Mode] Load Guest Branding';
export const LOAD_GUEST_BRANDING_SUCCESS =
  '[Guest Mode] Load Guest Branding Success';
export const LOAD_GUEST_BRANDING_FAIL = '[Guest Mode] Load Guest Branding Fail';

export class LoadGuestBranding implements Action {
  readonly type = LOAD_GUEST_BRANDING;
  constructor(public token: string) {}
}

export class LoadGuestBrandingSuccess implements Action {
  readonly type = LOAD_GUEST_BRANDING_SUCCESS;
  constructor(public branding: CustomerBranding) {}
}

export class LoadGuestBrandingFail implements Action {
  readonly type = LOAD_GUEST_BRANDING_FAIL;
  constructor(public error: string) {}
}

export const DELETE_GUEST = '[Guest Mode] Delete Guest';
export const DELETE_GUEST_SUCCESS = '[Guest Mode] Delete Guest Success';
export const DELETE_GUEST_FAIL = '[Guest Mode] Delete Guest Fail';

export class DeleteGuest implements Action {
  readonly type = DELETE_GUEST;
  constructor(
    public reasonData: ReasonData,
    public token: string
  ) {}
}

export class DeleteGuestSuccess implements Action {
  readonly type = DELETE_GUEST_SUCCESS;
}

export class DeleteGuestFail implements Action {
  readonly type = DELETE_GUEST_FAIL;
  constructor(public error: string) {}
}

export const LOAD_GUEST_APPOINTMENTS = '[Guest Mode] Load Guest Appointments';
export const LOAD_GUEST_APPOINTMENTS_SUCCESS =
  '[Guest Mode] Load Guest Appointments Success';
export const LOAD_GUEST_APPOINTMENTS_FAIL =
  '[Guest Mode] Load Guest Appointments Fail';

export class LoadGuestAppointments implements Action {
  readonly type = LOAD_GUEST_APPOINTMENTS;
  constructor(public token: string) {}
}

export class LoadGuestAppointmentsSuccess implements Action {
  readonly type = LOAD_GUEST_APPOINTMENTS_SUCCESS;
  constructor(public appointmentsBundles: AppointmentsBundles[]) {}
}

export class LoadGuestAppointmentsFail implements Action {
  readonly type = LOAD_GUEST_APPOINTMENTS_FAIL;
  constructor(public error: string) {}
}

export const LOAD_GUEST_APPLICATION = '[Guest Mode] Load Guest Application';
export const LOAD_GUEST_APPLICATION_SUCCESS =
  '[Guest Mode] Load Guest Application Success';
export const LOAD_GUEST_APPLICATION_FAIL =
  '[Guest Mode] Load Guest Application Fail';

export class LoadGuestApplication implements Action {
  readonly type = LOAD_GUEST_APPLICATION;
  constructor(public token: string) {}
}

export class LoadGuestApplicationSuccess implements Action {
  readonly type = LOAD_GUEST_APPLICATION_SUCCESS;
  constructor(public application: GuestApplication) {}
}

export class LoadGuestApplicationFail implements Action {
  readonly type = LOAD_GUEST_APPLICATION_FAIL;
  constructor(public error: Error) {}
}

export const ACCEPT_GUEST_APPOINTMENT = '[Guest Mode] Accept Guest Appointment';
export const ACCEPT_GUEST_APPOINTMENT_SUCCESS =
  '[Guest Mode] Accept Guest Appointment Success';
export const ACCEPT_GUEST_APPOINTMENT_FAIL =
  '[Guest Mode] Accept Guest Appointment Fail';

export class AcceptGuestAppointment implements Action {
  readonly type = ACCEPT_GUEST_APPOINTMENT;
  constructor(
    public appointmentId: string,
    public token: string
  ) {}
}

export class AcceptGuestAppointmentSuccess implements Action {
  readonly type = ACCEPT_GUEST_APPOINTMENT_SUCCESS;
}

export class AcceptGuestAppointmentFail implements Action {
  readonly type = ACCEPT_GUEST_APPOINTMENT_FAIL;
  constructor(public error: string) {}
}

export const CANCEL_GUEST_APPOINTMENT = '[Guest Mode] Cancel Guest Appointment';
export const CANCEL_GUEST_APPOINTMENT_SUCCESS =
  '[Guest Mode] Cancel Guest Appointment Success';
export const CANCEL_GUEST_APPOINTMENT_FAIL =
  '[Guest Mode] Cancel Guest Appointment Fail';

export class CancelGuestAppointment implements Action {
  readonly type = CANCEL_GUEST_APPOINTMENT;
  constructor(
    public cancelAppointmentData: CancelAppointmentData,
    public token: string
  ) {}
}

export class CancelGuestAppointmentSuccess implements Action {
  readonly type = CANCEL_GUEST_APPOINTMENT_SUCCESS;
}

export class CancelGuestAppointmentFail implements Action {
  readonly type = CANCEL_GUEST_APPOINTMENT_FAIL;
  constructor(public error: string) {}
}

export const GUEST_DECLARE_INTENT = '[Guest Mode] Guest Declare Intent';
export const GUEST_DECLARE_INTENT_SUCCESS =
  '[Guest Mode] Guest Declare Intent Success';
export const GUEST_DECLARE_INTENT_FAIL =
  '[Guest Mode] Guest Declare Intent Fail';

export class GuestDeclareIntent implements Action {
  readonly type = GUEST_DECLARE_INTENT;
  constructor(
    public declareIntentData: DeclareIntentData,
    public token: string
  ) {}
}

export class GuestDeclareIntentSuccess implements Action {
  readonly type = GUEST_DECLARE_INTENT_SUCCESS;
}

export class GuestDeclareIntentFail implements Action {
  readonly type = GUEST_DECLARE_INTENT_FAIL;
  constructor(public error: string) {}
}

export const LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES =
  '[Guest Mode] Hierarchical Question Responses';
export const LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES_FAIL =
  '[Guest Mode] Hierarchical Question Responses Fail';
export const LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES_SUCCESS =
  '[Guest Mode] Hierarchical Question Responses Success';

export class LoadGuestHierarchicalQuestionsResponses implements Action {
  readonly type = LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES;
  constructor(public token: string) {}
}

export class LoadGuestHierarchicalQuestionsResponsesSuccess implements Action {
  readonly type = LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES_SUCCESS;
  constructor(public questionContainer: QuestionResponseContainer) {}
}

export class LoadGuestHierarchicalQuestionsResponsesFail implements Action {
  readonly type = LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES_FAIL;
  constructor(public error: Error) {}
}

export type guestModeActions =
  | CheckGuestApplicationPossible
  | CheckGuestApplicationPossibleFail
  | CheckGuestApplicationPossibleSuccess
  | ApplyAsGuest
  | ApplyAsGuestFail
  | ApplyAsGuestSuccess
  | OpenGuestRegisterModal
  | GuestRegister
  | GuestRegisterFail
  | GuestRegisterSuccess
  | GuestApplicationRegister
  | LoadGuestCustomQuestionResponses
  | LoadGuestCustomQuestionResponsesFail
  | LoadGuestCustomQuestionResponsesSuccess
  | LoadGuestBranding
  | LoadGuestBrandingFail
  | LoadGuestBrandingSuccess
  | DeleteGuest
  | DeleteGuestFail
  | DeleteGuestSuccess
  | LoadGuestApplication
  | LoadGuestApplicationFail
  | LoadGuestApplicationSuccess
  | GuestDeclareIntent
  | GuestDeclareIntentFail
  | GuestDeclareIntentSuccess
  | AcceptGuestAppointment
  | AcceptGuestAppointmentFail
  | AcceptGuestAppointmentSuccess
  | CancelGuestAppointment
  | CancelGuestAppointmentFail
  | CancelGuestAppointmentSuccess
  | LoadGuestHierarchicalQuestionsResponses
  | LoadGuestHierarchicalQuestionsResponsesFail
  | LoadGuestHierarchicalQuestionsResponsesSuccess
  | LoadGuestAppointments
  | LoadGuestAppointmentsFail
  | LoadGuestAppointmentsSuccess;
