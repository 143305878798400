import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromBaseState from 'libs/infrastructure/base-state';

import {
  MainPageNavigation,
  notificationConfig as notification
} from 'tenant-pool/config';
import { SchufaFacade } from 'tenant-pool/core/services';
import * as fromAppReducers from 'tenant-pool/+state/reducers';

import { SchufaStepsRoutes } from 'tenant-pool/components/schufa/schufa-step.routes';
import { SchufaErrorStructure } from 'tenant-pool/models';

import * as fromActions from './schufa.actions';
import * as fromSelectors from './schufa.selectors';

@Injectable()
export class SchufaEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<fromAppReducers.AppState>>(Store);
  private schufaFacade = inject(SchufaFacade);

  changeStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.NextStep>(fromActions.NEXT_STEP),
      map(action => action.userData),
      withLatestFrom(this.store.select(fromSelectors.getCurrentStepNumber)),
      map(([userData, currentStepNumber]) => {
        return new fromBaseState.Go({
          path: [
            userData
              ? `${MainPageNavigation.SERVICE_CENTER}/${MainPageNavigation.SCHUFA}`
              : MainPageNavigation.SCHUFA,
            SchufaStepsRoutes[currentStepNumber - 1].path
          ]
        });
      })
    )
  );

  schufaPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SendSchufaPersonalData>(
        fromActions.SEND_SCHUFA_PERSONAL_DATA
      ),
      map(action => action.personalInformation),
      switchMap(personalInforamtion =>
        this.schufaFacade.personalData(personalInforamtion).pipe(
          map(
            response => new fromActions.SendSchufaPersonalDataSuccess(response)
          ),
          catchError((error: SchufaErrorStructure) => {
            return [
              new fromActions.SendSchufaPersonalDataFail(
                error.graphQLErrors[0].extra[0]
              )
            ];
          })
        )
      )
    )
  );

  schufaPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SendSchufaPayment>(fromActions.SEND_SCHUFA_PAYMENT),
      map(action => action.payment),
      withLatestFrom(
        this.store.select(fromSelectors.getSchufaPersonalDataResponseData)
      ),
      switchMap(([payment, order]) =>
        this.schufaFacade.payment(payment, order.orderNumber).pipe(
          map(response => new fromActions.SendSchufaPaymentSuccess(response)),
          catchError((error: SchufaErrorStructure) => {
            return [
              new fromActions.SendSchufaPaymentFail(
                error.graphQLErrors[0].extra[0]
              )
            ];
          })
        )
      )
    )
  );

  schufaOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SendSchufaOrder>(fromActions.SEND_SCHUFA_ORDER),
      withLatestFrom(
        this.store.select(fromSelectors.getSchufaPaymentResponseData)
      ),
      switchMap(([_, order]) =>
        this.schufaFacade.order(order.orderNumber).pipe(
          map(response => new fromActions.SendSchufaOrderSuccess(response)),
          catchError((error: SchufaErrorStructure) => {
            return [
              new fromActions.SendSchufaOrderFail(
                error.graphQLErrors[0].extra[0]
              )
            ];
          })
        )
      )
    )
  );

  schufaDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SendSchufaDelivery>(fromActions.SEND_SCHUFA_DELIVERY),
      map(action => action.delivery),
      withLatestFrom(
        this.store.select(fromSelectors.getSchufaOrderResponseData)
      ),
      switchMap(([delivery, order]) =>
        this.schufaFacade.delivery(delivery, order.deliveryNumber).pipe(
          map(response => new fromActions.SendSchufaDeliverySuccess(response)),
          catchError((error: SchufaErrorStructure) => {
            return [
              new fromActions.SendSchufaDeliveryFail(
                error.graphQLErrors[0]?.extra[0]
              ),
              new fromBaseState.ShowError(notification.schufa.delivery.error)
            ];
          })
        )
      )
    )
  );
}
