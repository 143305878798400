@if (introduction) {
  <div class="mb10" [innerHTML]="introduction.text"></div>
}
@for (checkbox of checkboxes; track checkbox.text) {
  @if (checkbox.text) {
    <app-checkbox
      (change)="checkboxValueChanged()"
      class="d-block"
      [innerHTML]="checkbox.text"
      [ngModel]="false"
    ></app-checkbox>
  }
}
@if (conclusion) {
  <div [innerHTML]="conclusion.text"></div>
}
