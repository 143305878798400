<div
  appElevation
  #switchContainer
  [ngClass]="{
    'fade-left': leftFade,
    'fade-right': rightFade,
    'switch-container': enableScrollFade
  }"
  (scroll)="enableScrollFade && onHorizontalScroll()"
  (wheel)="enableScrollFade && onWheelScroll($event)"
  [elevation]="enableScrollFade && elevation"
>
  <div
    appElevation
    [elevation]="!enableScrollFade && elevation"
    class="switch"
    [ngClass]="{ 'switch--disabled': disabled }"
    [class]="'switch--size-' + size"
  >
    @for (item of config; track item.id; let i = $index; let isLast = $last) {
      <div
        [ngClass]="{
          'd-flex align-items-center': true,
          switch__container: !enableDivider
        }"
      >
        @if (!item.invisible) {
          <div
            class="switch__item"
            [ngClass]="{
              'switch__item--active': selectedItem === item.id,
              'switch__item--disabled': item.disabled
            }"
            [ngbTooltip]="(item.disabled ? item.tooltip : null) | translate"
            (click)="selectItem(item)"
          >
            @if (item.label) {
              <span> {{ item.label | translate }} </span>
            }
            @if (item.amount !== undefined) {
              <app-badge
                [ngClass]="{ 'ms-2': item.label }"
                [color]="
                  item.id === selectedItem
                    ? 'neutral-light'
                    : 'neutral-light-dark'
                "
              >
                {{ item.amount }}
              </app-badge>
            }
          </div>
          @if (!isLast && enableDivider) {
            <div class="switch__divider"></div>
          }
        }
      </div>
    }
  </div>
</div>
