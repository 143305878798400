import { createSelector } from '@ngrx/store';
import { getCustomQuestionsState as state } from '../reducers';
import * as fromReducer from './custom-questions.reducers';

export const getSaveAnswersActionState = createSelector(
  state,
  fromReducer.getSaveAnswersActionState
);
export const getCustomQuestionActionState = createSelector(
  state,
  fromReducer.getCustomQuestionActionState
);
export const isCustomQuestionLoading = createSelector(
  state,
  fromReducer.isCustomQuestionLoading
);
export const getCustomQuestionLoading = createSelector(
  getCustomQuestionActionState,
  actionState => actionState.pending
);
export const getCustomQuestionErrorMessage = createSelector(
  getCustomQuestionActionState,
  actionState => actionState.error?.message
);
export const getCustomQuestionsByProperty = createSelector(
  state,
  fromReducer.getCustomQuestions
);
export const getHierarchicalQuestionsByProperty = createSelector(
  state,
  fromReducer.getHierarchicalQuestions
);
export const isHierarchicalQuestionsModalOpen = createSelector(
  state,
  fromReducer.isHierarchicalQuestionsModalOpen
);
export const getCustomerQuestionsAndAnswers = createSelector(
  state,
  fromReducer.getCustomerQuestionAndAnswers
);
