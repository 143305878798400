import { Action } from '@ngrx/store';
import { PropertyMatchBean, SelfDisclosureModel } from '@ui/shared/models';

export const LOAD_SELF_DISCLOSURE_DATA =
  '[Self Disclosure] Load Self Disclosure and Open Modal';

export class LoadSelfDisclosureData implements Action {
  readonly type = LOAD_SELF_DISCLOSURE_DATA;

  constructor(public propertySearcher: PropertyMatchBean) {}
}

export const OPEN_SELF_DISCLOSURE_MODAL =
  '[Self Disclosure] Open Self Disclosure Modal';

export class OpenSelfDisclosuresModal implements Action {
  readonly type = OPEN_SELF_DISCLOSURE_MODAL;

  constructor(public hasIntent?: boolean) {}
}

export const SAVE_PROPERTY_SEARCHER =
  '[Self Disclosure] Save Property Searcher';

export class SavePropertySearcher implements Action {
  readonly type = SAVE_PROPERTY_SEARCHER;

  constructor(public propertySearcher: PropertyMatchBean) {}
}

export const LOAD_SELF_DISCLOSURE_ANSWERS = '[Self Disclosure] Load Answers';
export const LOAD_SELF_DISCLOSURE_ANSWERS_FAIL =
  '[Self Disclosure] Load Answers Fail';
export const LOAD_SELF_DISCLOSURE_ANSWERS_SUCCESS =
  '[Self Disclosure] Load Answers Success';

export class LoadSelfDisclosureAnswers implements Action {
  readonly type = LOAD_SELF_DISCLOSURE_ANSWERS;

  constructor(public propertySearcherId: string) {}
}

export class LoadSelfDisclosureAnswersSuccess implements Action {
  readonly type = LOAD_SELF_DISCLOSURE_ANSWERS_SUCCESS;

  constructor(public selfDisclosureResponses: SelfDisclosureModel) {}
}

export class LoadSelfDisclosureAnswersFail implements Action {
  readonly type = LOAD_SELF_DISCLOSURE_ANSWERS_FAIL;

  constructor(public error: any) {}
}

export const SAVE_SELF_DISCLOSURE_ANSWERS = '[Self Disclosure] Save Answers';
export const SAVE_SELF_DISCLOSURE_ANSWERS_FAIL =
  '[Self Disclosure] Save Answers Fail';
export const SAVE_SELF_DISCLOSURE_ANSWERS_SUCCESS =
  '[Self Disclosure] Save Answers Success';

export class SaveSelfDisclosureAnswers implements Action {
  readonly type = SAVE_SELF_DISCLOSURE_ANSWERS;

  constructor(
    public id: string,
    public answers: SelfDisclosureModel
  ) {}
}

export class SaveSelfDisclosureAnswersSuccess implements Action {
  readonly type = SAVE_SELF_DISCLOSURE_ANSWERS_SUCCESS;

  constructor(public selfDisclosureResponses: SelfDisclosureModel) {}
}

export class SaveSelfDisclosureAnswersFail implements Action {
  readonly type = SAVE_SELF_DISCLOSURE_ANSWERS_FAIL;

  constructor(public error: any) {}
}

export const LOAD_SELF_DISCLOSURE_MODEL = '[Self Disclosure] Load Model';
export const LOAD_SELF_DISCLOSURE_MODEL_FAIL =
  '[Self Disclosure] Load Model Fail';
export const LOAD_SELF_DISCLOSURE_MODEL_SUCCESS =
  '[Self Disclosure] Load Model Success';

export class LoadSelfDisclosureModel implements Action {
  readonly type = LOAD_SELF_DISCLOSURE_MODEL;

  constructor(public propertyId: string) {}
}

export class LoadSelfDisclosureModelSuccess implements Action {
  readonly type = LOAD_SELF_DISCLOSURE_MODEL_SUCCESS;

  constructor(public selfDisclosureModel: SelfDisclosureModel) {}
}

export class LoadSelfDisclosureModelFail implements Action {
  readonly type = LOAD_SELF_DISCLOSURE_MODEL_FAIL;

  constructor(public error: any) {}
}

export type SelfDisclosureAction =
  | OpenSelfDisclosuresModal
  | SavePropertySearcher
  | LoadSelfDisclosureAnswers
  | LoadSelfDisclosureAnswersFail
  | LoadSelfDisclosureAnswersSuccess
  | SaveSelfDisclosureAnswers
  | SaveSelfDisclosureAnswersFail
  | SaveSelfDisclosureAnswersSuccess
  | LoadSelfDisclosureModel
  | LoadSelfDisclosureModelFail
  | LoadSelfDisclosureModelSuccess;
