<app-modal-v2>
  <div class="modal-s apply-confirmation">
    <app-modal-content>
      <div class="modal-body apply-confirmation__body">
        <div class="apply-confirmation__content">
          <div class="apply-confirmation__header">
            <p class="uppercase-m semibold header__title">
              {{
                'property.apply_confirmation.successful_application_l'
                  | translate
              }}
            </p>
          </div>

          <div class="apply-confirmation__sub-header">
            <img src="/assets/images/icons/icon-checkbox-green.svg" />
            <h3 class="title-xl header__title">
              {{
                'property.apply_confirmation.applied_to_property_l' | translate
              }}
            </h3>
          </div>

          <div class="apply-confirmation__information">
            {{ 'property.apply_confirmation.applied_message' | translate }}
            <div>
              <span class="information__property-name">{{ name }}</span>
              {{ 'general.property_l' | translate }}
            </div>
          </div>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer apply-confirmation__footer">
        <app-button [type]="'primary'" (clickEvent)="close()">{{
          'property.apply_confirmation.ok_l' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
