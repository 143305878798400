import { Directive, Input, TemplateRef, inject } from '@angular/core';

@Directive({
  selector: '[appTableHeader]',
  standalone: true
})
export class DataTableHeaderDirective {
  template = inject<TemplateRef<HTMLDivElement>>(TemplateRef);

  @Input('appTableHeader') name: string;
}
