import { AuthEffects } from './auth';
import { UserEffects } from './user';
import { SearchProfilesEffects } from './search-profiles';
import { PropertyMatchesEffects } from './property-matches';
import { AppointmentsEffects } from './appointments';
import { ApplyEffects } from './apply';
import { CustomQuestionsEffects } from './custom-questions';
import { SelfDisclosureEffects } from './self-disclosure';
import { SchufaEffects } from './schufa';
import { BrandingEffects } from './branding';
import { DigitalContractEffects } from './digital-contract';
import { GuestModeEffects } from './guest-mode';
import { RentDepositEffects } from './rent-deposit';
import { MovingChecklistEffects } from './moving-checklist';
import { MovingAuctionEffects } from './moving-auction';
import { ServiceCardsEffects } from './service-cards';
import { TooltipEffects } from './tooltip';
import { PaymentsEffects } from './payments';
import { ResidentEffects } from './resident';

export const effects: any[] = [
  AuthEffects,
  AppointmentsEffects,
  UserEffects,
  SearchProfilesEffects,
  PropertyMatchesEffects,
  ApplyEffects,
  CustomQuestionsEffects,
  SelfDisclosureEffects,
  SchufaEffects,
  BrandingEffects,
  DigitalContractEffects,
  GuestModeEffects,
  RentDepositEffects,
  MovingChecklistEffects,
  MovingAuctionEffects,
  ServiceCardsEffects,
  TooltipEffects,
  PaymentsEffects,
  ResidentEffects
];

export * from './auth';
export * from './user';
export * from './search-profiles';
export * from './appointments';
export * from './apply';
export * from './property-matches';
export * from './custom-questions';
export * from './self-disclosure';
export * from './schufa';
export * from './branding';
export * from './digital-contract';
export * from './guest-mode';
export * from './rent-deposit';
export * from './system';
export * from './moving-checklist';
export * from './moving-auction';
export * from './service-cards';
export * from './tooltip';
export * from './payments';
export * from './resident';

export * from './reducers';
