import { createAction, props } from '@ngrx/store';
import {
  MovingChecklist,
  MovingChecklistSetMoveInDatePayload,
  MovingChecklistTodo
} from '@ui/shared/models';

export const MovingChecklistGetByProperty = createAction(
  '[Moving Checklist] Get By Property'
);
export const MovingChecklistGetByPropertySuccess = createAction(
  '[Moving Checklist] Get By Property Success',
  props<{ movingChecklist: MovingChecklist }>()
);
export const MovingChecklistGetByPropertyFail = createAction(
  '[Moving Checklist] Get By Property Fail',
  props<{ error: any }>()
);

export const MovingChecklistSetMoveInDate = createAction(
  '[Moving Checklist] Set Move In Date',
  props<MovingChecklistSetMoveInDatePayload>()
);
export const MovingChecklistSetMoveInDateSuccess = createAction(
  '[Moving Checklist] Set Move In Date Success',
  props<{ movingChecklist: MovingChecklist }>()
);
export const MovingChecklistSetMoveInDateFail = createAction(
  '[Moving Checklist] Set Move In Date Fail',
  props<{ error: any }>()
);

export const MovingChecklistChangeElement = createAction(
  '[Moving Checklist] Change Element',
  props<{ element: MovingChecklistTodo }>()
);
export const MovingChecklistChangeElementSuccess = createAction(
  '[Moving Checklist] Change Element Success',
  props<{ movingChecklist: MovingChecklist }>()
);
export const MovingChecklistChangeElementFail = createAction(
  '[Moving Checklist] Change Element Fail',
  props<{ error: any }>()
);

export const MovingChecklistReset = createAction('[Moving Checklist] Reset');
export const MovingChecklistResetSuccess = createAction(
  '[Moving Checklist] Reset Success',
  props<{ movingChecklist: MovingChecklist }>()
);
export const MovingChecklistResetFail = createAction(
  '[Moving Checklist] Reset Fail',
  props<{ error: any }>()
);
