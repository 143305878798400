export enum NAVIGATION_LINK {
  SERVICE = '/service',

  NEW_HOME = '/new-home',

  PROPERTIES = '/properties',
  PROPERTIES_APPLICATIONS = '/properties/applications',

  SEARCH_PROFILES = '/search-profiles',

  MESSENGER = '/messenger',

  USER_PROFILE = '/profile',
  SETTINGS = '/settings',
  SETTINGS_ACCOUNT = '/settings/account',
  SETTINGS_CHANGE_EMAIL = '/settings/change-email',
  SETTINGS_CHANGE_PASSWORD = '/settings/change-password',

  PAYMENT_POSITIONS_REQUEST = '/payment/positions/request',
  PAYMENT_POSITIONS_RESULT = '/payment/positions/result',
  PAYMENT_SMARTDEPOSIT_APPLICATION = '/payment/smart-deposit/application',
  PAYMENT_SMARTDEPOSIT_CONTRACT = '/payment/smart-deposit/contract',
  PAYMENT_SMARTDEPOSIT_RESULT = '/payment/smart-deposit/result',

  TERMS_AND_CONDITIONS = '/terms-and-conditions'
}
