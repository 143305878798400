import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-rent-deposit-summary-card-header',
  templateUrl: './rent-deposit-summary-card-header.component.html',
  styleUrls: ['./rent-deposit-summary-card-header.component.scss'],
  standalone: true,
  imports: [SvgIconComponent]
})
export class RentDepositSummaryCardHeaderComponent {
  @Input() title: string;

  @Output() edit = new EventEmitter();

  public onEdit() {
    this.edit.emit();
  }
}
