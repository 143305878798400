<form [formGroup]="form" class="digital-contract-sign-viewing">
  <div class="row">
    <div class="col-12 mb12">
      <app-form-field>
        <app-form-field-label>{{
          'digital_contract.wizard_sign.flat_visited_l'
            | translate: { type: contract?.property?.type }
        }}</app-form-field-label>
        <app-flat-select
          class="form__flat-select boolean-flat-select"
          [items]="booleanOptions"
          formControlName="visited"
        >
        </app-flat-select>
      </app-form-field>
    </div>

    @if (hasVisited) {
      <div class="col-12 col-lg-8 pb-4">
        <app-form-field>
          <app-form-field-label>{{
            'digital_contract.wizard_sign.visit_date_l' | translate
          }}</app-form-field-label>
          <app-date
            formControlName="date"
            required
            [startDate]="maxViewingDate"
            [minDate]="minViewingDate"
            [maxDate]="maxViewingDate"
          >
          </app-date>
        </app-form-field>
      </div>
    }
    @if (hasNotVisited && continueSigning) {
      <div class="col-12">
        <app-form-field>
          <app-checkbox
            [required]="true"
            [label]="
              'digital_contract.wizard_sign.confirm_viewing_continue_l'
                | translate
            "
            formControlName="confirmViewing"
          ></app-checkbox>
        </app-form-field>
      </div>
    }
  </div>
</form>
