import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Elevation, ElevationType } from 'libs/directives';
import { CardBorderStyleEnum } from 'libs/components/atoms/card/card.enum';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CheckComponent } from '../../../legacy/form/controls/check/check.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { CardComponent } from '../../../atoms/card/card.component';

@Component({
  selector: 'app-portal-card',
  templateUrl: './portal-card.component.html',
  styleUrls: ['./portal-card.component.scss'],
  standalone: true,
  imports: [
    CardComponent,
    ImageComponent,
    NgbTooltip,
    CheckComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class PortalCardComponent implements OnInit {
  @Input() identification: any;
  @Input() imgUrl: string;
  @Input() title: string;
  @Input() deselectDisabledText: string;
  @Input() elevation: ElevationType = Elevation.ZERO;

  // behaviour control inputs
  @Input() selectable = true;
  @Input() deselectable = true;
  @Input() disabled = false;
  @Input() selected = false;

  @Output() toggleEvent = new EventEmitter<any>();

  public cardBorderStyle = CardBorderStyleEnum.DEFAULT;
  public cardBorderRadius = true;

  public checkControl = new FormControl({
    value: this.selected,
    disabled: this.isDisabled
  });

  public ngOnInit() {
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    this.checkControl.patchValue(this.selected);
    this.checkControl.valueChanges.subscribe(() =>
      this.toggleEvent.emit(this.identification)
    );
    /* eslint-enable @typescript-eslint/no-unsafe-call */
  }

  public onBoxClick() {
    if (this.isDisabled) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.checkControl.patchValue(!this.isSelected);
  }

  public get isSelected() {
    return !!this.checkControl?.value;
  }

  public get isDisabled() {
    return (
      this.disabled ||
      (!this.isSelected && !this.selectable) ||
      (this.isSelected && !this.deselectable)
    );
  }

  public get showDeselectionDisabledTooltip() {
    return this.isSelected && !this.deselectable && this.deselectDisabledText;
  }
}
