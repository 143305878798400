<input
  class="form-control"
  appInput
  #phoneInput
  [(ngModel)]="value"
  [required]="required"
  [disabled]="disabled"
  [readonly]="readOnly"
  (input)="onInput()"
  (focusout)="onFocusOut()"
/>
