<div
  class="navigation-drawer-item"
  [class.navigation-drawer-item--narrow]="narrow"
  [class.navigation-drawer-item--dark]="navigation.dark$ | async"
  [class.navigation-drawer-item--parent-of-open-floating-sub-menu]="
    isParentOfOpenFloatingSubMenu$ | async
  "
  [routerLinkActive]="hasChildren(item) ? 'active-parent' : 'active'"
  (mouseenter)="onMouseEnter(item)"
  (mouseleave)="onMouseLeave()"
>
  @if (isRouterLink(item) && hasChildren(item)) {
    <span
      class="navigation-drawer-item__hidden-router-link-indicator"
      [routerLink]="item.link"
    ></span>
  }
  <a
    class="navigation-drawer-item__link"
    [class.navigation-drawer-item__link--warning]="
      item.state === navigationItemState.WARNING
    "
    [routerLink]="
      isRouterLink(item) && (!narrow || !hasChildren(item)) ? item.link : null
    "
    [href]="item.redirectLink || false"
    [target]="item.redirectLink ? '_blank' : null"
    (click)="onClick(item)"
    [title]="narrow ? (item.label | translate) : ''"
  >
    @if (item.icon) {
      <i [class]="'navigation-drawer-item__icon icon icon--' + item.icon"></i>
    }
    <div
      class="navigation-drawer-item__notification-count navigation-drawer-item__notification-count--sticky"
      [class.navigation-drawer-item__notification-count--active]="
        item.notificationCount > 0
      "
      [@innerFade]="
        hasNotificationCount(item.notificationCount) && narrow ? 'show' : 'hide'
      "
    >
      <ng-container
        [ngTemplateOutlet]="notificationCount"
        [ngTemplateOutletContext]="{ limited: true }"
      ></ng-container>
    </div>

    <div
      class="navigation-drawer-item__label-container"
      [@innerFade]="narrow ? 'hide' : 'show'"
    >
      <span
        class="navigation-drawer-item__label"
        [class.navigation-drawer-item__label--no-text-wrap]="
          noTextWrap || narrow
        "
        >{{ item.label | translate }}</span
      >
      @if (hasNotificationCount(item.notificationCount)) {
        <div
          class="navigation-drawer-item__notification-count"
          [class.navigation-drawer-item__notification-count--active]="
            item.notificationCount > 0
          "
        >
          <ng-container
            [ngTemplateOutlet]="notificationCount"
            [ngTemplateOutletContext]="{ limited: false }"
          ></ng-container>
        </div>
      }
      @if (hasChildren(item)) {
        <i
          class="navigation-drawer-item__arrow-right icon icon--arrow-dropdown"
        ></i>
      }
    </div>
  </a>

  @if (hasChildren(item) && !narrow) {
    <div class="navigation-drawer-item__sub-list">
      <app-navigation-drawer-list
        [items]="item.children"
        [isSubList]="true"
      ></app-navigation-drawer-list>
    </div>
  }
</div>

<ng-template let-limited="limited" #notificationCount>
  <app-badge
    [color]="
      getNotificationCountBackgroundColor(
        item.notificationCount,
        navigation.dark$ | async
      )
    "
    size="small"
    borderRadius="big"
    >{{
      item.notificationCount > 99 && limited
        ? '+99'
        : (item.notificationCount | number)
    }}</app-badge
  >
</ng-template>
