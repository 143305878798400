import { NavigationItem } from 'tenant-pool/models';
import { NAVIGATION_LINK } from './navigation-links.config';

export const externalFAQLink = 'https://www.mieter.immomio.com/faq';
export const externalDataPrivacyLink =
  'https://www.mieter.immomio.com/datenschutz';
export const contactSupport = 'mailto:support@immomio.de';

// TODO: use full links given by NAVIGATION_LINK instead
export enum MainPageNavigation {
  APPLICATIONS = 'applications',
  APPOINTMENTS = 'appointments',
  PROPERTIES = 'properties',
  PROPERTY_NOT_FOUND = 'property-not-found',
  PROPERTY_RENTED = 'property-rented',
  NEW_HOME = 'new-home',
  OBJECTS = 'objects',
  EXPOSE = 'expose',
  MESSENGER = 'messenger',
  SEARCH_PROFILES = 'search-profiles',
  PROFILE = 'profile',
  SERVICE_CENTER = 'service',
  SERVICE_CENTER_LIST = 'list',
  MOVING_CHECKLIST_INTRO = 'moving-checklist-intro',
  MOVING_CHECKLIST = 'moving-checklist',
  LOGIN = 'login',
  SCHUFA = 'schufa',
  REGISTRATION = 'register',
  REGISTRATION_PROPERTYLOCATION = 'propertyLocation',
  MOVING_AUCTION = 'moving-auction',
  PAYMENT = 'payment',
  RESULT = 'result',
  TERMS_AND_CONDITIONS = 'terms-and-conditions'
}

// TODO: use full links given by NAVIGATION_LINK instead
export enum SettingsNavigation {
  ACCOUNT = 'account',
  CHANGE_EMAIL = 'change-email',
  CHANGE_PASSWORD = 'change-password'
}

export const navigationConfig: NavigationItem[] = [
  {
    name: 'service',
    label: 'navigation.service_l',
    link: NAVIGATION_LINK.SERVICE,
    icon: 'icon-service'
  },
  {
    name: 'new-home',
    label: 'navigation.new_home_l',
    link: NAVIGATION_LINK.NEW_HOME,
    icon: 'icon-my-application',
    newHome: true,
    mobileLabel: 'navigation.objects_l',
    additionalLinks: [
      MainPageNavigation.PROPERTIES,
      MainPageNavigation.SEARCH_PROFILES
    ]
  },
  {
    name: 'properties',
    label: 'navigation.properties_l',
    link: NAVIGATION_LINK.PROPERTIES_APPLICATIONS,
    hideOnMobile: true,
    icon: 'icon-home',
    additionalLinks: [MainPageNavigation.PROPERTIES]
  },
  {
    name: 'search-profiles',
    label: 'navigation.search_profiles_l',
    link: NAVIGATION_LINK.SEARCH_PROFILES,
    icon: 'icon-search-profile',
    hideOnMobile: true,
    additionalLinks: [MainPageNavigation.PROPERTIES]
  },
  {
    name: 'messenger',
    label: 'navigation.messenger_l',
    link: NAVIGATION_LINK.MESSENGER,
    icon: 'icon-messenger'
  },
  {
    name: 'profile',
    label: 'navigation.profile_l',
    link: NAVIGATION_LINK.USER_PROFILE,
    hideOnDesktop: true,
    icon: 'icon-person'
  },
  {
    name: 'settings',
    label: 'navigation.settings_l',
    link: NAVIGATION_LINK.SETTINGS,
    hide: true,
    children: [
      {
        name: 'account',
        label: 'navigation.account_settings_l',
        link: NAVIGATION_LINK.SETTINGS_ACCOUNT
      },
      {
        name: 'change-email',
        label: 'navigation.change_email_l',
        link: NAVIGATION_LINK.SETTINGS_CHANGE_EMAIL
      },
      {
        name: 'change-password',
        label: 'navigation.change_password_l',
        link: NAVIGATION_LINK.SETTINGS_CHANGE_PASSWORD
      }
    ]
  }
];
