import { gql } from 'apollo-angular';
import {
  SelfDisclosureQuestion,
  SelfDisclosureRequestResponseData
} from '@ui/shared/models';

export interface SelfDisclosureQueryResponse {
  selfDisclosureModel: {
    id: string;
    confirmations?: string[];
    feedbackEmail?: string;
    description?: string;
    documents?: string[];
    questions?: SelfDisclosureQuestion[];
  };
}

export interface SaveAnswersResponse {
  saveSelfDisclosureResponses: {
    status: number;
    statusText: string;
  };
}

export interface SelfDisclosureAnswerResponse {
  getSelfDisclosureResponses: SelfDisclosureRequestResponseData;
}

export interface SelfDisclosureSaveResponse {
  saveSelfDisclosureResponses: {
    status: number;
    statusText: string;
  };
}

export const selfDisclosureQuery = gql`
  query selfDisclosureModel($id: String!) {
    selfDisclosureModel(id: $id) {
      id
      feedbackEmail
      questions {
        id
        title
        mandatory
        showSelfDisclosureQuestions
        type
        commentAllowed
        commentHint
        uploadAllowed
        uploadHint
        subQuestions {
          id
          constantName
          title
          type
          showSelfDisclosureQuestions
          mandatory
          commentAllowed
          commentHint
        }
      }
      documents
      confirmations
      description
    }
  }
`;

export const selfDisclosureResponses = gql`
  query getSelfDisclosureResponses($id: String!) {
    getSelfDisclosureResponses(id: $id)
  }
`;

export const saveSelfDisclosureResponsesMutation = gql`
  mutation saveSelfDisclosureResponses($id: String, $input: JSON) {
    saveSelfDisclosureResponses(id: $id, input: $input) {
      status
      statusText
    }
  }
`;
