<app-modal-v2>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body search-profile-modal">
        <app-search-profile-details
          [form]="form"
          [landlordCityPacket]="landlordCityPacket"
          [searchProfile]="searchProfile"
          [onlyShowConfiguredCityPartsToUser]="
            onlyShowConfiguredCityPartsToUser && !!landlordCityPacket
          "
          [constants]="constants$ | async"
          [project]="project"
          [inInternalPool]="(userData$ | async)?.inInternalPool"
          [internalizationSettings]="itpLandlordCountries"
        >
        </app-search-profile-details>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer">
        <app-button
          [type]="'light-bordered'"
          [disabled]="processing$ | async"
          (clickEvent)="dismiss()"
        >
          {{ 'general.cancel_a' | translate }}
        </app-button>

        <app-button
          [disabled]="(processing$ | async) || form.invalid"
          [loading]="processing$ | async"
          [type]="'primary'"
          (clickEvent)="save()"
        >
          {{ 'search_profile.save_a' | translate }}
        </app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
