import { createAction, props } from '@ngrx/store';
import { RentDepositInfo, RentDepositModel } from '@ui/shared/models';

export const RentDepositWizardNextStep = createAction(
  '[RentDeposit] Wizard Next Step',
  props<{ id: string }>()
);

export const RentDepositWizardPreviousStep = createAction(
  '[RentDeposit] Wizard Previous Step',
  props<{ id: string }>()
);

export const RentDepositWizardGoToStep = createAction(
  '[RentDeposit] Wizard Go To Step',
  props<{ stepNumber: number; id: string }>()
);

export const RentDepositWizardReset = createAction(
  '[RentDeposit] Wizard Reset'
);

export const LoadDepositInfo = createAction(
  '[RentDeposit] Load Deposit Info',
  props<{ propertyId: string }>()
);

export const LoadDepositInfoSuccess = createAction(
  '[RentDeposit] Load Deposit Info Success',
  props<{ depositInfo: RentDepositInfo }>()
);

export const LoadDepositInfoFail = createAction(
  '[RentDeposit] Load Deposit Info Fail',
  props<{ error: Error }>()
);

export const UseRuvDeposit = createAction(
  '[RentDeposit] Use R+V Deposit',
  props<{ propertyId: string; input: RentDepositModel }>()
);

export const UseRuvDepositSuccess = createAction(
  '[RentDeposit] Use R+V Deposit Success',
  props<{ rentDepositInfo: RentDepositInfo }>()
);

export const UseRuvDepositFail = createAction(
  '[RentDeposit] Use R+V Deposit Fail',
  props<{ error: Error }>()
);

export const UseCashDeposit = createAction(
  '[RentDeposit] Use Cash Deposit',
  props<{ propertyId: string }>()
);

export const UseCashDepositSuccess = createAction(
  '[RentDeposit] Use Cash Deposit Success'
);

export const UseCashDepositFail = createAction(
  '[RentDeposit] Use Cash Deposit Fail',
  props<{ error: Error }>()
);
