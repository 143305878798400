import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, of, take } from 'rxjs';

import * as fromBaseState from 'libs/infrastructure/base-state';

import {
  AuthTokenService,
  LocalStorageService,
  SessionStorageService,
  WINDOW_REF
} from 'libs/infrastructure';
import { coerceBooleanProperty } from 'libs/utils';

import { StateAfterAuth } from '@ui/shared/models';
import { DeepLinkService } from 'libs/infrastructure/keycloak-authentication-module/services';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { storageKeys } from 'tenant-pool/config';
import { AuthToken } from 'libs/infrastructure/keycloak-authentication-module/model';
import { KeycloakTenantWebService } from 'libs/infrastructure/keycloak-authentication-module/services/keycloak-tenant-web.service';

@Injectable()
export class AuthGuard {
  private sessionStorage = inject(SessionStorageService);
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private authTokenService = inject(AuthTokenService);
  private keycloakWebService = inject(KeycloakTenantWebService);
  private deepLinkService = inject(DeepLinkService);
  private localStorageService = inject(LocalStorageService);
  private windowRef = inject(WINDOW_REF);
  private infrastructure = inject(INFRASTRUCTURE_CONFIG);

  private stateAfterAuth: StateAfterAuth;

  canActivate(route: ActivatedRouteSnapshot) {
    const queryParams = route.queryParams;
    const { socialLogin, isSearching, ...rest } = queryParams;
    this.stateAfterAuth = (
      rest?.pathAfterAuth?.length > 0
        ? rest
        : this.sessionStorage.getItem(storageKeys.stateAfterAuth)
    ) as StateAfterAuth;

    if (!this.stateAfterAuth || !this.stateAfterAuth.pathAfterAuth) {
      return of(true);
    }

    this.stateAfterAuth.queryParams = {
      ...queryParams,
      socialLogin: coerceBooleanProperty(socialLogin),
      isSearching
    };

    // When the path includes query params, cut them off
    // Because they are already set in stateAfterAuth.queryParams
    if (this.stateAfterAuth.pathAfterAuth.includes('?'))
      this.stateAfterAuth.pathAfterAuth =
        this.stateAfterAuth.pathAfterAuth?.split('?')[0];

    const ssoParams = route?.fragment
      ? this.deepLinkService.getSSOParams(route)
      : this.authTokenService.getToken();
    const redirectUri = this.localStorageService.getItem(
      storageKeys.redirectUri
    );

    if (ssoParams?.access_token) {
      return of(true);
    } else {
      this.keycloakWebService
        .keycloakInitialized()
        .pipe(
          filter(initialized => initialized),
          take(1)
        )
        .subscribe(() => {
          const keycloakInstanceToken =
            this.keycloakWebService.getAuthTokenFromKeycloakInstance();
          if (keycloakInstanceToken) {
            this.continueLogin(keycloakInstanceToken);
          } else {
            void this.keycloakWebService
              .continueLoginWithCode({
                code: ssoParams.code,
                redirectUri
              })
              .then(
                // eslint-disable-next-line @typescript-eslint/require-await
                authToken => this.continueLogin(authToken)
              );
          }
        });
    }
  }

  private continueLogin(authToken: AuthToken) {
    this.localStorageService.removeItem(storageKeys.redirectUri);
    this.store.dispatch(
      new fromBaseState.UserLoginSuccess(authToken, this.stateAfterAuth)
    );
    return of(true);
  }
}
