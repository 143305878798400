import { createSelector } from '@ngrx/store';
import { getBrandingState } from '../reducers';
import * as fromReducer from './branding.reducer';

export const getValidateTokenActionState = createSelector(
  getBrandingState,
  fromReducer.getValidateTokenActionState
);
export const getTheme = createSelector(getBrandingState, fromReducer.getTheme);
export const getValidTheme = createSelector(
  getBrandingState,
  fromReducer.getValidTheme
);
export const getTokenId = createSelector(getTheme, theme => theme?.tokenId);
export const getCustomerBranding = createSelector(
  getBrandingState,
  fromReducer.getCustomerBranding
);

export const isCustomerBrandingLoading = createSelector(
  getBrandingState,
  fromReducer.isCustomerBrandingLoading
);
