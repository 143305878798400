import { Component, Input } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-self-disclosure-address',
  templateUrl: './self-disclosure-address.component.html',
  styleUrls: ['./self-disclosure-address.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class SelfDisclosureAddressComponent {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() form: FormGroup;

  public get getFormControl() {
    return this.form.get('answer') as FormGroup;
  }
}
