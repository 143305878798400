import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ComponentsModule } from 'libs/components';

import {
  SelfDisclosureFormComponent,
  SelfDisclosureFlatComponent,
  SelfDisclosurePersonComponent,
  SelfDisclosurePersonListComponent,
  SelfDisclosureAddressComponent,
  SelfDisclosureEmploymentComponent,
  SelfDisclosureChildComponent,
  SelfDisclosureChildListComponent,
  SelfDisclosureDocumentComponent
} from './components';
import { SelfDisclosureService } from './self-disclosure.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    ComponentsModule,
    AngularSvgIconModule,
    SelfDisclosureFormComponent,
    SelfDisclosureFlatComponent,
    SelfDisclosurePersonComponent,
    SelfDisclosurePersonListComponent,
    SelfDisclosureAddressComponent,
    SelfDisclosureEmploymentComponent,
    SelfDisclosureChildComponent,
    SelfDisclosureChildListComponent,
    SelfDisclosureDocumentComponent
  ],
  exports: [
    SelfDisclosureFormComponent,
    SelfDisclosureFlatComponent,
    SelfDisclosurePersonComponent,
    SelfDisclosurePersonListComponent,
    SelfDisclosureAddressComponent,
    SelfDisclosureEmploymentComponent,
    SelfDisclosureChildComponent,
    SelfDisclosureChildListComponent,
    SelfDisclosureDocumentComponent
  ]
})
export class SelfDisclosureModule {
  public static forRoot(): ModuleWithProviders<SelfDisclosureModule> {
    return {
      ngModule: SelfDisclosureModule,
      providers: [SelfDisclosureService]
    };
  }
}
