import { ActionState, ActionStateCreator } from 'libs/state-utils';
import { BrandingTheme, SimpleCustomerBranding } from '@ui/shared/models';
import { notificationConfig } from 'tenant-pool/config';

import * as fromActions from '../branding/branding.actions';

export interface BrandingState {
  theme: BrandingTheme;
  valid: boolean;
  validateTokenActionState: ActionState;
  customerBranding: SimpleCustomerBranding;
  customerBrandingActionState: ActionState;
}

export const initialState: BrandingState = {
  theme: null,
  valid: false,
  validateTokenActionState: ActionStateCreator.create(),
  customerBranding: null,
  customerBrandingActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.BrandingActions
): BrandingState {
  switch (action.type) {
    case fromActions.VALIDATE_THEME_TOKEN: {
      return {
        ...state,
        validateTokenActionState: ActionStateCreator.onStart(),
        theme: null,
        valid: false
      };
    }

    case fromActions.VALIDATE_THEME_TOKEN_SUCCESS: {
      return {
        ...state,
        validateTokenActionState: ActionStateCreator.onSuccess(),
        theme: action.theme,
        valid: true
      };
    }

    case fromActions.VALIDATE_THEME_TOKEN_FAIL: {
      return {
        ...state,
        validateTokenActionState: ActionStateCreator.onError(
          new Error(notificationConfig.branding.validate.error)
        ),
        theme: null,
        valid: false
      };
    }

    case fromActions.LOAD_CUSTOMER_BRANDING: {
      return {
        ...state,
        customerBrandingActionState: ActionStateCreator.onStart(),
        customerBranding: null
      };
    }

    case fromActions.LOAD_CUSTOMER_BRANDING_SUCCESS: {
      return {
        ...state,
        customerBrandingActionState: ActionStateCreator.onSuccess(),
        customerBranding: action.customerBranding
      };
    }

    case fromActions.LOAD_CUSTOMER_BRANDING_FAIL: {
      return {
        ...state,
        customerBrandingActionState: ActionStateCreator.onError(action.error),
        customerBranding: null
      };
    }

    default:
      return state;
  }
}
export const getValidateTokenActionState = (state: BrandingState) =>
  state.validateTokenActionState;
export const getTheme = (state: BrandingState) => state.theme;
export const getValidTheme = (state: BrandingState) => state.valid;
export const getCustomerBranding = (state: BrandingState) =>
  state.customerBranding;
export const isCustomerBrandingLoading = (state: BrandingState) =>
  state.customerBrandingActionState.pending &&
  !state.customerBrandingActionState.done;
