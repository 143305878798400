<div class="social-login row">
  <div
    class="col-12 social-login__wrapper"
    [ngClass]="{ 'social-login__wrapper--horizontal': horizontalLayout }"
  >
    <app-button
      class="w-100"
      [type]="'light-bordered'"
      (clickEvent)="onSocialLogin(socialLoginProvider.GOOGLE)"
    >
      <img src="/assets/images/socialLogin/Google_Logo.png" />
      @if (showText) {
        <span class="pl10"> {{ 'social_login.google_l' | translate }} </span>
      }
    </app-button>
    <app-button
      class="w-100"
      [type]="'light-bordered'"
      (clickEvent)="onSocialLogin(socialLoginProvider.APPLE)"
    >
      <img src="/assets/images/socialLogin/apple.svg" />
      @if (showText) {
        <span class="pl10">
          {{ 'social_login.apple_signin' | translate }}
        </span>
      }
    </app-button>
  </div>
</div>
