export const addressFields = `
  city
  country
  countryName
  region
  street
  houseNumber
  zipCode
  district
  additional
  coordinates {
    lon
    lat
  }
`;
