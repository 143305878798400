import { Injectable, inject } from '@angular/core';

import { Apollo, QueryRef } from 'apollo-angular';
import {
  FilterOptions,
  PropertyMatchBean,
  QuestionContainer
} from '@ui/shared/models';

import { map } from 'rxjs/operators';
import { notificationConfig } from 'tenant-pool/config';
import {
  PropertyApplyProcessPSBeansResult,
  PropertyApplyProcessRentedResult
} from 'tenant-pool/models';

import {
  propertyApplyProcessPSBeansQuery,
  propertyApplyProcessPSBeanQuery,
  propertyApplyProcessRentedQuery,
  propertyApplyProcessPSBeanQuestionsQuery
} from '../queries';

@Injectable()
export class PropertyMatchesFacade {
  private apollo = inject(Apollo);

  private propertyMatchesQuery: QueryRef<PropertyApplyProcessPSBeansResult>;
  private propertyMatchesRentedQuery: QueryRef<PropertyApplyProcessRentedResult>;
  private propertyMatchQuery: QueryRef<{
    propertyApplyProcessPSBean: PropertyMatchBean;
  }>;

  public loadPropertyMatchesBeans(filterOptions: FilterOptions) {
    this.propertyMatchesQuery =
      this.apollo.watchQuery<PropertyApplyProcessPSBeansResult>({
        query: propertyApplyProcessPSBeansQuery,
        variables: { filter: filterOptions },
        fetchPolicy: 'no-cache'
      });

    return this.propertyMatchesQuery.valueChanges.pipe(
      map(response => {
        const propertyApplyProcessPSBeans =
          response.data.propertyApplyProcessPSBeans;

        if (!propertyApplyProcessPSBeans) {
          throw new Error(
            notificationConfig.propertyApplyProcessPSBeans.load.error
          );
        }

        return propertyApplyProcessPSBeans;
      })
    );
  }

  public loadPropertyMatchesRented(filterOptions: FilterOptions) {
    this.propertyMatchesRentedQuery =
      this.apollo.watchQuery<PropertyApplyProcessRentedResult>({
        query: propertyApplyProcessRentedQuery,
        variables: { filter: filterOptions },
        fetchPolicy: 'no-cache'
      });

    return this.propertyMatchesRentedQuery.valueChanges.pipe(
      map(response => response.data.propertyApplyProcessRented)
    );
  }

  public loadPropertyMatchBean(id: string) {
    this.propertyMatchQuery = this.apollo.watchQuery<{
      propertyApplyProcessPSBean: PropertyMatchBean;
    }>({
      query: propertyApplyProcessPSBeanQuery,
      variables: { id },
      fetchPolicy: 'no-cache'
    });

    return this.propertyMatchQuery.valueChanges.pipe(
      map(response => {
        const propertyApplyProcessPSBean =
          response?.data?.propertyApplyProcessPSBean;

        if (!propertyApplyProcessPSBean) {
          throw new Error(
            notificationConfig.propertyApplyProcessPSBeans.load.error
          );
        }

        return propertyApplyProcessPSBean;
      })
    );
  }

  public loadPropertyMatchBeanQuestions(id: string, status: string) {
    return this.apollo.query<{
      propertyApplyProcessPSBeanQuestions: QuestionContainer;
    }>({
      query: propertyApplyProcessPSBeanQuestionsQuery,
      variables: { id, status },
      fetchPolicy: 'no-cache'
    });
  }

  public refetchMatch(id?: string): void {
    if (this.propertyMatchQuery) {
      id
        ? void this.propertyMatchQuery.refetch({ id })
        : void this.propertyMatchQuery.refetch();
    }
  }

  public refetchMatches(): void {
    if (this.propertyMatchesQuery) {
      void this.propertyMatchesQuery.refetch();
    }
  }

  public refetchRentedMatches(): void {
    if (this.propertyMatchesRentedQuery) {
      void this.propertyMatchesRentedQuery.refetch();
    }
  }
}
