import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ConstantsTypeTranslatorService } from 'libs/services';
import { DecimalPipe } from '@angular/common';

import { IsItemInArrayPipe } from 'libs/pipes/is-item-in-array.pipe';
import { IsNotNullAndUndefined } from 'libs/pipes/is-not-null-and-undefined.pipe';
import { AlphaIndexPipe } from './alpha-index.pipe';
import { LocationPipe } from './location.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FullNamePipe } from './full-name.pipe';
import { CardNumberPipe } from './card-number.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { HumanizeBooleanPipe } from './humanize-boolean.pipe';
import { ConstantsTypePipe } from './constants-type.pipe';
import { FloorPipe } from './floor.pipe';
import { MessageTimePipe } from './message-time.pipe';
import { TextSearchPipe } from './text-search.pipe';
import { SortPipe } from './sort.pipe';

import { AddressPipeModule } from './address-pipe.module';
import { DateTimePipeModule } from './date-time-pipe.module';
import { ImageSizePipeModule } from './image-size-pipe.module';
import { KeyValueMaintainMapOrderPipe } from './key-value-maintain-map-order.pipe';
import { StatusColorPipe } from './status-color.pipe';
import { FormControlPipeModule } from './form-control.pipe.module';
import { ReplacePipe } from './replace.pipe';
import { EnergyClassPipe } from './energy-class.pipe';

const pipes = [
  AlphaIndexPipe,
  LocationPipe,
  FileSizePipe,
  FullNamePipe,
  CardNumberPipe,
  EllipsisPipe,
  ConstantsTypePipe,
  HumanizeBooleanPipe,
  FloorPipe,
  MessageTimePipe,
  TextSearchPipe,
  SortPipe,
  KeyValueMaintainMapOrderPipe,
  StatusColorPipe,
  ReplacePipe,
  EnergyClassPipe,
  IsItemInArrayPipe,
  IsNotNullAndUndefined
];

const pipeModules = [
  AddressPipeModule,
  DateTimePipeModule,
  ImageSizePipeModule,
  FormControlPipeModule
];

@NgModule({
  imports: [...pipeModules, TranslateModule, ...pipes],
  exports: [...pipeModules, ...pipes],
  providers: [ConstantsTypeTranslatorService, DecimalPipe, ...pipes]
})
export class PipesModule {}
