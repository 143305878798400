import { Action } from '@ngrx/store';
import { Project, PropertyType, SearchProfile } from '@ui/shared/models';

export const LOAD_SEARCH_PROFILES = '[SearchProfile] Load Search Profiles';
export const LOAD_SEARCH_PROFILES_FAIL =
  '[SearchProfile] Load Search Profiles Fail';
export const LOAD_SEARCH_PROFILES_SUCCESS =
  '[SearchProfile] Load Search Profiles Success';

export class LoadSearchProfiles implements Action {
  readonly type = LOAD_SEARCH_PROFILES;
}

export class LoadSearchProfilesSuccess implements Action {
  readonly type = LOAD_SEARCH_PROFILES_SUCCESS;
  constructor(public searchProfiles: SearchProfile[]) {}
}

export class LoadSearchProfilesFail implements Action {
  readonly type = LOAD_SEARCH_PROFILES_FAIL;
  constructor(public error: Error) {}
}

export const SAVE_SEARCH_PROFILE = '[SearchProfile] Save Search Profile';
export const SAVE_SEARCH_PROFILE_FAIL =
  '[SearchProfile] Save Search Profile Fail';
export const SAVE_SEARCH_PROFILE_SUCCESS =
  '[SearchProfile] Save Search Profile Success';

export class SaveSearchProfile implements Action {
  readonly type = SAVE_SEARCH_PROFILE;
  constructor(
    public searchProfile: SearchProfile,
    public addToState = true
  ) {}
}

export class SaveSearchProfileSuccess implements Action {
  readonly type = SAVE_SEARCH_PROFILE_SUCCESS;
  constructor(
    public searchProfile: SearchProfile,
    public addToState = true
  ) {}
}

export class SaveSearchProfileFail implements Action {
  readonly type = SAVE_SEARCH_PROFILE_FAIL;
  constructor(public error: Error) {}
}

export const DELETE_SEARCH_PROFILE = '[SearchProfile] Delete Search Profile';
export const DELETE_SEARCH_PROFILE_FAIL =
  '[SearchProfile] Delete Search Profile Fail';
export const DELETE_SEARCH_PROFILE_SUCCESS =
  '[SearchProfile] Delete Search Profile Success';

export class DeleteSearchProfile implements Action {
  readonly type = DELETE_SEARCH_PROFILE;
  constructor(public searchProfileId: string) {}
}

export class DeleteSearchProfileSuccess implements Action {
  readonly type = DELETE_SEARCH_PROFILE_SUCCESS;
  constructor(public searchProfileId: string) {}
}

export class DeleteSearchProfileFail implements Action {
  readonly type = DELETE_SEARCH_PROFILE_FAIL;
  constructor(public error: Error) {}
}

export const DELETE_ALL_SEARCH_PROFILES =
  '[SearchProfile] Delete All Search Profiles';
export const DELETE_ALL_SEARCH_PROFILES_FAIL =
  '[SearchProfile] Delete All Search Profiles Fail';
export const DELETE_ALL_SEARCH_PROFILES_SUCCESS =
  '[SearchProfile] Delete All Search Profiles Success';

export class DeleteAllSearchProfiles implements Action {
  readonly type = DELETE_ALL_SEARCH_PROFILES;
}

export class DeleteAllSearchProfilesSuccess implements Action {
  readonly type = DELETE_ALL_SEARCH_PROFILES_SUCCESS;
}

export class DeleteAllSearchProfilesFail implements Action {
  readonly type = DELETE_ALL_SEARCH_PROFILES_FAIL;
  constructor(public error: Error) {}
}

export const DEACTIVATE_ALL_SEARCH_PROFILES =
  '[SearchProfile] Deactivate All Search Profiles';
export const DEACTIVATE_ALL_SEARCH_PROFILES_FAIL =
  '[SearchProfile] Deactivate All Search Profiles Fail';
export const DEACTIVATE_ALL_SEARCH_PROFILES_SUCCESS =
  '[SearchProfile] Deactivate All Search Profiles Success';

export class DeactivateAllSearchProfiles implements Action {
  readonly type = DEACTIVATE_ALL_SEARCH_PROFILES;
  constructor(public propertyType: PropertyType) {}
}

export class DeactivateAllSearchProfilesSuccess implements Action {
  readonly type = DEACTIVATE_ALL_SEARCH_PROFILES_SUCCESS;
}

export class DeactivateAllSearchProfilesFail implements Action {
  readonly type = DEACTIVATE_ALL_SEARCH_PROFILES_FAIL;
  constructor(public error: Error) {}
}

export const ACTIVATE_ALL_SEARCH_PROFILES =
  '[SearchProfile] Activate All Search Profiles';
export const ACTIVATE_ALL_SEARCH_PROFILES_FAIL =
  '[SearchProfile] Activate All Search Profiles Fail';
export const ACTIVATE_ALL_SEARCH_PROFILES_SUCCESS =
  '[SearchProfile] Activate All Search Profiles Success';

export class ActivateAllSearchProfiles implements Action {
  readonly type = ACTIVATE_ALL_SEARCH_PROFILES;
  constructor(public propertyType: PropertyType) {}
}

export class ActivateAllSearchProfilesSuccess implements Action {
  readonly type = ACTIVATE_ALL_SEARCH_PROFILES_SUCCESS;
}

export class ActivateAllSearchProfilesFail implements Action {
  readonly type = ACTIVATE_ALL_SEARCH_PROFILES_FAIL;
  constructor(public error: Error) {}
}

export const TOGGLE_SEARCH_PROFILE = '[SearchProfile] Toggle Search Profile';
export const TOGGLE_SEARCH_PROFILE_FAIL =
  '[SearchProfile] Toggle Search Profile Fail';
export const TOGGLE_SEARCH_PROFILE_SUCCESS =
  '[SearchProfile] Toggle Search Profile Success';

export class ToggleSearchProfile implements Action {
  readonly type = TOGGLE_SEARCH_PROFILE;
  constructor(public searchProfileId: string) {}
}

export class ToggleSearchProfileSuccess implements Action {
  readonly type = TOGGLE_SEARCH_PROFILE_SUCCESS;
  constructor(public searchProfileId: string) {}
}

export class ToggleSearchProfileFail implements Action {
  readonly type = TOGGLE_SEARCH_PROFILE_FAIL;
  constructor(public error: Error) {}
}

export const FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE =
  '[SearchProfile] Fetch Project For Project Search Profile';
export const FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE_SUCCESS =
  '[SearchProfile] Fetch Project For Project Search Profile Success';
export const FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE_FAIL =
  '[SearchProfile] Fetch Project For Project Search Profile Fail';

export class FetchProjectForProjectSearchProfile implements Action {
  readonly type = FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE;
  constructor(public token: string) {}
}

export class FetchProjectForProjectSearchProfileSuccess implements Action {
  readonly type = FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE_SUCCESS;
  constructor(public project: Project) {}
}

export class FetchProjectForProjectSearchProfileFail implements Action {
  readonly type = FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE_FAIL;
  constructor(public error: Error) {}
}

export const UNLOAD_PROJECT_FOR_PROJECT_SEARCH_PROFILE =
  '[SearchProfile] Unload Project For Project Search Profile';

export class UnloadProjectForProjectSearchProfile implements Action {
  readonly type = UNLOAD_PROJECT_FOR_PROJECT_SEARCH_PROFILE;
}

export type SearchProfilesAction =
  | LoadSearchProfiles
  | LoadSearchProfilesFail
  | LoadSearchProfilesSuccess
  | SaveSearchProfile
  | SaveSearchProfileFail
  | SaveSearchProfileSuccess
  | DeleteSearchProfile
  | DeleteSearchProfileFail
  | DeleteSearchProfileSuccess
  | ToggleSearchProfile
  | ToggleSearchProfileFail
  | ToggleSearchProfileSuccess
  | DeleteAllSearchProfiles
  | DeleteAllSearchProfilesFail
  | DeleteAllSearchProfilesSuccess
  | DeactivateAllSearchProfiles
  | DeactivateAllSearchProfilesSuccess
  | DeactivateAllSearchProfilesFail
  | ActivateAllSearchProfiles
  | ActivateAllSearchProfilesSuccess
  | ActivateAllSearchProfilesFail
  | FetchProjectForProjectSearchProfile
  | FetchProjectForProjectSearchProfileSuccess
  | FetchProjectForProjectSearchProfileFail
  | UnloadProjectForProjectSearchProfile;
