import { Injectable, inject } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { QesCheck, SignatureType, TenantInformation } from '@ui/shared/models';

import { notificationConfig } from 'tenant-pool/config';

import {
  currentStatusQuery,
  CurrentStatusQueryResponse,
  qesCheckMutation,
  QesCheckResponse,
  signingUrlQuery,
  SigningUrlQueryResponse
} from 'libs/queries/digital-contract.query';
import {
  digitalContractQuery,
  DigitalContractQueryResult,
  QueryBaseResponse,
  sendMessageQuery,
  sendTenantInfoMutation,
  SendTenantInfoResponse
} from '../queries';

@Injectable()
export class DigitalContractFacade {
  private apollo = inject(Apollo);

  private aesStatusQueryRef: QueryRef<CurrentStatusQueryResponse>;

  public loadDigitalContract(token: string) {
    return this.apollo
      .query<DigitalContractQueryResult>({
        query: digitalContractQuery,
        variables: { token }
      })
      .pipe(
        map(response => {
          const contract = response?.data?.digitalContract;

          if (!contract) {
            throw new Error(notificationConfig.property.fetch.error);
          }

          return contract;
        })
      );
  }

  public sendMessage(token: string) {
    return this.apollo.query<QueryBaseResponse>({
      variables: { token },
      query: sendMessageQuery
    });
  }

  public sendTenantInfo(info: TenantInformation, signatureType: SignatureType) {
    return this.apollo
      .mutate<SendTenantInfoResponse>({
        mutation: sendTenantInfoMutation,
        variables: { info },
        update:
          signatureType === SignatureType.AES_MAIL
            ? () => this.refetchAesStatus()
            : null
      })
      .pipe(map(response => response.data.sendTenantInfo));
  }

  public currentStatus(token: string) {
    this.aesStatusQueryRef = this.apollo.watchQuery<CurrentStatusQueryResponse>(
      {
        variables: { token },
        query: currentStatusQuery,
        fetchPolicy: 'no-cache'
      }
    );
    return this.aesStatusQueryRef.valueChanges.pipe(
      map(response => response.data.currentStatus)
    );
  }

  public getSigningUrl(token: string, redirectUrl) {
    return this.apollo
      .query<SigningUrlQueryResponse>({
        variables: { token, redirectUrl },
        query: signingUrlQuery,
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.getSigningUrl));
  }

  public qesCheck(qesCheck: QesCheck) {
    return this.apollo
      .mutate<QesCheckResponse>({
        variables: { data: qesCheck },
        mutation: qesCheckMutation
      })
      .pipe(map(res => res.data.qesCheck));
  }

  private refetchAesStatus(): void {
    if (this.aesStatusQueryRef) {
      void this.aesStatusQueryRef.refetch();
    }
  }
}
