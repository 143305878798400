import { NgModule, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CONSTANTS_LOADER, ConstantsParserService } from 'libs/infrastructure';

import { ComponentsModule } from 'libs/components';

import { PipesModule } from 'libs/pipes';
import { HierarchicalQuestionService } from 'libs/services';

import { environment } from 'tenant-pool-env';

import {
  ApplicationCreationGuard,
  AuthGuard,
  ExtendSearchGuard,
  MovingChecklistGuard,
  NewHomeGuard,
  SchufaStepGuard,
  ServiceCenterGuard,
  TenantTranslationsGuard,
  UserDataGuard
} from 'tenant-pool/core/guards';
import { resolver } from './resolver';
import { ConstantsLoaderService } from './loaders';
import { ENVIRONMENT_CONFIG } from './environment';

import {
  ApplicationFacade,
  PropertyMatchesFacade,
  ProposalFacade,
  SearchProfileFacade,
  UserFacade,
  AppointmentFacade,
  PropertyFacade,
  CustomQuestionFacade,
  SelfDisclosureFacade,
  SchufaFacade,
  DigitalContractFacade,
  AuthService,
  AsyncValidatorsService,
  GuestModeFacade,
  UrlUtilService,
  RentalDepositFacade,
  RentalDepositService,
  MovingChecklistFacade,
  MovingAuctionFacade,
  ServiceCardsFacade,
  TooltipFacade,
  PaymentsFacade,
  ResidentFacade
} from './services';

import {
  AuthenticatedShellComponent,
  MainNavigationComponent,
  MainNavigationItemComponent,
  MobileNavigationComponent,
  UnauthenticatedShellComponent
} from './shell';
import { PaymentsService } from './services/payments.service';

const services = [
  ApplicationFacade,
  PropertyMatchesFacade,
  AppointmentFacade,
  ProposalFacade,
  SearchProfileFacade,
  UserFacade,
  PropertyFacade,
  CustomQuestionFacade,
  HierarchicalQuestionService,
  SelfDisclosureFacade,
  SchufaFacade,
  DigitalContractFacade,
  AuthService,
  AsyncValidatorsService,
  UrlUtilService,
  GuestModeFacade,
  RentalDepositFacade,
  RentalDepositService,
  MovingAuctionFacade,
  MovingChecklistFacade,
  ServiceCardsFacade,
  TooltipFacade,
  PaymentsFacade,
  ResidentFacade,
  PaymentsService
];

const providers = [
  AuthGuard,
  UserDataGuard,
  ApplicationCreationGuard,
  ExtendSearchGuard,
  SchufaStepGuard,
  NewHomeGuard,
  MovingChecklistGuard,
  ServiceCenterGuard,
  TenantTranslationsGuard,
  ...services,
  ...resolver,
  ConstantsParserService,
  { provide: CONSTANTS_LOADER, useClass: ConstantsLoaderService },
  { provide: ENVIRONMENT_CONFIG, useValue: environment }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ComponentsModule,
    PipesModule,
    AngularSvgIconModule,
    NgScrollbarModule,
    MainNavigationItemComponent,
    MainNavigationComponent,
    MobileNavigationComponent,
    AuthenticatedShellComponent,
    UnauthenticatedShellComponent
  ],
  exports: [
    MainNavigationItemComponent,
    MainNavigationComponent,
    MobileNavigationComponent,
    AuthenticatedShellComponent,
    UnauthenticatedShellComponent
  ],
  providers
})
export class CoreModule {
  constructor() {
    const parentModule = inject(CoreModule, { optional: true, skipSelf: true });

    if (parentModule) {
      throw new Error(
        `${String(
          parentModule
        )} has already been loaded. Import core module in the AppModule only.`
      );
    }
  }
}
