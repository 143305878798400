<div class="schufa-delivery container-padding">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" appFocusFormError>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-8">
        <div>
          <app-heading level="2" styleType="100">{{
            'schufa.delivery_title_l' | translate
          }}</app-heading>
          <div class="mb20">
            @if (isMale) {
              {{
                'schufa.salutation_male_l'
                  | translate: { name: personalData.lastName }
              }}
            } @else {
              {{
                'schufa.salutation_female_l'
                  | translate: { name: personalData.lastName }
              }}
            }
          </div>
          <div
            class="schufa__delivery-description mb25"
            [innerHTML]="'schufa.delivery_description_l' | translate"
          ></div>
        </div>

        <div>
          <div class="mb10@sm-max">
            <app-form-field>
              <app-flat-select
                [items]="deliveryOptions"
                formControlName="deliverySelection"
              >
              </app-flat-select>
            </app-form-field>
          </div>

          <!-- Post -->
          @if (isPost) {
            <div class="mt15 mb30">
              <span>{{ 'schufa.delivery_post_m' | translate }}</span>
            </div>
          }

          <!-- Identification -->
          @if (isDownload) {
            <div class="mt30 mb30">
              <app-schufa-identification
                formControlName="identityData"
              ></app-schufa-identification>
            </div>
          }

          <!-- Actions -->
          @if (isDownload || isPost) {
            <div class="mb30 row">
              <div class="col-12 col-md-6 mb10@sm-max">
                <app-button [type]="'light-bordered'" (clickEvent)="cancel()">{{
                  'general.cancel_a' | translate
                }}</app-button>
              </div>
              <div class="col-12 col-md-6">
                <app-button [type]="'primary'" [buttonType]="'submit'">{{
                  'general.confirm_a' | translate
                }}</app-button>
              </div>
              @if (deliveryResponseError?.length > 0) {
                <div class="error mt10">
                  {{ 'schufa.download_identify_error_l' | translate }}
                </div>
              }
            </div>
          }
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 u-hide@lg-max">
        <app-schufa-benefits-card></app-schufa-benefits-card>
      </div>
    </div>
  </form>
</div>
<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>
