import { Pipe, PipeTransform, inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { isNumber } from 'libs/utils';

@Pipe({
  name: 'appFloor',
  standalone: true
})
export class FloorPipe implements PipeTransform {
  private translate = inject(TranslateService);

  transform(value: any): string | number {
    if (!isNumber(value) || isNaN(value)) {
      return '-';
    }

    if (value === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.translate.instant('general.floor.ground_floor_short_l');
    }

    if (value === -1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.translate.instant('general.floor.basement_short_l');
    }

    return value;
  }
}
