import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Property, PropertyBean, SocialLogin } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SocialLoginComponent } from '../../social-login/social-login.component';
import { MessageComponent } from '../../../legacy/message/message.component';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { CheckboxComponent } from '../../../legacy/form/controls/checkbox/checkbox.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { PasswordComponent } from '../../../legacy/form/controls/password/password.component';
import { AppInputDirective } from '../../../legacy/form/controls/input/input.directive';
import { FormFieldComponent } from '../../../legacy/form/form-field/form-field.component';

@Component({
  selector: 'app-register-card',
  templateUrl: './register-card.component.html',
  styleUrls: ['./register-card.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    AppInputDirective,
    PasswordComponent,
    CardComponent,
    CheckboxComponent,
    ButtonComponent,
    MessageComponent,
    SocialLoginComponent,
    RouterLink,
    TitleCasePipe,
    TranslateModule
  ]
})
export class RegisterCardComponent {
  private themeService = inject(ThemeService);

  @Input() form: FormGroup;
  @Input() property: Property & PropertyBean;
  @Input() logoRedirectUrl: string;
  @Input() disabled: boolean;
  @Input() errorMessage: string;
  @Input() socialLogin: SocialLogin;
  @Input() guestLoginRedirectUrl: string;
  @Input() showLoginAsGuest: boolean;

  @Output() registerEvent = new EventEmitter();
  @Output() loginRedirectEvent = new EventEmitter();
  @Output() socialLoginEvent = new EventEmitter<string>();
  @Output() loginAsGuestEvent = new EventEmitter();

  public get customerName() {
    return this.property?.customerName || this.property?.customer?.name;
  }

  public get logo() {
    return this.property?.customerLogo || this.property?.customer?.logo;
  }

  public get customerLogo() {
    return (
      this.logo ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }

  public onRegister() {
    this.registerEvent.emit();
  }

  public onLoginRedirect() {
    this.loginRedirectEvent.emit();
  }

  public onSocialLogin($event: string) {
    this.socialLoginEvent.emit($event);
  }

  public onLoginAsGuest() {
    this.loginAsGuestEvent.emit();
  }
}
