import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationSearchResultEntry } from 'libs/queries/location-search.queries';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../../../../../../../libs/legacy-lib/src/lib/components/components.module';

@Component({
  selector: 'app-schufa-address',
  templateUrl: './schufa-address.component.html',
  styleUrls: ['./schufa-address.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class SchufaAddressComponent {
  @Input() form: FormGroup;
  @Input() addressTitle: string;
  @Input() addressHint: string;
  @Input() removable: boolean;
  @Input() index: number;
  @Input() useStreetForSearch: boolean;
  @Output() remove = new EventEmitter();

  public onLocationSearchSelect(value: LocationSearchResultEntry) {
    this.form.patchValue({
      city: value.properties.city,
      street: value.properties.street,
      zipCode: value.properties.postcode,
      houseNumber: value.properties.housenumber
    });
  }

  public onRemove() {
    this.remove.emit();
  }
}
