import moment from 'moment';

import { Injectable, inject } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  tap,
  mergeMap
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { DownloadService } from 'libs/infrastructure';
import { ModalService } from 'libs/components/legacy/modal';
import * as fromBaseState from 'libs/infrastructure/base-state';
import {
  AppointmentSelection,
  SelectAppointmentInput
} from '@ui/shared/models';
import * as fromAppReducers from 'tenant-pool/+state/reducers';
import * as fromUser from 'tenant-pool/+state/user/user.selectors';
import { notificationConfig as notification } from 'tenant-pool/config';

import { AppointmentFacade } from 'tenant-pool/core/services';
import { AppointmentSuccessModalComponent } from 'tenant-pool/components/appointment-success-modal/appointment-success-modal.component';

import * as fromActions from './appointments.actions';

@Injectable()
export class AppointmentsEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<fromAppReducers.AppState>>(Store);
  private appointmentFacade = inject(AppointmentFacade);
  private downloadService = inject(DownloadService);
  private modalService = inject(ModalService);

  loadAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadAppointments>(fromActions.LOAD_APPOINTMENTS),
      withLatestFrom(this.store.select(fromUser.getUserId)),
      switchMap(([_, id]) =>
        this.appointmentFacade.loadAppointments(id).pipe(
          map(
            appointmentsBundle =>
              new fromActions.LoadAppointmentsSuccess(appointmentsBundle)
          ),
          catchError(error => [
            new fromBaseState.ShowError(notification.property.fetch.error),
            new fromActions.LoadAppointmentsFail(error)
          ])
        )
      )
    )
  );

  selectAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SelectAppointment>(fromActions.SELECT_APPOINTMENT),
      switchMap(({ data }) =>
        this.appointmentFacade
          .selectAppointment(this.getSelectInput(data))
          .pipe(
            map(() => new fromActions.SelectAppointmentSuccess(data)),
            catchError(error => [
              new fromBaseState.ShowError(
                notification.appointment.select.error
              ),
              new fromActions.SelectAppointmentFail(error)
            ])
          )
      )
    )
  );

  cancelAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.NoAppointmentFitting>(
        fromActions.NO_APPOINTMENT_FITTING
      ),
      switchMap(({ cancelAppointmentData }) =>
        this.appointmentFacade.cancelAppointments(cancelAppointmentData).pipe(
          mergeMap(() => [
            new fromBaseState.ShowInfo(
              notification.appointment.cancelAll.success
            ),
            new fromActions.NoAppointmentFittingSuccess()
          ]),
          catchError(error => [
            new fromBaseState.ShowError(
              notification.appointment.cancelAll.error
            ),
            new fromActions.NoAppointmentFittingFail(error)
          ])
        )
      )
    )
  );

  switchAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SwitchAppointment>(fromActions.SWITCH_APPOINTMENT),
      switchMap(({ data }) =>
        this.appointmentFacade
          .switchAppointment(this.getSelectInput(data))
          .pipe(
            map(() => new fromActions.SwitchAppointmentSuccess(data)),
            catchError(error => [
              new fromBaseState.ShowError(
                notification.appointment.cancel.error
              ),
              new fromActions.SwitchAppointmentFail(error)
            ])
          )
      )
    )
  );

  selectAppointmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | fromActions.SelectAppointmentSuccess
        | fromActions.SwitchAppointmentSuccess
      >(
        fromActions.SELECT_APPOINTMENT_SUCCESS,
        fromActions.SWITCH_APPOINTMENT_SUCCESS
      ),
      withLatestFrom(this.store.select(fromUser.getUserData)),
      switchMap(
        ([
          {
            data: { property, appointment }
          },
          user
        ]) =>
          this.modalService
            .open<AppointmentSuccessModalComponent>(
              AppointmentSuccessModalComponent,
              {
                data: {
                  schufaCard: user.customerBranding?.itpSettings?.schufaCard
                }
              }
            )
            .onClose()
            .pipe(
              map(
                () =>
                  new fromActions.ExportCalendarFile({ property, appointment })
              )
            )
      )
    )
  );

  cancelAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CancelAppointment>(fromActions.CANCEL_APPOINTMENT),
      switchMap(({ cancelAppointmentData }) =>
        this.appointmentFacade.cancelAppointment(cancelAppointmentData).pipe(
          map(() => new fromActions.CancelAppointmentSuccess()),
          catchError(error => [
            new fromBaseState.ShowError(notification.appointment.cancel.error),
            new fromActions.CancelAppointmentFail(error)
          ])
        )
      )
    )
  );

  exportCalendarFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ExportCalendarFile>(fromActions.EXPORT_CALENDAR_FILE),
      switchMap(({ application, token }) =>
        this.appointmentFacade
          .exportCalendarFile(application.appointment.id, token)
          .pipe(
            tap(file => {
              const name = application.property.name;
              const date = moment(application.appointment.date).format(
                'DDMMYYYY'
              );

              this.downloadService.download(file, `${name} ${date}.ics`);
            }),
            map(() => new fromActions.ExportCalendarFileSuccess()),
            catchError(error => [new fromActions.ExportCalendarFileFail(error)])
          )
      )
    )
  );

  public getSelectInput(selection: AppointmentSelection) {
    const { appointment, appointmentAcceptanceId, applicationId } = selection;

    return {
      appointmentId: appointment?.id,
      appointmentAcceptanceId,
      applicationId
    } as SelectAppointmentInput;
  }
}
