<div
  class="data-table-cell"
  [ngClass]="{
    'data-table-cell--without-padding-left': withoutPaddingLeft,
    'data-table-cell--hover': hover,
    'data-table-cell--clickable': clickable
  }"
  [class]="'data-table-cell--align-' + alignment"
  [class.p-0]="withoutPadding"
>
  @if (cellData || icon) {
    @if (icon) {
      <div [class]="'ms-1 me-2 d-flex icon icon--' + icon"></div>
    }
    @if (cellData) {
      <ng-container [ngTemplateOutlet]="cellData"></ng-container>
    }
  } @else {
    {{ 'shared.no_data' | translate }}
  }
</div>
