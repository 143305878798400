import { createReducer, on } from '@ngrx/store';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import { MovingChecklist } from '@ui/shared/models';
import * as fromActions from './moving-checklist.actions';

export interface MovingChecklistState {
  movingChecklist: MovingChecklist;
  getByPropertyActionState: ActionState;
  changeElementActionState: ActionState;
  setMoveInDateActionState: ActionState;
  resetActionState: ActionState;
}

export const initialState: MovingChecklistState = {
  movingChecklist: null,
  getByPropertyActionState: ActionStateCreator.create(),
  changeElementActionState: ActionStateCreator.create(),
  setMoveInDateActionState: ActionStateCreator.create(),
  resetActionState: ActionStateCreator.create()
};

export const movingChecklistReducer = createReducer(
  initialState,

  on(fromActions.MovingChecklistGetByProperty, state => {
    return {
      ...state,
      getByPropertyActionState: ActionStateCreator.onStart()
    };
  }),
  on(
    fromActions.MovingChecklistGetByPropertySuccess,
    (state, { movingChecklist }) => {
      return {
        ...state,
        movingChecklist,
        getByPropertyActionState: ActionStateCreator.onSuccess()
      };
    }
  ),
  on(fromActions.MovingChecklistGetByPropertyFail, (state, { error }) => {
    return {
      ...state,
      propertyID: null,
      movingChecklist: null,
      getByPropertyActionState: ActionStateCreator.onError(error)
    };
  }),

  on(fromActions.MovingChecklistChangeElement, state => {
    return {
      ...state,
      changeElementActionState: ActionStateCreator.onStart()
    };
  }),
  on(
    fromActions.MovingChecklistChangeElementSuccess,
    (state, { movingChecklist }) => {
      return {
        ...state,
        movingChecklist,
        changeElementActionState: ActionStateCreator.onSuccess()
      };
    }
  ),
  on(fromActions.MovingChecklistChangeElementFail, (state, { error }) => {
    return {
      ...state,
      changeElementActionState: ActionStateCreator.onError(error)
    };
  }),

  on(fromActions.MovingChecklistSetMoveInDate, state => {
    return {
      ...state,
      setMoveInDateActionState: ActionStateCreator.onStart()
    };
  }),
  on(
    fromActions.MovingChecklistSetMoveInDateSuccess,
    (state, { movingChecklist }) => {
      return {
        ...state,
        movingChecklist,
        setMoveInDateActionState: ActionStateCreator.onSuccess()
      };
    }
  ),
  on(fromActions.MovingChecklistSetMoveInDateFail, (state, { error }) => {
    return {
      ...state,
      setMoveInDateActionState: ActionStateCreator.onError(error)
    };
  }),

  on(fromActions.MovingChecklistReset, state => {
    return {
      ...state,
      resetActionState: ActionStateCreator.onStart()
    };
  }),
  on(fromActions.MovingChecklistResetSuccess, (state, { movingChecklist }) => {
    return {
      ...state,
      movingChecklist,
      resetActionState: ActionStateCreator.onSuccess()
    };
  }),
  on(fromActions.MovingChecklistResetFail, (state, { error }) => {
    return {
      ...state,
      resetActionState: ActionStateCreator.onError(error)
    };
  })
);

export const getMovingChecklist = (state: MovingChecklistState) =>
  state.movingChecklist;
export const getGetByPropertyActionState = (state: MovingChecklistState) =>
  state.getByPropertyActionState;
export const getChangeElementActionState = (state: MovingChecklistState) =>
  state.changeElementActionState;
export const getSetMoveInDateActionState = (state: MovingChecklistState) =>
  state.setMoveInDateActionState;
export const getResetActionState = (state: MovingChecklistState) =>
  state.resetActionState;
