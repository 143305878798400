import { gql } from 'apollo-angular';
import { questionContainerFields } from 'libs/queries/custom-questions.queries';
import { paginationQuery } from 'libs/queries/pagination.query';
import {
  propertyPsSide,
  rentDepositInfoFields
} from 'libs/infrastructure/base-state';

export const basicPropertySearcherFields = `
  id
  created
  updated
  status
`;

export const additionalPropertySearcherFields = `
  inInternalPool
  userAllowedToSendMessage
  conversationId
  propertyTenantUserId
  askedForIntent
  acceptedAppointment
  applicantStatus
  upcomingAppointmentDate
  upcomingAppointmentId
  appointmentSlotsAvailable
  applicationBlocked
  downloadExposeAllowed
  downloadExposeUrl
`;

export const propertySearcherFields = `
  ${basicPropertySearcherFields}
  ${additionalPropertySearcherFields}
  ${propertyPsSide}
`;

export const allPropertySearcherFields = `
  ${propertySearcherFields}
  rentDepositInfo {
    ${rentDepositInfoFields}
  }
`;

export const propertyApplyProcessFields = `
  nodes {
    id
    type
    status
    appointmentSlotsAvailable
    upcomingAppointmentDate
    upcomingAppointmentId
    hasQuestions
    hasUnansweredQuestions
    conversationExists
    allowedToSendMessage
    askForViewingAppointmentAttendance
    questionContainer {
      ${questionContainerFields}
    }
    rentDepositInfo {
      ${rentDepositInfoFields}
    }
    ${propertyPsSide}
  }
  page {
    ${paginationQuery}
  }
`;

export const propertyApplyProcessPSBeansQuery = gql`
  query propertyApplyProcessPSBeans($filter: Filter){
    propertyApplyProcessPSBeans(filter: $filter) {
      ${propertyApplyProcessFields}
    }
  }

`;

export const propertyApplyProcessRentedQuery = gql`
  query propertyApplyProcessRented($filter: Filter){
    propertyApplyProcessRented(filter: $filter) {
      ${propertyApplyProcessFields}
    }
  }

`;

export const propertyApplyProcessPSBeanQuery = gql`
  query propertyApplyProcessPSBean($id: ID!){
    propertyApplyProcessPSBean(id: $id) {
      id
      type
      status
      appointmentSlotsAvailable
      upcomingAppointmentDate
      upcomingAppointmentId
      hasQuestions
      hasUnansweredQuestions
      conversationExists
      allowedToSendMessage
      askForViewingAppointmentAttendance
      rentDepositInfo {
        ${rentDepositInfoFields}
      }
      ${propertyPsSide}
    }
  }
`;

export const propertyApplyProcessPSBeanQuestionsQuery = gql`
  query propertyApplyProcessPSBeanQuestions($id: ID!, $status: String!) {
    propertyApplyProcessPSBeanQuestions(id: $id, status: $status) {
      ${questionContainerFields}
    }
  }
`;
