import { createAction, props } from '@ngrx/store';
import {
  AnonymousApplicationPayload,
  DeclareIntentData,
  PropertyMatchBean,
  QuestionContainer,
  ReasonData
} from '@ui/shared/models';

import { PropertyMatches } from 'tenant-pool/models';

export const LoadPropertyMatchesBeans = createAction(
  '[PropertySearcher] Load PropertyMatches',
  props<{
    data: { page?: number; sort?: string; size?: number };
    loadMore?: boolean;
  }>()
);
export const LoadPropertyMatchesBeansSuccess = createAction(
  '[PropertySearcher] Load PropertyMatches Success',
  props<{ result: PropertyMatches; loadMore?: boolean }>()
);
export const LoadPropertyMatchesBeansFail = createAction(
  '[PropertySearcher] Load PropertyMatches Fail',
  props<{ error: Error; loadMore?: boolean }>()
);

export const LoadPropertyMatchesRented = createAction(
  '[PropertySearcher] Load PropertyMatches Rented',
  props<{
    data: { page?: number; sort?: string; size?: number };
    loadMore?: boolean;
  }>()
);
export const LoadPropertyMatchesRentedSuccess = createAction(
  '[PropertySearcher] Load PropertyMatches Rented Success',
  props<{ result: PropertyMatches; loadMore?: boolean }>()
);
export const LoadPropertyMatchesRentedFail = createAction(
  '[PropertySearcher] Load PropertyMatches Rented Fail',
  props<{ error: Error; loadMore?: boolean }>()
);

export const LoadPropertyMatchBean = createAction(
  '[PropertySearcher] Load PropertyMatch',
  props<{ id: string }>()
);
export const LoadPropertyMatchBeanSuccess = createAction(
  '[PropertySearcher] Load PropertyMatch Success',
  props<{ result: PropertyMatchBean }>()
);
export const LoadPropertyMatchBeanFail = createAction(
  '[PropertySearcher] Load PropertyMatch Fail',
  props<{ error: Error }>()
);

export const RemoveApplication = createAction(
  '[Applications] Remove Application',
  props<{ reasonData: ReasonData; rented?: boolean }>()
);

export const RemoveApplicationSuccess = createAction(
  '[Applications] Remove Application Success',
  props<{ id: string }>()
);

export const RemoveApplicationFail = createAction(
  '[Applications] Remove Application Fail',
  props<{ error: Error }>()
);

export const CheckIdentity = createAction(
  '[Applications] Check Identity',
  props<{ identityToken: string }>()
);

export const CheckIdentityFail = createAction(
  '[Applications] Check Identity Fail',
  props<{ error: Error }>()
);
export const CheckIdentitySuccess = createAction(
  '[Applications] Check Identity Success'
);

export const CheckApplicationExist = createAction(
  '[Applications] Check Applciation Exist',
  props<{ propertyId: string; token?: string }>()
);

export const CheckApplicationExistFail = createAction(
  '[Applications] Check Applciation Exist Fail',
  props<{ error: Error }>()
);
export const CheckApplicationExistSuccess = createAction(
  '[Applications] Check Applciation Exist Success',
  props<{ id?: string; propertyId?: string; token?: string }>()
);

export const CreateApplication = createAction(
  '[Applications] Create Application',
  props<{ id: string; token?: string }>()
);

export const CreateApplicationSuccess = createAction(
  '[Applications] Create Application Success',
  props<{ application: PropertyMatchBean }>()
);

export const CreateApplicationFail = createAction(
  '[Applications] Create Application Fail',
  props<{ error: Error }>()
);

export const ApplyAnonymously = createAction(
  '[Properties] Apply Anonymously',
  props<{ anonymousApplicationPayload: AnonymousApplicationPayload }>()
);

export const ApplyAnonymouslySuccess = createAction(
  '[Properties] Apply Anonymously Success'
);

export const ApplyAnonymouslyFail = createAction(
  '[Properties] Apply Anonymously Fail',
  props<{ error: Error }>()
);

export const DeclareIntent = createAction(
  '[Applications] Declare Intent',
  props<{ declareIntentData: DeclareIntentData }>()
);

export const DeclareIntentSuccess = createAction(
  '[Applications] Declare Intent Success',
  props<{ id: string; intent: boolean }>()
);

export const DeclareIntentFail = createAction(
  '[Applications] Declare Intent Fail',
  props<{ error: Error }>()
);

export const CacheIntent = createAction(
  '[Applications] Cache Intent',
  props<{ applicationId: string }>()
);

export const UncacheIntent = createAction('[Applications] Uncache Intent');

export const DenyIntent = createAction(
  '[Applications] Deny Intent',
  props<{ declareIntentData: DeclareIntentData; token: string }>()
);

export const DenyIntentFail = createAction(
  '[Applications] Deny Intent Fail',
  props<{ error: Error }>()
);

export const DenyIntentSuccess = createAction(
  '[Applications] Deny Intent Success'
);

export const FindAvailableForNewConversation = createAction(
  '[Applications] Find Available For New Conversation',
  props<{ userId: string }>()
);
export const FindAvailableForNewConversationSuccess = createAction(
  '[Applications] Find Available For New Conversation Success',
  props<{ applications: PropertyMatchBean[] }>()
);
export const FindAvailableForNewConversationError = createAction(
  '[Applications] Find Available For New Conversation Error',
  props<{ error: Error }>()
);

export const DenyProposal = createAction(
  '[Proposals] Deny Proposal',
  props<{ reasonData: ReasonData }>()
);

export const DenyProposalSuccess = createAction(
  '[Proposals] Deny Proposal Success',
  props<{ id: string }>()
);

export const DenyProposalFail = createAction(
  '[Proposals] Deny Proposal Fail',
  props<{ error: Error }>()
);

export const AcceptProposal = createAction(
  '[Properties] Accept Proposal',
  props<{ id: string; name: string }>()
);

export const AcceptProposalSuccess = createAction(
  '[Properties] Accept Proposal Success',
  props<{ id: string }>()
);

export const AcceptProposalFail = createAction(
  '[Properties] Accept Proposal Fail',
  props<{ error: Error }>()
);

export const LoadRentedFlatsApplications = createAction(
  '[Applications] Load Rented Flats Applications'
);

export const LoadRentedFlatsApplicationsSuccess = createAction(
  '[Applications] Load Rented Flats Applications Success',
  props<{ applications: PropertyMatchBean[] }>()
);

export const LoadRentedFlatsApplicationsFail = createAction(
  '[Applications] Load Rented Flats Applications Fail',
  props<{ error: Error }>()
);

export const RevokeAllOpenApplications = createAction(
  '[Applications] Revoke All Open Applications'
);

export const RevokeAllOpenApplicationsSuccess = createAction(
  '[Applications] Revoke All Open Applications Success'
);

export const RevokeAllOpenApplicationsFail = createAction(
  '[Applications] Revoke All Open Applications Fail',
  props<{ error: Error }>()
);

export const OpenPostLoginModal = createAction(
  '[Applications] Open Post Login Modal'
);

export const OpenIntentModal = createAction(
  '[Applications] Open Intent Modal',
  props<{
    application: PropertyMatchBean;
    intent: string;
    fromEmail: boolean;
  }>()
);

export const OpenCompleteModal = createAction(
  '[Applications] Open Complete Modal',
  props<{ showGenericModal?: boolean; propertyMatch?: PropertyMatchBean }>()
);

export const LoadPropertyMatchBeanQuestions = createAction(
  '[PropertySearcher] Load PropertyMatch Questions',
  props<{ id: string; status: string }>()
);
export const LoadPropertyMatchBeanQuestionsSuccess = createAction(
  '[PropertySearcher] Load PropertyMatch Questions Success',
  props<{ id: string; status: string; questionContainer: QuestionContainer }>()
);
export const LoadPropertyMatchBeanQuestionsFail = createAction(
  '[PropertySearcher] Load PropertyMatch Questions Fail',
  props<{ error: Error }>()
);
