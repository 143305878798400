<div class="side-modal">
  <div class="side-modal__header">
    <div class="side-modal__header-title">
      <div class="side-modal__header-title-content">
        <ng-content select="[header-title]"></ng-content>
      </div>
      <ng-content select="[header-subtitle]"></ng-content>
    </div>

    <nav
      ngbNav
      #nav="ngbNav"
      class="nav-pills side-modal__content-tabs"
      [(activeId)]="activeNav"
      (navChange)="onNavChange($event)"
    >
      @for (content of contents; track content.name; let i = $index) {
        <ng-container [ngbNavItem]="content.navName">
          <a ngbNavLink>{{ content.name | translate }}</a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="content.template"></ng-template>
          </ng-template>
        </ng-container>
      }
    </nav>
  </div>

  <div class="side-modal__content" #navOutlet [ngbNavOutlet]="nav"></div>

  <div class="side-modal__footer">
    <div class="side-modal__footer-button-container">
      <ng-content select="[action-button]"></ng-content>
      <ng-content select="app-context-menu"></ng-content>
    </div>
  </div>
</div>
