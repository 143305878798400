import { IconTypeEnum } from '@ui/shared/models';
import { PipelineSteps } from 'libs/components/molecules/status-pipelines/status-pipeline-base.config';

export const applicationSteps: PipelineSteps = [
  {
    label: 'application.status_pipeline.proposal.label',
    iconType: IconTypeEnum.Offer
  },
  {
    label: 'application.status_pipeline.application.label',
    iconType: IconTypeEnum.Contracts
  },
  {
    label: 'application.status_pipeline.invitation.label',
    iconType: IconTypeEnum.Mail
  },
  {
    label: 'application.status_pipeline.viewing.label',
    iconType: IconTypeEnum.Calendar
  },
  {
    label: 'application.status_pipeline.feedback.label',
    iconType: IconTypeEnum.Feedback
  }
];
