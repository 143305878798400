import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
  mergeMap
} from 'rxjs/operators';
import { of } from 'rxjs';

import { Store } from '@ngrx/store';

import { getResponseValidator, LocalStorageService } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';

import {
  // MovingChecklistFacade,
  SearchProfileFacade
} from 'tenant-pool/core/services';

import {
  storageKeys,
  notificationConfig as notification
} from 'tenant-pool/config';

import * as fromAppReducers from '../reducers';
import * as fromUserSelectors from '../user/user.selectors';
import * as fromActions from './search-profiles.actions';

@Injectable()
export class SearchProfilesEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<fromAppReducers.AppState>>(Store);
  private searchProfileFacade = inject(SearchProfileFacade);
  private localStorage = inject(LocalStorageService);
  private router = inject(Router);
  // private movingChecklistFacade = inject(MovingChecklistFacade);

  loadSearchProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadSearchProfiles>(fromActions.LOAD_SEARCH_PROFILES),
      switchMap(() =>
        this.searchProfileFacade.loadSearchProfiles().pipe(
          map(
            searchProfiles =>
              new fromActions.LoadSearchProfilesSuccess(searchProfiles)
          ),
          catchError(err =>
            of(
              new fromActions.LoadSearchProfilesFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        )
      )
    )
  );

  saveSearchProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SaveSearchProfile>(fromActions.SAVE_SEARCH_PROFILE),
      withLatestFrom(this.store.select(fromUserSelectors.getUserId)),
      switchMap(([{ searchProfile, addToState }, userId]) => {
        const sp = { ...searchProfile, userId };
        return this.searchProfileFacade.save(sp).pipe(
          tap(getResponseValidator()),
          mergeMap(() => {
            // TODO: clarify if still needed – where is corresponding setItem()?
            this.localStorage.removeItem(storageKeys.searchProfileData);

            return [
              new fromActions.SaveSearchProfileSuccess(sp, addToState),
              new fromBaseState.ShowInfo(
                notification.searchProfile.save.success
              )
            ];
          }),
          catchError(err => [
            new fromActions.SaveSearchProfileFail(
              new Error(err ? err.message : 'Unexpected error')
            ),
            new fromBaseState.ShowError(notification.searchProfile.save.error)
          ])
        );
      })
    )
  );

  deleteSearchProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteSearchProfile>(
        fromActions.DELETE_SEARCH_PROFILE
      ),
      switchMap(({ searchProfileId }) =>
        this.searchProfileFacade.delete(searchProfileId).pipe(
          tap(getResponseValidator()),
          mergeMap(() => [
            new fromActions.DeleteSearchProfileSuccess(searchProfileId),
            new fromBaseState.ShowInfo(
              notification.searchProfile.delete.success
            )
          ]),
          catchError(err => [
            new fromActions.DeleteSearchProfileFail(
              new Error(err ? err.message : 'Unexpected error')
            ),
            new fromBaseState.ShowError(notification.searchProfile.delete.error)
          ])
        )
      )
    )
  );

  toggleSearchProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ToggleSearchProfile>(
        fromActions.TOGGLE_SEARCH_PROFILE
      ),
      switchMap(({ searchProfileId }) =>
        this.searchProfileFacade.toggle(searchProfileId).pipe(
          tap(getResponseValidator()),
          map(
            () => new fromActions.ToggleSearchProfileSuccess(searchProfileId)
          ),
          catchError(err => [
            new fromActions.ToggleSearchProfileFail(
              new Error(err ? err.message : 'Unexpected error')
            ),
            new fromBaseState.ShowError(notification.searchProfile.toggle.error)
          ])
        )
      )
    )
  );

  deleteAllSearchProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteAllSearchProfiles>(
        fromActions.DELETE_ALL_SEARCH_PROFILES
      ),
      switchMap(() =>
        this.searchProfileFacade.deleteAll().pipe(
          tap(getResponseValidator()),
          map(() => new fromActions.DeleteAllSearchProfilesSuccess()),
          catchError(err => [
            new fromActions.DeleteAllSearchProfilesFail(
              new Error(err ? err.message : 'Unexpected error')
            ),
            new fromBaseState.ShowError(notification.searchProfile.delete.error)
          ])
        )
      )
    )
  );

  deactivateAllSearchProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeactivateAllSearchProfiles>(
        fromActions.DEACTIVATE_ALL_SEARCH_PROFILES
      ),
      switchMap(({ propertyType }) =>
        this.searchProfileFacade.deactivateAll(propertyType).pipe(
          tap(getResponseValidator()),
          map(() => new fromActions.DeactivateAllSearchProfilesSuccess()),
          catchError(err => [
            new fromActions.DeactivateAllSearchProfilesFail(err),
            new fromBaseState.ShowError(notification.searchProfile.delete.error)
          ])
        )
      )
    )
  );

  activateAllSearchProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ActivateAllSearchProfiles>(
        fromActions.ACTIVATE_ALL_SEARCH_PROFILES
      ),
      switchMap(({ propertyType }) =>
        this.searchProfileFacade.activateAll(propertyType).pipe(
          tap(getResponseValidator()),
          map(() => {
            //this.movingChecklistFacade.refetchChecklist();
            return new fromActions.ActivateAllSearchProfilesSuccess();
          }),
          catchError(err => [
            new fromActions.ActivateAllSearchProfilesFail(err),
            new fromBaseState.ShowError(notification.searchProfile.delete.error)
          ])
        )
      )
    )
  );

  fetchProjectForSearchProfileRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchProjectForProjectSearchProfile>(
        fromActions.FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE
      ),
      switchMap(({ token }) =>
        this.searchProfileFacade
          .searchProfileProjectRegistrationProject(token)
          .pipe(
            map(project => {
              return new fromActions.FetchProjectForProjectSearchProfileSuccess(
                project
              );
            }),
            catchError(err => [
              new fromActions.FetchProjectForProjectSearchProfileFail(
                Error(err)
              ),
              new fromBaseState.ShowError(
                notification.searchProfile.project.fetchProject.error
              )
            ])
          )
      )
    )
  );

  unloadProjectForSearchProfileRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.UnloadProjectForProjectSearchProfile>(
          fromActions.UNLOAD_PROJECT_FOR_PROJECT_SEARCH_PROFILE
        ),
        map(() => {
          // remove project token from query params
          void this.router.navigate([], {
            queryParams: { project: null }
          });
        })
      ),
    { dispatch: false }
  );
}
