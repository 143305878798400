<div class="info-collapse">
  <div class="info-collapse-accordion" ngbAccordion #acc="ngbAccordion">
    <div
      ngbAccordionItem="ngb-panel-standalone-component"
      #accordionItem="ngbAccordionItem"
      [collapsed]="collapsed"
      class="rounded-border"
      [class]="'info-collapse__basis--' + type"
    >
      <div ngbAccordionHeader>
        <div
          (click)="acc.toggle('ngb-panel-standalone-component')"
          class="info-collapse__header"
          [class]="'info-collapse__header--' + type"
          [class.info-collapse__header--opened]="!accordionItem.collapsed"
        >
          <div class="d-flex align-items-center">
            <span class="pe-2">
              <div [class]="'icon icon--' + getStatusIcon(type)"></div>
            </span>
            <ng-content select="[info-header]"></ng-content>
          </div>

          <div class="ps-2">
            <div
              class="icon icon--arrow-dropdown info-collapse__header-chevron"
              [ngClass]="{
                'info-collapse__header-chevron--opened':
                  !accordionItem.collapsed
              }"
            ></div>
          </div>
        </div>
      </div>
      <div
        ngbAccordionCollapse
        class="info-collapse__content"
        [class]="'info-collapse__content--' + type"
      >
        <div ngbAccordionBody>
          <ng-template>
            <ng-content select="[info-content]"></ng-content>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
