import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import moment from 'moment';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  Constants,
  SelfDisclosureQuestion,
  SelfDisclosureQuestionType
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';
import { SelfDisclosureDocumentComponent } from '../self-disclosure-document/self-disclosure-document.component';
import { SelfDisclosureAddressComponent } from '../self-disclosure-address/self-disclosure-address.component';
import { SelfDisclosureEmploymentComponent } from '../self-disclosure-employment/self-disclosure-employment.component';
import { ComponentsModule } from '../../../components/components.module';

// TODO MOVE
const BOOLEAN_OPTIONS = [
  { name: 'general.yes_l', value: true },
  { name: 'general.no_l', value: false }
];

@Component({
  selector: 'app-self-disclosure-person',
  templateUrl: './self-disclosure-person.component.html',
  styleUrls: ['./self-disclosure-person.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SvgIconComponent,
    ComponentsModule,
    SelfDisclosureEmploymentComponent,
    SelfDisclosureAddressComponent,
    SelfDisclosureDocumentComponent,
    TranslateModule
  ]
})
export class SelfDisclosurePersonComponent implements OnInit {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() title: string;
  @Input() form: FormGroup;
  @Input() showRemove = false;
  @Input() constants: Constants;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRemove = new EventEmitter<void>();

  public maxDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public startDateStruct: NgbDateStruct = {
    year: 1990,
    month: 1,
    day: 1
  };

  public minDateStruct: NgbDateStruct = {
    year: 1900,
    month: 1,
    day: 1
  };

  ngOnInit(): void {
    this.title = this.title || this.selfDisclosureQuestion.title;
  }

  public get getAnswerFormControl() {
    return this.form.get('answer') as FormGroup;
  }

  public get getConfirmationsFormControl() {
    return this.form.get('confirmations') as FormArray;
  }

  public get getSubQuestionsFormControl() {
    return this.form.get('subQuestions') as FormArray;
  }

  public getQuestionsForm(index: number): FormGroup {
    return this.getSubQuestionsFormControl.controls[index] as FormGroup;
  }

  public getSubQuestionForm(index: number): FormGroup {
    return this.getQuestionsForm(index);
  }

  public options(question: SelfDisclosureQuestion) {
    return this.isTypeBoolean(question)
      ? BOOLEAN_OPTIONS
      : this.isTypeSelect(question) && this.constants
        ? this.constants[question.constantName]
        : [];
  }

  public isTypeBooleanOrSelect(subQuestion: SelfDisclosureQuestion) {
    return this.isTypeSelect(subQuestion) || this.isTypeBoolean(subQuestion);
  }

  public isTypeSelect(question: SelfDisclosureQuestion) {
    return question && question.type === SelfDisclosureQuestionType.SELECT;
  }

  public isTypeBoolean(question: SelfDisclosureQuestion) {
    return question && question.type === SelfDisclosureQuestionType.BOOLEAN;
  }

  public isEmployment(subQuestion: SelfDisclosureQuestion) {
    return (
      subQuestion && subQuestion.type === SelfDisclosureQuestionType.EMPLOYMENT
    );
  }

  public isAddress(subQuestion: SelfDisclosureQuestion) {
    return (
      subQuestion && subQuestion.type === SelfDisclosureQuestionType.ADDRESS
    );
  }

  public isDocument(subQuestion: SelfDisclosureQuestion) {
    return (
      subQuestion && subQuestion.type === SelfDisclosureQuestionType.DOCUMENT
    );
  }

  public showSelectTypeQuestion(question: SelfDisclosureQuestion) {
    return (
      question.title === SelfDisclosureQuestionType.SALUTATION &&
      question.showSelfDisclosureQuestions
    );
  }

  public isOtherSubQuestion(question: SelfDisclosureQuestion) {
    return question.title !== SelfDisclosureQuestionType.SALUTATION;
  }

  public getSelectTypeItems(constantName: string) {
    return this.constants ? this.constants[constantName] : [];
  }

  public removeHandler() {
    this.onRemove.emit();
  }

  public showHint(index: number) {
    const control = this.getSubQuestionsFormControl.value[index];

    return (
      this.selfDisclosureQuestion.subQuestions &&
      this.selfDisclosureQuestion.subQuestions[index] &&
      this.selfDisclosureQuestion.subQuestions[index].commentAllowed &&
      control &&
      control.answer === true
    );
  }
}
