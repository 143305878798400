import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthToken } from 'libs/infrastructure/keycloak-authentication-module/model';

@Injectable()
export class DeepLinkService {
  private paramsSubject = new Subject<string>();

  public params() {
    return this.paramsSubject.pipe(filter(str => !!str));
  }

  public extractData($link) {
    const extractCode = this.getSSOParams($link);
    if (extractCode) {
      this.nextParams(extractCode.code);
    }
  }

  public deconstructUrl(fragment: string) {
    const regex =
      /^(https:\/\/tenant.immomio.com\/)(([\w]{2}\/)?)+([\w\-.]+[^#?\s]+.*)?/;
    const slug = regex.exec(fragment)?.[4].replace(/^(([\w]{2})\/)/, '');
    const urlObj = slug?.split('?');
    if (urlObj?.length > 0) {
      const query = this.getQueryFragmentAsObject<{ [key: string]: string }>(
        fragment
      );
      return { path: [urlObj[0]], query };
    }
  }

  public getSSOParams($link) {
    const hasCode = this.containsCode($link?.fragment);
    if (!hasCode) {
      return;
    }
    const ssoParams =
      this.getQueryFragmentAsObject<AuthToken>($link.fragment) ||
      ({} as AuthToken);
    if (!ssoParams || !ssoParams.code) {
      return;
    }
    ssoParams.code = (ssoParams.code as string).split('#')[0];
    return ssoParams;
  }

  public getQueryFragmentAsObject<T>(url: string): T {
    return url
      ?.slice(url.indexOf('?') + 1)
      ?.split('&')
      .reduce((asObject, currentPair) => {
        const splitted = currentPair.split('=');
        asObject[splitted[0]] = decodeURIComponent(splitted[1]);
        return asObject;
      }, {} as T);
  }

  private nextParams(code: string) {
    this.paramsSubject.next(code);
  }

  public containsCode(fragment: string) {
    if (!fragment) {
      return false;
    }
    return fragment.indexOf('code') > -1;
  }
}
