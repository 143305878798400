import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActionState } from 'libs/state-utils';

import { SchufaOrderResponseData } from 'tenant-pool/core/queries';
import { SchufaPersonalInformationData } from 'tenant-pool/models';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseSchufaStep } from '../../base-schufa-step';
import { FullNamePipe } from '../../../../../../../../libs/legacy-lib/src/lib/pipes/full-name.pipe';
import { DateTimePipe } from '../../../../../../../../libs/legacy-lib/src/lib/pipes/date-time.pipe';
import { AddressPipe } from '../../../../../../../../libs/legacy-lib/src/lib/pipes/address.pipe';
import { SchufaBenefitsCardComponent } from '../schufa-benefits-card/schufa-benefits-card.component';
import { SchufaCertificatesComponent } from '../schufa-certificates/schufa-certificates.component';
import { ConfirmationInfoComponent } from '../confirmation-info/confirmation-info.component';
import { ComponentsModule } from '../../../../../../../../libs/legacy-lib/src/lib/components/components.module';

@Component({
  selector: 'app-schufa-payment-order',
  templateUrl: './schufa-payment-order.component.html',
  styleUrls: ['./schufa-payment-order.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    ConfirmationInfoComponent,
    SchufaCertificatesComponent,
    SchufaBenefitsCardComponent,
    TranslateModule,
    AddressPipe,
    DateTimePipe,
    FullNamePipe
  ]
})
export class SchufaPaymentOrderComponent
  extends BaseSchufaStep
  implements OnInit
{
  @Input() paymentActionState: ActionState;
  @Input() orderActionState: ActionState;
  @Input() paymentResponse: SchufaOrderResponseData;
  @Input() paymentErrorCount: number;
  @Input() personalData: SchufaPersonalInformationData;

  @Output() openSchufaPaymentMoreInformation = new EventEmitter();

  get hasError() {
    return (
      this.paymentResponse &&
      this.paymentResponse.error &&
      this.paymentResponse.error.length > 0
    );
  }

  get retryAndNext() {
    return (
      this.paymentResponse &&
      this.paymentResponse.error &&
      this.paymentResponse.error.find(
        error => error.code === 'ValidierungUniserv.0002'
      )
    ); // retry or next error
  }

  get address() {
    return this.personalData.address;
  }

  get birthdate() {
    return this.personalData.dateOfBirth;
  }

  public onOpenSchufaPaymentMoreInformation() {
    this.openSchufaPaymentMoreInformation.emit();
  }
}
