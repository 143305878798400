import { createAction, props } from '@ngrx/store';
import { Tooltip, TooltipTour } from '@ui/shared/models';

export const fetchTooltipsUnseen = createAction(
  '[Tooltip] Fetch Unseen Tooltips',
  props<{ path: string }>()
);

export const fetchTooltipsUnseenSuccess = createAction(
  '[Tooltip] Fetch Unseen Tooltips Success',
  props<{ path: string; tooltips: Tooltip[] }>()
);

export const fetchTooltipsUnseenFail = createAction(
  '[Tooltip] Fetch Unseen Tooltips Fail',
  props<{ error: Error }>()
);

export const fetchTooltipToursUnseen = createAction(
  '[Tooltip] Fetch Unseen Tooltip Tours',
  props<{ path: string }>()
);

export const fetchTooltipToursUnseenSuccess = createAction(
  '[Tooltip] Fetch Unseen Tooltip Tours Success',
  props<{ path: string; tooltipTours: TooltipTour[] }>()
);

export const fetchTooltipToursUnseenFail = createAction(
  '[Tooltip] Fetch Unseen Tooltip Tours Fail',
  props<{ error: Error }>()
);

export const setTooltipsSeen = createAction(
  '[Tooltip] Set Tooltips Seen',
  props<{ ids: number[] }>()
);

export const setTooltipsSeenSuccess = createAction(
  '[Tooltip] Set Tooltips Seen Success',
  props<{ ids: number[] }>()
);

export const setTooltipsSeenFail = createAction(
  '[Tooltip] Set Tooltips Seen',
  props<{ error: Error; ids: number[] }>()
);

export const setTooltipTourSeen = createAction(
  '[Tooltip] Set Tooltip Tour Seen',
  props<{ tooltipTour: TooltipTour }>()
);

export const setTooltipTourSeenSuccess = createAction(
  '[Tooltip] Set Tooltip Tour Seen Success',
  props<{ ids: number[] }>()
);

export const setTooltipTourSeenFail = createAction(
  '[Tooltip] Set Tooltip Tour Seen Fail',
  props<{ error: Error; ids: number[] }>()
);

export const continueTooltipProgress = createAction(
  '[Tooltip] Continue Tooltip Progress'
);

export const resetTooltipProgress = createAction(
  '[Tooltip] Reset Tooltip Progress'
);

export const setCurrentTooltipPath = createAction(
  '[Tooltip] Set Current Tooltip Path',
  props<{ path: string }>()
);
