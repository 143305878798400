import { createAction, props } from '@ngrx/store';
import { ServiceCard } from '@ui/shared/models';

export const fetchServiceCards = createAction('[Cards] Fetch Service Cards');

export const fetchServiceCardsSuccess = createAction(
  '[Cards] Fetch Service Cards Success',
  props<{ serviceCards: ServiceCard[] }>()
);

export const fetchServiceCardsFail = createAction(
  '[Cards] Fetch Service Cards Fail',
  props<{ error: Error }>()
);

export const loadMilesCardAllowed = createAction(
  '[Service Cards] Load MilesCard Allowed'
);

export const loadMilesCardAllowedSuccess = createAction(
  '[Service Cards] Load MilesCard Allowed Success',
  props<{ isAllowed: boolean }>()
);

export const loadMilesCardAllowedError = createAction(
  '[Service Cards] Load MilesCard Allowed Error',
  props<{ error?: Error }>()
);
