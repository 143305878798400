import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule
} from '@angular/forms';
import {
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownMenu
} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseControl } from 'libs/components/legacy/form';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { filter } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';
import { LoadingSpinnerComponent } from '../../legacy/loading-spinner/loading-spinner.component';
import { AppInputDirective } from '../../legacy/form/controls/input/input.directive';

@UntilDestroy()
@Component({
  selector: 'app-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html',
  styleUrls: ['./single-select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectDropdownComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SingleSelectDropdownComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbDropdown,
    FormsModule,
    NgbDropdownAnchor,
    NgClass,
    AppInputDirective,
    ReactiveFormsModule,
    SvgIconComponent,
    LoadingSpinnerComponent,
    NgbDropdownMenu,
    InfiniteScrollComponent,
    NgTemplateOutlet,
    TranslateModule
  ]
})
export class SingleSelectDropdownComponent
  extends BaseControl<unknown>
  implements OnInit, AfterViewInit
{
  @Input() items: unknown[];
  @Input() placeholderSelectedItemKey = 'name';
  @Input() itemValueKey = 'value';
  @Input() searchCharactersLimit = 3;
  @Input() relativelyPositioned: boolean;
  @Input() useValue: boolean;
  @Input() isLoadingMenuItems: boolean;
  @Input() disableSearch: boolean;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() scrollChange = new EventEmitter<string>();
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;

  public searchControl: FormControl<string> = new FormControl<string>('');
  public selectedItem: unknown;

  ngOnInit(): void {
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());

    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => value.length >= this.searchCharactersLimit),
        untilDestroyed(this)
      )
      .subscribe(value => this.searchChange.emit(value));
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  public apply(item: unknown) {
    this.dropdown.close();
    this.value = this.useValue ? item : item[this.itemValueKey];
    this.selectedItem = item;
    this.selectionChange.emit(item);
    this.searchControl.patchValue('');

    this.updateItems();
  }

  public removeSelectedItem(): void {
    this.value = this.selectedItem = null;
    this.selectionChange.emit(this.value);
    this.touch();
  }

  public onScroll() {
    this.scrollChange.emit(this.searchControl.value);
  }

  writeValue(value: unknown): void {
    if (!value) {
      return;
    }
    const valueToWrite: unknown = value[this.itemValueKey] ?? value;
    this.selectedItem = value;
    // if value already set on init, select correct item
    if (!this.useValue && typeof this.selectedItem !== 'object') {
      const itemFromCollection = this.items.find(
        item => item[this.itemValueKey] === value
      );
      if (itemFromCollection) {
        this.selectedItem = itemFromCollection;
      }
    }

    super.writeValue(valueToWrite);
  }

  private updateItems() {
    if (this.searchCharactersLimit > 0) this.items = [];
  }
}
