<div
  class="d-flex justify-content-center align-items-center main-nav ios-padding-bottom"
>
  <ul class="navbar-nav flex-row justify-content-between w-100">
    @for (item of visibleItems; track item) {
      <li
        class="main-nav-item col d-flex justify-content-center"
        routerLinkActive="active"
        [routerLink]="getItemLink(item)"
      >
        <a
          class="nav-link d-flex flex-column align-items-center"
          [class.active]="setActiveNav(item)"
        >
          @if (item.name !== 'profile') {
            <svg-icon
              src="/assets/images/icons/{{ item.icon }}.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
              class="default-svg-color path rect polyline polygon circle line"
              routerLinkActive="white-svg-color"
              [class.white-svg-color]="setActiveNav(item)"
            ></svg-icon>
          } @else {
            @if (!user?.profile?.portrait?.url) {
              <svg-icon
                src="/assets/images/icons/{{ item.icon }}.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
                class="default-svg-color path rect polyline polygon circle line"
                routerLinkActive="white-svg-color"
                [class.white-svg-color]="setActiveNav(item)"
              ></svg-icon>
            } @else {
              <div class="navbar__profile-picture">
                <img [src]="user?.profile?.portrait?.url" alt="" />
              </div>
            }
          }
          <ng-template #profile>
            @if (!user?.profile?.portrait?.url) {
              <svg-icon
                src="/assets/images/icons/{{ item.icon }}.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
                class="default-svg-color path rect polyline polygon circle line"
                routerLinkActive="white-svg-color"
                [class.white-svg-color]="setActiveNav(item)"
              ></svg-icon>
            } @else {
              <div class="navbar__profile-picture">
                <img [src]="user?.profile?.portrait?.url" alt="" />
              </div>
            }
          </ng-template>
          <span class="mt5">
            @if (isMobile && item?.mobileLabel) {
              {{ item.mobileLabel | translate }}
            } @else {
              {{ item.label | translate }}
            }
          </span>
          @if (unreadCount > 0 && item.name === 'messenger') {
            <span class="unread-counter">{{ unreadCount }}</span>
          }
        </a>
      </li>
    }
  </ul>
</div>
