import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable()
export class SvgService {
  private httpClient = inject(HttpClient);
  private domSanitizer = inject(DomSanitizer);

  public replaceFillColorWithVar(svg: string, find: string, replace: string) {
    return svg.replaceAll(`fill="${find}"`, `style="fill: var(${replace})"`);
  }

  public removeFirstWidthHeight(svg: string) {
    /**
     * Removes first occurance. If the svg element
     * has no height or width, this might break some
     * elements inside the svg.
     */
    return svg.replace(/(width="\d*")/, '').replace(/(height="\d*")/, '');
  }

  public loadSvgAsXML(url) {
    return this.getSvgObservable(url);
  }

  public sanitizeSvgHtml(svg: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(svg);
  }

  public replaceColorAndFinalize(
    svg: string,
    find: string,
    replace: string
  ): SafeHtml {
    let newSvg = this.replaceFillColorWithVar(svg, find, replace);
    newSvg = this.removeFirstWidthHeight(newSvg);
    return this.sanitizeSvgHtml(newSvg);
  }

  private getSvgObservable(svgUrl: string) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return this.httpClient.get(svgUrl, {
      headers,
      responseType: 'text'
    });
  }
}
