import { Component, Input } from '@angular/core';

import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';
import { InputFieldsDirective } from '../input-fields.directive';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  standalone: true
})
export class TextFieldComponent extends InputFieldsDirective<any> {
  @Input() type = 'text';
  @Input() placeholder = '-';
  @Input() smart = false;
  @Input() edited = false;
  @Input() error = false;

  public baseClass = 'text-field';

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      disabled: this.disabled,
      smart: this.smart,
      error: this.error,
      edited: this.edited
      // touched: this.touched
      // errors: !!this.errors
    });
  }

  // TODO: implement ghost text-field to handle auto resize for variant smart

  // private autoResize(): void {}
}
