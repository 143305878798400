import { createSelector } from '@ngrx/store';
import { getServiceCardsState } from '../reducers';

export const getServiceCards = createSelector(
  getServiceCardsState,
  state => state.serviceCards
);

export const getIsMilesCardAllowed = createSelector(
  getServiceCardsState,
  state => state.isMilesCardAllowed
);

export const getMilesCardAllowedActionState = createSelector(
  getServiceCardsState,
  state => state.milesCardActionState
);
