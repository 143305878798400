<div class="row m0 confirmation-info">
  <div class="col col-xs-8 col-sm-8 col-md-9">
    <span class="confirmation-info__required-star">*</span>
    <span [innerHTML]="infoText | translate"></span>
    @if (linkText) {
      <div class="confirmation-info__link" (click)="linkClicked()">
        {{ linkText | translate }}
      </div>
    }
  </div>
  <div class="col col-xs-4 col-sm-4 col-md-3">
    <app-form-field>
      <app-slide-toggle [formControl]="field"></app-slide-toggle>
    </app-form-field>
  </div>
</div>
