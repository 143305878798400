import { gql } from 'apollo-angular';
import { CurrentState, DocuSignResponse } from '@ui/shared/models';

export const docuSignResponse = `
  internalContractId
  embeddedUrl
  workflowState
`;

export interface CurrentStatusQueryResponse {
  currentStatus: CurrentState;
}

export type QesCheckResponse = {
  qesCheck: {
    targetUrl: string;
  };
};

export interface SigningUrlQueryResponse {
  getSigningUrl: DocuSignResponse;
}

export const signerCurrentStateResponse = `
  schufaState
  itpState
  signerState
`;

export const currentStatusQuery = gql`
  query currentStatus($token: String!) {
    currentStatus(token: $token) {
      ${signerCurrentStateResponse}
    }
  }
`;

export const qesCheckMutation = gql`
  mutation qesCheck($data: QesCheck) {
    qesCheck(data: $data) {
      targetUrl
    }
  }
`;

export const signingUrlQuery = gql`
  query getSigningUrl($token: String, $redirectUrl: String) {
    getSigningUrl(token: $token, redirectUrl: $redirectUrl) {
      ${docuSignResponse}
    }
  }
`;
