import { createAction, props } from '@ngrx/store';

export const verifyEmail = createAction(
  '[Resident] Verify Email',
  props<{
    token: string;
    residentVerificationCode: string;
    customerIdent: string;
  }>()
);

export const verifyEmailSuccess = createAction(
  '[Resident] Verify Email Success',
  props<{ status: boolean }>()
);

export const verifyEmailFail = createAction(
  '[Resident] Verify Email Fail',
  props<{ error: Error }>()
);

export const changeEmail = createAction(
  '[Resident] Change Email',
  props<{ token: string; customerIdent: string }>()
);

export const changeEmailSuccess = createAction(
  '[Resident] Change Email Success',
  props<{ status: boolean }>()
);

export const changeEmailFail = createAction(
  '[Resident] Change Email Fail',
  props<{ error: Error }>()
);
