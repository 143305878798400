<app-modal-v2>
  <div class="modal-s schufa-success__modal">
    <app-modal-content>
      <div class="modal-body schufa-success__content">
        <div class="schufa-success__header">
          <p class="uppercase-m semibold schufa-success__title">
            {{ 'schufa.success.title_l' | translate }}
          </p>
          <div class="d-flex schufa-success__sub-title-container mb12">
            <img
              class="mr8"
              src="/assets/images/icons/icon-checkbox-green.svg"
            />
            <h3 class="title-xl semibold schufa-success__sub-title mb0">
              {{ 'schufa.success.sub_title_l' | translate }}
            </h3>
          </div>
          <p class="schufa-success__info-title">
            {{ 'schufa.success.description_l' | translate }}
          </p>
          <p [innerHTML]="'schufa.success.deposit_l' | translate"></p>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer schufa-success__footer">
        <app-button (clickEvent)="dismiss()" [type]="'primary'">{{
          'schufa.success.ok_a' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
