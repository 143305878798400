<div class="summary">
  <div class="summary__main-header">
    <span>{{ 'rent_deposit.summary.title_l' | translate }}</span>
  </div>

  <div class="col-12 col-xl-8 card-columns p0">
    <div class="card">
      <app-rent-deposit-summary-card-header
        [title]="
          'rent_deposit.summary.rent_deposit_title_l'
            | translate
              : { amount: rentDepositInfo?.oneTimeDeposit | currency: 'EUR' }
        "
        (edit)="onGoToStep('payment')"
      >
      </app-rent-deposit-summary-card-header>

      <div class="card-body">
        <div class="row">
          <div class="col-6">
            @if (isMonthly) {
              <div>
                <span class="summary__label-title">{{
                  'rent_deposit.summary.monthly_total_label' | translate
                }}</span>
              </div>
            }
            @if (isYearly) {
              <div>
                <span class="summary__label-title">{{
                  'rent_deposit.summary.yearly_total_label' | translate
                }}</span>
              </div>
            }
            {{ 'rent_deposit.summary.including_tax_label' | translate }}
          </div>
          <div class="col-6 d-flex justify-content-end">
            <span class="summary__total-amount"
              >{{ rentDepositModel?.contractData?.paymentAmount }}€</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- TENANT INFO -->
    <div class="card">
      <app-rent-deposit-summary-card-header
        [title]="'rent_deposit.summary.tenant_title_l' | translate"
        (edit)="onGoToStep('personalInformation')"
      >
      </app-rent-deposit-summary-card-header>

      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div>
              <span class="summary__label-title">{{
                'general.salutation_l' | translate
              }}</span>
            </div>
            {{
              'GENDERTYPES_' + rentDepositModel?.tenantData?.gender | translate
            }}
          </div>
          <div class="col-6">
            <div>
              <span class="summary__label-title">{{
                'general.name_l' | translate
              }}</span>
            </div>
            {{ rentDepositModel?.tenantData | appFullName }}
          </div>
        </div>

        <div>
          <span class="summary__label-title">{{
            'general.birthdate_l' | translate
          }}</span>
        </div>
        {{ rentDepositModel?.tenantData?.dateOfBirth | appDateTime }}

        <div>
          <span class="summary__label-title">{{
            'general.address_l' | translate
          }}</span>
        </div>
        {{ rentDepositModel?.tenantData?.currentAddress | appAddress }}

        <div>
          <span class="summary__label-title">{{
            'general.email_l' | translate
          }}</span>
        </div>
        {{ rentDepositModel?.tenantData?.emailAddress }}

        <div>
          <span class="summary__label-title">{{
            'general.phone_l' | translate
          }}</span>
        </div>
        {{ rentDepositModel?.tenantData?.phoneNumberPrefix }}
        {{ rentDepositModel?.tenantData.phoneNumber }}
      </div>
    </div>

    <!-- OBJECT INFORMATION -->
    <div class="card">
      <app-rent-deposit-summary-card-header
        [title]="'rent_deposit.summary.object_title_l' | translate"
        (edit)="onGoToStep('object')"
      >
      </app-rent-deposit-summary-card-header>

      <div class="card-body">
        <div>
          <span class="summary__label-title">{{
            'general.address_l' | translate
          }}</span>
        </div>
        {{ rentDepositModel?.propertyData?.address | appAddress }}

        <div>
          <span class="summary__label-title">{{
            'rent_deposit.object_information.contract_start_date_l' | translate
          }}</span>
        </div>
        {{
          rentDepositModel?.contractData?.startOfRentalContract | appDateTime
        }}

        <div>
          <span class="summary__label-title">{{
            'rent_deposit.object_information.contract_sign_date_l' | translate
          }}</span>
        </div>
        {{
          rentDepositModel?.contractData?.rentalContractSignDate | appDateTime
        }}

        <div>
          <span class="summary__label-title">{{
            'general.base_rent_l' | translate
          }}</span>
        </div>
        {{ propertyMatch?.property?.data?.basePrice | currency: 'EUR' }}
        @if (isNaturalPerson) {
          <div>
            <span class="summary__label-title">{{
              'rent_deposit.summary.landlord_gender_l' | translate
            }}</span>
          </div>
          {{
            'GENDERTYPES_' + rentDepositModel?.landlordData?.gender | translate
          }}
          <div>
            <span class="summary__label-title">{{
              'rent_deposit.summary.landlord_name_l' | translate
            }}</span>
          </div>
          {{ rentDepositModel?.landlordData | appFullName }}
        }
        @if (!isNaturalPerson) {
          <div>
            <span class="summary__label-title">{{
              'general.company_name_l' | translate
            }}</span>
          </div>
          {{ rentDepositModel?.landlordData?.name | translate }}
        }
      </div>
    </div>

    <!-- PAYMENT INFORMATION -->
    <div class="card">
      <app-rent-deposit-summary-card-header
        [title]="'rent_deposit.summary.payment_title_l' | translate"
        (edit)="onGoToStep('payment')"
      >
      </app-rent-deposit-summary-card-header>

      <div class="card-body">
        <div>
          <span class="summary__label-title">{{
            'general.iban_l' | translate
          }}</span>
        </div>
        {{ rentDepositModel?.bankData?.iban }}

        <div>
          <span class="summary__label-title">{{
            'general.bank_name_l' | translate
          }}</span>
        </div>
        {{ rentDepositModel?.bankData?.bankName }}
      </div>
    </div>

    <!-- DELIVERY INFORMATION -->
    <div class="card">
      <app-rent-deposit-summary-card-header
        [title]="'rent_deposit.summary.delivery_title_l' | translate"
        (edit)="onGoToStep('payment')"
      >
      </app-rent-deposit-summary-card-header>

      <div class="card-body">
        @if (rentDepositModel?.deliveryData?.sendDocumentsToLandlord) {
          <div>
            <span class="summary__label-title">{{
              'rent_deposit.summary.delivery_address_l' | translate
            }}</span>
          </div>
          {{ rentDepositModel?.landlordData?.landlordAddress | appAddress }}
        }
        @if (!rentDepositModel?.deliveryData?.sendDocumentsToLandlord) {
          <div>
            <span class="summary__label-title">{{
              'rent_deposit.summary.delivery_address_l' | translate
            }}</span>
          </div>
          {{ 'rent_deposit.summary.delivery_send_to_me_l' | translate }}
        }
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 pr30 mb20">
            <span>{{
              'rent_deposit.summary.acknowledge_privacy_pre' | translate
            }}</span>
            <span class="summary__link" (click)="openPrivacyModal()">{{
              'rent_deposit.summary.acknowledge_privacy_link' | translate
            }}</span>
            <span>{{
              'rent_deposit.summary.acknowledge_privacy_post' | translate
            }}</span>
          </div>
        </div>

        <div class="row summary__documents">
          <div class="col-12 d-flex flex-column">
            <div class="d-flex flex-row">
              <div class="col-10 p0">
                {{ 'rent_deposit.summary.acknowledge_documents_l' | translate }}
              </div>
              <app-slide-toggle
                class="col p0 d-flex justify-content-end"
                [(ngModel)]="acknowledgePrivacy"
              ></app-slide-toggle>
            </div>
            <ul class="pt15">
              <li>
                <app-button [type]="'link'" (clickEvent)="getProtocolPdf()">
                  {{
                    'rent_deposit.summary.acknowledge_consultation_protocol_l'
                      | translate
                  }}
                </app-button>
              </li>
              <li>
                <a target="_blank" href="/assets/pdf/ruv_informationsblatt.pdf">
                  <strong>
                    {{
                      'rent_deposit.summary.product_information_sheet_l'
                        | translate
                    }}
                  </strong>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="/assets/pdf/allgemeine_bedingungen_fuer_ruv.pdf"
                >
                  <strong>
                    {{
                      'rent_deposit.summary.insurance–conditions_l' | translate
                    }}
                  </strong>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="/assets/pdf/merkblatt_zur_datenverarbeitung.pdf"
                >
                  <strong>
                    {{ 'rent_deposit.summary.data_privacy_l' | translate }}
                  </strong>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p0">
      <app-wizard-footer
        [hasNext]="false"
        [hasPrevious]="true"
        [hasFinish]="true"
        [disabled]="!acknowledgePrivacy || processing"
        [hideRequiredHint]="true"
        [completeActionText]="'rent_deposit.complete_a'"
        (completeStep)="onCompleteStep()"
        (previousStep)="previousStep()"
      >
      </app-wizard-footer>
    </div>
  </div>
</div>
