import { createSelector } from '@ngrx/store';
import { getPropertiesState } from '../reducers';
import * as fromReducer from './apply.reducers';

export const getProperty = createSelector(
  getPropertiesState,
  fromReducer.getProperty
);
export const getLoadPropertyActionState = createSelector(
  getPropertiesState,
  fromReducer.getLoadPropertyActionState
);
export const getSetStorageForApplyActionState = createSelector(
  getPropertiesState,
  fromReducer.getSetStorageForApplyActionState
);
