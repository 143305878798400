import { inject, Injectable } from '@angular/core';
import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { RegexTypes } from 'libs/utils';
import { ibanValidator } from 'ngx-iban';
import {
  ResidentSince,
  SmartDepositAccordionPanelId,
  SmartDepositAddressForm,
  SmartDepositBankForm,
  SmartDepositBudgetForm,
  SmartDepositContactForm,
  SmartDepositForm,
  SmartDepositLandlordForm,
  SmartDepositPersonalForm,
  SmartDepositRentedObjectForm,
  TypeOfEmployment
} from 'tenant-pool/screens/payment/smart-deposit/smart-deposit.models';
import {
  isValidDateValidator,
  minDateValidator
} from 'libs/components/legacy/form/controls/validation';
import {
  Address,
  EmploymentType,
  HouseholdType,
  PropertySearcherUser,
  SmartDepositApplicationStatus,
  SmartDepositDictionary,
  SmartDepositOverview,
  SmartDepositPreFillData,
  SmartDepositRequestOfferInput,
  SmartDepositStatus
} from '@ui/shared/models';
import { LoadSmartDepositDictionaryResponse } from 'tenant-pool/core/queries';
import { LocationSearchService } from 'libs/services';
import { first, Observable, of } from 'rxjs';
import { delay, map, switchMap, take } from 'rxjs/operators';
import { GERMAN_COUNTRY_CODE } from 'libs/config/country-config';
import { BaseState, Go } from 'libs/infrastructure/base-state';
import { Store } from '@ngrx/store';
import {
  NAVIGATION_LINK,
  SmartDepositRedirectConfig,
  smartDepositRedirectConfig,
  SmartDepositTranslationConfig,
  smartDepositTranslationConfig
} from 'tenant-pool/config';
import moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthTokenService } from 'libs/infrastructure';
import { ENVIRONMENT_CONFIG } from 'tenant-pool/core';
import { Router } from '@angular/router';
import {
  getSmartDepositBranding,
  loadSmartDepositBranding
} from 'tenant-pool/+state';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
/* eslint-disable @typescript-eslint/unbound-method */

type DictionaryUntranslated =
  LoadSmartDepositDictionaryResponse['smartDepositDictionary'];

@Injectable({
  providedIn: 'root'
})
export class SmartDepositService {
  private fb = inject(FormBuilder);
  private locationSearchService = inject(LocationSearchService);
  private store = inject<Store<BaseState>>(Store);
  private http = inject(HttpClient);
  private authTokenService = inject(AuthTokenService);
  private router = inject(Router);
  private themeService = inject(ThemeService);
  private env = inject(ENVIRONMENT_CONFIG);

  public createForm(): SmartDepositForm {
    return this.fb.group({
      deposit: this.fb.control(null, [
        Validators.required,
        Validators.pattern(RegexTypes.NUMBERS),
        Validators.max(2147483647)
      ]),
      [SmartDepositAccordionPanelId.PERSONAL]: this.createPersonalForm(),
      [SmartDepositAccordionPanelId.CONTACT]: this.createContactForm(),
      [SmartDepositAccordionPanelId.BUDGET]: this.createBudgetForm(),
      [SmartDepositAccordionPanelId.RENTED_OBJECT]:
        this.createRentedObjectForm(),
      [SmartDepositAccordionPanelId.BANK]: this.createBankForm(),
      landlord: this.createLandlordForm()
    });
  }

  public applyBranding(token: string): void {
    this.store.dispatch(loadSmartDepositBranding({ token }));
    this.store
      .select(getSmartDepositBranding)
      .pipe(first(branding => !!branding))
      .subscribe(branding => this.themeService.createTheme(branding));
  }

  public convertFormToPayload(
    form: SmartDepositForm
  ): SmartDepositRequestOfferInput {
    const { deposit, personal, contact, budget, rentedObject, bank, landlord } =
      form.getRawValue();

    const contactAddresses = { address: contact.address };
    if (contact.residentSince === ResidentSince.LESS_THAN_TWO_YEARS) {
      contactAddresses['previousAddress'] = contact.previousAddress;
    }

    const birthName = personal.birthName.trim() || null;
    return {
      applicant: {
        ...personal,
        birthName,
        ...contactAddresses,
        birthDate: moment(personal.birthDate).format('DD-MM-YYYY'),
        numberOfChildren: budget.numberOfChildren,
        totalIncome: {
          employment: {
            income: budget.employmentIncome,
            since: moment(budget.employedSince).format('DD-MM-YYYY'),
            occupationalGroup: budget.occupationalGroup
          },
          otherIncome: budget.otherIncome ?? 0
        },
        bankAccount: {
          iban: bank.iban
        },
        contact: {
          email: contact.email,
          telephone: contact.telephone
        },
        consent: {
          dataProcessing: bank.dataProcessing,
          advertising: bank.advertising,
          ownAccount: bank.ownAccount,
          schufaQuery: bank.schufaQuery
        },
        deposit: deposit
      },
      rentalProperty: {
        startOfRental: moment(rentedObject.startOfRental).format('DD-MM-YYYY'),
        startOfGuarantee: moment(rentedObject.startOfGuarantee).format(
          'DD-MM-YYYY'
        ),
        basicRent: rentedObject.basicRent,
        address: rentedObject.address,
        landlord: {
          name: landlord.name,
          address: landlord.address
        }
      },
      deposit: deposit
    };
  }

  public getContractFile(queryToken: string) {
    const token = this.authTokenService.getToken().access_token;
    const PATH = `${this.env.graphql_host}/smartDeposit/contract`;

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    return this.http.request('POST', PATH, {
      headers,
      responseType: 'blob',
      body: { queryToken, token }
    });
  }

  public redirectByOverview(overview?: SmartDepositOverview, token?: string) {
    let redirect = NAVIGATION_LINK.PAYMENT_SMARTDEPOSIT_RESULT;

    if (token && overview) {
      let foundRedirect: SmartDepositRedirectConfig;
      const { legitimationStatus, applicationStatus } =
        overview?.smartDepositApplication?.currentState ?? {};

      // fallback for generic invitation links
      // BE should maybe add a placeholder status instead of null?
      if (!legitimationStatus && !applicationStatus) {
        foundRedirect = smartDepositRedirectConfig.find(config =>
          config.status?.includes(SmartDepositStatus.INVITED)
        );
      }

      // do 3 passes
      if (legitimationStatus) {
        foundRedirect = smartDepositRedirectConfig.find(config =>
          config.legitimationStatus?.includes(legitimationStatus)
        );
      }

      if (!foundRedirect && applicationStatus) {
        foundRedirect = smartDepositRedirectConfig.find(config =>
          config.applicationStatus?.includes(applicationStatus)
        );
      }

      if (!foundRedirect && overview.status) {
        foundRedirect = smartDepositRedirectConfig.find(config =>
          config.status?.includes(overview.status)
        );
      }

      redirect = foundRedirect?.redirectUrl ?? redirect;
    }

    // check if redirect is the same url currently on
    if (this.router.url.split('?')[0] !== redirect) {
      this.store.dispatch(
        new Go({
          path: [redirect],
          query: {
            ...(token ? { token } : {})
          }
        })
      );
    }
  }

  public findTranslationByOverview(
    overview?: SmartDepositOverview
  ): SmartDepositTranslationConfig {
    if (!overview) {
      return smartDepositTranslationConfig.find(config =>
        config.status?.includes(SmartDepositStatus.ERROR)
      );
    }

    const { applicationStatus, legitimationStatus } =
      overview?.smartDepositApplication?.currentState ?? {};
    let translation: SmartDepositTranslationConfig;

    if (legitimationStatus) {
      translation = smartDepositTranslationConfig.find(config =>
        config.legitimationStatus?.includes(legitimationStatus)
      );
    }

    if (!translation && applicationStatus) {
      translation = smartDepositTranslationConfig.find(config =>
        config.applicationStatus?.includes(applicationStatus)
      );
    }

    if (!translation && overview.status) {
      translation = smartDepositTranslationConfig.find(config =>
        config.status?.includes(overview.status)
      );
    }

    if (!translation) {
      translation = smartDepositTranslationConfig.find(config =>
        config.status?.includes(SmartDepositStatus.ERROR)
      );
    }

    return translation;
  }

  public findTranslationByApplicationStatus(
    applicationStatus: SmartDepositApplicationStatus
  ): SmartDepositTranslationConfig {
    let translation = smartDepositTranslationConfig.find(config =>
      config.applicationStatus?.includes(applicationStatus)
    );

    if (!translation) {
      translation = smartDepositTranslationConfig.find(config =>
        config.status?.includes(SmartDepositStatus.ERROR)
      );
    }

    return translation;
  }

  public patchFormWithUserData(
    user: PropertySearcherUser,
    form: SmartDepositForm
  ): void {
    const { address, email } = user;
    const { firstname, name, dateOfBirth, householdType, profession } =
      user.profile;

    const householdTypesWithoutChildren = [
      HouseholdType.COUPLE_WITHOUT_CHILDREN,
      HouseholdType.SINGLE
    ];
    const householdTypesPrefillNetIncome = [
      HouseholdType.SINGLE,
      HouseholdType.SINGLE_WITH_CHILDREN
    ];
    const employmentTypeTimeMap = new Map<EmploymentType, TypeOfEmployment>([
      [EmploymentType.EMPLOYED_LIMITED, TypeOfEmployment.Temporary],
      [EmploymentType.EMPLOYED_UNLIMITED, TypeOfEmployment.Unlimited]
    ]);

    const foundTypeOfEmployment = employmentTypeTimeMap.get(profession?.type);

    form.patchValue({
      personal: {
        givenName: firstname,
        familyName: name,
        birthDate: new Date(dateOfBirth)
      },
      contact: {
        email,
        address
      },
      budget: {
        ...(householdTypesWithoutChildren.includes(
          householdType as HouseholdType
        )
          ? { numberOfChildren: 0 }
          : {}),
        ...(householdTypesPrefillNetIncome.includes(
          householdType as HouseholdType
        ) && profession
          ? { employmentIncome: profession.income }
          : {}),
        ...(foundTypeOfEmployment
          ? { typeOfEmployment: foundTypeOfEmployment }
          : {})
      }
    });
  }

  /**
   * This method will check if a value in the dictionary
   * exists like expected. It won't prefill otherwise, since
   * it could prefill wrong data if there is a mismatch between
   * the immomio and bank11 required data.
   */
  public patchFormWithUserDataAndDictionary(
    user: PropertySearcherUser,
    dictionary: SmartDepositDictionary,
    form: SmartDepositForm
  ) {
    const employmentType = user?.profile?.profession?.type;

    const employmentTypeOccupationMap = new Map<EmploymentType, string>([
      [EmploymentType.EMPLOYED_LIMITED, 'Angestellter'],
      [EmploymentType.EMPLOYED_UNLIMITED, 'Angestellter'],
      [EmploymentType.CIVIL_SERVANT, 'Beamter'],
      [EmploymentType.SELF_EMPLOYED, 'Freiberufler'],
      [EmploymentType.APPRENTICE, 'in Ausbildung'],
      [EmploymentType.RETIRED, 'Rentner/Pensionär']
    ]);

    const foundOccupation = employmentTypeOccupationMap.get(employmentType);
    const existingFoundOccupation = dictionary.occupation.find(
      occupation => occupation.value === foundOccupation
    );

    if (existingFoundOccupation) {
      form.patchValue({
        budget: {
          occupationalGroup: existingFoundOccupation.key
        }
      });
    }
  }

  public patchFormWithPrefillData(
    prefillData: SmartDepositPreFillData,
    form: SmartDepositForm
  ): void {
    const {
      depositAmount,
      propertyAddress,
      landlordAddress,
      landlordName,
      startOfContract,
      baseRent
    } = prefillData;

    let startOfContractConverted: Date;

    if (startOfContract) {
      const [day, month, year] = startOfContract?.split('-');
      startOfContractConverted = new Date(+year, +month - 1, +day);
    }

    form.patchValue({
      deposit: depositAmount,
      landlord: {
        name: landlordName,
        address: landlordAddress
      },
      rentedObject: {
        ...(startOfContractConverted
          ? {
              startOfRental: startOfContractConverted,
              startOfGuarantee: startOfContractConverted
            }
          : {}),
        address: propertyAddress,
        basicRent: baseRent
      }
    });
  }

  public addTranslateKeysToDictionary(
    dictionary: DictionaryUntranslated
  ): SmartDepositDictionary {
    return {
      salutation: dictionary.salutation.map(item => ({
        ...item,
        translate: `smart-deposit.salutation_${item.key}_l`
      })),
      country: dictionary.country.map(item => ({
        ...item,
        translate: `smart-deposit.country_${item.key?.toLowerCase()}_l`
      })),
      occupation: dictionary.occupation.map(item => ({
        ...item,
        translate: `smart-deposit.occupation_${item.key}_l`
      })),
      maritalStatus: dictionary['marital-status'].map(item => ({
        ...item,
        translate: `smart-deposit.marital_status_${item.key}_l`
      })),
      numberOfChildren: dictionary['number-of-children'].map(item => ({
        ...item,
        translate: `smart-deposit.number_of_children_${item.key}_l`
      })),
      wayOfLiving: dictionary['way-of-living'].map(item => ({
        ...item,
        translate: `smart-deposit.way_of_living_${item.key}_l`
      })),
      commercialSector: dictionary['commercial-sector'].map(item => ({
        ...item,
        translate: `smart-deposit.commercial_sector_${item.key}_l`
      })),
      postcode: dictionary.postcode
    };
  }

  private createPersonalForm(): SmartDepositPersonalForm {
    return this.fb.group({
      salutation: this.fb.control(null, [Validators.required]),
      givenName: this.fb.nonNullable.control('', [Validators.required]),
      familyName: this.fb.nonNullable.control('', [Validators.required]),
      birthName: this.fb.nonNullable.control(''),
      birthDate: this.fb.control(null, [
        Validators.required,
        isValidDateValidator
      ]),
      nationality: this.fb.control(null, [Validators.required]),
      maritalStatus: this.fb.control(null, Validators.required),
      wayOfLiving: this.fb.control(null, [Validators.required])
    });
  }

  private createContactForm(): SmartDepositContactForm {
    return this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      telephone: this.fb.control('', [
        Validators.required,
        Validators.pattern(RegexTypes.INTERNATIONAL_PHONE)
      ]),
      address: this.createAddressForm(
        {
          asyncValidators: [this.germanAddressValidator()]
        },
        true
      ),
      residentSince: this.fb.control(null, Validators.required),
      previousAddress: this.createAddressForm({}, true)
    });
  }

  private createBudgetForm(): SmartDepositBudgetForm {
    return this.fb.group(
      {
        occupationalGroup: this.fb.control(null, Validators.required),
        commercialSector: this.fb.control(
          { value: null, disabled: true },
          Validators.required
        ),
        typeOfEmployment: this.fb.control(null, Validators.required), // not needed for application
        employmentIncome: this.fb.control(null, [
          Validators.required,
          Validators.pattern(RegexTypes.ONLY_TWO_DECIMALS)
        ]),
        employedSince: this.fb.control(null, [
          Validators.required,
          isValidDateValidator
        ]),
        employedUntil: this.fb.control({ value: null, disabled: true }, [
          Validators.required,
          isValidDateValidator
        ]),
        numberOfChildren: this.fb.control(null, Validators.required),
        otherIncome: this.fb.control(
          null,
          Validators.pattern(RegexTypes.ONLY_TWO_DECIMALS)
        )
      },
      { validators: [this.employedSinceBeforeEmployedUntilValidator()] }
    );
  }

  private createRentedObjectForm(): SmartDepositRentedObjectForm {
    return this.fb.group(
      {
        startOfRental: this.fb.control(null, [
          Validators.required,
          isValidDateValidator
        ]),
        startOfGuarantee: this.fb.control(null, [
          Validators.required,
          isValidDateValidator,
          minDateValidator(moment().startOf('day').toDate())
        ]),
        address: this.createAddressForm(),
        basicRent: this.fb.control(null, Validators.required)
      },
      { validators: [this.rentalBeforeGuaranteeValidator()] }
    );
  }

  private createBankForm(): SmartDepositBankForm {
    return this.fb.group({
      iban: this.fb.control('', [Validators.required, ibanValidator()]),
      schufaQuery: this.fb.nonNullable.control(false, [
        Validators.requiredTrue
      ]),
      advertising: this.fb.nonNullable.control(false, [Validators.required]),
      ownAccount: this.fb.nonNullable.control(false, [Validators.requiredTrue]),
      dataProcessing: this.fb.nonNullable.control(false, [
        Validators.requiredTrue
      ])
    });
  }

  private createAddressForm(
    options: AbstractControlOptions = {},
    withCountry = false
  ): SmartDepositAddressForm {
    const country = this.fb.control(GERMAN_COUNTRY_CODE, [
      Validators.pattern(RegexTypes.COUNTRY),
      Validators.required
    ]);
    return this.fb.group(
      {
        street: this.fb.control('', [
          Validators.required,
          Validators.pattern(RegexTypes.STREET)
        ]),
        houseNumber: this.fb.control('', [
          Validators.required,
          Validators.pattern(RegexTypes.STREETNUMBER)
        ]),
        zipCode: this.fb.control('', [
          Validators.required,
          Validators.pattern(RegexTypes.ZIPCODE)
        ]),
        city: this.fb.control('', [
          Validators.required,
          Validators.pattern(RegexTypes.CITY)
        ]),
        ...(withCountry ? { country } : {})
      },
      options
    );
  }

  private createLandlordForm(): SmartDepositLandlordForm {
    return this.fb.group({
      name: this.fb.nonNullable.control('', [Validators.required]),
      address: this.createAddressForm()
    });
  }

  private rentalBeforeGuaranteeValidator(): ValidatorFn {
    return (form: SmartDepositRentedObjectForm): ValidationErrors => {
      const { startOfRental, startOfGuarantee } = form.value;

      if (!startOfRental || !startOfGuarantee) {
        return null;
      }

      return moment(startOfGuarantee).isBefore(moment(startOfRental), 'date')
        ? { guaranteeBeforeRental: true }
        : null;
    };
  }

  private employedSinceBeforeEmployedUntilValidator(): ValidatorFn {
    return (form: SmartDepositBudgetForm): ValidationErrors => {
      const { employedSince, employedUntil } = form.value;
      if (!employedSince || !employedUntil) {
        return null;
      }

      if (moment(employedUntil).isBefore(moment(), 'date')) {
        return { employedUntilNotInFuture: true };
      }

      return moment(employedUntil).isBefore(moment(employedSince), 'date')
        ? { employedUntilBeforeSince: true }
        : null;
    };
  }

  private isGermanAddress(address: Address): Observable<boolean> {
    const { street, houseNumber, zipCode, city } = address;
    const search = `${street} ${houseNumber} ${zipCode} ${city}`;
    return this.locationSearchService.getLocations$(search).pipe(
      take(1),
      map(result => result?.data?.searchLocations?.features),
      map(locations =>
        locations.some(
          location => location?.properties?.countrycode === GERMAN_COUNTRY_CODE
        )
      )
    );
  }

  private germanAddressValidator(): AsyncValidatorFn {
    return (
      addressForm: SmartDepositAddressForm
    ): Observable<ValidationErrors | null> => {
      return of(addressForm.value).pipe(
        delay(1000),
        switchMap(() =>
          this.isGermanAddress(addressForm.value).pipe(
            map(isGermanAddress =>
              !isGermanAddress ? { nonGermanAddress: true } : null
            )
          )
        )
      );
    };
  }
}
