import { gql } from 'apollo-angular';

export const userUpdateLastLogin = gql`
  mutation {
    updateLastLogin {
      statusText
    }
  }
`;

export const ssoQuery = gql`
  query sso($email: String, $redirectUri: String!, $loginMethod: String) {
    sso(
      input: {
        email: $email
        redirectUri: $redirectUri
        loginMethod: $loginMethod
      }
    ) {
      nonce
      state
      url
    }
  }
`;

export const logoutMutation = gql`
  mutation userLogout($redirectUri: String!, $session_state: String) {
    userLogout(redirectUri: $redirectUri, session_state: $session_state) {
      url
    }
  }
`;

export interface SSOQueryResult {
  sso: {
    nonce: string;
    state: string;
    url: string;
  };
}

export interface LogoutMutationResult {
  userLogout: {
    url: string;
  };
}

export const requestPasswordResetMutation = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      token
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword(
    $password: String!
    $confirmedPassword: String!
    $token: String!
  ) {
    resetPassword(
      password: $password
      confirmedPassword: $confirmedPassword
      token: $token
    ) {
      status
      statusText
    }
  }
`;

export interface ResetPasswordResponse {
  resetPassword: {
    status: number;
    statusText: string;
  };
}
