import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { RentDepositModel } from '@ui/shared/models';
import {
  depositInfoQuery,
  DepositInfoResponse,
  UseCashDepositResponse,
  useCashMutation,
  useRuvDepositMutation,
  UseRuvDepositResponse
} from 'tenant-pool/core/queries';
import { PropertyMatchesFacade } from './property-matches.facade';

@Injectable()
export class RentalDepositFacade {
  private apollo = inject(Apollo);
  private propertyMatchesFacade = inject(PropertyMatchesFacade);

  public loadDepositInfo(propertyId: string) {
    return this.apollo
      .query<DepositInfoResponse>({
        query: depositInfoQuery,
        variables: {
          propertyId
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.depositInfo));
  }

  public useRuvDeposit(propertyId: string, input: RentDepositModel) {
    return this.apollo
      .mutate<UseRuvDepositResponse>({
        mutation: useRuvDepositMutation,
        variables: {
          propertyId,
          input
        },
        update: () => this.propertyMatchesFacade.refetchRentedMatches()
      })
      .pipe(map(response => response.data.useRuvDeposit));
  }

  public useCashDeposit(propertyId: string) {
    return this.apollo
      .mutate<UseCashDepositResponse>({
        mutation: useCashMutation,
        variables: {
          propertyId
        },
        update: () => this.propertyMatchesFacade.refetchRentedMatches()
      })
      .pipe(map(response => response.data.useCash));
  }
}
