import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';
import { ResidentFacade } from 'tenant-pool/core';
import * as fromActions from './resident.actions';

@Injectable()
export class ResidentEffects {
  private actions$ = inject(Actions);
  private facade = inject(ResidentFacade);

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyEmail),
      switchMap(({ token, residentVerificationCode, customerIdent }) =>
        this.facade
          .verifyEmail(token, residentVerificationCode, customerIdent)
          .pipe(
            map(status => fromActions.verifyEmailSuccess({ status })),
            catchError(error => [fromActions.verifyEmailFail({ error })])
          )
      )
    )
  );

  changeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeEmail),
      switchMap(({ token, customerIdent }) =>
        this.facade.changeEmail(token, customerIdent).pipe(
          map(status => fromActions.changeEmailSuccess({ status })),
          catchError(error => [fromActions.changeEmailFail({ error })])
        )
      )
    )
  );
}
