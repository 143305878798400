import { SignatureType } from '@ui/shared/models';
import { QualifiedElectronicSignatureComponent } from 'libs/components/legacy/digital-contract/qualified-electronic-signature/qualified-electronic-signature.component';
import { NoDataDisclaimerComponent } from 'libs/components/molecules/no-data-disclaimer/no-data-disclaimer.component';
import { ContractViewingComponent } from './components/contract-viewing/contract-viewing.component';
import { ContractIdVerificationComponent } from './components/contract-id-verification/contract-id-verification.component';
import { ContractCodeVerificationComponent } from './components/contract-code-verification/contract-code-verification.component';
import { ContractSigningComponent } from './components/contract-signing/contract-signing.component';
import { ContractPersonalInformationComponent } from './components/contract-personal-information/contract-personal-information.component';

export enum DigitalContractWizardSteps {
  PERSONAL_INFORMATION = 'personalInformation',
  VIEWING = 'viewing',
  QES = 'qes',
  QES_MISSING_TERMS_AND_CONDITIONS = 'qesMissingTermsAndConditions',
  ID_VERIFICATION = 'idVerification',
  CODE_VERIFICATION = 'codeVerification',
  SIGNING = 'signing'
}

export const SignContractStepsRoutes = [
  {
    path: DigitalContractWizardSteps.PERSONAL_INFORMATION,
    component: ContractPersonalInformationComponent,
    data: {
      title: 'digital_contract.wizard_tenant_sign.personal_information_title_l'
    }
  },
  {
    path: DigitalContractWizardSteps.VIEWING,
    component: ContractViewingComponent,
    data: {
      title: 'digital_contract.wizard_tenant_sign.viewing_title_l'
    }
  },
  {
    path: DigitalContractWizardSteps.QES,
    component: QualifiedElectronicSignatureComponent,
    signatureType: SignatureType.QES,
    data: {
      title: 'digital_contract.wizard_qes_title_l'
    }
  },
  {
    path: DigitalContractWizardSteps.QES_MISSING_TERMS_AND_CONDITIONS,
    component: NoDataDisclaimerComponent,
    signatureType: SignatureType.QES,
    data: {
      title: 'digital_contract.wizard_qes_missing_tac_title_l'
    }
  },
  {
    path: DigitalContractWizardSteps.ID_VERIFICATION,
    component: ContractIdVerificationComponent,
    signatureType: SignatureType.AES_MAIL,
    data: {
      title: 'digital_contract.wizard_tenant_sign.id_verification_title_l'
    }
  },
  {
    path: DigitalContractWizardSteps.CODE_VERIFICATION,
    component: ContractCodeVerificationComponent,
    signatureType: SignatureType.AES_MAIL,
    data: {
      title: 'digital_contract.wizard_tenant_sign.code_verification_title_l'
    }
  },
  {
    path: DigitalContractWizardSteps.SIGNING,
    component: ContractSigningComponent,
    data: {
      title: 'digital_contract.wizard_tenant_sign.signing_title_l'
    }
  }
];
