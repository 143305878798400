@if (!paymentActionState?.pending && !orderActionState.pending) {
  <div class="row schufa container-padding">
    <form
      [formGroup]="form"
      appFocusFormError
      class="col-12 col-xl-8"
      (ngSubmit)="onSubmit()"
    >
      <div class="schufa__header mb30">
        <app-heading level="2" styleType="100">{{
          'schufa.payment_data_title_l' | translate
        }}</app-heading>
        <div
          class="mb20 text-justify"
          [innerHTML]="'schufa.payment_data_description_l' | translate"
        ></div>
      </div>
      <div class="schufa__summary-card mt20 mb30">
        <div class="col-12">
          <app-heading level="3" styleType="200">{{
            'schufa.product_title_l' | translate
          }}</app-heading>
          <div class="pl15 mb20">
            <div>{{ personalData | appFullName }}</div>
            <div>{{ address | appAddress }}</div>
            <div>
              {{ 'schufa.order_personal_information_birthdate_l' | translate }}
              {{ personalData.dateOfBirth | appDateTime }}
            </div>
            <div>
              {{ 'schufa.order_personal_information_email_l' | translate }}
              {{ personalData.email }}
            </div>
          </div>
          <div class="row">
            <b class="col-8">{{ 'schufa.order_price_label_l' | translate }}</b>
            <b class="col-4 d-flex justify-content-end">{{
              'schufa.order_price_l' | translate
            }}</b>
          </div>
        </div>
      </div>
      <div class="schufa-body mt5 pb10 mb20" formGroupName="sepaLev">
        <div class="schufa__payment-information mb20">
          <app-heading level="3" styleType="200">{{
            'schufa.payment_from_bankaccount_l' | translate
          }}</app-heading>
          <span>{{
            'schufa.payment_detailed_from_bankaccount_l' | translate
          }}</span>
        </div>
        <div class="row">
          <div class="col-12 mb20">
            <app-form-field>
              <app-form-field-label [showRequiredMarker]="true">{{
                'general.bank_account_owner_l' | translate
              }}</app-form-field-label>
              <input
                appInput
                type="text"
                required
                [placeholder]="'forms.type_name' | translate"
                formControlName="bankAccountOwner"
                class="form-control"
              />
            </app-form-field>
          </div>
          <div class="col-12 mb20">
            <app-form-field>
              <app-form-field-label [showRequiredMarker]="true">{{
                'general.iban_l' | translate
              }}</app-form-field-label>
              <input
                appInput
                type="text"
                required
                [placeholder]="'forms.type_iban' | translate"
                formControlName="iban"
                class="form-control"
              />
            </app-form-field>
          </div>
          <!-- IBAN is only error here and we need to count the amount of it to reset the Order on 3 -->
          @if (paymentErrorCount > 0 && hasError) {
            <div
              class="col-12 error mb20"
              [innerHTML]="
                'schufa.iban_incorrect_l'
                  | translate: { count: paymentErrorCount }
              "
            ></div>
          }
          <div class="col-12 mt10">
            <app-form-field>
              <app-confirmation-info
                class="form-control"
                formControlName="approvalSEPA"
                [infoText]="'schufa.schufa_payment_information_l'"
                [linkText]="'schufa.schufa_payment_more_information_l'"
                (onLinkClicked)="onOpenSchufaPaymentMoreInformation()"
              >
              </app-confirmation-info>
            </app-form-field>
          </div>
        </div>
        @if (retryAndNext) {
          <div class="schufa__retry mt30">
            <b> {{ 'schufa.payment_retry_title_l' | translate }} </b>
            <div
              class="mb20"
              [innerHTML]="'schufa.payment_retry_message_l' | translate"
            ></div>
          </div>
        }
      </div>
      <div class="row mb30">
        <div class="col-12 col-sm-6 col-md-7 col-lg-8 order-sm-2">
          <div class="d-flex justify-content-end mb10@xs">
            <app-button
              class="w-100 u-width-auto@sm-min ps-1"
              [buttonType]="'submit'"
              [type]="'primary'"
              >{{ 'schufa.chargeable_order_now_a' | translate }}</app-button
            >
          </div>
        </div>
        <div class="col-6 col-md-5 col-lg-4 order-sm-1">
          <app-schufa-certificates></app-schufa-certificates>
        </div>
        <div class="col-6 col-sm-12 order-sm-3">
          <div class="d-flex justify-content-end">
            {{ 'general.mandatory_field_information_l' | translate }}
          </div>
        </div>
      </div>
    </form>
    <app-schufa-benefits-card
      class="col-0 col-lg-4 u-hide@lg-max"
    ></app-schufa-benefits-card>
  </div>
} @else {
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
}

<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>
