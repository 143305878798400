<nav
  class="navigation-drawer-list"
  [class.navigation-drawer-list--sub]="isSubList"
>
  @for (item of items; track item) {
    @if (!item.hide) {
      <app-navigation-drawer-item
        class="navigation-drawer-list__item"
        [item]="item"
        [narrow]="
          (navigationDrawer.narrowAndNotMobileView$ | async) && !isSubList
        "
      ></app-navigation-drawer-item>
    }
  }

  <ng-content></ng-content>
</nav>
