export interface GuestStatus {
  applicationPossible: boolean;
  alreadyRegistered: boolean;
  alreadyGuest: boolean;
  token: string;
}

export enum GuestAction {
  DELETE = 'DELETE'
}
