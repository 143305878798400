import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  inject
} from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';
import { NavigationDrawerService } from 'libs/components/organisms/navigation/navigation-drawer';
import { AsyncPipe } from '@angular/common';
import { NavigationDrawerItemComponent } from '../navigation-drawer-item/navigation-drawer-item.component';

@Component({
  selector: 'app-navigation-drawer-list',
  templateUrl: './navigation-drawer-list.component.html',
  styleUrls: ['./navigation-drawer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    // forwardRef is added to solve cyclic dependency
    forwardRef(() => NavigationDrawerItemComponent),
    AsyncPipe
  ]
})
export class NavigationDrawerListComponent {
  navigationDrawer = inject(NavigationDrawerService);

  @Input() items: NavigationItem[];
  @Input() isSubList = false;
}
