import { Pipe, PipeTransform } from '@angular/core';
import { PropertiesTag } from '@ui/shared/models';
import { sortTags } from 'libs/components/molecules/tag/tag-helper-functions';

@Pipe({
  name: 'appGetAssignablePropertyTags',
  standalone: true
})
export class GetAssignablePropertyTagsPipe implements PipeTransform {
  transform(
    propertyTags: PropertiesTag[],
    propertiesTags: PropertiesTag[]
  ): PropertiesTag[] {
    return sortTags(
      propertiesTags?.filter(
        ({ id }) => !propertyTags?.some(tag => tag?.id === id)
      )
    );
  }
}
