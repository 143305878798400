import { Component, forwardRef, Input, OnInit, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import {
  dateRangeValidator,
  integerValidator
} from 'libs/components/legacy/form';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';
import {
  MovingAuctionAddressForm,
  MovingAuctionObjectType,
  NoParkingZoneType,
  TimeType
} from '@ui/shared/models';

import { Address, PropertyMatchBean } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { AddressVerticalPipe } from 'libs/pipes/address-vertical.pipe';
import { ComponentsModule } from 'libs/components';

@UntilDestroy()
@Component({
  selector: 'app-moving-auction-data',
  templateUrl: './moving-auction-data.component.html',
  styleUrls: ['./moving-auction-data.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MovingAuctionDataComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MovingAuctionDataComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule,
    AddressVerticalPipe
  ]
})
export class MovingAuctionDataComponent
  extends BaseStep
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);

  @Input() address: MovingAuctionAddressForm;
  @Input() selectedRentedObject: PropertyMatchBean;

  public formGroup: FormGroup;
  // if exact/complete destination address is entered
  public hasExactDestinationAddress: boolean;
  public detailsNotKnown: FormControl;

  private onChange: (args) => any = () => null;
  private onTouch: () => any = () => null;

  public BOOLEAN_OPTIONS = [
    { id: '0', value: true, label: 'general.yes_l' },
    { id: '1', value: false, label: 'general.no_l' }
  ];

  public PARKING_OPTIONS = [
    {
      name: 'moving_auction.wizard_data.option_no_parking_not_necessary',
      value: NoParkingZoneType.NOT_NECCESSARY
    },
    {
      name: 'moving_auction.wizard_data.option_no_parking_customer',
      value: NoParkingZoneType.CUSTOMER
    },
    {
      name: 'moving_auction.wizard_data.option_no_parking_moving_company',
      value: NoParkingZoneType.MOVING_COMPANY
    }
  ];

  public OBJECT_TYPES = [
    {
      name: 'moving_auction.wizard_data.option_object_type_house',
      value: MovingAuctionObjectType.HOUSE
    },
    {
      name: 'moving_auction.wizard_data.option_object_apartment',
      value: MovingAuctionObjectType.APARTMENT
    }
  ];

  public FLOOR_OPTIONS = [
    { name: 'moving_auction.wizard_data.option_floor_ground', value: 0 },
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
    { name: '6', value: 6 },
    { name: '7', value: 7 }
  ];

  public TIME_TYPE_OPTIONS = [
    {
      name: 'moving_auction.wizard_data.time_type_option_flexible',
      value: TimeType.FLEXIBLE
    },
    {
      name: 'moving_auction.wizard_data.time_type_option_exact',
      value: TimeType.EXACT
    },
    {
      name: 'moving_auction.wizard_data.time_type_option_storage',
      value: TimeType.STORAGE
    }
  ];

  public minDayToday: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  minLatestDay = this.minDayToday;

  private get startFloor(): FormControl {
    return this.start.get('floor') as FormControl;
  }

  private get destinationFloor(): FormControl {
    return this.destination.get('floor') as FormControl;
  }

  private get startElevator(): FormControl {
    return this.start.get('elevator') as FormControl;
  }

  private get destinationElevator(): FormControl {
    return this.destination.get('elevator') as FormControl;
  }

  private get destinationBookedServices() {
    return this.destination.get('bookedServices');
  }

  public get start(): FormGroup {
    return this.formGroup.get('start') as FormGroup;
  }

  public get destination(): FormGroup {
    return this.formGroup.get('destination') as FormGroup;
  }

  public get movingDates(): FormGroup {
    return this.formGroup.get('movingDates') as FormGroup;
  }

  public get residenceTypeStart() {
    return this.start.get('residenceType');
  }

  public get residenceTypeDestination() {
    return this.destination.get('residenceType');
  }

  public get relocationTimeType() {
    return this.movingDates.get('relocationTimeType');
  }

  public get isExactMovingDate() {
    return this.relocationTimeType?.value === TimeType.EXACT;
  }

  public get isStorage() {
    return this.relocationTimeType?.value === TimeType.STORAGE;
  }

  public get earliestMovingDate() {
    return this.movingDates.get('movingEarliest');
  }

  public get latestMovingDate() {
    return this.movingDates.get('movingLatest');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.detailsNotKnown = this.fb.control(false);
    this.hasExactDestinationAddress = this.isExactAddress(
      this.address.newAddress
    );
    const propertyData = this.selectedRentedObject?.property?.data;
    this.formGroup = this.fb.group({
      movingDates: this.fb.group({
        relocationTimeType: ['FLEXIBLE'],
        movingEarliest: [
          null,
          Validators.compose([
            Validators.required,
            dateRangeValidator('movingLatest', true)
          ])
        ],
        movingLatest: [
          null,
          Validators.compose([
            Validators.required,
            dateRangeValidator('movingEarliest', false)
          ])
        ]
      }),
      start: this.fb.group({
        residents: [1, Validators.required],
        livingSpace: [
          null,
          Validators.compose([
            integerValidator,
            Validators.required,
            Validators.min(5),
            Validators.max(2000)
          ])
        ],
        floor: 0,
        rooms: 1,
        residenceType: ['HOUSE'],
        elevator: [false],
        comment: [null], // not displayed in UI, will be set by BE
        bookedServices: this.fb.group({
          noParkingZone: [NoParkingZoneType.NOT_NECCESSARY]
        })
      }),
      destination: this.fb.group({
        floor: propertyData?.floor ?? 0,
        residenceType: [
          propertyData?.objectType === 'FLAT'
            ? MovingAuctionObjectType.APARTMENT
            : MovingAuctionObjectType.HOUSE
        ],
        elevator: [propertyData?.elevator ?? false],
        comment: [null], // not displayed in UI, will be set by BE
        bookedServices: this.fb.group({
          noParkingZone: [NoParkingZoneType.NOT_NECCESSARY]
        })
      })
    });

    if (this.destinationFloor.value === 0) {
      this.destinationElevator.disable();
    }

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
    });

    this.relocationTimeType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.isExactMovingDate) {
          this.latestMovingDate.patchValue(this.earliestMovingDate.value);
        }
      });

    this.earliestMovingDate.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (this.isExactMovingDate) {
          this.latestMovingDate.patchValue(value);
        }
        const date = moment(value);
        this.minLatestDay = {
          year: date.year(),
          month: date.month() + 1,
          day: date.date()
        };
      });

    this.residenceTypeStart.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value =>
        this.formGroup
          .get('start.floor')
          .patchValue(value === MovingAuctionObjectType.APARTMENT ? 2 : 0)
      );

    this.residenceTypeDestination.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value =>
        this.formGroup
          .get('destination.floor')
          .patchValue(value === MovingAuctionObjectType.APARTMENT ? 2 : 0)
      );

    this.startFloor.valueChanges.pipe(untilDestroyed(this)).subscribe(floor => {
      if (floor === 0) {
        this.startElevator.disable();
      } else {
        this.startElevator.enable();
      }
    });

    this.destinationFloor.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(floor => {
        if (floor === 0) {
          this.destinationElevator.disable();
        } else {
          this.destinationElevator.enable();
        }
      });

    this.detailsNotKnown.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((detailsNotKnown: boolean) => {
        const controls = [
          this.residenceTypeDestination,
          this.destinationFloor,
          this.destinationElevator,
          this.destinationBookedServices
        ];
        controls.forEach(control =>
          detailsNotKnown ? control.disable() : control.enable()
        );
      });
  }

  private isExactAddress(address: Address): boolean {
    return (
      !!address.street &&
      !!address.houseNumber &&
      !!address.zipCode &&
      !!address.city
    );
  }

  public validate() {
    return this.formGroup.valid ? null : { missingFields: true };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }

  protected onCheckForm() {
    this.formGroup.valid ? this.nextStep() : this.formGroup.markAllAsTouched();
  }
}
