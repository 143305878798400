import { Component, Input } from '@angular/core';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';

@Component({
  selector: 'app-smart-input-field-set',
  templateUrl: './smart-input-field-set.component.html',
  styleUrls: ['./smart-input-field-set.component.scss'],
  standalone: true
})
export class SmartInputFieldSetComponent {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() edited = false;
  @Input() error = false;
  @Input() errorMessage = '';
  @Input() required = false;
  @Input() disabled = false;

  public baseClass = 'smart-input-field-set';
  public baseClassLabel = `${this.baseClass}__label`;

  public getLabelClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClassLabel, {
      disabled: this.disabled
      // touched: this.touched,
      // errors: !!this.errors
    });
  }
}
