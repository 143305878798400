<div class="main-container container-primary">
  <app-cookie-banner
    class="cookie-banner"
    [cookiePreference]="cookiesPreference$ | async"
    (acceptAll)="acceptCookies($event)"
    (customSettings)="customCookieSettings($event)"
  ></app-cookie-banner>
  @if (showWarningBanner) {
    <app-performance-warning-banner
      [config]="warningBannerConfig"
      [confirmed]="confirmedPerformanceWarning$ | async"
      (confirm)="confirmPerformanceWarning()"
      [@fadeOnEnterLeave]
    ></app-performance-warning-banner>
  }

  <div class="content-container">
    <router-outlet></router-outlet>
  </div>

  <aside></aside>

  @if (displayFooter) {
    <app-footer
      [links]="footerLinks"
      [showLanguagePicker]="true"
      [availableLanguages]="availableLanguages$ | async"
      [currentLanguageCode]="currentLanguageCode$ | async"
      (languageChange)="onLanguageChange($event)"
    ></app-footer>
  }
</div>
