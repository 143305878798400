import { Injectable, inject } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { getResponseValidator } from 'libs/infrastructure';
import {
  milesCardAllowedQuery,
  MilesCardAllowedResponse,
  serviceCardsQuery,
  ServiceCardsResponse
} from '../queries/cards.queries';

@Injectable()
export class ServiceCardsFacade {
  private apollo = inject(Apollo);

  private serviceCardsQuery: QueryRef<ServiceCardsResponse>;

  public serviceCards() {
    this.serviceCardsQuery = this.apollo.watchQuery<ServiceCardsResponse>({
      query: serviceCardsQuery
    });

    return this.serviceCardsQuery.valueChanges.pipe(
      map(response => response.data.serviceCards)
    );
  }

  public getMilesCardAllowed() {
    return this.apollo
      .query({
        query: milesCardAllowedQuery,
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<MilesCardAllowedResponse>()),
        map(res => res.data.milesCardAllowed.milesAllowed)
      );
  }
}
