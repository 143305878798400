<div class="segment">
  @for (segment of segments; track segment.id; let isLast = $last) {
    <app-button
      [type]="
        segment.id === selectedSegment.id
          ? ButtonTypeEnum.SECONDARY
          : ButtonTypeEnum.PRIMARY_INVERTED
      "
      (click)="selectSegment(segment)"
    >
      <i
        [class]="
          'd-flex icon icon--' +
          segment.icon +
          ' justify-content-center property-icon icon-padding'
        "
      ></i>
    </app-button>
    @if (!isLast) {
      <div class="segment__divider"></div>
    }
  }
</div>
