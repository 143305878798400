import { createAction, props } from '@ngrx/store';
import {
  ManualPaymentInput,
  PaymentExecutionInput,
  PaymentMandate,
  PaymentPayInResponse,
  PaymentRequest,
  SmartDepositLegitimationInput,
  SmartDepositDictionary,
  SmartDepositOverview,
  SmartDepositRequestOfferInput,
  CustomerBranding
} from '@ui/shared/models';

// # Payment Request (Positions)

export const fetchPaymentRequest = createAction(
  '[Payments] Fetch payment request information',
  props<{ token: string }>()
);

export const fetchPaymentRequestSuccess = createAction(
  '[Payments] Fetch payment request information success',
  props<{ paymentRequest: PaymentRequest }>()
);

export const fetchPaymentRequestFail = createAction(
  '[Payments] Fetch payment request information fail',
  props<{ error: Error }>()
);

export const fetchMandate = createAction(
  '[Payments] Fetch mandate status',
  props<{ token: string; mandateId: string }>()
);

export const fetchMandateSuccess = createAction(
  '[Payments] Fetch mandate status success',
  props<{ paymentMandate: PaymentMandate }>()
);

export const fetchMandateFail = createAction(
  '[Payments] Fetch mandate status fail',
  props<{ error: Error }>()
);

export const fetchPaymentBranding = createAction(
  '[Payments] Fetch payment branding',
  props<{ token: string }>()
);

export const fetchPaymentBrandingSuccess = createAction(
  '[Payments] Fetch payment branding success',
  props<{ paymentBranding: CustomerBranding }>()
);

export const fetchPaymentBrandingFail = createAction(
  '[Payments] Fetch payment branding fail',
  props<{ error: Error }>()
);

export const manualPayment = createAction(
  '[Payments] Set payment as manually paid',
  props<{ input: ManualPaymentInput }>()
);

export const manualPaymentSuccess = createAction(
  '[Payments] Set payment as manually paid success'
);

export const manualPaymentFail = createAction(
  '[Payments] Set payment as manually paid fail',
  props<{ error: Error }>()
);

export const paymentExecution = createAction(
  '[Payments] Execute payment',
  props<{ input: PaymentExecutionInput }>()
);

export const paymentExecutionSuccess = createAction(
  '[Payments] Execute payment success',
  props<{ response: PaymentPayInResponse }>()
);

export const paymentExecutionFail = createAction(
  '[Payments] Execute payment fail',
  props<{ error: Error }>()
);

// # SmartDeposit

export const loadSmartDepositFee = createAction(
  '[Payments] Load SmartDeposit Fee',
  props<{ deposit: number }>()
);

export const loadSmartDepositFeeSuccess = createAction(
  '[Payments] Load SmartDeposit Fee Success',
  props<{
    commission: number;
    guaranteeFee: number;
    commissionAmount: number;
  }>()
);

export const loadSmartDepositFeeError = createAction(
  '[Payments] Load SmartDeposit Fee Error',
  props<{ error?: Error }>()
);

export const fetchSmartDeposit = createAction(
  '[Payments] Fetch SmartDeposit',
  props<{ token: string }>()
);

export const fetchSmartDepositSuccess = createAction(
  '[Payments] Fetch SmartDeposit Success',
  props<{ smartDepositOverview: SmartDepositOverview }>()
);

export const fetchSmartDepositError = createAction(
  '[Payments] Fetch SmartDeposit Error',
  props<{ error: Error }>()
);

export const loadSmartDepositBranding = createAction(
  '[Payments] Fetch SmartDeposit Branding',
  props<{ token: string }>()
);

export const loadSmartDepositBrandingSuccess = createAction(
  '[Payments] Fetch SmartDeposit Branding Success',
  props<{ smartDepositBranding: CustomerBranding }>()
);

export const loadSmartDepositBrandingError = createAction(
  '[Payments] Fetch SmartDeposit Branding Error',
  props<{ error: Error }>()
);

export const applySmartDeposit = createAction(
  '[Payments] Apply to SmartDeposit',
  props<{
    token: string;
    smartDepositApplicationPayload: SmartDepositRequestOfferInput;
  }>()
);

export const applySmartDepositSuccess = createAction(
  '[Payments] Apply to SmartDeposit Success',
  props<{ token: string }>()
);

export const applySmartDepositError = createAction(
  '[Payments] Apply to SmartDeposit Error',
  props<{ error: Error }>()
);

export const cancelSmartDeposit = createAction(
  '[Payments] Cancel SmartDeposit Application',
  props<{ token: string }>()
);

export const cancelSmartDepositSuccess = createAction(
  '[Payments] Cancel SmartDeposit Application Success'
);

export const cancelSmartDepositError = createAction(
  '[Payments] Cancel SmartDeposit Application Error',
  props<{ error: Error }>()
);

export const startSmartDepositLegitimation = createAction(
  '[Payments] Start SmartDeposit Legitimation',
  props<{ token: string; input: SmartDepositLegitimationInput }>()
);

export const startSmartDepositLegitimationSuccess = createAction(
  '[Payments] Start SmartDeposit Legitimation Success'
);

export const startSmartDepositLegitimationError = createAction(
  '[Payments] Start SmartDeposit Legitimation Error',
  props<{ error: Error }>()
);

export const cancelSmartDepositLegitimation = createAction(
  '[Payments] Cancel SmartDeposit Legitimation',
  props<{ token: string }>()
);

export const cancelSmartDepositLegitimationSuccess = createAction(
  '[Payments] Cancel SmartDeposit Legitimation Success'
);

export const cancelSmartDepositLegitimationError = createAction(
  '[Payments] Cancel SmartDeposit Legitimation Error',
  props<{ error: Error }>()
);

export const loadSmartDepositDictionary = createAction(
  '[Payments] Load SmartDeposit Dictionary'
);

export const loadSmartDepositDictionarySuccess = createAction(
  '[Payments] Load SmartDeposit Dictionary Success',
  props<{ smartDepositDictionary: SmartDepositDictionary }>()
);

export const loadSmartDepositDictionaryError = createAction(
  '[Payments] Load SmartDeposit Dictionary Error',
  props<{ error: Error }>()
);

export const clearSmartDepositFromStore = createAction(
  '[Payments] Clear SmartDeposit from Store'
);

export const clearSmartDepositLegitimationActionState = createAction(
  '[Payments] Clear SmartDeposit Legitimation ActionState'
);
