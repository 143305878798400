import { inject, Injectable } from '@angular/core';
import {
  AuthToken,
  FetchKeycloakJSON,
  KeycloakInterface
} from 'libs/infrastructure/keycloak-authentication-module/model';
import { AuthTokenService, LogoutReasons } from 'libs/infrastructure';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { KEYCLOAK_OPTIONS } from 'libs/infrastructure/keycloak-authentication-module/contant';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { getRedirectUrl } from 'libs/utils';
import { Store } from '@ngrx/store';

const jwtHelperService: JwtHelperService = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class KeycloakTenantService {
  http = inject(HttpClient);
  authTokenService = inject(AuthTokenService);
  infrastructur = inject(INFRASTRUCTURE_CONFIG);
  private store = inject<Store<fromBaseState.AppState>>(Store);

  public keycloakInstance: Keycloak.KeycloakInstance;
  public readonly keycloakConfig: FetchKeycloakJSON;

  constructor() {
    const kcConfig = inject(KEYCLOAK_OPTIONS);

    this.keycloakConfig = kcConfig.jsonConfig;
  }

  public initKeycloak(keycloakInstance: KeycloakInstance) {
    this.keycloakInstance = keycloakInstance;
  }

  public async getKcJsonStructure(): Promise<KeycloakInterface> {
    const prom = this.keycloakConfig();
    let config;
    if (prom instanceof Promise) {
      config = await prom;
    } else {
      config = prom;
    }
    config.clientId = config.resource;
    config.url = config['auth-server-url'];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return config;
  }

  public async refresh(isMobile = false): Promise<AuthToken> {
    let authToken = this.authTokenService.getToken();
    if (
      !authToken ||
      (authToken &&
        authToken.refresh_token &&
        this.isTokenExpiringIn(authToken.refresh_token, 10))
    ) {
      this.dispatchUserLogout();
    }

    if (!Object.keys(authToken).length) {
      return;
    }

    if (this.isTokenExpiringIn(authToken.access_token, 7200)) {
      const uri = this.getTokenUrl();
      const headers = this.getTokenRequestHeaders();
      const body = this.getRefreshParams(authToken.refresh_token);
      try {
        authToken = await this.createPostRequest(uri, body, { headers });
      } catch (error) {
        // TODO mobile app release: this logout will not work on mobile! Refactor to logout with return value
        //  in correct keycloak service e.g.
        if (!isMobile) this.dispatchUserLogout();
      }
      this.authTokenService.setToken(authToken);
    }
    return authToken;
  }

  private dispatchUserLogout() {
    return this.store.dispatch(
      new fromBaseState.UserLogout(
        getRedirectUrl(window.location.toString(), '/login', {
          queryParams: {
            reason: LogoutReasons.EXPIRED_TOKEN
          },
          pathAfterAuth: ''
        })
      )
    );
  }

  public getTokenUrl() {
    return `${this.keycloakInstance.authServerUrl}realms/${this.keycloakInstance.realm}/protocol/openid-connect/token`;
  }

  public isTokenExpiringIn(token: string, offsetSeconds: number) {
    return jwtHelperService.isTokenExpired(token, offsetSeconds);
  }

  public getTokenRequestHeaders() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const clientSecret = this.keycloakInstance.clientSecret;
    const clientId = this.keycloakInstance.clientId;
    if (clientId && clientSecret) {
      headers.set(
        'Authorization',
        'Basic ' + btoa(clientId + ':' + clientSecret)
      );
    }
    return headers;
  }

  public getAccessTokenParams(code: string, redirectUrl: string) {
    let redirectUri = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('client_id', encodeURIComponent(this.keycloakInstance.clientId))
      .set('redirect_uri', redirectUrl);
    const secret = this.keycloakInstance.clientSecret;
    if (secret) {
      redirectUri = redirectUri.set(
        'client_secret',
        encodeURIComponent(secret)
      );
    }
    return redirectUri;
  }

  public getRefreshParams(refreshToken: string) {
    const params = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('client_id', encodeURIComponent(this.keycloakInstance.clientId));
    const secret = this.keycloakInstance.clientSecret;
    if (secret) {
      return params.set('client_secret', encodeURIComponent(secret));
    }
    return params;
  }

  public getExchangeTokenParams(token: string, clientId: string) {
    const params = new HttpParams()
      .set('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange')
      .set('subject_token', token)
      .set(
        'subject_token_type',
        'urn:ietf:params:oauth:token-type:access_token'
      )
      .set('client_id', encodeURIComponent(clientId));

    const secret = this.keycloakInstance.clientSecret;
    if (secret) {
      return params.set('client_secret', encodeURIComponent(secret));
    }

    return params;
  }

  public async getExchangeToken(
    token: string,
    realm: string,
    clientId: string
  ) {
    let authToken;

    const uri = `${this.keycloakInstance.authServerUrl}realms/${realm}/protocol/openid-connect/token`;
    const headers = this.getTokenRequestHeaders();
    const body = this.getExchangeTokenParams(token, clientId);
    try {
      authToken = await this.createPostRequest(uri, body, { headers });
    } catch (error) {
      this.dispatchUserLogout();
    }

    this.authTokenService.setToken(authToken);
  }

  public getAuthTokenFromKeycloakInstance(): AuthToken {
    if (this.keycloakInstance.token) {
      return {
        access_token: this.keycloakInstance.token,
        expires_in: this.keycloakInstance.tokenParsed.exp,
        token_type: (this.keycloakInstance.tokenParsed as any).typ,
        refresh_token: this.keycloakInstance.refreshToken,
        refresh_expires_in: this.keycloakInstance.refreshTokenParsed.exp,
        id_token: this.keycloakInstance.idToken,
        session_state: this.keycloakInstance.tokenParsed.session_state,
        scope: (this.keycloakInstance.tokenParsed as any).scope
      } as AuthToken;
    } else {
      return null;
    }
  }

  public createPostRequest(
    uri: string,
    body: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
    }
  ) {
    return this.http.post<AuthToken>(uri, body, options).toPromise();
  }
}
