import { gql } from 'apollo-angular';

export interface SchufaStatus {
  code?: string;
  description?: string;
  next?: string;
}

export interface SchufaError {
  code?: string;
  description?: string;
  fieldReference?: string;
}

export interface SchufaPersonalDataResponse {
  schufaPersonalData: SchufaOrderResponseData;
}

export interface SchufaOrderResponseData {
  orderNumber?: number;
  status?: SchufaStatus;
  error?: SchufaError[];
}

export interface SchufaDeliveryResponseData {
  deliveryNumber?: number;
  oneTimeURL?: string;
  verifyCode?: string;
  verifyURL?: string;
  status?: SchufaStatus;
  error?: SchufaError[];
}

export const schufaPersonalData = gql`
  query schufaPersonalData($input: SchufaPersonalInformationInput!) {
    schufaPersonalData(input: $input) {
      orderNumber
      status {
        code
        description
        next
      }
      error {
        code
        description
        fieldReference
      }
    }
  }
`;

export interface SchufaPaymentResponse {
  schufaPayment?: SchufaOrderResponseData;
}

export const schufaPayment = gql`
  query schufaPayment($orderNumber: String!, $input: SchufaPaymentInput!) {
    schufaPayment(orderNumber: $orderNumber, input: $input) {
      orderNumber
      status {
        code
        description
        next
      }
      error {
        code
        description
        fieldReference
      }
    }
  }
`;

export interface SchufaOrderResponse {
  schufaOrder?: SchufaOrderResponseData;
}

export const schufaOrder = gql`
  query schufaOrder($orderNumber: String!) {
    schufaOrder(orderNumber: $orderNumber) {
      deliveryNumber
      status {
        code
        description
        next
      }
      error {
        code
        description
        fieldReference
      }
      oneTimeURL
      verifyCode
      verifyURL
    }
  }
`;

export interface SchufaDeliveryResponse {
  schufaDelivery: SchufaDeliveryResponseData;
}

export const schufaDelivery = gql`
  query schufaDelivery($deliveryNumber: String!, $input: SchufaDeliveryInput!) {
    schufaDelivery(deliveryNumber: $deliveryNumber, input: $input) {
      deliveryNumber
      status {
        code
        description
        next
      }
      error {
        code
        description
        fieldReference
      }
      oneTimeURL
      verifyCode
      verifyURL
    }
  }
`;
