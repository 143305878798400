import { multiLanguageStringQuery } from './multi-language-string.query';

const title = `
  title {
    ${multiLanguageStringQuery}
  }
`;

const commentHint = `
  commentHint {
    ${multiLanguageStringQuery}
  }
`;

export const customQuestionFields = `
  id
  key
  type
  subType
  title
  options
  commentHint
  commentAllowed
  comment
  importance
  responses {
    responses
    comment
    selectedRange
  }
  answer
  selectedRange
  knockout
`;

export const customQuestionScoreFields = `
  customQuestionScore {
    score
    answerOverview
  }
`;

export const hierarchicalQuestionDataFields = `
  type
  order
  idType
  ${title}
  commentAllowed
  ${commentHint}
  maxAnswers
`;

export const hierarchicalQuestionAnswerFields = `
  id
  data {
    type
    order
    answerId
    ${title}
    identifier
    lowerBound
    upperBound
    upperBoundIncluded
    lowerBoundIncluded
    scoreType
  }
  questionIds
`;

export const hierarchicalQuestionAnswerModelFields = `
  ${hierarchicalQuestionAnswerFields}
  score
  preferredAnswer
  knockout
  actions {
    type
    tagIds
  }
`;

export const questionFields = `
  id
  data {
    ${hierarchicalQuestionDataFields}
  }
  answers {
    ${hierarchicalQuestionAnswerFields}
  }
`;

export const questionModelFields = `
  id
  data {
    ${hierarchicalQuestionDataFields}
  }
  answers {
    ${hierarchicalQuestionAnswerModelFields}
  }
  score
`;

export const rootQuestionFields = `
  id
  mainQuestionId
  questions {
    ${questionFields}
  }
`;

export const rootQuestionModelFields = `
  id
  mainQuestionId
  questions {
    ${questionModelFields}
  }
`;

export const rootQuestionContainerFields = `
  rootQuestion {
    ${rootQuestionFields}
  }
  responses {
    questionId
    data {
      type
      comment
      answerIds
      response
    }
  }
`;

export const rootQuestionDisplayFields = `
  id
  rootQuestion {
    ${rootQuestionModelFields}
  }
  editTaskType
  importance
`;

export const questionContainerFields = `
  customQuestions {
    ${customQuestionFields}
  }
  questions {
    ${rootQuestionContainerFields}
  }
`;

export const customQuestion = `
  customQuestions {
    ${customQuestionFields}
  }
`;

export const hierarchicalQuestionResponseDataFields = `
  type
  comment
  response
  answerIds
`;

export const hierarchicalQuestionAnswerDataFields = `
  type
  answerId
  response
`;

export const hierarchicalQuestionBaseFields = `
  id
  data {
    ${hierarchicalQuestionDataFields}
  }
  response {
    data {
      ${hierarchicalQuestionResponseDataFields}
    }
  }
  answers {
    ${hierarchicalQuestionAnswerFields}
  }
`;

export const customQuestionResponseFields = `
  responses
  selectedRange
  comment
  customQuestion {
    key
    type
    subType
    title
    options
    commentHint
  }
`;

export const questionResponseContainerFields = `
  customQuestions {
    ${customQuestionResponseFields}
  }
  questions {
    ${rootQuestionContainerFields}
  }
`;
