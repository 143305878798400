import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';
import { PaymentsFacade } from 'tenant-pool/core';
import { ShowError } from 'libs/infrastructure/base-state';
import { notificationConfig } from 'tenant-pool/config';
import { SmartDepositService } from 'tenant-pool/screens/payment/smart-deposit/smart-deposit.service';
import * as fromActions from './payments.actions';

@Injectable()
export class PaymentsEffects {
  private actions$ = inject(Actions);
  private facade = inject(PaymentsFacade);
  private smartDepositService = inject(SmartDepositService);

  fetchPaymentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchPaymentRequest),
      switchMap(({ token }) =>
        this.facade.fetchPaymentRequest(token).pipe(
          map(paymentRequest =>
            fromActions.fetchPaymentRequestSuccess({ paymentRequest })
          ),
          catchError((error: Error) => [
            fromActions.fetchPaymentRequestFail({ error }),
            new ShowError(notificationConfig.payments.fetchPaymentRequest.error)
          ])
        )
      )
    )
  );

  fetchMandate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchMandate),
      switchMap(({ token, mandateId }) =>
        this.facade.fetchMandate(token, mandateId).pipe(
          map(paymentMandate =>
            fromActions.fetchMandateSuccess({ paymentMandate })
          ),
          catchError((error: Error) => [
            fromActions.fetchMandateFail({ error }),
            new ShowError(notificationConfig.payments.fetchMandate.error)
          ])
        )
      )
    )
  );

  fetchPaymentBranding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchPaymentBranding),
      switchMap(({ token }) =>
        this.facade.fetchPaymentBranding(token).pipe(
          map(paymentBranding =>
            fromActions.fetchPaymentBrandingSuccess({
              paymentBranding
            })
          ),
          catchError((error: Error) => [
            fromActions.fetchPaymentBrandingFail({ error }),
            new ShowError(
              notificationConfig.payments.fetchPaymentBranding.error
            )
          ])
        )
      )
    )
  );

  manualPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.manualPayment),
      switchMap(({ input }) =>
        this.facade.manualPayment(input).pipe(
          map(() => fromActions.manualPaymentSuccess()),
          catchError((error: Error) => [
            fromActions.manualPaymentFail({ error }),
            new ShowError(notificationConfig.payments.manualPayment.error)
          ])
        )
      )
    )
  );

  paymentExecution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.paymentExecution),
      switchMap(({ input }) =>
        this.facade.paymentExecution(input).pipe(
          map(response => fromActions.paymentExecutionSuccess({ response })),
          catchError((error: Error) => [
            fromActions.paymentExecutionFail({ error }),
            new ShowError(notificationConfig.payments.paymentExecution.error)
          ])
        )
      )
    )
  );

  smartDepositFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSmartDepositFee),
      switchMap(({ deposit }) =>
        this.facade.loadSmartDepositFee(deposit).pipe(
          map(smartDepositFee =>
            fromActions.loadSmartDepositFeeSuccess(smartDepositFee)
          ),
          catchError((error: Error) => [
            fromActions.loadSmartDepositFeeError({ error }),
            new ShowError(
              notificationConfig.smartDeposit.loadSmartDepositFee.error
            )
          ])
        )
      )
    )
  );

  fetchSmartDeposit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchSmartDeposit),
      switchMap(({ token }) =>
        this.facade.fetchSmartDeposit(token).pipe(
          map(smartDepositOverview =>
            fromActions.fetchSmartDepositSuccess({ smartDepositOverview })
          ),
          catchError((error: Error) => [
            fromActions.fetchSmartDepositError({ error }),
            new ShowError(
              notificationConfig.smartDeposit.fetchSmartDeposit.error
            )
          ])
        )
      )
    )
  );

  loadSmartDepositBranding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSmartDepositBranding),
      switchMap(({ token }) =>
        this.facade.loadSmartDepositBranding(token).pipe(
          map(smartDepositBranding =>
            fromActions.loadSmartDepositBrandingSuccess({
              smartDepositBranding
            })
          ),
          catchError((error: Error) => [
            fromActions.loadSmartDepositBrandingError({ error }),
            new ShowError(
              notificationConfig.smartDeposit.loadSmartDepositBranding.error
            )
          ])
        )
      )
    )
  );

  smartDepositDictionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSmartDepositDictionary),
      switchMap(() =>
        this.facade.loadSmartDepositDictionary().pipe(
          map(dictionary =>
            this.smartDepositService.addTranslateKeysToDictionary(dictionary)
          ),
          map(smartDepositDictionary =>
            fromActions.loadSmartDepositDictionarySuccess({
              smartDepositDictionary
            })
          ),
          catchError((error: Error) => [
            fromActions.loadSmartDepositDictionaryError({ error }),
            new ShowError(
              notificationConfig.smartDeposit.loadSmartDepositDictionary.error
            )
          ])
        )
      )
    )
  );

  applySmartDeposit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.applySmartDeposit),
      switchMap(({ token, smartDepositApplicationPayload }) =>
        this.facade
          .applySmartDeposit(token, smartDepositApplicationPayload)
          .pipe(
            map(({ token }) => fromActions.applySmartDepositSuccess({ token })),
            catchError(error => [
              fromActions.applySmartDepositError({ error }),
              new ShowError(
                notificationConfig.smartDeposit.applySmartDeposit.error
              )
            ])
          )
      )
    )
  );

  cancelSmartDeposit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelSmartDeposit),
      switchMap(({ token }) =>
        this.facade.cancelSmartDeposit(token).pipe(
          map(() => fromActions.cancelSmartDepositSuccess()),
          catchError(error => [
            fromActions.cancelSmartDepositError({ error }),
            new ShowError(
              notificationConfig.smartDeposit.cancelSmartDeposit.error
            )
          ])
        )
      )
    )
  );

  startSmartDepositLegitimation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startSmartDepositLegitimation),
      switchMap(({ token, input }) =>
        this.facade.startSmartDepositLegitimation(token, input).pipe(
          map(() => fromActions.startSmartDepositLegitimationSuccess()),
          catchError(error => [
            fromActions.startSmartDepositLegitimationError({ error }),
            new ShowError(
              notificationConfig.smartDeposit.startSmartDepositLegitimation.error
            )
          ])
        )
      )
    )
  );

  cancelSmartDepositLegitimation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelSmartDepositLegitimation),
      switchMap(({ token }) =>
        this.facade.cancelSmartDepositLegitimation(token).pipe(
          map(() => fromActions.cancelSmartDepositLegitimationSuccess()),
          catchError(error => [
            fromActions.cancelSmartDepositLegitimationError({ error }),
            new ShowError(
              notificationConfig.smartDeposit.cancelSmartDepositLegitimation.error
            )
          ])
        )
      )
    )
  );
}
