@if (!isLoading) {
  @if (data?.length) {
    <div class="position-relative">
      @if (columns?.length) {
        <ng-scrollbar
          class="data-table"
          [ngClass]="{
            'data-table--with-sticky-last-cell': withStickyLastCell
          }"
          trackClass="data-table__scrollbar"
          [orientation]="'horizontal'"
          [position]="scrollbarPosition"
          [visibility]="'native'"
          (scroll)="onScroll()"
        >
          <table
            class="data-table__table"
            [class.data-table__no-border-spacing]="removeBorderSpacing"
          >
            <thead [hidden]="hideHeader">
              <tr>
                @for (column of columns; track column) {
                  <th
                    class="data-table__head-cell"
                    [class.same-background]="sameBackgroundHeader"
                  >
                    @if (headerTemplatesMap[column.name]) {
                      <div
                        [class]="
                          column.alignment
                            ? 'data-table__head-cell--align-' + column.alignment
                            : 'data-table__head-cell--align-left'
                        "
                        class="data-table__head-cell--inserted-label"
                      >
                        <ng-container
                          [ngTemplateOutlet]="headerTemplatesMap[column.name]"
                        ></ng-container>
                      </div>
                    } @else {
                      <span
                        [class]="
                          column.alignment
                            ? 'data-table__head-cell--align-' + column.alignment
                            : 'data-table__head-cell--align-left'
                        "
                        class="data-table__head-cell--label"
                        >{{ column?.label | translate }}
                        @if (column.required) {
                          <span class="required-marker ng-star-inserted"
                            >&nbsp;*</span
                          >
                        }
                      </span>
                    }
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (item of data; track item) {
                <tr
                  class="data-table__row"
                  (click)="onRowClick(item)"
                  [ngClass]="{
                    'data-table__row--specific':
                      highlightKey && item[highlightKey]
                  }"
                >
                  @for (column of columns; track column) {
                    <td
                      class="data-table__cell"
                      [attr.data-qa-column-name]="column.name"
                    >
                      @if (cellTemplatesMap[column.name]) {
                        <ng-container
                          [ngTemplateOutlet]="cellTemplatesMap[column.name]"
                          [ngTemplateOutletContext]="{ $implicit: item }"
                        ></ng-container>
                      } @else {
                        {{ item[column.name] || '-' }}
                      }
                    </td>
                  }
                </tr>
              }
            </tbody>
          </table>
        </ng-scrollbar>
      }
    </div>
  } @else {
    <div class="container-fluid">
      <div class="row mt-5">
        <div class="col-12">
          <app-no-data-disclaimer
            [title]="noDataHeader"
            [description]="noDataMessage"
            [showImage]="noDataShowImage"
          ></app-no-data-disclaimer>
        </div>
      </div>
    </div>
  }
} @else {
  <div class="data-table__skeleton-row mb-2"></div>
  @for (item of createRange(skeletonRowsAmount); track i; let i = $index) {
    <div
      class="data-table__skeleton-row skeleton mb-2"
      [style]="{ opacity: (i / skeletonRowsAmount) * -1 + 1 }"
    ></div>
  }
}
@if (enableVariableScrollbar) {
  <div #tableAnchor class="data-table__anchor"></div>
}
