<div class="schufa-certificates">
  <picture class="schufa-certificates__geotrust-image mr12">
    <source
      srcset="
        /assets/images/certificates/geotrust@2x.png 2x,
        /assets/images/certificates/geotrust.png    1x
      "
    />
    <img
      src="/assets/images/certificates/geotrust.png"
      alt=""
      itemprop="image"
    />
  </picture>
  <picture class="schufa-certificates__ssl-image">
    <source
      srcset="
        /assets/images/certificates/tempteria-ssl@2x.png 2x,
        /assets/images/certificates/tempteria-ssl.png    1x
      "
    />
    <img
      src="/assets/images/certificates/tempteria-ssl.png"
      alt=""
      itemprop="image"
    />
  </picture>
</div>
