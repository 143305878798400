import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { of, iif, combineLatest, forkJoin } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import {
  LocalStorageService,
  SessionStorageService
} from 'libs/infrastructure';

import { storageKeys } from 'tenant-pool/config';
import * as fromAppState from 'tenant-pool/+state';
import {
  getPropertyMatchData,
  getPropertyMatchesQuestions
} from 'tenant-pool/+state';
import { PropertyType } from '@ui/shared/models';

@Injectable()
export class ApplicationCreationGuard {
  private store = inject<Store<fromAppState.AppState>>(Store);
  private sessionStorage = inject(SessionStorageService);
  private localStorageService = inject(LocalStorageService);

  canActivate() {
    const propertyId = this.sessionStorage.getItem<string>(
      storageKeys.propertyIdToApply
    );

    if (!propertyId) {
      return of(true);
    }
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore() {
    const propertyId = String(
      this.sessionStorage.getItem(storageKeys.propertyIdToApply)
    );
    const token = String(
      this.sessionStorage.getItem(storageKeys.propertyTokenToApply)
    );

    return this.store.select(fromAppState.getUserData).pipe(
      filter(user => !!user?.id),
      take(1),
      switchMap(() => {
        const identityToken = this.localStorageService.getItem(
          storageKeys.identityToken
        );
        if (identityToken) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this.store.dispatch(fromAppState.CheckIdentity({ identityToken }));
        }
        return this.store.select(fromAppState.getCheckIdentityActionState);
      }),
      filter(state => !state.pending),
      take(1),
      tap(() =>
        this.store.dispatch(
          fromAppState.CheckApplicationExist({ propertyId, token })
        )
      ),
      // iif: when identityCheck fails for some reason, we do not want to get stuck. We simply skip
      // the application creation and show an error toast from identityCheck effect. We still get logged into
      // the app. We check for !error, because identityCheck has never been called and thus done state is not set.
      // This entire case should only be relevant when linking an anon application to the userProfile on Apple login
      // and when Apple proxy / hidden email address is used.
      // Note: in error case we do not delete the propertyIdToApply so that on every reload we will try again and
      // the application is not lost.
      switchMap(state =>
        iif(
          () => !state.error,
          combineLatest([
            this.store.select(fromAppState.getCheckApplicationExistActionState),
            this.store.select(fromAppState.getPropertyMatchDataActionState),
            this.store.select(
              fromAppState.getPropertyMatchDataByPropertyId(propertyId)
            )
          ]).pipe(
            filter(
              ([applicationCheck, propertyMatchCheck, propertyMatch]) =>
                propertyMatch &&
                applicationCheck.done &&
                propertyMatchCheck.done
            ),
            take(1),
            map(([, , application]) => {
              this.sessionStorage.removeItem(storageKeys.propertyIdToApply);
              this.sessionStorage.removeItem(storageKeys.propertyTokenToApply);
              this.sessionStorage.removeItem(storageKeys.identityToken);

              const registeredAsGuest = this.sessionStorage.getItem<boolean>(
                storageKeys.registeredAsGuest
              );
              const showCustomQuestions =
                application?.hasQuestions &&
                application?.hasUnansweredQuestions;

              if (showCustomQuestions && !registeredAsGuest) {
                // If you apply via a property link, then you get redirected to the expose page
                // Before that happens this guard is called, where the custom question modal can be shown
                // The same applies for the expose page
                // Then the custom question modal will be opened twice in a row, which doesn't make sense
                this.localStorageService.removeItem(
                  storageKeys.customQuestionApplicationId
                );
                const { id, status } = application;
                this.store.dispatch(
                  fromAppState.LoadPropertyMatchBeanQuestions({
                    id,
                    status
                  })
                );

                this.store
                  .select(fromAppState.getPropertyMatchQuestionsActionState)
                  .pipe(
                    filter(({ pending, done }) => !pending && done),
                    switchMap(() =>
                      forkJoin([
                        this.store
                          .select(getPropertyMatchData, {
                            id
                          })
                          .pipe(take(1)),
                        this.store
                          .select(getPropertyMatchesQuestions)
                          .pipe(take(1))
                      ])
                    ),
                    take(1)
                  )
                  .subscribe(([match, questionContainer]) => {
                    this.store.dispatch(
                      new fromAppState.OpenCustomQuestionsModal(
                        { ...match, questionContainer },
                        match.property.type === PropertyType.GARAGE
                      )
                    );
                  });
              }

              this.sessionStorage.removeItem(storageKeys.registeredAsGuest);

              return true;
            })
          ),
          of(true)
        )
      )
    );
  }
}
