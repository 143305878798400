import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quotaStyle',
  standalone: true
})
export class QuotaStylePipe implements PipeTransform {
  transform(amount: number, numberAlwaysVisible: boolean): string {
    if (amount > 0) {
      return 'positive';
    }

    if (amount === 0 && numberAlwaysVisible) {
      return 'yellow';
    }

    return 'negative';
  }
}
