import { ITPSettings } from '@ui/shared/models';

export const defaultAdvertising: ITPSettings = {
  shopCard: true,
  schufaCard: true,
  milesCard: true,
  wechselPilotCard: true,
  whitelabel: false,
  dmvCard: true,
  allowUserToLeaveItp: false,
  showCreditScreeningDocumentUploadArea: true,
  movingChecklist: true,
  movingAuction: true,
  informalLanguage: false
};

export const showAssistant = (
  schufaCard = defaultAdvertising.schufaCard,
  shopCard = defaultAdvertising.shopCard,
  movingChecklist = defaultAdvertising.movingChecklist,
  movingAuction = defaultAdvertising.movingAuction,
  milesCard = defaultAdvertising.milesCard,
  wechselPilotCard = defaultAdvertising.wechselPilotCard
): boolean => {
  return (
    schufaCard ||
    shopCard ||
    movingChecklist ||
    movingAuction ||
    milesCard ||
    wechselPilotCard
  );
};
