import {
  SmartDepositApplicationStatus,
  SmartDepositLegitimationStatus,
  SmartDepositStatus
} from '@ui/shared/models';
import { NAVIGATION_LINK } from './navigation-links.config';

export type SmartDepositRedirectConfig = {
  status?: SmartDepositStatus[];
  applicationStatus?: SmartDepositApplicationStatus[];
  legitimationStatus?: SmartDepositLegitimationStatus[];
  redirectUrl: NAVIGATION_LINK;
};

export type SmartDepositTranslationConfig = {
  status?: SmartDepositStatus[];
  applicationStatus?: SmartDepositApplicationStatus[];
  legitimationStatus?: SmartDepositLegitimationStatus[];
  title?: string;
  image?: string;
  text?: string;
  showReturnButton?: boolean;
  showDownloadButton?: boolean;
  showPriceTable?: boolean;
  showLegitimationButton?: boolean;
  showCancelApplicationButton?: boolean;
  showCancelLegitimationButton?: boolean;
};

export const smartDepositRedirectConfig: SmartDepositRedirectConfig[] = [
  {
    applicationStatus: [
      SmartDepositApplicationStatus.OFFER_REQUESTED,
      SmartDepositApplicationStatus.OFFER,
      SmartDepositApplicationStatus.APPLICATION_SUBMITTED,
      SmartDepositApplicationStatus.DOCUMENTS_READY,
      SmartDepositApplicationStatus.LEGITIMATION,
      SmartDepositApplicationStatus.BILLING
    ],
    redirectUrl: NAVIGATION_LINK.PAYMENT_SMARTDEPOSIT_CONTRACT
  },
  {
    applicationStatus: [
      SmartDepositApplicationStatus.NO_OFFER,
      SmartDepositApplicationStatus.REJECTED,
      SmartDepositApplicationStatus.CANCELED,
      SmartDepositApplicationStatus.FINISHED
    ],
    redirectUrl: NAVIGATION_LINK.PAYMENT_SMARTDEPOSIT_RESULT
  },
  {
    status: [SmartDepositStatus.INVITED, SmartDepositStatus.DECLINED],
    redirectUrl: NAVIGATION_LINK.PAYMENT_SMARTDEPOSIT_APPLICATION
  }
];

export const smartDepositTranslationConfig: SmartDepositTranslationConfig[] = [
  {
    // "oops"
    status: [SmartDepositStatus.ERROR],
    applicationStatus: [SmartDepositApplicationStatus.ERROR],
    title: 'smart-deposit.error_page.heading',
    image: '/assets/images/illustrations/Failure.svg',
    text: 'smart-deposit.error_page.description_m',
    showReturnButton: true
  },
  {
    // "beantragung wird geprüft"
    applicationStatus: [
      SmartDepositApplicationStatus.OFFER_REQUESTED,
      SmartDepositApplicationStatus.OFFER
    ],
    title: 'smart-deposit.application.inspect_offer_page.heading',
    image: '/assets/images/illustrations/Organizing_Projects.svg',
    text: 'smart-deposit.application.inspect_offer_page.description_m',
    showCancelApplicationButton: true
  },
  {
    // "prüfung erfolgt"
    applicationStatus: [SmartDepositApplicationStatus.APPLICATION_SUBMITTED],
    title: 'smart-deposit.application.generating_documents_page.heading',
    image: '/assets/images/illustrations/Online_Payment.svg',
    text: 'smart-deposit.application.generating_documents_page.description_m',
    showPriceTable: true,
    showDownloadButton: true,
    showLegitimationButton: true
  },
  {
    // "prüfung erfolgt" + contract download
    applicationStatus: [SmartDepositApplicationStatus.DOCUMENTS_READY],
    legitimationStatus: [SmartDepositLegitimationStatus.CANCELED],
    title: 'smart-deposit.application.documents_ready_page.heading',
    image: '/assets/images/illustrations/Online_Payment.svg',
    text: 'smart-deposit.application.documents_ready_page.description_m',
    showPriceTable: true,
    showDownloadButton: true,
    showLegitimationButton: true
  },
  {
    // "prüfen sie ihre email um zu legitimieren"
    applicationStatus: [SmartDepositApplicationStatus.LEGITIMATION],
    legitimationStatus: [SmartDepositLegitimationStatus.STARTED],
    title: 'smart-deposit.application.start_legitimation_page.heading',
    image: '/assets/images/illustrations/Sign_Contract.svg',
    text: 'smart-deposit.application.start_legitimation_page.description_m',
    showDownloadButton: true,
    showCancelLegitimationButton: true
  },
  {
    // "legitimation gestartet, legitimation wird geprüft"
    applicationStatus: [SmartDepositApplicationStatus.BILLING],
    legitimationStatus: [SmartDepositLegitimationStatus.FINISHED],
    title: 'smart-deposit.application.billing_page.heading',
    image: '/assets/images/illustrations/Sign_Contract.svg',
    text: 'smart-deposit.application.billing_page.description_m',
    showDownloadButton: true
  },
  {
    // "prozess abgebrochen"
    applicationStatus: [SmartDepositApplicationStatus.CANCELED],
    title: 'smart-deposit.application.process_canceled_page.heading',
    image: '/assets/images/illustrations/Done.svg',
    text: 'smart-deposit.application.process_canceled_page.description_m',
    showReturnButton: true
  },
  {
    // "abgelehnt"
    applicationStatus: [
      SmartDepositApplicationStatus.NO_OFFER,
      SmartDepositApplicationStatus.REJECTED
    ],
    title: 'smart-deposit.application.rejected_page.heading',
    image: '/assets/images/illustrations/Bankruptcy.svg',
    text: 'smart-deposit.application.rejected_page.description_m',
    showReturnButton: true
  },
  {
    // "herzlichen glückwunsch"
    applicationStatus: [SmartDepositApplicationStatus.FINISHED],
    title: 'smart-deposit.application.congratulation_page.heading',
    image: '/assets/images/illustrations/Certification.svg',
    text: 'smart-deposit.application.congratulation_page.description_m'
  }
];
