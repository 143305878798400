import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { errorMessageParser, getResponseValidator } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { ModalService } from 'libs/components/legacy/modal';
import { HierarchicalQuestionService } from 'libs/services';

import { CustomQuestionFacade } from 'tenant-pool/core/services';
import { notificationConfig as notification } from 'tenant-pool/config';
import { CustomQuestionsModalComponent } from 'tenant-pool/components/custom-questions-modal/custom-questions-modal.component';

import { OpenCompleteModal } from 'tenant-pool/+state';
import * as fromReducers from './custom-questions.reducers';
import * as fromSelectors from './custom-questions.selectors';
import * as fromActions from './custom-questions.actions';

@Injectable()
export class CustomQuestionsEffects {
  private store = inject<Store<fromReducers.CustomQuestionsState>>(Store);
  private actions$ = inject(Actions);
  private customQuestionFacade = inject(CustomQuestionFacade);
  private hierarchicalQuestionService = inject(HierarchicalQuestionService);
  private modalService = inject(ModalService);

  saveCustomQuestionAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SaveCustomQuestionsAnswers>(
        fromActions.SAVE_CUSTOM_QUESTIONS_ANSWERS
      ),
      switchMap(({ answers }) =>
        this.customQuestionFacade.saveAnswers(answers).pipe(
          tap(getResponseValidator()),
          map(() => new fromActions.SaveCustomQuestionsAnswersSuccess()),
          catchError(err => [
            new fromActions.SaveCustomQuestionsAnswersFail(err && err.message),
            new fromBaseState.ShowError(
              notification.customQuestions.answer.error
            )
          ])
        )
      )
    )
  );

  loadCustomQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadCustomQuestions>(
        fromActions.LOAD_CUSTOM_QUESTIONS
      ),
      switchMap(({ id }) =>
        this.customQuestionFacade.getCustomQuestions(`${id}`).pipe(
          mergeMap(customQuestionContainer => [
            new fromActions.LoadCustomQuestionsSuccess(
              customQuestionContainer.customQuestions
            ),
            new fromActions.LoadHierarchicalQuestionsSuccess(
              this.hierarchicalQuestionService.getAnsweredRootQuestions(
                customQuestionContainer.questions
              )
            )
          ]),
          catchError(err => [
            new fromActions.LoadCustomQuestionsFail(err),
            new fromBaseState.ShowError(
              notification.customQuestions.questions.error
            )
          ])
        )
      )
    )
  );

  loadQuestionByCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadQuestionsByCustomer>(
        fromActions.LOAD_QUESTIONS_BY_CUSTOMER
      ),
      switchMap(({ id }) =>
        this.customQuestionFacade.getQuestionsByCustomer(`${id}`).pipe(
          mergeMap(customQuestionContainer => [
            new fromActions.LoadCustomQuestionsSuccess(
              customQuestionContainer.customQuestions
            ),
            new fromActions.LoadHierarchicalQuestionsSuccess(
              this.hierarchicalQuestionService.getAnsweredRootQuestions(
                customQuestionContainer.questions
              )
            ),
            new fromActions.LoadCustomerQuestionsSuccess(
              customQuestionContainer.questions
            )
          ]),
          catchError(err => [
            new fromActions.LoadCustomQuestionsFail(err),
            new fromBaseState.ShowError(
              notification.customQuestions.questions.error
            )
          ])
        )
      )
    )
  );

  loadQuestionsAndAnswersByCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadQuestionsAndAnswersByCustomer>(
        fromActions.LOAD_QUESTIONS_AND_ANSWERS_BY_CUSTOMER
      ),
      switchMap(({ id }) =>
        this.customQuestionFacade
          .getQuestionsAndAnswersByCustomer(`${id}`)
          .pipe(
            mergeMap(customQuestionContainer => [
              new fromActions.LoadQuestionsAndAnswersByCustomerSuccess(
                this.hierarchicalQuestionService.getAnsweredRootQuestions(
                  customQuestionContainer.questions
                )
              )
            ]),
            catchError(err => [
              new fromActions.LoadCustomQuestionsFail(err),
              new fromBaseState.ShowError(
                notification.customQuestions.questions.error
              )
            ])
          )
      )
    )
  );

  hierarchicalRootQuestionResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.HierarchicalQuestionResponse>(
        fromActions.HIERARCHICAL_ROOT_QUESTION_RESPONSE
      ),
      switchMap(({ response }) =>
        this.customQuestionFacade
          .hierarchicalRootQuestionResponse(response)
          .pipe(
            mergeMap(rootQuestions => [
              new fromActions.HierarchicalQuestionResponseSuccess(
                rootQuestions
              ),
              new fromBaseState.ShowInfo(
                notification.customQuestions.answer.success
              )
            ]),
            catchError(error => [
              new fromActions.HierarchicalQuestionResponseFail(
                new Error(errorMessageParser(error))
              )
            ])
          )
      )
    )
  );

  openCustomQuestionModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.OpenCustomQuestionsModal>(
          fromActions.OPEN_CUSTOM_QUESTIONS_MODAL
        ),
        withLatestFrom(
          this.store.select(fromSelectors.isHierarchicalQuestionsModalOpen)
        ),
        tap(
          ([
            { propertyMatchBean, openSecondModalForGarageProperties },
            isOpen
          ]) => {
            const questions = propertyMatchBean.questionContainer?.questions
              ? this.hierarchicalQuestionService.getAnsweredRootQuestions(
                  propertyMatchBean.questionContainer.questions
                )
              : [];

            if (isOpen) return;

            if (
              !questions?.length &&
              !propertyMatchBean.questionContainer?.customQuestions?.length
            ) {
              if (!openSecondModalForGarageProperties) return;

              this.store.dispatch(
                OpenCompleteModal({ showGenericModal: true })
              );
            }

            this.modalService.open<CustomQuestionsModalComponent>(
              CustomQuestionsModalComponent,
              {
                backdrop: 'static',
                keyboard: false,
                data: {
                  propertyMatchBean,
                  questionContainer: {
                    ...propertyMatchBean.questionContainer,
                    questions
                  },
                  openSecondModalForGarageProperties
                }
              }
            );
          }
        )
      ),
    { dispatch: false }
  );
}
