import { Component, Input } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';
import { defaultDocumentsConfig } from 'libs/config';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { ComponentsModule } from '../../../components/components.module';

@Component({
  selector: 'app-self-disclosure-document',
  templateUrl: './self-disclosure-document.component.html',
  styleUrls: ['./self-disclosure-document.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    NgClass,
    TranslateModule
  ]
})
export class SelfDisclosureDocumentComponent {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() form: FormGroup;

  public acceptedDocumentsTypes = defaultDocumentsConfig.allAcceptedTypes;
  public documentsMaxSize = 1024 * 1024 * 20;

  public get getFormControl() {
    return this.form.get('answer') as FormGroup;
  }

  public get getUploadFormControl() {
    return this.form.get('upload') as FormGroup;
  }

  public get isDocumentValid() {
    return (
      !this.getUploadFormControl.valid && this.getUploadFormControl.touched
    );
  }
}
