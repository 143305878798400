import { Directive, Input, TemplateRef, inject } from '@angular/core';

@Directive({
  selector: '[appSideModalContent]',
  standalone: true
})
export class SideModalContentDirective {
  template = inject<TemplateRef<any>>(TemplateRef);

  @Input() name: string;
  @Input() navName: string;
}
