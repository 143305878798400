import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  model,
  Output,
  QueryList,
  ViewChild,
  inject
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { slideAnimation, SlideAnimationStateEnum } from 'libs/utils';
import {
  NgbNav,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase
} from '@ng-bootstrap/ng-bootstrap';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ElevationDirective } from 'libs/directives';
import { ButtonComponent } from '../../atoms/button/button.component';
import { SideSheetContentDirective } from './side-sheet-content.directive';

@UntilDestroy()
@Component({
  selector: 'app-side-sheet',
  templateUrl: './side-sheet.component.html',
  styleUrls: ['./side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideAnimation],
  standalone: true,
  imports: [
    NgClass,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgTemplateOutlet,
    ButtonComponent,
    TranslateModule,
    ElevationDirective
  ]
})
export class SideSheetComponent implements AfterViewInit {
  private _router = inject(Router);
  private cd = inject(ChangeDetectorRef);

  @Input() isPending: boolean;
  @Input() isFormValid = true;
  @Input() singleSubmitButton: string;
  @Input() hasClosingCheck: boolean;
  @Input() isLayer = false;
  @Input() disableActions: boolean;
  currentFormIndex = model(0);
  @Input() showDefaultFooterButtons = true;
  @Input() showCustomFooterButtons = false;
  @Input() showEditButton = false;
  @Input() disableNext = false;
  @Input() preventNext = false;
  @Input() useFullWidth = false;

  @ViewChild('nav') nav: NgbNav;
  @ViewChild('navigation') el: ElementRef;

  @Output() currentFormIdChange = new EventEmitter<string>();
  @Output() completeFlow = new EventEmitter();
  @Output() closeSideSheet = new EventEmitter();
  @Output() editEntry = new EventEmitter();
  @Output() nextStepClicked = new EventEmitter<number>();
  @ContentChildren(SideSheetContentDirective)
  contents: QueryList<SideSheetContentDirective>;

  public leftFade = false;
  public rightFade = true; // right fade set to true initially to prevent delay before setting to false

  public activeNav = 'nav-0';

  public slideAnimationStateEnum = SlideAnimationStateEnum;

  constructor() {
    toObservable(this.currentFormIndex)
      .pipe(untilDestroyed(this))
      .subscribe(currentFormIndex => {
        this.selectSpecificTab(currentFormIndex);
        this.scrollIntoView(currentFormIndex);
      });
  }

  public get activeNavIndex(): number {
    return parseInt(this.activeNav.slice(-1));
  }

  public ngAfterViewInit(): void {
    this.nav.activeIdChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.currentFormIndex.set(this.activeNavIndex);
      this.currentFormIdChange.emit(
        this.contents.get(this.activeNavIndex).identifier
      );
      this.scrollIntoView(this.activeNavIndex);
    });

    this.currentFormIndex() ? this.switchToTab(this.currentFormIndex()) : null;

    this.focusFirstTabForKeyNavigation();

    // check whether nav has overflow and add fade if it does
    const target = this.el.nativeElement as HTMLElement;
    if (target.scrollWidth > target.offsetWidth) {
      this.rightFade = true;
      this.leftFade = false;
    } else {
      this.leftFade = false;
      this.rightFade = false;
    }
  }

  public get isNextStepDisabled() {
    return (
      !this.isFormValid ||
      this.isPending ||
      this.disableActions ||
      this.disableNext
    );
  }

  public get hasSteps(): boolean {
    return this.singleSubmitButton ? false : true;
  }

  public dismiss(): void {
    if (!this.hasClosingCheck) {
      if (!this.isLayer) {
        void this._router.navigate(['', { outlets: { side: null } }]);
      } else {
        this.isLayer = false;
      }
    }
    this.closeSideSheet.emit();
  }

  public save(): void {
    this.completeFlow.emit();
  }

  public editEntryClicked() {
    this.editEntry.emit();
  }

  /**
   * Used for navigating back and forth between the tabs
   * @param index
   */
  public switchToTab(index: number): void {
    const navIndex = index + this.activeNavIndex;
    this.nextStepClicked.emit(navIndex);
    if (!this.disableActions) {
      if (!this.preventNext || (this.preventNext && index === -1)) {
        this.nav.select(`nav-${navIndex}`);
        this.scrollIntoView(index);
        this.cd.detectChanges();
      }
    }
  }

  public selectSpecificTabByIdentifier(identifier: string): void {
    if (!this.disableActions) {
      let index = 0;
      // no findIndex() possible for queryLists, loop through
      for (let i = 0; i < this.contents.length; i++) {
        index = i;
        if (this.contents.get(i).identifier === identifier) {
          break;
        }
      }
      this.selectSpecificTab(index);
    }
  }

  /**
   * Used to set a specific tab index
   * @param index
   */
  public selectSpecificTab(index: number): void {
    if (!this.disableActions) {
      this.nav.select(`nav-${index}`);
      this.cd.detectChanges();
    }
  }

  private focusFirstTabForKeyNavigation() {
    const firstTab = this.el.nativeElement.childNodes[0] as HTMLElement;
    firstTab ? firstTab.focus() : null;
  }

  public scrollIntoView(index: number): void {
    const target = this.el.nativeElement.children[index] as HTMLElement;
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  public onHorizontalScroll(): void {
    const target = this.el.nativeElement as HTMLElement;
    const edgeTolerance = 10;
    // remove fade if scroll is near edges
    if (target.scrollLeft < edgeTolerance) {
      this.leftFade = false;
    } else if (
      target.scrollLeft >=
      target.scrollWidth - target.offsetWidth - edgeTolerance
    ) {
      this.rightFade = false;
    } else {
      this.leftFade = true;
      this.rightFade = true;
    }
  }

  public onWheelScroll(event: WheelEvent): void {
    const target = this.el.nativeElement as HTMLElement;

    if (!event.deltaY) {
      return; // keep horizontal scrolling same
    }

    // transform vertical scroll to horizontal scroll
    target.scrollLeft += event.deltaY + event.deltaX;
  }
}
