import { createReducer, on } from '@ngrx/store';
import { RentDepositInfo } from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import * as fromActions from './rent-deposit.actions';

export interface RentDepositState {
  currentStepNumber: number;
  rentDepositInfo: RentDepositInfo;
  useRuvDepositActionState: ActionState;
}

export const initialState: RentDepositState = {
  currentStepNumber: 1,
  rentDepositInfo: null,
  useRuvDepositActionState: ActionStateCreator.create()
};

export const rentDepositReducer = createReducer(
  initialState,
  on(fromActions.RentDepositWizardNextStep, state => {
    const { currentStepNumber } = state;
    return {
      ...state,
      currentStepNumber: currentStepNumber + 1
    };
  }),
  on(fromActions.RentDepositWizardPreviousStep, state => {
    const { currentStepNumber } = state;
    return {
      ...state,
      currentStepNumber: currentStepNumber > 1 ? currentStepNumber - 1 : 1
    };
  }),
  on(fromActions.RentDepositWizardGoToStep, (state, { stepNumber }) => {
    return {
      ...state,
      currentStepNumber: stepNumber
    };
  }),
  on(fromActions.RentDepositWizardReset, state => {
    return {
      ...state,
      currentStepNumber: 1,
      useRuvDepositActionState: ActionStateCreator.create()
    };
  }),
  on(fromActions.UseRuvDeposit, state => {
    return {
      ...state,
      useRuvDepositActionState: ActionStateCreator.onStart()
    };
  }),
  on(fromActions.UseRuvDepositSuccess, (state, { rentDepositInfo }) => {
    return {
      ...state,
      rentDepositInfo,
      useRuvDepositActionState: ActionStateCreator.onSuccess()
    };
  }),
  on(fromActions.UseRuvDepositFail, (state, { error }) => {
    return {
      ...state,
      useRuvDepositActionState: ActionStateCreator.onError(error)
    };
  })
);

export const getCurrentWizardStep = (state: RentDepositState) =>
  state.currentStepNumber;

export const getRuvActionState = (state: RentDepositState) =>
  state.useRuvDepositActionState;

export const getRentDepositInfo = (state: RentDepositState) =>
  state.rentDepositInfo;
