import {
  SystemTag,
  ContactTag,
  TagOwnershipType,
  PropertiesTag
} from '@ui/shared/models';

export function sortTags(tags: ContactTag[] | PropertiesTag[]) {
  return [...(tags || [])].sort((a, b) => {
    if (a?.tagOwnershipType === b?.tagOwnershipType) {
      return a?.title.toUpperCase() > b?.title.toUpperCase() ? 1 : -1;
    }
    return (
      Object.values(TagOwnershipType).indexOf(a.tagOwnershipType) -
      Object.values(TagOwnershipType).indexOf(b.tagOwnershipType)
    );
  });
}

export function prioritizeSystemTags(
  tags: SystemTag[],
  prioritizedType: SystemTag
): SystemTag[] {
  if (tags?.includes(prioritizedType)) {
    tags = tags?.sort((a, b) => {
      if (a === prioritizedType) return -1;
      if (b === prioritizedType) return 1;
      return 0;
    });
  }
  return tags;
}
