<div>
  <form [formGroup]="form">
    <div class="row mt20">
      <div class="col-12">
        {{ 'digital_contract.sign_wizard.provide_iban_text_l' | translate }}
      </div>
    </div>
    <div class="row mb20">
      <div class="col-12">
        {{ 'digital_contract.sign_wizard.provide_iban_explain_l' | translate }}
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-form-field>
          <app-form-field-label>{{
            'general.iban_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="text"
            [required]="true"
            [placeholder]="'forms.type_iban' | translate"
            formControlName="iban"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-12 mt20">
        <app-form-field>
          <app-form-field-label>{{
            'general.birthdate_l' | translate
          }}</app-form-field-label>
          <app-date
            formControlName="dateOfBirth"
            [required]="true"
            [minDate]="minBirthDate"
            [maxDate]="maxBirthDate"
          >
          </app-date>
        </app-form-field>
      </div>
    </div>

    <div class="mt30">
      @if (isRestart) {
        <div class="row">
          <div class="verification-error col-12">
            <div class="verification-error__header">
              {{
                'digital_contract.sign_wizard.id_error_iban_verification_l'
                  | translate
              }}
            </div>
            <div>
              {{
                'digital_contract.sign_wizard.id_restart_iban_verification_l'
                  | translate
              }}
            </div>
          </div>
        </div>
      }
      @if (isCancel) {
        <div class="row">
          <div class="verification-error col-12">
            <div class="verification-error__header">
              {{ 'digital_contract.sign_wizard.id_cancel_l' | translate }}
            </div>
            <div>
              {{
                'digital_contract.sign_wizard.id_cancel_contract_landlord_l'
                  | translate
              }}
            </div>
          </div>
        </div>
      }
      @if (isErrorMaxTries) {
        <div class="row">
          <div class="verification-error col-12">
            <div class="verification-error__header">
              {{
                'digital_contract.sign_wizard.id_error_iban_max_tries_l'
                  | translate
              }}
            </div>
            <div>
              {{
                'digital_contract.sign_wizard.id_error_contract_landlord_l'
                  | translate
              }}
            </div>
          </div>
        </div>
      }
      @if (isError) {
        <div class="row">
          <div class="verification-error col-12">
            <div class="verification-error__header">
              {{ 'digital_contract.sign_wizard.id_error_l' | translate }}
            </div>
          </div>
        </div>
      }
      @if (isItpError) {
        <div class="row">
          <div class="verification-error col-12">
            <div class="verification-error__header">
              {{ 'digital_contract.sign_wizard.itp_error_l' | translate }}
            </div>
            <div>
              {{
                'digital_contract.sign_wizard.id_error_contract_landlord_l'
                  | translate
              }}
            </div>
          </div>
        </div>
      }
    </div>
  </form>
</div>
