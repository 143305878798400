import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject
} from '@angular/core';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { RegexTypes } from 'libs/utils';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ImageComponent } from '../../atoms/image/image.component';

@Component({
  selector: 'app-landlord-info',
  templateUrl: './landlord-info.component.html',
  styleUrls: ['./landlord-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, ImageComponent, TranslateModule]
})
export class LandlordInfoComponent {
  private themeService = inject(ThemeService);

  @Input() name: string;
  @Input() logo: string;
  @Input() logoRedirectUrl: string;
  @Input() showOfferedBy = true;
  @Input() offeredByTitle: string;
  @Input() showInCooperationWith = true;

  public baseClass = 'landlord-info';

  public get withLinkedLogo(): boolean {
    return !!RegexTypes.URL.exec(this.logoRedirectUrl);
  }

  public get showInCooperationWithImmomio(): boolean {
    return !!this.logo;
  }

  public get customerLogo(): string {
    return (
      this.logo ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }
}
